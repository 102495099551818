import { FormEvent, useCallback, useContext, useState } from 'react'
import Papa from 'papaparse'
import getDatasetFields from 'apollo/requests/getDatasetFields'
import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'
import { trackEvent } from '@utils/metrics'
import UploadContext from '@contexts/UploadContext'
import { VALID_CSV_TYPES } from '@utils/dataset'
import type { DatasetFileData } from 'models/Dataset'
import { CheckList, ValidationFormat } from './constants'

const useLogic = () => {
  const { addFileToDatasetPayload, modalType, onCloseModal } = useModal()
  const uploadContext = useContext(UploadContext)
  const [formatConditions, setFormatConditions] = useState(CheckList)
  const [error, setError] = useState('')
  const [datasetFileData, setDatasetFileData] =
    useState<DatasetFileData | null>(null)
  const [fileInputRef, setFileInputRef] = useState<HTMLInputElement | null>(
    null,
  )

  const modalPayload = addFileToDatasetPayload

  const handleOnCloseModal = useCallback(() => {
    if (modalPayload.onClose) {
      modalPayload.onClose()
    }

    onCloseModal()
  }, [onCloseModal, modalPayload])

  const handleAssignInputRef = useCallback((el: HTMLInputElement) => {
    if (el) {
      setFileInputRef(el)
    }
  }, [])

  const handleCleanErrors = useCallback(() => {
    setError('')
    setFormatConditions(prev =>
      prev.map(item => ({ ...item, hasError: false })),
    )
  }, [])

  const handleApplyError = useCallback((errorType: ValidationFormat) => {
    setFormatConditions(prev =>
      prev.map(item =>
        item.key === errorType ? { ...item, hasError: true } : item,
      ),
    )
    switch (errorType) {
      case ValidationFormat.FILE_FORMAT:
        setError('Debes seleccionar un archivo valido')
        break
      case ValidationFormat.SAME_COLUMNS:
        setError('El archivo debe contener las mismas columnas')
        break
      case ValidationFormat.ONE_FILE:
        setError('Selecciona un único archivo')
        break
      default:
        setError(
          'Ha ocurrido un problema al validar el archivo, contacte con soporte.',
        )
        break
    }
  }, [])

  const handleOpenExplorerFiles = useCallback(() => {
    if (fileInputRef) {
      // @ts-ignore
      fileInputRef.click()
    }
  }, [fileInputRef])

  const handleChangeFile = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const files: FileList = event.currentTarget.files
      if (!files || !files.length) {
        trackEvent('Upload: file invalid')

        handleApplyError(ValidationFormat.ONE_FILE)
        return
      }

      // Handle case where Windows passes an empty type
      const fileType = files[0].type
      if (fileType && !VALID_CSV_TYPES.includes(fileType)) {
        handleApplyError(ValidationFormat.FILE_FORMAT)
        return
      }

      if (files[0]) {
        setDatasetFileData(prev => ({
          ...prev,
          headerError: false,
          file: { error: false },
        }))
        try {
          Papa.parse(files[0], {
            preview: 2,
            complete: results => {
              if (!results.data[0]) {
                trackEvent('Upload: file invalid')
                throw new Error('No hay columnas para seleccionar')
              }

              if (results.data[0].length === 1) {
                const headers = results.data[0][0]
                const possibleDelimiters = [',', ';']
                let delimiter = possibleDelimiters[0]
                let score = 1
                for (const del of possibleDelimiters) {
                  const tempScore = headers.split(del).length
                  if (tempScore > score) {
                    score = tempScore
                    delimiter = del
                  }
                }

                if (score !== 1) {
                  Papa.parse(files[0], {
                    preview: 2,
                    delimiter,
                    complete: results2 => {
                      setDatasetFileData(prev => ({
                        ...prev,
                        header: results2.data[0],
                        file: files[0],
                        firstRows: results.data[1],
                        delimiter: results2.meta.delimiter,
                      }))
                      handleCleanErrors()
                      return results2.data
                    },
                  })
                  return null
                }
              }

              setDatasetFileData(prev => ({
                ...prev,
                header: results.data[0],
                file: files[0],
                firstRows: results.data[1],
                rowsSampleToValidate: results.data?.slice(1) || [],
                delimiter: results.meta.delimiter,
              }))

              handleCleanErrors()
              return results.data
            },
          })
        } catch (err) {
          handleApplyError(ValidationFormat.DEFAULT)
        }
      }
    },
    [handleCleanErrors, handleApplyError],
  )

  const handleUploadFile = useCallback(async () => {
    let datasetFields = await getDatasetFields({
      projectId: modalPayload?.projectId,
      datasetId: modalPayload?.datasetId,
    })

    if (datasetFields) {
      let fieldsToValidate = [
        ...datasetFields.contentColumnsKeys,
        ...datasetFields.documentFieldKeys,
      ]

      const withDate = !!datasetFields.hasDate
      const withLocation = !!datasetFields.hasLocation

      if (withDate || withLocation) {
        datasetFields = await getDatasetFields({
          projectId: modalPayload?.projectId,
          datasetId: modalPayload?.datasetId,
          withDate,
          withLocation,
        })
      }

      if (withDate) {
        fieldsToValidate.push(datasetFields.dateKey)
      }
      if (withLocation) {
        fieldsToValidate.push(datasetFields.locationKey)
      }

      const matchHeaders =
        datasetFileData.header &&
        datasetFileData.header.filter(key => fieldsToValidate.includes(key))

      // remove empty fields
      fieldsToValidate = fieldsToValidate.filter(Boolean)

      if (matchHeaders && matchHeaders.length === fieldsToValidate.length) {
        uploadContext.startUpload(
          datasetFields.id,
          datasetFileData.file,
          datasetFileData.delimiter,
        )
        handleOnCloseModal()
      } else {
        handleApplyError(ValidationFormat.SAME_COLUMNS)
      }
    }
  }, [
    datasetFileData,
    handleApplyError,
    handleOnCloseModal,
    modalPayload,
    uploadContext,
  ])

  return {
    datasetFileData,
    datasetName: modalPayload?.dataseName || '',
    error,
    formatConditions,
    handleAssignInputRef,
    handleChangeFile,
    handleOnCloseModal,
    handleOpenExplorerFiles,
    handleUploadFile,
    isOpen: modalType === ModalType.ADD_DATASET_FILE,
  }
}

export default useLogic
