import { gql } from '@apollo/client'

export const TEAM_FRAGMENT = gql`
  fragment TeamData on Team {
    id
    name
    client {
      id
      name
    }
  }
`
