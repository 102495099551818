import { memo, FC } from 'react'
import { formatNumber } from '@utils/format'
import { Props } from './types'
import { Container, StyledIcon, Value } from './styles'

const EvolutionArrow: FC<Props> = ({
  className,
  value = 0,
  monochrome,
  size = 'small',
}) => {
  return (
    <Container className={className}>
      <StyledIcon
        $value={value}
        $monochrome={monochrome}
        name="evolution"
        size={8}
      />
      <Value $size={size}>
        {formatNumber(Math.abs(value), { shorten: true })}%
      </Value>
    </Container>
  )
}

export default memo(EvolutionArrow)
