import { FC } from 'react'
import Loadable from 'react-loadable'
import Loading from '@components/Loading'

/**
 *
 * @deprecated TODO remove LoadableComponent
 */
const LoadableComponent = Loadable({
  loader: () => import('@views/NewProject/NewProject'),
  // @ts-ignore
  loading: Loading,
  delay: 200,
  timeout: 10000,
})

const LoadableNewProject: FC = props => <LoadableComponent {...props} />

export default LoadableNewProject
