import { FC, memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as S from './styles'
import { Props } from './types'

const Link: FC<Props> = ({ children, external, to, size = 16, ...rest }) => {
  return (
    <>
      {external ? (
        <S.A href={to} target="_blank" rel="noopener noreferrer">
          <S.Link $size={size}>{children}</S.Link>
        </S.A>
      ) : (
        <RouterLink to={to} {...rest}>
          <S.Link $size={size}>{children}</S.Link>
        </RouterLink>
      )}
    </>
  )
}

export default memo(Link)
