import { FormEvent, useCallback, useEffect, useState } from 'react'
import type { FilterFormState, LogicProps } from './types'
import { getOr, has, isArray, isEmpty, omit, set } from 'lodash/fp'
import { trackEvent } from '@utils/metrics'

// TODO these methods have just been moved from one file to another, we need to check them.
const useLogic = ({
  filters,
  filtersToShow,
  openState,
  handleChangeFilters,
  handleClickOpenFilters,
}: LogicProps) => {
  const [formState, setFormState] = useState<FilterFormState>({
    selectedFilters: filters,
    activeItem: '',
  })

  useEffect(() => {
    setFormState(set('selectedFilters', filters))
  }, [filters])

  // Metrics
  const trackMetrics = useCallback((filters: any) => {
    const filterChanges: string[] = []
    const omitMetrics = ['from', 'to']
    Object.keys(omit(omitMetrics, filters)).forEach(filter => {
      if (filters[filter]) {
        // Get the keys
        if (typeof filters[filter] === 'object') {
          Object.keys(filters[filter]).forEach(key => {
            const length = getOr(0, 'length', filters[filter][key])
            filterChanges.push(`${filter}-${key}-${length}`)
          })
        } else {
          filterChanges.push(filter)
        }
      }
    })

    trackEvent('User use Advance Filters', { filterChanges })
  }, [])

  // updates properties in component state
  const handleChange = useCallback(
    (type: string, operator: string, value: string, index?: number) => {
      const path =
        index !== undefined
          ? ['selectedFilters', type, operator, index]
          : ['selectedFilters', type, operator]

      return setFormState(set(path, isEmpty(value) ? undefined : value))
    },
    [],
  )

  const formatFilter = useCallback(
    (type, operator, value, index = undefined) => {
      const flatValues = isArray(value) ? value.map(v => v.value) : value

      // update state with parsed values
      handleChange(type, operator, flatValues, index)
    },
    [handleChange],
  )

  // Checks which group is selected by comparing against filtersOpen in context
  const getSelectedGroup = useCallback(
    () => filtersToShow.find(g => g.name === openState.secondLevel),
    [openState, filtersToShow],
  )

  // Checks if item is active in dropdown or if filters in context contains group key
  const itemIsActive = useCallback(
    group => {
      const dropDownIsActive = group.columnKey === getSelectedGroup()?.columnKey
      const filterHasKey = has(group.columnKey, filters)

      return filterHasKey || dropDownIsActive
    },
    [getSelectedGroup, filters],
  )

  // Form submit event
  const handleSubmit = useCallback(
    (submitEvent?: FormEvent<HTMLFormElement>) => {
      if (submitEvent) {
        submitEvent.preventDefault()
      }
      trackMetrics(formState.selectedFilters)

      // output events
      handleChangeFilters(formState.selectedFilters)
      handleClickOpenFilters(openState.firstLevel, '')
    },
    [
      formState,
      handleChangeFilters,
      handleClickOpenFilters,
      openState,
      trackMetrics,
    ],
  )

  return {
    formatFilter,
    formState,
    getSelectedGroup,
    handleChange,
    handleSubmit,
    itemIsActive,
    setFormState
  }
}

export default useLogic
