import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '@contexts/Auth'
import { FiltersContext } from '@providers/FiltersProvider'
import { useParams } from 'react-router-dom'
import { getOr } from 'lodash/fp'
import { useLazyQuery } from '@apollo/client'
import { AdvancedFiltersRouteParams, FiltersConfig } from './types'
import { FILTERS_QUERY } from 'apollo/queries/dataset'
import { getCurrentKey, getFiltersToShow, keyIsGroup } from './helpers'
import useDatasetFiltersOptions from 'apollo/hooks/dataset/useDatasetFiltersOptions'

// TODO these methods have just been moved from one file to another, we need to check them.
const useLogic = () => {
  const { getPermits } = useContext(AuthContext)
  const {
    handleClickOpenFilters,
    handleChangeFilters,
    clearAllFilters,
    filtersState: { filters: filtersCTX, filtersOpen: filtersOpenCTX },
  } = useContext(FiltersContext)

  const { projectId, datasetId } = useParams<AdvancedFiltersRouteParams>()
  // Main key Used in Filters Query
  const key = getCurrentKey(filtersOpenCTX.secondLevel)
  const filtersQueryVariables = useCallback(
    (key: string) => {
      return {
        projectId,
        datasetId,
        key,
        withColumnKey: key !== 'contentFields',
        order: {
          sort: 'GROUP',
          direction: 'DESC',
        },
      }
    },
    [projectId, datasetId],
  )

  const filtersConfigRef = useRef(null)
  const [filtersConfig, setFiltersConfig] = useState<FiltersConfig>()

  const { datasetFilterOptions, datasetFilterOptionsIsLoading } = useDatasetFiltersOptions({
    datasetId,
    projectId,
    key
  })
    
  // Retrieves values for options in filters
  const [getFiltersQuery, { loading, error, data }] = useLazyQuery(
    FILTERS_QUERY,
    {
      // fetchPolicy: 'cache-first',
      fetchPolicy: 'cache-and-network',
    },
  )

  const mainFiltersQuery = useCallback(
    (queryKey: string) => {
      return getFiltersQuery({
        variables: filtersQueryVariables(queryKey),
      })
    },
    [filtersQueryVariables, getFiltersQuery],
  )

  // Main Filters Query
  useEffect(() => {
    // Default to 'category' for initial load
    const queryKey = key === '' ? 'category' : key
    const isGroup = queryKey && keyIsGroup(queryKey)

    // TODO temporary hack until filters refactor
    if (queryKey && (!isGroup || queryKey !== 'Mis filtros')) {
      mainFiltersQuery(queryKey)
    }
  }, [key, mainFiltersQuery])

  // User Fields Effect Query
  useEffect(() => {
    if (data?.project && filtersConfigRef.current === null) {
      const documentFields = getOr([], 'project.dataset.documentFields', data)
      const filtersToShow = getFiltersToShow(documentFields, data, getPermits)
      filtersConfigRef.current = true

      setFiltersConfig({
        documentFields,
        filtersToShow,
      })
    }
  }, [datasetId, data, getPermits])

  // on Destroy effect
  useEffect(() => {
    return () => {
      clearAllFilters()
      handleClickOpenFilters(filtersOpenCTX.firstLevel, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isExpanded = filtersOpenCTX.firstLevel
  const handleToggleFilters = useCallback(
    () => handleClickOpenFilters(!isExpanded, filtersOpenCTX.secondLevel),
    [filtersOpenCTX.secondLevel, handleClickOpenFilters, isExpanded],
  )

  return {
    error,
    loading,
    filtersConfig,
    key,
    data,
    filtersCTX,
    filtersOpenCTX,
    isExpanded,
    handleChangeFilters,
    handleClickOpenFilters,
    mainFiltersQuery,
    handleToggleFilters,
    datasetFilterOptions,
    datasetFilterOptionsIsLoading
  }
}

export default useLogic
