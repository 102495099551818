import styled, { css } from 'styled-components'
import LinearProgressMaterial from '@material-ui/core/LinearProgress'

export const LinearProgress = styled(LinearProgressMaterial)<{ $color }>`
  && {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colours.gray[20]};
  }

  .MuiLinearProgress-bar {
    border-radius: 4px;

    ${({ theme, $color }) => {
      const colors = {
        violet: theme.colours.violet[60],
        green: theme.colors.green,
      }

      return css`
        background-color: ${colors[$color]};
      `
    }}
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const TextProgress = styled.div`
  font-size: 16px;
  font-weight: 600;
`
