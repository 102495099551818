import WebSocket from '@utils/websocket'
import { GET_CLIENTS } from 'apollo/queries/client'
import { FetchPolicy } from '@apollo/client'
import { normalizeClient } from 'models/Client'
import type {
  AllClientsQuery,
  AllClientsQueryVariables,
} from '@customTypes/graphql-public'

const getClients = async ({
  fetchPolicy = 'cache-first',
}: {
  fetchPolicy?: FetchPolicy
} = {}) => {
  const apolloClient = WebSocket.createApolloClient('graphql')
  // @ts-ignore
  const { data } = await apolloClient.query<
    AllClientsQuery,
    AllClientsQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy,
    query: GET_CLIENTS,
  })

  const response = data?.allClients

  if (!response) {
    return []
  }

  return response.map(normalizeClient)
}

export default getClients
