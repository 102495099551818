import { FC, memo } from 'react'
import type { Props } from '../types'

const DoneIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm-5.698-9.045a.446.446 0 0 1 .001-.64L5.47 9.181a.479.479 0 0 1 .66 0l1.895 1.834a.478.478 0 0 0 .66 0l5.191-5.05a.48.48 0 0 1 .66-.001l1.161 1.118a.444.444 0 0 1 .002.64l-6.295 6.123c-.182.176-.54.32-.797.32h-.543c-.257 0-.615-.144-.796-.32l-2.965-2.89z"
      fill="#6EDBA4"
    />
  </svg>
)

export default memo(DoneIcon)
