import React from 'react'
import { Props } from './types'
import * as S from './styles'

const Dropdown = ({
  anchorEl,
  children,
  handleClose,
  horizontal = 'right',
  open,
  options,
}: Props) => {
  return (
    <>
      {children}
      <S.Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal,
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal,
        }}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
          <S.MenuItem key={option.label} onClick={option.onClick}>
            {option.label}
          </S.MenuItem>
        ))}
      </S.Menu>
    </>
  )
}

export default Dropdown
