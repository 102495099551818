import { FC, memo } from 'react'
import {
  Container,
  Content,
  Logo,
  LogoContainer,
  Subtitle,
  Title,
} from './Maintenance.styles'

const MaintenanceView: FC = () => (
  <Container>
    <Content>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <Title>Estamos en mantenimiento</Title>
      <Subtitle>
        Desde el equipo de Séntisis siempre queremos ofrecer la mejor
        experiencia para nuestros clientes.
      </Subtitle>
      <Subtitle>¡Volveremos pronto!</Subtitle>
    </Content>
  </Container>
)

export default memo(MaintenanceView)
