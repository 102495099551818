import PropTypes from 'prop-types'
import classNames from 'classnames'
import './field.scss'

const propTypes = {
  // Label for the field
  label: PropTypes.string,

  // Same is as the once associated to the input. It's required!
  id: PropTypes.string.isRequired,

  // Actual field to surround
  children: PropTypes.node.isRequired,

  // Renders the field in error state when set
  error: PropTypes.string,

  // Passed tot the `Field` container
  className: PropTypes.string,

  // When > 0 shows the counter
  count: PropTypes.number,

  // Label for the count
  countLabel: PropTypes.string,

  // Description shown below the field
  description: PropTypes.string,

  // Lowers down opacity
  disabled: PropTypes.bool,
}

const defaultProps = {
  label: '',
  error: '',
  className: '',
  count: 0,
  countLabel: '',
  description: '',
  disabled: false,
}

function Field(props) {
  const fieldClassName = classNames('Field', props.className, {
    'is-error': props.error,
    'is-disabled': props.disabled,
  })

  let errorMessage
  if (props.error) {
    const html = { __html: props.error }
    /* eslint-disable react/no-danger */
    errorMessage = (
      <span className="Field__error" dangerouslySetInnerHTML={html} />
    )
    /* eslint-enable */
  }

  let counter
  if (props.count > 0) {
    counter = (
      <span className="Field__counter">
        <span className="Field__count">{props.count}</span>
        {props.countLabel}
      </span>
    )
  }

  let label
  if (props.label) {
    label = (
      <label htmlFor={props.id} className="Field__label">
        {props.label}
        {counter}
      </label>
    )
  }

  let descriptionText
  if (props.description) {
    descriptionText = (
      <span className="Field__description">{props.description}</span>
    )
  }

  return (
    <div className={fieldClassName}>
      {label}
      <div className="Field__control">{props.children}</div>
      {errorMessage || descriptionText}
    </div>
  )
}

Field.propTypes = propTypes
Field.defaultProps = defaultProps

export default Field
