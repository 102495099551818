import { FC, memo, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SentimentRow from '@components/SentimentRow'
import { FiltersContext } from '@providers/FiltersProvider'
import { encodeUrlFilters } from '@utils/filters'
import { getPercentages, toSentimentObject } from '@utils/sentiment'
import { TableCellComponentProps } from '../types'

const Sentiment: FC<TableCellComponentProps> = ({ rowData }) => {
  const { projectId, datasetId } = useParams<{
    projectId: string
    datasetId: string
  }>()

  const [sentiment, setSentiment] = useState({
    positive: 0,
    negative: 0,
    objective: 0,
  })

  const history = useHistory()

  const { addFilterToQuery, getFilterList } = useContext(FiltersContext)

  const handleSentimentClick = useCallback(
    (selectedSentiment: string) => {
      addFilterToQuery('sentiment', 'or', selectedSentiment)
      if (
        rowData.filterKey &&
        rowData.filterParam &&
        rowData[rowData.filterParam]
      ) {
        addFilterToQuery(rowData.filterKey, 'and', rowData[rowData.filterParam])
      }

      history.push(
        `/messages/${projectId}/${datasetId}?${encodeUrlFilters(
          getFilterList(),
        )}`,
      )
    },
    [addFilterToQuery, rowData, history, projectId, datasetId, getFilterList],
  )

  useEffect(() => {
    if (!rowData) {
      return null
    }

    const { count, sentimentsCount } = rowData

    const sentiments: any = getPercentages(
      toSentimentObject(sentimentsCount),
      count,
    )

    setSentiment(sentiments)
  }, [rowData])

  return (
    <SentimentRow
      sentiment={sentiment}
      rawSentiment={null}
      onClickSemtimentCell={handleSentimentClick}
      hideUnknown
      sentimentOrder={['negative', 'objective', 'positive', 'unknown']}
    />
  )
}

export default memo(Sentiment)
