import WebSocket from '@utils/websocket'
import { GET_DATASET_FIELDS } from 'apollo/queries/dataset'
import { FetchPolicy } from '@apollo/client'
import { normalizeDatasetFields } from 'models/Dataset'
import type {
  DatasetFieldsQuery,
  DatasetFieldsQueryVariables,
} from '@customTypes/graphql-public'

const getDatasetFields = async ({
  datasetId,
  fetchPolicy = 'cache-first',
  projectId,
  withDate = false,
  withLocation = false,
}: {
  datasetId: string
  fetchPolicy?: FetchPolicy
  projectId: string
  withDate?: boolean
  withLocation?: boolean
}) => {
  const apolloClient = WebSocket.createApolloClient('graphql')
  // @ts-ignore
  const { data } = await apolloClient.query<
    DatasetFieldsQuery,
    DatasetFieldsQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy,
    query: GET_DATASET_FIELDS,
    variables: {
      datasetId,
      projectId,
      withDate,
      withLocation,
    },
  })

  const response = data?.project?.dataset

  if (!response) {
    return undefined
  }

  return normalizeDatasetFields(response)
}

export default getDatasetFields
