import { useCallback, useEffect, useMemo } from 'react'
import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UpdateDatasetValidationSchema } from './constants'
import useDatasetActions from 'apollo/hooks/dataset/useDatasetActions'
import useJobActions from 'apollo/hooks/job/useJobActions'

const useLogic = () => {
  const { updateDataset, datasetActionsLoading } = useDatasetActions()
  const { updateJob, jobActionsLoading } = useJobActions()
  const { renameDatasetOrJobPayload, modalType, onCloseModal } = useModal()
  const modalPayload = renameDatasetOrJobPayload

  const initialValues = useMemo(
    () => ({
      name: modalPayload?.name || '',
    }),
    [modalPayload],
  )

  const updateDatasetForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(UpdateDatasetValidationSchema),
    defaultValues: initialValues,
  })

  useEffect(() => {
    updateDatasetForm.reset({ ...initialValues })
  }, [initialValues, updateDatasetForm])

  const handleOnCloseModal = useCallback(() => {
    if (modalPayload?.onClose) {
      modalPayload.onClose()
    }

    onCloseModal()
  }, [modalPayload, onCloseModal])

  const handleOnSubmit = useCallback(
    async ({ name }: { name: string }) => {
      if (modalPayload?.id) {
        if (modalPayload?.type === 'Dataset') {
          await updateDataset(modalPayload.id, name)
        } else {
          await updateJob(modalPayload.id, name)
        }
        handleOnCloseModal()
      }
    },
    [handleOnCloseModal, modalPayload, updateDataset, updateJob],
  )

  return {
    handleOnCloseModal,
    handleOnSubmit,
    isLoading: datasetActionsLoading || jobActionsLoading,
    isOpen: modalType === ModalType.RENAME_DATASET_OR_JOB,
    modalPayload,
    updateDatasetForm,
  }
}

export default useLogic
