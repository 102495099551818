import * as React from 'react';
import { ReactComponent as Logo } from './logo.svg'

const SentisisLogo: React.FunctionComponent<any> = props => {
  return (
    <div {...props}>
      <Logo />
    </div>
  )
}
export default SentisisLogo
