import styled, { css } from 'styled-components/macro'
import { Button as MaterialButton } from '@material-ui/core'
import DefaultIcon from '@components/Icon'
import type { Props } from './types'

export const Button = styled(MaterialButton)<{
  $hasIcon?: boolean
  $variant: Props['variant']
  $isDanger?: boolean
  $size?: Props['size']
  $padding?: Props['padding']
}>`
  &.MuiButton-contained {
    border-radius: 32px;
    box-sizing: border-box;
    font-size: 14px;

    ${({ $size, $padding }) => {
      const padding = {
        big: {
          normal: '40px',
          narrow: '20px',
        },
        medium: {
          normal: '32px',
          narrow: '32px',
        },
        small: {
          normal: '16px',
          narrow: '16px',
        },
      }

      switch ($size) {
        case 'big':
          return css`
            height: 40px;
            padding: 0 ${() => padding[$size][$padding]};
          `
        case 'medium':
          return css`
            height: 32px;
            padding: 0 ${() => padding[$size][$padding]};
          `
        case 'small':
          return css`
            height: 28px;
            padding: 0 ${() => padding[$size][$padding]};
          `
        default:
          return css`
            height: 40px;
            padding: 0 ${() => padding[$size][$padding]};
          `
      }
    }}

    ${({ $hasIcon, $variant }) => {
      if ($hasIcon && $variant === 'inline') {
        return css`
          padding: 8px 16px;
        `
      }
    }}

    ${({ theme, $isDanger, $variant }) => {
      switch ($variant) {
        case 'primary':
          return css`
            background-color: ${$isDanger
              ? theme.colours.red[70]
              : theme.colours.violet[70]};
            color: ${theme.colours.white};
          `
        case 'secondary':
          return css`
            background-color: transparent;
            border: solid 1px
              ${$isDanger ? theme.colours.red[70] : theme.colours.violet[70]};
            color: ${$isDanger
              ? theme.colours.red[70]
              : theme.colours.violet[70]};
          `
        case 'ternary':
          return css`
            background-color: ${theme.colours.turqoise[60]};
            color: ${theme.colours.white};
          `
        case 'inline':
          return css`
            background-color: unset;
            color: ${$isDanger
              ? theme.colours.red[70]
              : theme.colours.turqoise[70]};
          `
      }
    }}

    &:hover {
      ${({ theme, $isDanger, $variant }) => {
        switch ($variant) {
          case 'primary':
            return css`
              background-color: ${$isDanger
                ? theme.colours.red[80]
                : theme.colours.violet[80]};
            `
          case 'secondary':
            return css`
              background-color: ${theme.colours.gray[20]};
            `
          case 'ternary':
            return css`
              background-color: ${theme.colours.turqoise[70]};
            `
          case 'inline':
            return css`
              background-color: rgba(200, 235, 236, 0.2);
            `
        }
      }}
    }

    &:focus {
      ${({ theme, $isDanger, $variant }) => {
        switch ($variant) {
          case 'primary':
            return css`
              background-color: ${$isDanger
                ? theme.colours.red[80]
                : theme.colours.violet[80]};
            `
          case 'inline':
            return css`
              background-color: rgba(200, 235, 236, 0.6);
            `
        }
      }}
    }

    &.Mui-disabled {
      background-color: ${({ theme }) => theme.colours.gray[20]};
      color: ${({ theme }) => theme.colours.gray[80]};
    }
  }
`

export const Icon = styled(DefaultIcon).attrs(props => ({
  size: props.size || 14,
}))``

export const Separator = styled.div<{ $size: Props['size'] }>`
  background-color: ${({ theme }) => theme.colours.violet[70]};
  height: 100%;
  position: absolute;
  width: 1px;

  right: ${({ $size }) => {
    switch ($size) {
      case 'big':
        return '37px'
      case 'medium':
        return '37px'
      case 'small':
        return '26px'
      default:
        break
    }
  }};
`

export const DropdDownButtonIcon = styled(DefaultIcon)<{
  $size: Props['size']
}>`
  color: ${({ theme }) => theme.colours.violet[70]};
  position: relative;
  top: 6px;

  left: ${({ $size }) => {
    switch ($size) {
      case 'big':
        return '29px'
      case 'medium':
        return '21px'
      case 'small':
        return '11px'
      default:
        break
    }
  }};
`
