// this keys are separated from MyFilters group
import {
  FilterGroup,
  FiltersColumnKeyEnum,
} from '@components/AdvanceFilters/types'

export const excludedKeys = ['nps', 'date']

export const fixedGroups: FilterGroup[] = [
  {
    name: 'Categorías',
    columnKey: FiltersColumnKeyEnum.category,
  },
  {
    name: 'Sentimiento',
    columnKey: FiltersColumnKeyEnum.sentiment,
  },
  {
    name: 'Texto avanzado',
    columnKey: FiltersColumnKeyEnum.content,
  },
  {
    name: 'Conceptos Clave',
    columnKey: FiltersColumnKeyEnum.keyConcepts,
  },
  {
    name: 'Columnas analizadas',
    columnKey: FiltersColumnKeyEnum.contentFields,
  },
  {
    name: 'Localización',
    columnKey: FiltersColumnKeyEnum.location,
  },
]
