import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import mixpanel from 'mixpanel-browser'

import 'sentisis-components/dist/styles.css'

// Add Mixpanel metrics
mixpanel.init('3c6007a30e760e67d2bb3810b0816b7e')

ReactDOM.render(<App />, document.getElementById('root'))
