/* eslint-disable react/no-children-prop */
import * as React from 'react'
import classNames from 'classnames'
import { kebabCase } from 'lodash/fp'
import './ButtonDropdown.scss'
import SingleDropdownOption from './SingleDropdownOption'

export interface ButtonDropdownOption {
  label: string
  onClick?: () => void
  children?: React.ReactNode
  value?: string
  colorHover?: string
}

export interface ButtonDropdownProps {
  children: React.ReactNode
  className?: string
  options: ButtonDropdownOption[]

  // Shows a divider on the last option
  divider: boolean

  // Show a menu from left to right
  lefty?: boolean
}

export default class ButtonDropdown extends React.Component<
  ButtonDropdownProps,
  { isDropdownVisible: boolean }
> {
  state = {
    isDropdownVisible: false,
  }

  containerEl: any
  componentWillMount() {
    document.addEventListener('click', this.handleClickDoc, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickDoc, false)
  }

  handleClickDoc = (event: MouseEvent) => {
    if (
      this.state.isDropdownVisible &&
      !this.containerEl.contains(event.target)
    ) {
      this.handleClickToggle()
    }
  }

  handleClickToggle = () => {
    this.setState(state => ({
      isDropdownVisible: !state.isDropdownVisible,
    }))
  }

  render() {
    const dropdownClassName = classNames(
      'c-ButtonDropdown',
      this.props.className,
      {
        'is-open': this.state.isDropdownVisible,
        'has-divider': this.props.divider,
      },
    )
    const options = this.props.options.map(option => (
      <SingleDropdownOption
        key={`option-${kebabCase(option.label)}`}
        children={option.children}
        label={option.label}
        onClick={option.onClick}
        value={option.value}
        colorHover={option.colorHover}
      />
    ))

    let content
    if (this.state.isDropdownVisible) {
      let listClassName = 'c-ButtonDropdown-menu'
      if (this.props.lefty) listClassName += ' c-ButtonDropdown-menu--lefty'
      content = <ul className={listClassName}>{options}</ul>
    }

    return (
      <div
        className={dropdownClassName}
        ref={el => {
          this.containerEl = el
        }}
        onClick={this.handleClickToggle}
      >
        {this.props.children}
        {content}
      </div>
    )
  }
}
