import { memo } from 'react'
import type { FC } from 'react'
import { Placeholder } from './styles'
import { IMAGES } from './constants'
import { Props } from './types'

const Image: FC<Props> = ({ name, width = 100, height = 40, ...rest }) => {
  const SelectedImage = IMAGES[name]

  if (!SelectedImage) return <Placeholder>?</Placeholder>

  return <SelectedImage width={width} height={height} {...rest} />
}

export default memo(Image)
