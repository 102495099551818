import { UNKNOWN } from '@utils/sentiment'
import { getPercentage } from '@utils/formatters'
import { Option } from '@customTypes/shared'
import type {
  LocationFieldInput,
  DateFieldInput,
  DocumentFieldInput,
  DatasetFieldsQuery_project_dataset,
  DatasetQuery_project_dataset_byTheme,
  DatasetQuery_project_dataset_byTheme_byCategory,
} from '@customTypes/graphql-public'
import { normalizeDatasetCategoryMetrics } from './metrics'

export type DatasetFileData = {
  delimiter: string
  expectedHeader?: string[]
  file?: any
  firstRows?: string[]
  rowsSampleToValidate: string[]
  header?: string[]
  headerError?: boolean
}
export interface CreateDatasetMutationVariables {
  projectId: string
  name: string
  contentColumnsKeys: string[]
  location?: LocationFieldInput | null
  date?: DateFieldInput | null
  documentFields?: DocumentFieldInput[] | null
  delimiter: string
  attributes: Array<{ key: string; value: string; type?: DatasetAttributeType }>
}

// TODO remove types when we get types from graphql

export type DatasetTheme = DatasetQuery_project_dataset_byTheme & {
  prevCount?: number
}

export type DatasetCategory =
  DatasetQuery_project_dataset_byTheme_byCategory & {
    prevCount?: number
  }

export type NpsScoreProps = {
  datasetId: string
  projectId: string
  filters?: Record<string, unknown>
  sections: NpsSection[]
  showEvolution: boolean
  total: number
  prevTotal: number
}

export enum DatasetAttributeType {
  DATASET_ATTRIBUTE = 'DATASET_ATTRIBUTE',
  DOCUMENT_COLUMN = 'DOCUMENT_COLUMN',
  BENCHMARK_COLUMN = 'BENCHMARK_COLUMN',
}

export type NpsSection = {
  count: number
  items: NpsScore[]
  prevCount?: number
  title: NpsTitleSection
}

export type NpsScore = {
  name: string
  count: number
}

export type SummaryInsightsCategoryGroup = {
  label: string
  value: number
}

type SummaryInsightsResponsePercent = {
  negative: number
  positive: number
  objective: number
}

export type SummaryInsightsInput = {
  worst: SummaryInsightsCategoryGroup[]
  best: SummaryInsightsCategoryGroup[]
  hasDate?: boolean
  nps?: number
  npsEvolution?: number
  npsPercent?: SummaryInsightsResponsePercent
  npsEvolutionByGroup?: SummaryInsightsResponsePercent
  sentimentsPercent?: SummaryInsightsResponsePercent
  sentimentsEvolution?: SummaryInsightsResponsePercent
}

export enum NpsTitleSection {
  PROMOTERS = 'promotores',
  PASSIVE = 'pasivos',
  DETRACTORS = 'detractores',
}

export enum NpsValue {
  PROMOTERS = 'promoters',
  PASSIVE = 'passives',
  DETRACTORS = 'detractors',
}

export enum SentimentEnum {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  OBJECTIVE = 'OBJECTIVE',
  UNKNOWN = 'UNKNOWN',
}

export enum SentimentsDictionary {
  'POSITIVE' = 'Positivo',
  'NEGATIVE' = 'Negativo',
  'OBJECTIVE' = 'Neutro',
  'UNKNOWN' = 'Sin determinar',
}

export type Sentiment = {
  count: number
  prevCount?: number
  sentiment: keyof typeof SentimentEnum
}

export type SentimentMetricsResponse = {
  name: string
  allDocuments: {
    totalCount: number
    previousPeriodCount: number
  }
  sentiments: Sentiment[]
}

export type SentimentChartData = {
  label: string
  type: keyof typeof SentimentEnum
  value: number
  prevValue?: number
  percentage: number
}

export type FormDynamicOption<T> = { name: string; column: Option<T> } & {
  id: string
}

export type FormDynamicColumn<T> = { column: Option<T> }

export type DatasetFormFields = {
  /** Details */
  datasetName: string
  filename: string
  delimiter: string
  analysis: FormDynamicColumn<string>[]
  textColumn: Option<string>

  /** Advanced Filters */
  npsColumn: Option<string>
  urlColumn: Option<string>
  locationColumn: Option<string>
  dateColumn: Option<string>
  dateFormat: Option<string>
  country?: Option<string>

  /** Comparative Benchmark */
  benchmark: {
    column: Option<string>
    name: string
  }

  /** Additional Fields */
  customFilters: FormDynamicOption<string>[]

  /**
   * column options
   */
  options: {
    dataset?: DatasetFileData
  }
}

export const NpsValues = {
  promoters: [10, 9],
  passives: [8, 7],
  detractors: [6, 5, 4, 3, 2, 1, 0],
}

export const parseFormAsDatasetVariables = ({ formData, projectId }) => {
  const {
    datasetName,
    analysis,
    delimiter,
    locationColumn,
    country,
    dateColumn,
    dateFormat,
    npsColumn,
    customFilters,
  } = formData

  const newDatasetVariables: CreateDatasetMutationVariables = {
    projectId,
    name: datasetName,
    contentColumnsKeys: [],
    delimiter,
    attributes: [],
  }

  if (locationColumn?.value && country?.value) {
    newDatasetVariables.location = {
      columnKey: locationColumn.value,
      country: country.value,
    }
  }

  if (dateColumn && dateFormat) {
    newDatasetVariables.date = {
      columnKey: dateColumn.value,
      format: dateFormat.value,
    }
  }

  if (npsColumn) {
    newDatasetVariables.attributes.push({
      key: npsColumn.value,
      value: 'NPS',
    })
  }

  customFilters.forEach(field => {
    const name = field?.name || null
    const columnKey = field?.column?.value
    if (name && columnKey) {
      newDatasetVariables.attributes.push({
        key: columnKey,
        value: name,
      })
    }
  })

  analysis.forEach(field => {
    const columnKey = field?.column?.value || null
    if (columnKey) {
      newDatasetVariables.contentColumnsKeys.push(columnKey)
    }
  })

  return newDatasetVariables
}

export const normalizeDatasetSentimentsMetric = (
  dataset: SentimentMetricsResponse,
): SentimentChartData[] => {
  const initial = new Map()
  const values = ['POSITIVE', 'NEGATIVE', 'OBJECTIVE']
  values.forEach(val =>
    initial.set(val, {
      label: SentimentsDictionary[val],
      type: val,
      value: 0,
      prevValue: 0,
      percentage: 0,
    }),
  )

  const totalMessages = dataset?.allDocuments?.totalCount || 0
  const unknownMessages =
    dataset?.sentiments?.find(item => item.sentiment === UNKNOWN)?.count || 0
  const totalWithoutUnknown = totalMessages - unknownMessages

  dataset?.sentiments?.forEach(({ sentiment, count, prevCount }) => {
    if (sentiment !== 'UNKNOWN') {
      const current = initial.get(sentiment)
      initial.set(sentiment, {
        ...current,
        value: count,
        prevValue: prevCount || 0,
        percentage: Number(getPercentage(count, totalWithoutUnknown)),
      })
    }
  })

  return Array.from(initial.values())
}

export const normalizeDatasetMetrics = (input: any) => {
  const dataset = input?.dataset || null

  return {
    client: {
      openaiInsightsEnabled: input?.client?.openaiInsightsEnabled || false,
    },
    name: dataset?.name || '',
    nps: dataset?.nps || 0,
    netNps: {
      count: dataset?.netNps?.count || 0,
      prevCount: dataset?.netNps?.prevCount || 0,
    },
    documents: {
      totalCount: dataset?.allDocuments?.totalCount || 0,
      previousPeriodCount: dataset?.allDocuments?.previousPeriodCount || 0,
    },
    sentiments: normalizeDatasetSentimentsMetric(dataset),
    npsScores:
      dataset?.npsScores?.map(score => ({
        name: score?.name || '',
        count: score?.count || 0,
        prevCount: score?.prevCount || 0,
      })) || [],
    topBenchmark: dataset?.topBenchmark?.map(benchmark => ({
      name: benchmark?.name || '',
      count: benchmark?.count || 0,
      npsScores:
        benchmark?.byNps?.map(score => ({
          nps: score?.nps,
          count: score?.count || 0,
        })) || [],
      prevState: {
        count: benchmark?.prevState?.count || 0,
        npsScores:
          benchmark?.prevState?.byNps?.map(score => ({
            nps: score?.nps,
            count: score?.count || 0,
          })) || [],
      },
    })),
    topCategories: normalizeDatasetCategoryMetrics(dataset.topCategories || []),
  }
}

export const normalizeDatasetFiltersOptions = filtersOptions => {
  return (
    filtersOptions?.map(filter => ({
      name: filter.name || '',
      options:
        filter?.options?.map(option => ({
          name: option.name || '',
        })) || [],
    })) || []
  )
}

export type DatasetMetrics = ReturnType<typeof normalizeDatasetMetrics>

export const normalizeDatasetFields = (
  input: DatasetFieldsQuery_project_dataset,
) => {
  return {
    // @ts-ignore TODO improve graphql type
    contentColumnsKeys: input?.contentColumnsKeys || [],
    dateKey: input?.dateKey?.columnKey || '',
    documentFieldKeys:
      input?.documentFieldKeys?.map(item => item.columnKey) || [],
    hasDate: input?.hasDate?.datasetDateRange || false,
    hasLocation: input?.hasLocation || false,
    id: input?.id || '',
    locationKey: input?.locationKey?.columnKey || '',
  }
}

export type DatasetFields = ReturnType<typeof normalizeDatasetFields>

export const normalizeDatasetTags = ({ total, tags }) => {
  return {
    total: (total || 0) as number,
    tags:
      tags?.map(tag => ({
        tag: (tag.tag || '') as string,
        count: (tag.count || 0) as number,
        prevCount: (tag.prevCount || 0) as number,
        evolution: (tag.evolution || 0) as number,
        netNpsCount: {
          count: (tag.netNpsCount?.count || 0) as number,
          prevCount: (tag.netNpsCount?.prevCount || 0) as number,
          evolution: (tag.netNpsCount?.evolution || 0) as number,
        },
        sentimentsCount:
          tag.sentimentsCount?.map(sentiment => ({
            count: (sentiment.count || 0) as number,
            sentiment: (sentiment.sentiment || 0) as number,
          })) || [],
      })) || [],
  }
}
