import { FC } from 'react'
import * as S from './styles'
import { Props } from './types'

const Button: FC<Props> = ({
  children,
  className,
  disabled,
  fullWidth,
  isDanger = false,
  onClick,
  startIconName,
  endIconName,
  endIconProps,
  type,
  variant = 'primary',
  size = 'big',
  padding = 'normal',
  hasDropDown = false,
}) => {
  return (
    <S.Button
      $hasIcon={!!startIconName}
      $variant={variant}
      $isDanger={isDanger}
      $size={size}
      $padding={padding}
      className={className}
      disabled={disabled}
      disableElevation={true}
      disableRipple={true}
      disableTouchRipple={true}
      focusRipple={true}
      fullWidth={fullWidth}
      onClick={onClick}
      startIcon={startIconName ? <S.Icon name={startIconName} /> : undefined}
      endIcon={
        endIconName ? (
          <S.Icon name={endIconName} {...endIconProps} />
        ) : undefined
      }
      type={type}
      variant="contained"
    >
      {children}
      {hasDropDown && (
        <>
          <S.Separator $size={size} />
          <S.DropdDownButtonIcon name="chevronClosed" $size={size} />
        </>
      )}
    </S.Button>
  )
}

export default Button
