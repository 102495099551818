import * as React from 'react';

/**
 * Creates an handler to detect when clicking outside of a children component
 */

interface Props {
  // Only one children can be passed
  children?: React.ReactElement<any>

  // True when wrapping a styled component
  styled?: boolean

  onClick?: (e: any) => void
}

class ClickOutside extends React.PureComponent<Props> {
  static defaultProps = {
    onClick: () => {},
    styled: false,
  }
  childElement: any = React.createRef()

  componentDidMount() {
    document.addEventListener('click', this.handleClickDoc, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickDoc, false)
  }

  handleClickDoc = (event: Event) => {
    if (!this.childElement) return

    const { target } = event

    if (
      this.childElement &&
      !this.childElement.contains(target) &&
      this.props.onClick
    ) {
      this.props.onClick(event)
    }
  }

  childRef = (el: React.ReactElement<any>) => {
    if (!el) return
    this.childElement = el
  }

  render() {
    const { children, styled } = this.props
    const childElement = React.Children.only(children)

    const refKey = styled ? 'innerRef' : 'ref'
    return React.cloneElement(childElement, {
      [refKey]: this.childRef,
    })
  }
}

export default ClickOutside
