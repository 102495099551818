import * as yup from 'yup'

export const createProyectoValidationSchema = yup.object().shape({
  name: yup.string().required('Este campo es obligatorio.'),
  clientId: yup.string().required('Este campo es obligatorio.'),
})

export const initialValues = {
  clientId: '',
  teamId: '',
  name: '',
  description: '',
}
