import Loadable from 'react-loadable'

import Loading from '@components/Loading'

const LoadableComponent = Loadable({
  loader: () => import('@components/LogOutView'),
  loading: Loading,
  delay: 200,
  timeout: 10000,
})

const LoadableLogOutView = props => <LoadableComponent {...props} />

export default LoadableLogOutView
