import styled, { css } from 'styled-components'
import Icon from '@components/Icon'

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-left: 0.3rem;
`

export const StyledIcon = styled(Icon)<{
  $monochrome: boolean
  $value: number
}>`
  display: inline-block;
  transform: ${({ $value }) => ($value <= 0 ? 'rotate(180deg)' : 'rotate(0)')};
  ${({ theme, $monochrome, $value }) => css`
    color: ${$monochrome
      ? theme.colors.lightGray
      : $value < 0
      ? theme.colors.sentiment.negative
      : $value > 0
      ? theme.colors.mermaid
      : theme.colors.lightGray};
  `}
`

export const Value = styled.div<{ $size: string }>`
  display: inline-block;
  margin-left: 0.15rem;
  font-size: ${({ $size }) => {
    const sizes = {
      small: '0.8rem',
      normal: '16px',
    }

    return sizes[$size]
  }};
  font-weight: 500;
  width: ${({ $size }) => {
    const sizes = {
      small: '2rem',
      normal: '2.35rem',
    }

    return sizes[$size]
  }};
`
