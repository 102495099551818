import { get } from 'lodash/fp'
import { POSITIVE, NEGATIVE, OBJECTIVE, UNKNOWN } from './sentiment'
import themes from 'styles/themes'

/**
 * @function getColor returns color for tag from theme colors
 * @param {any} props from styledComponent
 * @param {boolean} isTagOperator
 */
export const getColor = (color: string, isTagOperator?: boolean) => {
  let key
  if (isTagOperator) {
    if (['category', 'originalCategory', 'theme'].includes(color)) {
      key = 'tagOperator.category'
    } else if ([POSITIVE, NEGATIVE, OBJECTIVE, UNKNOWN].includes(color)) {
      key = `tagOperator.${color.toLowerCase()}`
    } else if (color === 'emotion') {
      key = `emotion`
    } else if ('tag' === color) {
      key = 'turqoise[100]'
    } else {
      key = 'tagOperator.custom'
    }
  } else if (['category', 'originalCategory', 'theme'].includes(color)) {
    key = `blue.tag`
  } else if ([POSITIVE, NEGATIVE, OBJECTIVE, UNKNOWN].includes(color)) {
    key = `sentiment.${color.toLowerCase()}`
  } else if (color === 'emotion') {
    key = `emotion`
  } else if ('tag' === color) {
    key = 'turqoise[80]'
  } else {
    key = 'gray[50]'
  }

  return get(key, themes.light.colors)
}
