import { FC, memo } from 'react'
import type { Props } from '../types'

const DownloadIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.834 9.16683L14.834 12.2779C14.834 13.137 14.1375 13.8335 13.2784 13.8335L2.38954 13.8335C1.53043 13.8335 0.833984 13.1371 0.833984 12.2779L0.833984 9.16683"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.94488 7.61084L7.83377 11.4997L11.7227 7.61084"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83377 11.4998L7.83377 2.1665"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(DownloadIcon)
