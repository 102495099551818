import { getNpsValuesPercentage } from '@utils/dataset'
import { SentimentEnum } from './index'

const MIN_MESSAGES_CATEGORY_COUNT = 10

type CategoryMetric = {
  count: number
  category: { name: string }
  byNps: Array<{ nps: number; count: number }>
  bySentiment: Array<{ sentiment: SentimentEnum; count: number }>
}

export type MetricValue = {
  label: string
  value: number
}

/**
 * This method sorts the list with the highest values, collects only the
 * first 8 and then sorts in reverse to show the correct order in the metrics.
 */
const getOrderedCategories = (categories: MetricValue[]) =>
  categories
    .sort((a, b) => b.value - a.value)
    .slice(0, 8)
    .sort((a, b) => a.value - b.value)

export const normalizeDatasetCategoriesByNps = (
  categories: CategoryMetric[],
): {
  positive: MetricValue[]
  negative: MetricValue[]
} => {
  const positiveNetNpsCategories = []
  const negativeNetNpsCategories = []

  categories.forEach(item => {
    if (item.count > MIN_MESSAGES_CATEGORY_COUNT) {
      const { netNps } = getNpsValuesPercentage({
        npsScores: item.byNps,
        total: item.count,
      })

      const body = { label: item.category.name, value: netNps }

      if (netNps > 0) {
        positiveNetNpsCategories.push(body)
      } else if (netNps < 0) {
        negativeNetNpsCategories.push(body)
      }
    }
  })

  return {
    positive: getOrderedCategories(positiveNetNpsCategories),
    // The negative value of NPS is not ruled by the same order as the other category values.
    negative: negativeNetNpsCategories
      .sort((a, b) => a.value - b.value)
      .slice(0, 8)
      .sort((a, b) => b.value - a.value),
  }
}

export const normalizeDatasetCategoriesByVolume = (
  categories: CategoryMetric[],
): MetricValue[] => {
  const categoriesByVolume = []

  categories.forEach(item => {
    categoriesByVolume.push({ label: item.category.name, value: item.count })
  })

  return getOrderedCategories(categoriesByVolume)
}

export const normalizeDatasetCategoriesBySentiment = (
  categories: CategoryMetric[],
): { positive: MetricValue[]; negative: MetricValue[] } => {
  const positivesCategories = []
  const negativeCategories = []

  categories.forEach(item => {
    const positiveValue = item.bySentiment.find(
      s => s.sentiment === SentimentEnum.POSITIVE,
    )
    const negativeValue = item.bySentiment.find(
      s => s.sentiment === SentimentEnum.NEGATIVE,
    )

    if (positiveValue) {
      positivesCategories.push({
        label: item.category.name,
        value: positiveValue.count,
      })
    }

    if (negativeValue) {
      negativeCategories.push({
        label: item.category.name,
        value: negativeValue.count,
      })
    }
  })

  return {
    positive: getOrderedCategories(positivesCategories),
    negative: getOrderedCategories(negativeCategories),
  }
}

export const normalizeDatasetCategoryMetrics = (
  categories: CategoryMetric[],
) => ({
  byNetNps: normalizeDatasetCategoriesByNps(categories),
  byVolume: normalizeDatasetCategoriesByVolume(categories),
  bySentiment: normalizeDatasetCategoriesBySentiment(categories),
})
