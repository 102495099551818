import { mapKeys, lowerCase, toUpper, getOr, curry, isArray } from 'lodash/fp'

export const POSITIVE = 'POSITIVE'
export const NEGATIVE = 'NEGATIVE'
export const OBJECTIVE = 'OBJECTIVE'
export const UNKNOWN = 'UNKNOWN'

/**
 * Converts form a SentimentGroup array (GraphQL response) to a Sentiment object
 *
 * @param {SentimentGroup[]} sentiments - SentimentGroup array
 * @return {object}
 */
export function toSentimentObject(groups) {
  return groups.reduce(
    (acc, group) => ({
      ...acc,
      [group.sentiment]: group.count,
    }),
    {},
  )
}

/**
 * Get the available sentiments
 *
 * @return {string[]} Array of sentiments
 */
export function getSentiments() {
  return ['NEGATIVE', 'OBJECTIVE', 'POSITIVE', 'UNKNOWN']
}

/**
 * Get the sentiments as percentages. Returns a normalized object with lowecased sentiment keys
 * unless `uppercaseKeys` is set to true
 *
 * @param {Object} sentiments             - Object of sentiments
 * @param {number} total                  - Total mentions
 * @param {bool}   [uppercaseKeys=false]  - Return the sentiment keys in uppercase
 */
export function getPercentages(_sentiments, total, uppercaseKeys) {
  let sentiments
  if (isArray(_sentiments)) {
    sentiments = toSentimentObject(_sentiments)
  } else {
    sentiments = _sentiments || {}
  }

  const result = {}

  Object.keys(sentiments).forEach(k => {
    const mentions = sentiments[k] || 0
    const key = uppercaseKeys ? k.toUpperCase() : k.toLowerCase()

    let val = 0
    if (total > 0) {
      val = Math.round((mentions * 100) / total)
    }

    result[key] = val
  })

  return result
}

/**
 * Get the sentiment ratio as a number
 *
 * @param {Object} sentiments - Object of sentiments
 * @return {Number} sentiment
 */
export function getSentimentRatio(rawSentiment) {
  let sentiment = rawSentiment
  if (isArray(sentiment)) {
    sentiment = toSentimentObject(sentiment)
  }

  // Uppercase the sentiment keys
  sentiment = mapKeys(toUpper, sentiment)

  let ratio = 0
  if (sentiment && sentiment.NEGATIVE && sentiment.POSITIVE) {
    ratio = sentiment.POSITIVE / sentiment.NEGATIVE
  } else if (sentiment && sentiment.POSITIVE && !sentiment.NEGATIVE) {
    ratio = sentiment.POSITIVE
  } else if (sentiment && sentiment.NEGATIVE && !sentiment.POSITIVE) {
    ratio = 0
  } else if (sentiment && !sentiment.NEGATIVE && !sentiment.POSITIVE) {
    ratio = 0
  }

  ratio = Number.parseFloat(ratio)

  // Returns 0 instead of 0.000
  if (!ratio) {
    return 0
  }

  return ratio
}

/**
 * Get the sentiment of a tag after analyzing the number of mentions
 * from each sentiment type
 *
 * @param {object} rawSentiment - Sentiment object
 * @return {string} Sentiment: 'positive', 'negative' or 'objective'
 */
export function getTagSentiment(rawSentiment) {
  // Lowercase the sentiment keys
  const sentiment = mapKeys(lowerCase, rawSentiment)
  const { negative = 0, positive = 0, objective = 0 } = sentiment
  const negAnPos = negative + positive

  // Returns a sentiment depending the number of ratio
  const getTagSentimentByRatio = () => {
    if (!positive) return NEGATIVE
    if (!negative) return POSITIVE

    const ratio = positive / negative
    if (ratio >= 0.9 && ratio <= 1.1) return OBJECTIVE
    if (ratio > 1.1) return POSITIVE
    return NEGATIVE
  }

  // Step 0 (objective): no negative or positive mentions
  if (!negAnPos) return OBJECTIVE

  // Step 1 (ratio): not enough objectives mentions
  if (objective <= negAnPos * 20) {
    return getTagSentimentByRatio()
  }

  // Step 2 (objective): not enough positive and negative mentions
  if (negAnPos <= 20) {
    return OBJECTIVE
  }

  // Step 3 (objective): way more objective mentions than negative and positive
  if (objective >= negAnPos * 30) {
    return OBJECTIVE
  }

  return getTagSentimentByRatio()
}

/**
 * The mesasge response has a sentiment expresses in number.
 * Translate form that number to the "verbose" sentiment
 *
 * @param {object} message - Message object
 * @return {string} sentiment
 */
export function getMessageSentiment(message) {
  let sentiment = null

  if (!message || !message.a) {
    return 'unknown'
  }

  if (message.a.length) {
    sentiment = message.a[0].rs
  }

  return {
    0: 'unknown',
    1: 'objective',
    2: 'positive',
    3: 'negative',
  }[sentiment]
}

/**
 * Utility function to get a specific sentiment from a sentiment object.
 * This is needed during the transition to sentiment constants, as we have a
 * mix of lowercase and uppercase sentiments.
 *
 * This function is curried.
 *
 * @param {String} key - Sentiment to get
 * @param {Object} sentiment - Sentiment object to extract from
 * @return {Number} Value for that sentiment or 0
 */
export const getSentiment = curry((key, sentiment) => {
  // Uppercase everything
  const k = toUpper(key)
  const s = mapKeys(toUpper, sentiment)

  return getOr(0, k, s)
})

export const localeSentiments = {
  POSITIVE: 'positivo',
  NEGATIVE: 'negativo',
  OBJECTIVE: 'neutro',
  UNKNOWN: 'desconocido',
}

/**
 * does de name it has
 *
 * @param {(key: string,  val?: { value: string; error?: string }, totalToAction?: number) => void} onEdit - HOC func for mutation
 * @param {((selectedDoc: Message,  totalCount: number,  categoryAction?: 'add' | 'del') => void)= } onSelectDocument  - HOC func for document selection (edit and download) in this case to avoid it if its passed
 * @param {number} selectedMessages
 * @returns {Array} Array containing options with the functions involve
 */
export const getEditSentimentOptions = (
  onEdit,
  onSelectDocument,
  selectedMessages = 1,
) => {
  const editSentimentFunc = value => {
    onSelectDocument && onSelectDocument(document, selectedMessages, 'add')
    onEdit('sentiment', { value }, selectedMessages)
  }

  return [
    {
      label: 'Positivo',
      value: 'POSITIVE',
      onClick: () => editSentimentFunc('POSITIVE'),
    },
    {
      label: 'Negativo',
      value: 'NEGATIVE',
      onClick: () => editSentimentFunc('NEGATIVE'),
    },
    {
      label: 'Neutro',
      value: 'OBJECTIVE',
      onClick: () => editSentimentFunc('OBJECTIVE'),
    },
  ]
}
