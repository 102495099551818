import styled from 'styled-components'
import { TableCell as TableCellMaterial } from '@material-ui/core'

// TODO: cant use Material TableCell align property because bulma.css overrides styles
export const TableCell = styled(TableCellMaterial)<{ $align: string }>`
  && {
    text-align: ${({ $align }) => $align};
    color: ${({ theme }) => theme.colours.gray[100]};
    padding: 10px 16px;
    font-size: 16px;
  }

  &.MuiTableCell-stickyHeader {
    background-color: ${({ theme }) => theme.colours.white};
  }
`
