export type Props = {
  datasetName: string
  projectName: string
  links: LinkType[]
  toggle: boolean
  onToggle: (toggle: boolean) => void
  onNavigate: (path: string) => void
  handleTrackEvent: (path: string) => void
  checkIsLinkActive: (link: LinkType, path: string) => boolean
}

/* Types  */
export enum MenuEntryType {
  SECTION = 'section',
  MENUITEM = 'menuItem',
  SUBMENU = 'subMenu',
  POPUPITEM = 'popupItem',
  SELECTOR = 'selector',
}

export type LinkType = {
  clickable?: boolean
  key?: string
  visible: boolean
  title?: string
  icon?: string
  path?: string
  children?: Array<LinkType>
  type?: MenuEntryType
  popup?: boolean
  focusTarget?: string
}

export type SidebarProps = {
  onToggle: (toggle: boolean) => void
}

export type ProjectType = {
  __typename: string
  id: string
  name: string
  sharingConfig: any
  dataset: {
    __typename: string
    id: string
    name: string
    contentColumnsKeys: string[]
    byLocation: {
      __typename: string
      count: number
    }[]
  }
}

export type ConfigType = {
  pathname: string
  search: string
  canRead: boolean
  project?: ProjectType
  projectId?: string
  datasetId?: string
}
