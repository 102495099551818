import { gql } from '@apollo/client'

export const CLIENT_FRAGMENT = gql`
  fragment ClientData on Client {
    id
    name
    tier
    logo
    createdAt
    blocked
    apiEnabled
  }
`
