import { createContext } from 'react'

export interface UploadContextState {
  error: string
  isUploading: boolean
  file?: File
  datasetId?: string
  clientId?: string
  startUpload: (datasetId: string, file: File, clientId?: string) => void
  cancelUpload: () => void
}

const UploadContext = createContext<UploadContextState>({
  error: '',
  isUploading: false,
  startUpload() {},
  cancelUpload() {},
})

export default UploadContext
