import { LinkType, MenuEntryType } from './types'
import { get, some } from 'lodash/fp'

type ConfigType = {
  project?: Object
  projectId?: string
  datasetId?: string
  canRead: boolean
  pathname: string
  search: string
}

// pathIsInPaths :: string -> [string] -> boolean
const pathIsInPaths: (path: string, paths: Array<string>) => boolean = (
  path,
  paths,
) => some(p => path.includes(p), paths)

const hasLocation = get('dataset.byLocation.0')
const sectionPaths = [
  'summary',
  'messages',
  'categories',
  'keyconcepts',
  'location',
]

export const generateMenuLinks = (config: ConfigType): LinkType[] => {
  const { pathname, search, project, projectId, datasetId, canRead } = config

  return [
    {
      icon: 'customers',
      title: 'Clientes',
      visible: pathname.includes('admin'),
      type: MenuEntryType.SECTION,
      path: '/admin/clients',
      clickable: true,
    },
    {
      icon: 'teams',
      title: 'Equipos',
      visible: pathname.includes('admin'),
      type: MenuEntryType.SECTION,
      path: '/admin/teams',
      clickable: true,
    },
    {
      icon: 'users',
      title: 'Usuarios',
      visible: pathname.includes('admin'),
      type: MenuEntryType.SECTION,
      path: '/admin/users',
      clickable: true,
    },
    {
      icon: 'projects',
      title: 'Proyectos',
      visible: pathname.includes('admin'),
      type: MenuEntryType.SECTION,
      path: '/admin/projects',
      clickable: true,
    },
    {
      icon: 'categories',
      title: 'Sincronización',
      visible: pathname.includes('admin'),
      type: MenuEntryType.SECTION,
      path: '/admin/sync-domains',
      clickable: true,
    },
    {
      icon: 'summary',
      title: 'Resumen',
      visible: canRead && pathIsInPaths(pathname, sectionPaths),
      type: MenuEntryType.SECTION,
      path: `/summary/${projectId}/${datasetId}${search}`,
      clickable: true,
    },
    {
      icon: 'categories',
      title: 'Categorías',
      visible: canRead && pathIsInPaths(pathname, sectionPaths),
      type: MenuEntryType.SECTION,
      path: `/categories/${projectId}/${datasetId}${search}`,
      clickable: true,
    },
    {
      icon: 'mention',
      title: 'Mensajes',
      visible: pathIsInPaths(pathname, sectionPaths),
      type: MenuEntryType.SECTION,
      path: `/messages/${projectId}/${datasetId}${search}`,
      clickable: true,
    },
    {
      icon: 'keyConcepts',
      title: 'Conceptos clave',
      visible: pathIsInPaths(pathname, sectionPaths),
      type: MenuEntryType.SECTION,
      path: `/keyconcepts/${projectId}/${datasetId}`,
      clickable: true,
    },
    {
      icon: 'localization',
      title: 'Localización',
      visible: hasLocation(project) && pathIsInPaths(pathname, sectionPaths),
      type: MenuEntryType.SECTION,
      path: `/location/${projectId}/${datasetId}${search}`,
      clickable: true,
    },
  ]
}
