import { FC, memo } from 'react'
import { Controller } from 'react-hook-form'
import type { Props } from './types'
import Input from '@components/Inputs/Input/Input'
import ErrorMessage from '@components/ErrorMessage/ErrorMessage'
import * as S from '../styles'

const InputController: FC<Props> = ({ control, name, hasError, ...rest }) => {
  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Input
              id={name}
              error={!!(error || hasError)}
              {...rest}
              {...field}
            />
            {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
          </>
        )}
      />
    </S.Container>
  )
}

export default memo(InputController)
