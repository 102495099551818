import { FC, forwardRef, memo } from 'react'
import { InputAdornment } from '@material-ui/core'
import Icon from '../../Icon'
import * as S from '../styles'
import { Props } from '../types'

const Input: FC<Props> = forwardRef(
  (
    {
      id,
      type,
      value,
      onChange,
      label,
      placeholder,
      error,
      style,
      labelHelper,
      endIcon,
      disabled,
      multiline,
      ...rest
    },
    ref,
  ) => {
    const endAdornment = endIcon ? (
      <S.IconContainer
        onClick={e => {
          e.stopPropagation()
          endIcon.onClick()
        }}
      >
        <S.Icon name={endIcon.icon} $hasAction={!!endIcon.onClick}></S.Icon>
      </S.IconContainer>
    ) : null

    return (
      <>
        <S.LabelContainer>
          <S.Label htmlFor={id}>{label}</S.Label>
          {labelHelper && <S.LabelHelper>{labelHelper}</S.LabelHelper>}
        </S.LabelContainer>
        <S.Input
          id={id}
          variant="outlined"
          type={type || 'text'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
          multiline={multiline}
          InputProps={{
            endAdornment,
            startAdornment: error ? (
              <InputAdornment position="start">
                <Icon
                  name={'inputAlert'}
                  size={14}
                  data-testid="input-alert-icon"
                />
              </InputAdornment>
            ) : null,
          }}
          inputRef={ref}
          style={style}
          {...rest}
        />
      </>
    )
  },
)

export default memo(Input)
