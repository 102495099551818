import React, { FC, MouseEvent, memo } from 'react'
import TableHeadMaterial from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'
import type { Props } from './types'
import * as S from './styles'

const TableHead: FC<Props> = ({ headCells, order, orderBy, onRequestSort }) => {
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHeadMaterial>
      <TableRow>
        {headCells.map(headCell => (
          <S.TableCell
            $align={headCell.align}
            align={headCell.align}
            key={headCell.id}
            padding="normal"
            sortDirection={orderBy === headCell.sortable ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.sortable}
                direction={orderBy === headCell.sortable ? order : 'asc'}
                onClick={createSortHandler(headCell.sortable)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </S.TableCell>
        ))}
      </TableRow>
    </TableHeadMaterial>
  )
}

export default memo(TableHead)
