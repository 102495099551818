import { gql } from '@apollo/client'

export const CREATE_DATASET = gql`
  mutation CreateDatasetMutation(
    $projectId: ID!
    $name: String!
    $contentColumnsKeys: [String!]
    $location: LocationFieldInput
    $date: DateFieldInput
    $attributes: [DatasetAttributeInput!]
    $delimiter: String
  ) {
    createDataset(
      projectId: $projectId
      name: $name
      contentColumnsKeys: $contentColumnsKeys
      location: $location
      date: $date
      attributes: $attributes
      delimiter: $delimiter
    ) {
      id
    }
  }
`

export const UPDATE_DATASET = gql`
  mutation UpdateDatasetMutation($id: ID!, $name: String) {
    updateDataset(id: $id, name: $name) {
      id
      name
      createdAt
      status
    }
  }
`

export const DELETE_DATASET = gql`
  mutation DeleteDatasetMutation($id: ID!) {
    deleteDataset(id: $id)
  }
`
