import round from 'lodash/fp/round'
const roundAt = round.convert({ fixed: false })

export function formatNumber(number, options = {}) {
  const opt = Object.assign(
    {
      shorten: false,
      decimals: undefined,
    },
    options,
  )

  // First ensure that we actually have a number
  const n = Number(number)
  if (Number.isNaN(n)) {
    // eslint-disable-next-line
    console.warn('formatNumber was called with a non-number:', n)
    return n
  }

  // Shorten the number
  if (n >= 1000 && opt.shorten) {
    return ['k', 'M', 'B'].reduceRight((acc, key, index) => {
      // When the accumulator is astring, we are done
      if (typeof acc === 'string') return acc

      const target = 1000 ** (index + 1)

      // The number is too small for this key, move to alower one
      if (acc < target) return acc

      // Nailed it
      const res = acc / target
      return `${roundAt(res, 1)}${key}`
    }, n)
  }

  let decimals = opt.decimals

  // Deice the decimals based on the number when not specified
  if (!decimals) {
    if (n < 1) {
      decimals = 3
    } else if (n < 10) {
      decimals = 1
    } else {
      decimals = 0
    }
  }

  // Format using the standard library
  return n.toLocaleString('es-ES', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  })
}

/**
 * Calculates the percentage of n agains total
 *
 * @param {number} n          - Value
 * @param {number} total      - Total amount (100%)
 * @param {number} [digits=1] - Significant digits to include in the result
 *
 * @return {number} Percentage
 */
export function getPercentage(n, tot, digits = 0) {
  if (tot === 0 || !tot) return 0

  const perc = (n * 100) / tot
  return roundAt(perc, digits)
}

export function parseUserStatus(status) {
  switch (status) {
    case 'INACTIVE':
      return 'Inactivo'
    case 'BLOCKED':
      return 'Bloqueado'
    default:
      return 'Activo'
  }
}
