export type Sentiment = 'OBJECTIVE' | 'NEGATIVE' | 'UNKNOWN' | 'POSITIVE'

export enum SentimentPaletteEnum {
  NEGATIVE = '#e6312e',
  // OBJECTIVE = '#ffae00',
  OBJECTIVE = '#7e8c9b',
  POSITIVE = '#6cdb78',
  UNKNOWN = '#a4afbb',
}
export type SentimentGroup = {
  count: number
  sentiment: Sentiment
}

export type TopTagsResponse = {
  count: number
  bySentiment: SentimentGroup[]
  tag: {
    name: string
  }
}

export type Word = { label: string; size: number } & SentimentObject

export type TagCloudData = {
  label: string
  size: number
  type: Sentiment
  labelSettings: {
    fill: SentimentPaletteEnum
  }
}

export type SentimentObject = Record<
  Lowercase<SentimentGroup['sentiment']>,
  number
>
