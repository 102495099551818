import { FC, memo } from 'react'
import type { Props } from '../../types'

const ChevronClosed: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.5L9 0.5L4.5 5L0 0.5Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ChevronClosed)
