import { FC, memo } from 'react'
import { Props } from './types'
import useLogic from './logic'
import DateRange from '@components/DateRange/DateRange'
import 'react-datepicker/dist/react-datepicker.css'
import Button from '@components/Button/Button'
import { format, isSameDay } from 'date-fns-datepicker'
import es from 'date-fns-datepicker/locale/es'
import * as S from './styles'

// TODO INAC-8009 The commented code related to the Timezone has generated a new bug, we revert the changes until further fix.
const DatePickerHeader: FC<Props> = ({
  datasetDateRange,
  dateRange,
  handleChangeDate,
}) => {
  const {
    end = new Date(),
    maxDate,
    minDate,
    start,

    dates,
    handleDatesChange,
    handleSubmitDates,
    handleClick,
    handleClose,
    open,
    id,
    anchorEl,
  } = useLogic({
    datasetDateRange,
    dateRange,
    handleChangeDate,
  })

  return (
    <S.Container>
      <S.Content>
        <S.DateRangeButton
          aria-describedby={id}
          onClick={handleClick}
          $open={open}
        >
          <S.DateRangeButtonContent>
            <S.CalendarIcon name="calendar" size={16} />
            <S.DatesContainer>
              {format(start, 'dd/MM/yyyy', { locale: es })}
              {!isSameDay(start, end) &&
                ` - ${format(end || new Date(), 'dd/MM/yyyy', { locale: es })}`}
            </S.DatesContainer>
          </S.DateRangeButtonContent>

          <S.OpenIcon name={open ? 'chevronOpen' : 'chevronClosed'} size={12} />
        </S.DateRangeButton>

        <S.Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transitionDuration={200}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <S.DateRangeContainer>
            <DateRange
              start={start}
              end={end}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleDatesChange}
            />
            <S.ButtonContainer>
              <Button onClick={handleSubmitDates} disabled={!dates?.endDate}>
                Aplicar
              </Button>
            </S.ButtonContainer>
          </S.DateRangeContainer>
        </S.Popover>
      </S.Content>
    </S.Container>
  )
}

export default memo(DatePickerHeader)
