import { FC, memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { getCustomDomainParams } from '@utils/url'
import Image from '@components/Image'
import * as S from './styles'

const HeaderLogo: FC = () => {
  const history = useHistory()
  const domainParams = getCustomDomainParams()

  const navigateTo = useCallback(
    (path: string) => () => {
      history.push(path)
    },
    [history],
  )

  return (
    <>
      <S.IconButton
        edge="start"
        color="inherit"
        disableRipple={true}
        onClick={navigateTo('/')}
      >
        <Image
          name={domainParams.logo}
          width={domainParams.logoWidth}
          height={domainParams.logoHeight}
        />
      </S.IconButton>
      <S.IconTypography
        $color={domainParams.descriptionColor}
        onClick={navigateTo('/')}
      >
        {domainParams.description}
      </S.IconTypography>
    </>
  )
}

export default memo(HeaderLogo)
