import { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AuthContextConsumer } from '@contexts/Auth'
import { useRouter } from '@hooks/useRouter'
import Spinner from '@components/Spinner'
import { SCProvider } from 'sentisis-components'
import { Header } from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import BarWithFilters from '@components/BarWithFilters'
import { Props } from './types'
import * as S from './styles'
import { noneIsNil } from './constants'

/**
 * Main Layout for CX
 */
const ContainerLayoutView: FC<Props> = ({ children }) => {
  const router = useRouter()
  const { location } = router

  const { projectId, datasetId } = useParams<{
    projectId: string
    datasetId: string
  }>()

  const scrollableContainer = useRef<HTMLDivElement>(null)
  const isFilterVisible = noneIsNil([projectId, datasetId])
  const [collapse, setCollapse] = useState(false)

  const handleToggle = status => setCollapse(!status)

  useEffect(() => {
    setCollapse(true)
  }, [isFilterVisible])

  // change scroll to zero when pathname changes in message section
  useEffect(() => {
    const scrollContainer = scrollableContainer.current

    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0 })
    }
  }, [location])

  // TODO review this flow
  const splitUrl = router.pathname.split('/')
  const hasSidebar =
    router.pathname !== '/' &&
    router.pathname !== '/catalog' &&
    router.pathname !== '/project' &&
    splitUrl[2] !== 'datasets'

  return (
    <AuthContextConsumer>
      {({ context: { viewer, user } }) => {
        if (!user) return <Spinner />

        return (
          <SCProvider>
            <S.Container>
              <Header user={user} adminLink={viewer.isManager} />
              {hasSidebar ? <Sidebar onToggle={handleToggle} /> : null}
              <S.Main withSidebar={hasSidebar} withFilters={isFilterVisible}>
                {isFilterVisible && (
                  <S.FiltersWrapper isCollapsed={collapse}>
                    <BarWithFilters />
                  </S.FiltersWrapper>
                )}
                <S.Body
                  withSidebar={hasSidebar}
                  withFilters={isFilterVisible}
                  ref={scrollableContainer}
                >
                  {children}
                </S.Body>
              </S.Main>
            </S.Container>
          </SCProvider>
        )
      }}
    </AuthContextConsumer>
  )
}

export default ContainerLayoutView
