import { FC, memo } from 'react'
import { FilterGroup } from '../types'
import { set } from 'lodash/fp'
import FilterForm from '@components/FilterForm'
import { Icons } from 'sentisis-components'
import type { FilterFormProps } from './types'
import * as S from './styles'
import useLogic from './logic'
import { SetNPSText } from '@utils/formatters'

const FiltersSelect: FC<FilterFormProps> = ({
  handleChangeFilters,
  handleClickOpenFilters,
  handleQueryOptions,
  options,
  myFilters,
  myFiltersLoading,
}) => {
  const { filtersToShow, documentFields } = options.config
  const { openState } = options.context
  const {
    formatFilter,
    formState,
    getSelectedGroup,
    handleChange,
    handleSubmit,
    itemIsActive,
    setFormState,
  } = useLogic({
    ...options.context,
    handleClickOpenFilters,
    handleChangeFilters,
    filtersToShow,
  })

  // Return text and caret for select button
  const renderFilter = (group: FilterGroup) => {
    return group.name ? (
      <S.Label>
        <S.LabelText>{SetNPSText(group.name)}</S.LabelText>
        <S.Caret
          className={`${
            itemIsActive(group) && getSelectedGroup() ? 'is-open' : null
          }`}
        />
      </S.Label>
    ) : null
  }

  return (
    <div id="FiltersSelect">
      <S.TextSearchWrapper>
        <S.Search>
          <S.StyledIcon icon={Icons.Search} />

          <form autoComplete="off" onSubmit={handleSubmit}>
            <S.MatchInput
              id="contentMatchSearch"
              type="text"
              value={formState.selectedFilters?.content?.match || ''}
              placeholder="Realiza una búsqueda por texto o aplica filtros avanzados…"
              onChange={ev => formatFilter('content', 'match', ev.target.value)}
            />
          </form>
        </S.Search>
      </S.TextSearchWrapper>
      <S.FiltersWrapper>
        <S.FiltersList>
          {filtersToShow.map(group => {
            return (
              <S.Field key={group.name}>
                <S.TabSelectButton
                  className={`${itemIsActive(group) ? 'is-open' : null}`}
                  onClick={() => {
                    if (openState.secondLevel !== group.name) {
                      setFormState(set('activeItem', group.columnKey))
                    }
                    handleClickOpenFilters(
                      openState.firstLevel,
                      openState.secondLevel !== group.name && group.name,
                    )
                  }}
                >
                  {renderFilter(group)}
                </S.TabSelectButton>
              </S.Field>
            )
          })}
        </S.FiltersList>

        <S.FilterButton onClick={() => handleSubmit()}>
          Aplicar Filtros
        </S.FilterButton>
      </S.FiltersWrapper>

      {formState?.selectedFilters && (
        <FilterForm
          type={getSelectedGroup()?.columnKey || openState?.secondLevel || ''}
          options={options.values}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onQueryOptions={handleQueryOptions}
          documentFields={documentFields}
          filters={formState.selectedFilters}
          myFilters={myFilters}
          myFiltersLoading={myFiltersLoading}
        />
      )}
    </div>
  )
}

export default memo(FiltersSelect)
