import { FC, memo } from 'react'
import type { Props } from '../../types'

const ChevronRight: FC<Props> = ({ size = 16, ...rest }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 41 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_179_17394)">
            <ellipse cx="20.684" cy="19" rx="9.6842" ry="10" transform="rotate(-180 20.684 19)" fill="white" />
            <ellipse cx="20.684" cy="19" rx="9.6842" ry="10" transform="rotate(-180 20.684 19)" stroke="#D1D1D1" strokeWidth="0.5" />
        </g>
        <path d="M19.2314 23.5L23.1051 19L19.2314 14.5" fill="white" />
        <path d="M19.2314 23.5L23.1051 19L19.2314 14.5" stroke="#282A33" strokeWidth="0.5" />
        <defs>
            <filter id="filter0_d_179_17394" x="0.75" y="0.75" width="39.8682" height="40.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.639216 0 0 0 0.06 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_179_17394" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_179_17394" result="shape" />
            </filter>
        </defs>
    </svg>
)

export default memo(ChevronRight)


