import * as React from 'react'
import styled from 'styled-components/macro'
import Spinner from '@components/Spinner'

interface Props {
  error: string
  retry: () => any
  pastDelay: number
  timedOut: number
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-flow: column nowrap;
  min-height: 100vh;
  margin: 0;
  padding: 0;
`

const Input = styled.button`
  border: 2px solid #f0f1fa;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  padding: 10px;
  color: ${({ theme }) => theme.colors.gray[20]};
  transition: border-color 0.25s ease-in-out;

  &:active,
  &:focus {
    border-color: #64bffa;
  }
`
const CenteredText = styled.p`
  font-size: 12px;
  padding-top: 25px;
  text-align: center;
`
const ErrorMessage = styled.p`
  padding: 5px;
  color: ${({ theme }) => theme.colors.red[10]};
  text-align: center;
`
const Loading: React.ComponentType<Props> = ({
  error,
  retry,
  pastDelay,
  timedOut,
}) => {
  if (error) {
    console.log('error', error)
    return (
      <Wrapper>
        <ErrorMessage> ¡Error! </ErrorMessage>
        <Input onClick={retry}>Reintentar</Input>
      </Wrapper>
    )
  }
  if (timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <Wrapper>
        <CenteredText> Está llevando demasiado tiempo... </CenteredText>
        <Input onClick={retry}>Reintentar</Input>
      </Wrapper>
    )
  }
  if (pastDelay) {
    return (
      <Wrapper>
        <Spinner flex />
      </Wrapper>
    )
  } else {
    return <Wrapper />
  }
}
export default Loading
