import { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { get, find, getOr, isArray, isEmpty } from 'lodash/fp'
import { withRouter } from 'react-router'
import { sortAsNumericValues, sortStringsValues } from '@utils/filters'
import { splitFilterTypes } from '../AdvanceFilters/helpers'
import { DatasetAttributeType } from 'models/Dataset'
import * as S from './styles'

const propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  documentFields: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  filters: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    sentiment: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.shape({
      ors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      nots: PropTypes.arrayOf(PropTypes.string),
      ands: PropTypes.arrayOf(PropTypes.string),
      match: PropTypes.arrayOf(PropTypes.string),
    }),
    contentFields: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.arrayOf(PropTypes.object),
    NPS: PropTypes.shape({
      or: PropTypes.arrayOf(PropTypes.string),
    }),
    others: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  myFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
    }),
  ),
  myFiltersLoading: PropTypes.bool,
}

const defaultProps = {
  filters: {
    category: {},
    uncategorized: false,
  },
}

// Helper to calculate offset based on count
const getOffset = options => {
  if (options.length === 0) return 0
  if (options.length >= 7) return 280

  return options.length * 40
}

const sentimentOptions = [
  {
    value: 'POSITIVE',
    label: 'Positivo',
  },
  {
    value: 'NEGATIVE',
    label: 'Negativo',
  },
  {
    value: 'OBJECTIVE',
    label: 'Neutro',
  },
  {
    value: 'UNKNOWN',
    label: 'Sin determinar',
  },
]

class FilterForm extends PureComponent {
  state = {
    overflowOffset: 0,
  }

  handleKeyDown = e => {
    // User pressed Enter
    if (e.keyCode === 13) this.props.onSubmit()
  }

  getValues(key, operator, index = undefined) {
    const path =
      index !== undefined
        ? ['filters', key, operator, index]
        : ['filters', key, operator]
    return getOr([], path, this.props)
  }

  getAdvancedTagValues(key, operator, index = undefined) {
    return this.getValues(key, operator, index).map(value => ({
      value,
    }))
  }

  formatFilter = (type, operator, value, index = undefined) => {
    const flatValues = isArray(value) ? value.map(v => v.value) : value

    this.props.onChange(type, operator, flatValues, index)
  }

  getValueFromOptions = (type, operator, isMyFilter = false) => {
    const options = isMyFilter
      ? this.props.myFilters.find(f => f.name === type)?.options || []
      : this.props.options

    return this.getValues(type, operator).map(v => ({
      value: v.value || v,
      label: get('label', find({ value: v.value || v }, options)),
    }))
  }

  getPlaceholder(options) {
    return isEmpty(options)
      ? 'No hay opciones disponibles'
      : 'Selecciona una o varias opciones'
  }

  renderCategories = () => {
    const { options } = this.props
    const sortedCategories = sortStringsValues(options, 'label')
    const inEditionLog = this.props.location.pathname.includes('edition-log')
    const offset = getOffset(sortedCategories)

    return (
      <S.Form>
        <S.FormWrapper className="FilterForm">
          <S.Row>
            {inEditionLog ? (
              <S.Label htmlFor="categories_or" className="Field__label">
                Categorías actuales
              </S.Label>
            ) : (
              <S.Label htmlFor="categories_and" className="Field__label">
                Cualquiera de estas categorías:
              </S.Label>
            )}
            <S.FieldWrapper id={'categories_or'}>
              <S.Select
                active={this.getValueFromOptions('category', 'or').length > 0}
                value={this.getValueFromOptions('category', 'or')}
                name="categories"
                id="categoriesOr"
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onChange={data => this.formatFilter('category', 'or', data)}
                options={sortedCategories}
                onMenuOpen={() =>
                  this.setState({ overflowOffset: inEditionLog ? 190 : 100 })
                }
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti={true}
              />
            </S.FieldWrapper>
          </S.Row>

          {inEditionLog ? (
            <S.Row>
              <S.Label htmlFor="originalCategory" className="Field__label">
                Categorias originales
              </S.Label>
              <S.FieldWrapper id="originalCategory_and">
                <S.Select
                  active={
                    this.getValueFromOptions('originalCategory', 'or').length >
                    0
                  }
                  value={this.getValueFromOptions('originalCategory', 'or')}
                  name="originalCategory"
                  id="originalCategory"
                  onChange={v => this.formatFilter('originalCategory', 'or', v)}
                  options={sortedCategories}
                  placeholder={this.getPlaceholder(options)}
                  isDisabled={isEmpty(options)}
                  onMenuOpen={() => this.setState({ overflowOffset: 280 })}
                  onMenuClose={() => this.setState({ overflowOffset: 0 })}
                  isMulti
                />
              </S.FieldWrapper>
            </S.Row>
          ) : (
            <Fragment>
              <S.Row>
                <S.Label htmlFor="categories" className="Field__label">
                  Todas estas categorías:
                </S.Label>
                <S.FieldWrapper id="categories">
                  <S.Select
                    active={
                      this.getValueFromOptions('category', 'and').length > 0
                    }
                    value={this.getValueFromOptions('category', 'and')}
                    name="category"
                    id="categoryAnd"
                    onChange={v => this.formatFilter('category', 'and', v)}
                    options={sortedCategories}
                    placeholder={this.getPlaceholder(options)}
                    isDisabled={isEmpty(options)}
                    onMenuOpen={() =>
                      this.setState({
                        overflowOffset: 188,
                      })
                    }
                    onMenuClose={() => this.setState({ overflowOffset: 0 })}
                    isMulti
                  />
                </S.FieldWrapper>
              </S.Row>
              <S.Row>
                <S.Label htmlFor="categories" className="Field__label">
                  Ninguna de estas categorías:
                </S.Label>
                <S.FieldWrapper id="categories">
                  <S.Select
                    active={
                      this.getValueFromOptions('category', 'not').length > 0
                    }
                    value={this.getValueFromOptions('category', 'not')}
                    name="category"
                    id="category"
                    onChange={v => this.formatFilter('category', 'not', v)}
                    options={sortedCategories}
                    placeholder={this.getPlaceholder(options)}
                    isDisabled={isEmpty(options)}
                    onMenuOpen={() =>
                      this.setState({
                        overflowOffset: offset,
                      })
                    }
                    onMenuClose={() => this.setState({ overflowOffset: 0 })}
                    isMulti
                  />
                </S.FieldWrapper>
              </S.Row>
            </Fragment>
          )}
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderSentiments = () => {
    const inEditionLog = this.props.location.pathname.includes('edition-log')

    return (
      <S.Form>
        <S.FormWrapper>
          <S.Row>
            {inEditionLog ? (
              <S.Label htmlFor="sentiments" className="Field__label">
                Sentimiento actual
              </S.Label>
            ) : (
              <S.Label htmlFor="sentiments" className="Field__label">
                Cualquiera de estos sentimientos:
              </S.Label>
            )}
            <S.FieldWrapper id="sentiments">
              <S.Select
                $active={this.getValues('sentiment', 'or').length > 0}
                value={this.getValues('sentiment', 'or')}
                name="sentiments"
                id="sentiments"
                onChange={v => this.formatFilter('sentiment', 'or', v)}
                options={sentimentOptions}
                placeholder="Selecciona una o varias opciones"
                onMenuOpen={() => {
                  const overflowOffset = inEditionLog ? 55 : 140
                  this.setState({ overflowOffset })
                }}
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti
              />
            </S.FieldWrapper>
          </S.Row>
          {inEditionLog ? (
            <S.Row>
              {' '}
              <S.Label htmlFor="originalSentiment" className="Field__label">
                Sentimiento original
              </S.Label>
              <S.FieldWrapper id="originalSentiment">
                <S.Select
                  $active={this.getValues('originalSentiment', 'or').length > 0}
                  value={this.getValues('originalSentiment', 'or')}
                  name="originalSentiment"
                  id="originalSentiment"
                  onChange={v =>
                    this.formatFilter('originalSentiment', 'or', v)
                  }
                  options={sentimentOptions}
                  placeholder="Selecciona una o varias opciones"
                  onMenuOpen={() => this.setState({ overflowOffset: 140 })}
                  onMenuClose={() => this.setState({ overflowOffset: 0 })}
                  isMulti
                />
              </S.FieldWrapper>
            </S.Row>
          ) : null}
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderTextSearch = () => {
    const advancedFields = [
      {
        id: 'field_1',
        label: 'Cualquiera de estas palabras:',
        hint: 'Busca menciones que contienen alguna de estas palabras (palabra OR palabra).',
        values: this.getAdvancedTagValues('content', 'ors', 0),
        onChange: keywords => this.formatFilter('content', 'ors', keywords, 0),
      },

      {
        id: 'field_2',
        label: 'Y cualquiera de estas palabras:',
        hint: 'Busca menciones que contienen alguna de estas palabras (palabra OR palabra).',
        values: this.getAdvancedTagValues('content', 'ors', 1),
        onChange: keywords => this.formatFilter('content', 'ors', keywords, 1),
      },

      {
        id: 'field_3',
        label: 'Y todas estas palabras:',
        hint: 'Busca menciones que contienen todas las palabras seleccionadas (palabra AND palabra).',
        values: this.getAdvancedTagValues('content', 'ands', 0),
        onChange: keywords => this.formatFilter('content', 'ands', keywords, 0),
      },

      {
        id: 'field_4',
        label: 'Y ninguna de estas palabras:',
        hint: 'Busca menciones que no contienen ninguna de las palabras seleccionadas (NOT palabras).',
        values: this.getAdvancedTagValues('content', 'nots', 0),
        onChange: keywords => this.formatFilter('content', 'nots', keywords, 0),
      },
    ]

    return (
      <S.Form>
        <S.FormWrapper>
          {advancedFields.map(field => (
            <S.Row key={field.id}>
              <S.Label htmlFor={field.id} className="Field__label">
                {field.label}
              </S.Label>
              <S.FieldWrapper id={'advanced_txt1_or'}>
                <S.TagsInput
                  active={true}
                  tags={field.values}
                  onChange={field.onChange}
                  onSubmit={field.onChange}
                />
              </S.FieldWrapper>
              <S.Hint>{field.hint}</S.Hint>
            </S.Row>
          ))}
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderKeyConcepts = () => {
    const { options } = this.props
    const offset = getOffset(options)

    return (
      <S.Form>
        <S.FormWrapper className="FilterForm">
          <S.Row>
            <S.Label htmlFor="key-concepts-or">
              Cualquiera de estos conceptos clave{' '}
              <S.FilterType>(OR)</S.FilterType>
            </S.Label>
            <S.FieldWrapper id="key-concepts-or">
              <S.Select
                active={this.getValueFromOptions('tag', 'or').length > 0}
                value={this.getValueFromOptions('tag', 'or')}
                name="keyConceptsOr"
                id="keyConceptsOr"
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onChange={data => this.formatFilter('tag', 'or', data)}
                options={options}
                onMenuOpen={() => this.setState({ overflowOffset: 100 })}
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti={true}
              />
            </S.FieldWrapper>
          </S.Row>
          <>
            <S.Row>
              <S.Label htmlFor="key-concepts-and">
                Todos estos conceptos clave <S.FilterType>(AND)</S.FilterType>
              </S.Label>
              <S.FieldWrapper id="key-concepts-and">
                <S.Select
                  active={this.getValueFromOptions('tag', 'and').length > 0}
                  value={this.getValueFromOptions('tag', 'and')}
                  name="keyConceptsAnd"
                  id="keyConceptsAnd"
                  onChange={v => this.formatFilter('tag', 'and', v)}
                  options={options}
                  placeholder={this.getPlaceholder(options)}
                  isDisabled={isEmpty(options)}
                  onMenuOpen={() =>
                    this.setState({
                      overflowOffset: 188,
                    })
                  }
                  onMenuClose={() => this.setState({ overflowOffset: 0 })}
                  isMulti
                />
              </S.FieldWrapper>
            </S.Row>
            <S.Row>
              <S.Label htmlFor="key-concepts-not">
                Ninguno de estos conceptos clave{' '}
                <S.FilterType>(NOT)</S.FilterType>
              </S.Label>
              <S.FieldWrapper id="key-concepts-not">
                <S.Select
                  active={this.getValueFromOptions('tag', 'not').length > 0}
                  value={this.getValueFromOptions('tag', 'not')}
                  name="keyConceptsNot"
                  id="keyConceptsNot"
                  onChange={v => this.formatFilter('tag', 'not', v)}
                  options={options}
                  placeholder={this.getPlaceholder(options)}
                  isDisabled={isEmpty(options)}
                  onMenuOpen={() =>
                    this.setState({
                      overflowOffset: offset,
                    })
                  }
                  onMenuClose={() => this.setState({ overflowOffset: 0 })}
                  isMulti
                />
              </S.FieldWrapper>
            </S.Row>
          </>
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderLocation = () => {
    const { options } = this.props
    const sortedLocations = sortStringsValues(options, 'label')

    const $location = (
      <S.Row key="location-or">
        <S.Label htmlFor="location" className="Field__label">
          Cualquiera de estas localizaciones:
        </S.Label>
        <S.FieldWrapper id="location">
          <S.Select
            $active={this.getValues('location', 'or').length > 0}
            value={this.getValues('location', 'or')}
            name="location"
            id="location"
            onChange={v => this.formatFilter('location', 'or', v)}
            options={sortedLocations}
            placeholder={this.getPlaceholder(options)}
            isDisabled={isEmpty(options)}
            onMenuOpen={() =>
              this.setState({ overflowOffset: getOffset(sortedLocations) })
            }
            onMenuClose={() => this.setState({ overflowOffset: 0 })}
            isMulti
          />
        </S.FieldWrapper>
      </S.Row>
    )

    return (
      <S.Form>
        <S.FormWrapper>
          {$location}
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderNps = () => {
    const { options } = this.props
    const sortedNps = sortAsNumericValues(options, 'value')

    return (
      <S.Form>
        <S.FormWrapper className="FilterForm">
          <S.Row>
            <S.Label htmlFor="NPS" className="Field__label">
              Cualquiera de estas valoraciones:
            </S.Label>

            <S.FieldWrapper id="NPS">
              <S.Select
                active={this.getValueFromOptions('NPS', 'or').length > 0}
                value={this.getValueFromOptions('NPS', 'or')}
                name="custom"
                id="custom"
                onChange={v => this.formatFilter('NPS', 'or', v)}
                options={sortedNps}
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onMenuOpen={() => this.setState({ overflowOffset: 280 })}
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti
              />
            </S.FieldWrapper>
          </S.Row>
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderOther = customField => {
    const { options } = this.props

    const sortedCustom = sortStringsValues(options, 'value').map(
      ({ name, columnKey }) => ({ label: name, value: columnKey }),
    )

    return (
      <S.Form>
        <S.FormWrapper className="FilterForm">
          <S.Row>
            <S.Label htmlFor={customField.name} className="Field__label">
              {customField.name}
            </S.Label>

            <S.FieldWrapper id={customField.name}>
              <S.Select
                active={
                  this.getValueFromOptions(customField.name, 'or').length > 0
                }
                value={this.getValueFromOptions(customField.name, 'or')}
                name={customField.name}
                id={customField.name}
                onChange={v => this.formatFilter(customField.name, 'or', v)}
                options={sortedCustom}
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onMenuOpen={() =>
                  this.setState({
                    overflowOffset: getOffset(sortedCustom),
                  })
                }
                onMenuClose={() =>
                  this.setState({
                    overflowOffset: 0,
                  })
                }
                isMulti
              />
            </S.FieldWrapper>
          </S.Row>
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderBenchmark = fieldName => {
    const { options } = this.props
    const sortedOptions = sortStringsValues(options, 'label')

    return (
      <S.Form>
        <S.FormWrapper className="FilterForm">
          <S.Row>
            <S.Label htmlFor="benchmark_or" className="Field__label">
              Cualquiera de estos valores comparativos
            </S.Label>

            <S.FieldWrapper id="benchmark_or">
              <S.Select
                active={this.getValueFromOptions(fieldName, 'or').length > 0}
                value={this.getValueFromOptions(fieldName, 'or')}
                name="benchmark"
                id="benchmark"
                onChange={v => this.formatFilter(fieldName, 'or', v)}
                options={sortedOptions}
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onMenuOpen={() => this.setState({ overflowOffset: 280 })}
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti
              />
            </S.FieldWrapper>
          </S.Row>
          <S.Row>
            <S.Label htmlFor="benchmark_not" className="Field__label">
              Ninguno de estos valores comparativos
            </S.Label>

            <S.FieldWrapper id="benchmark_not">
              <S.Select
                active={this.getValueFromOptions(fieldName, 'not').length > 0}
                value={this.getValueFromOptions(fieldName, 'not')}
                name="benchmark"
                id="benchmark"
                onChange={v => this.formatFilter(fieldName, 'not', v)}
                options={sortedOptions}
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onMenuOpen={() => this.setState({ overflowOffset: 280 })}
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti
              />
            </S.FieldWrapper>
          </S.Row>
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderSingleField = customField => {
    const filter = this.props.myFilters?.find(f => f.name === customField.name)

    const options = filter
      ? filter.options.map(option => ({
          label: option.name,
          value: option.name,
        }))
      : []

    const sortedCustom = sortStringsValues(options, 'label')

    return (
      <div key={`singleField-${customField.columnKey}`}>
        <S.Row>
          <S.Label htmlFor={customField.name} className="Field__label">
            {customField.name}
          </S.Label>

          <S.FieldWrapper id={customField.name}>
            <S.Select
              value={this.getValueFromOptions(customField.name, 'or', true)}
              name={customField.name}
              id={customField.name}
              onChange={v => this.formatFilter(customField.name, 'or', v)}
              options={sortedCustom}
              placeholder={this.getPlaceholder(options)}
              isDisabled={isEmpty(options)}
              cacheOptions
              onMenuOpen={() => {
                this.setState({
                  overflowOffset: getOffset(sortedCustom),
                })
              }}
              onMenuClose={() =>
                this.setState({
                  overflowOffset: 0,
                })
              }
              isMulti
            />
          </S.FieldWrapper>
        </S.Row>
      </div>
    )
  }

  renderMyFilters = customFields => {
    const { myFilters } = splitFilterTypes(customFields)
    return (
      <S.Form>
        <S.FormWrapper className="FilterForm">
          {this.props.myFiltersLoading ? (
            <S.Spinner />
          ) : (
            myFilters.map(this.renderSingleField)
          )}
          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  renderAnalysisColumns = () => {
    const { options } = this.props
    const offset = getOffset(options)

    return (
      <S.Form>
        <S.FormWrapper className="FilterForm">
          <S.Row>
            <S.Label htmlFor="contentFields_or" className="Field__label">
              Cualquiera de estas columnas:
            </S.Label>
            <S.FieldWrapper id="contentFields_or-wrapper">
              <S.Select
                active={
                  this.getValueFromOptions('contentFields', 'or').length > 0
                }
                value={this.getValueFromOptions('contentFields', 'or')}
                name="contentFields-or"
                id="contentFieldsOr"
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onChange={data =>
                  this.formatFilter('contentFields', 'or', data)
                }
                options={options}
                onMenuOpen={() => this.setState({ overflowOffset: 100 })}
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti={true}
              />
            </S.FieldWrapper>
            <S.Hint>
              Selecciona las columnas de mensajes analizados por los que deseas
              filtrar.
            </S.Hint>
          </S.Row>

          <S.Row>
            <S.Label htmlFor="analysis-columns" className="Field__label">
              Ninguna de estas columnas:
            </S.Label>
            <S.FieldWrapper id="contentFields_not-wrapper">
              <S.Select
                active={
                  this.getValueFromOptions('contentFields', 'not').length > 0
                }
                value={this.getValueFromOptions('contentFields', 'not')}
                name="contentFields-not"
                id="contentFieldsNot"
                onChange={v => this.formatFilter('contentFields', 'not', v)}
                options={options}
                placeholder={this.getPlaceholder(options)}
                isDisabled={isEmpty(options)}
                onMenuOpen={() =>
                  this.setState({
                    overflowOffset: offset,
                  })
                }
                onMenuClose={() => this.setState({ overflowOffset: 0 })}
                isMulti
              />
            </S.FieldWrapper>
            <S.Hint>
              Selecciona las columnas de mensajes analizados que deseas excluir.
            </S.Hint>
          </S.Row>

          <S.OverflowFix $offset={this.state.overflowOffset} />
        </S.FormWrapper>
      </S.Form>
    )
  }

  render() {
    const { type, documentFields } = this.props
    const documentField = documentFields.find(
      f =>
        f.columnKey.toLowerCase() === type.toLowerCase() ||
        f.name.toLowerCase() === type.toLowerCase(),
    )

    // Render fields inside selected filter
    switch (type) {
      case 'category':
        return this.renderCategories()
      case 'sentiment':
        return this.renderSentiments()
      case 'content':
        return this.renderTextSearch()
      case 'tag':
        return this.renderKeyConcepts()
      case 'NPS':
        return this.renderNps()
      case 'location':
        return this.renderLocation()
      case documentField && documentField.name:
      case documentField && documentField.columnKey: {
        const fieldIsNps = documentField.name.toLowerCase().includes('nps')
        if (fieldIsNps) {
          return this.renderNps()
        }

        if (documentField.type === DatasetAttributeType.BENCHMARK_COLUMN) {
          return this.renderBenchmark(documentField.name)
        }

        return this.renderOther(documentField)
      }
      case 'myFilters':
        return this.renderMyFilters(documentFields)
      case 'contentFields':
        return this.renderAnalysisColumns()
      default:
        return null
    }
  }
}

FilterForm.propTypes = propTypes
FilterForm.defaultProps = defaultProps

export default withRouter(FilterForm)
