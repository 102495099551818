import { FC, memo } from 'react'
import type { Props } from '../../types'

const More: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="2.25" cy="8.25" r="1.25" fill="currentColor" />
    <circle cx="7.75" cy="8.25" r="1.25" fill="currentColor" />
    <circle cx="13.25" cy="8.25" r="1.25" fill="currentColor" />
  </svg>
)

export default memo(More)
