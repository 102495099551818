import { CLIENTS_DOMAINS_PARAMS } from '@constants/clients'

/**
 * Composes an absolute path by joining together the passed path segments
 *
 * @param {string} ...paths Paths to join
 * @return {string} Absolute path
 */
export function composePath(...paths) {
  if (!paths.length) return '/'

  // Ensure that all the paths segments are strings
  paths = paths.map(String)

  let head = paths.shift()

  // Decide if we need to prepend a slash to the path
  const absolutePathReg = /(^https?:\/{2})|^\//
  if (!absolutePathReg.test(head)) {
    head = `/${head}`
  }

  // Ensure the head doesn't have a taling slash
  head = head.replace(/\/$/, '')

  // Compose the path by ensuring each segment ensuring they don't have tailing or leading
  // slashes
  return paths.reduce((path, segment) => {
    if (!segment) return path

    const cleanSegment = segment.replace(/^\/?(.+)\/?$/, '$1')
    return `${path}/${cleanSegment}`
  }, head)
}

/**
 * Returns the url absolute to the API
 */
export function getAPIUrl(...paths) {
  const api = process.env.REACT_APP_API_URL || 'https://backend.cx.sentisis.io'

  return composePath(api, ...paths)
}

export function getSocketUrl(...paths) {
  let api = getAPIUrl(...paths)
  api = api.replace('https', 'wss')
  api = api.replace('http', 'ws')
  return api
}

export function getPublicAPIUrl(...paths) {
  return getAPIUrl(...paths).replace('backend', 'api')
}

export function getPublicAPIDocsUrl(...paths) {
  const api =
    process.env.REACT_APP_APIDOCS_URL || 'https://apidocs.cx.sentisis.io'

  return composePath(api, ...paths)
}

export function getClientDomain() {
  const hostname = window.location.hostname
  const client = Object.keys(CLIENTS_DOMAINS_PARAMS).find(
    client => CLIENTS_DOMAINS_PARAMS[client].domain === hostname,
  )

  return (
    (CLIENTS_DOMAINS_PARAMS[client] && CLIENTS_DOMAINS_PARAMS[client].domain) ||
    ''
  )
}

/**
 * @return {{domain: string, logo: string, logoWidth: number, logoHeight: number, description: string, descriptionColor: string}}
 */
export function getCustomDomainParams() {
  const hostname = window.location.hostname
  const client = Object.keys(CLIENTS_DOMAINS_PARAMS).find(
    client => CLIENTS_DOMAINS_PARAMS[client].domain === hostname,
  )

  return CLIENTS_DOMAINS_PARAMS[client] || CLIENTS_DOMAINS_PARAMS.default
}
