enum EmotionsEnum {
  'risa',
  'aburrimiento',
  'asombro',
  'interes',
  'alegria',
  'indiferencia',
  'admiracion',
  'rechazo',
  'deseo',
  'enojo',
  'emotividad',
  'tristeza',
  'miedo',
  'expectacion',
  'duda',
}
enum EmotionsEmoji {
  'risa' = '😂',
  'aburrimiento' = '😑',
  'asombro' = '🤯',
  'interes' = '🧐',
  'alegria' = '😀',
  'indiferencia' = '😶',
  'admiracion' = '🤩',
  'rechazo' = '🤢',
  'deseo' = '😍',
  'enojo' = '😡',
  'emotividad' = '🥺',
  'tristeza' = '😭',
  'miedo' = '😨',
  'expectacion' = '😯',
  'duda' = '🤔',
}

enum EmotionsId {
  'risa' = '54492e273fb68a843f651bc1',
  'aburrimiento' = '5429b8293e778dc419c72aab',
  'asombro' = '5429b8293e778dc419c72aac',
  'interes' = '614c654317e4df001a82dfd9',
  'alegria' = '614c654317e4df001a82dfd7',
  'indiferencia' = '6149f9c017e4df001a82dfb8',
  'admiracion' = '614c654317e4df001a82dfd8',
  'rechazo' = '614d9e0f17e4df001a82dfe0',
  'deseo' = '614c654317e4df001a82dfd6',
  'enojo' = '54173bbfe74dce5c0ce37783',
  'emotividad' = '5523e918d598d61cc42a336a',
  'tristeza' = '5a9593b3d48e3390a0fc1c20',
  'miedo' = '5a9593b3d48e3390a0fc1c16',
  'expectacion' = '5523e917d598d61cc42a334f',
  'duda' = '59a6e508d48e3390a0491761',
}

enum EmotionsTxt {
  'risa' = 'Risa',
  'aburrimiento' = 'Aburrimiento',
  'asombro' = 'Asombro',
  'interes' = 'Interés',
  'alegria' = 'Alegría',
  'indiferencia' = 'Indiferencia',
  'admiracion' = 'Admiración',
  'rechazo' = 'Rechazo',
  'deseo' = 'Deseo',
  'enojo' = 'Enojo',
  'emotividad' = 'Emotividad',
  'tristeza' = 'Tristeza',
  'miedo' = 'Miedo',
  'expectacion' = 'Expectación',
  'duda' = 'Duda',
}

export type Emotion = {
  id: string
  name: string
  color: string
  text: string
}

const BASE_COLOR = '#7c85c1'

// translate mock

const t = (str: string) => str

const space = '\xa0'

function makeEmotion(value: string): Emotion | null {
  if (isNaN(Number(value))) {
    const key = value

    return {
      id: EmotionsId[key],
      color: BASE_COLOR,
      name: `${EmotionsEmoji[key]} ${space} ${t(EmotionsTxt[key])}`,
      text: `${t(EmotionsTxt[key])}`,
    }
  }
  return null
}

export const getEmotions: () => Emotion[] = () => {
  const emotions: Emotion[] = []
  for (const value in EmotionsEnum) {
    emotions.push(makeEmotion(value))
  }

  return emotions.filter(e => e)
}
