import Loadable from 'react-loadable'

import Loading from '@components/Loading'

const LoadableComponent = Loadable({
  loader: () => import('./Location'),
  loading: Loading,
  delay: 200,
  timeout: 10000,
})

const LoadableLocationView = props => <LoadableComponent {...props} />

export default LoadableLocationView
