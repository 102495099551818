import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { CANCEL_JOB, DELETE_JOB, UPDATE_JOB } from 'apollo/mutations/job'
import type {
  UpdateJobMutation,
  UpdateJobMutationVariables,
  DeleteJobMutation,
  DeleteJobMutationVariables,
  CancelJob,
  CancelJobVariables,
} from '@customTypes/graphql-public'

const useJobActions = () => {
  const [updateJobMutation, { loading: updateLoading, error: updateError }] =
    useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UPDATE_JOB)
  const [deleteJobMutation, { loading: deleteLoading, error: deleteError }] =
    useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DELETE_JOB)
  const [cancelJobMutation, { loading: cancelLoading, error: cancelError }] =
    useMutation<CancelJob, CancelJobVariables>(CANCEL_JOB)

  const updateJob = useCallback(
    async (id: string, name: string): Promise<string> => {
      const response = await updateJobMutation({ variables: { id, name } })

      if (response?.data?.updateJob?.id) {
        return response.data.updateJob.id
      }
    },
    [updateJobMutation],
  )

  const deleteJob = useCallback(
    async (id: string): Promise<void> => {
      await deleteJobMutation({ variables: { id } })
    },
    [deleteJobMutation],
  )

  const cancelJob = useCallback(
    async (id: string): Promise<void> => {
      await cancelJobMutation({ variables: { id } })
    },
    [cancelJobMutation],
  )

  return {
    cancelJob,
    deleteJob,
    updateJob,
    jobActionsError: updateError || deleteError || cancelError,
    jobActionsLoading: updateLoading || deleteLoading || cancelLoading,
  }
}

export default useJobActions
