import styled, { css } from 'styled-components'
import IconDefault from '@components/Icon'

export const Content = styled.div<{ $type: string }>`
  background-color: black;
  border-radius: 4px;
  display: flex;
  padding: 12px 16px;
  max-width: 572px;

  ${({ theme, $type }) => {
    const colorMap = {
      error: {
        bg: theme.colours.red[80],
      },
      info: {
        bg: theme.colours.gray[100],
      },
    }

    return css`
      background-color: ${colorMap[$type].bg};
    `
  }}
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Icon = styled(IconDefault)`
  color: ${({ theme }) => theme.colours.white};
  width: 20px;
  height: 20px;
`

export const CloseIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`

export const TextContainer = styled.div`
  padding: 0 12px;
  color: ${({ theme }) => theme.colours.white};
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const Desc = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
`

export const CloseContainer = styled.div``

export const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`
