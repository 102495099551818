import Add from './Icons/Add'
import Alert from './Icons/Alert'
import Analysis from './Icons/Analysis'
import ArrowDown from './Icons/ArrowDown'
import ArrowRight from './Icons/ArrowRight'
import Calendar from './Icons/Calendar'
import Categories from './Icons/SidebarIcons/Categories'
import Check from './Icons/Check'
import ChevronClosed from './Icons/Chevron/ChevronClosed'
import ChevronLeft from './Icons/Chevron/ChevronLeft'
import ChevronOpen from './Icons/Chevron/ChevronOpen'
import ChevronRight from './Icons/Chevron/ChevronRight'
import ClipboardFilled from './Icons/ClipboardFilled'
import Close from './Icons/Close'
import Config from './Icons/Config'
import Customers from './Icons/SidebarIcons/Customers'
import CX from './Icons/SidebarIcons/CX'
import Dashboard from './Icons/SidebarIcons/Dashboard'
import Done from './Icons/Done'
import DownloadIcon from './Icons/Download'
import Edit from './Icons/Edit'
import Error from './Icons/Error'
import Evolution from './Icons/Evolution'
import EyeClosed from './Icons/Eye/Closed/EyeClosed'
import EyeOpen from './Icons/Eye/Open/EyeOpen'
import Info from './Icons/Info'
import Insight from './Icons/Insight'
import InputAlert from './Icons/Alerts/InputAlert'
import KeyConcepts from './Icons/SidebarIcons/KeyConcepts'
import Learning from './Icons/SidebarIcons/Learning'
import Localization from './Icons/SidebarIcons/Localization'
import Logout from './Icons/SidebarIcons/Logout'
import Mention from './Icons/SidebarIcons/Mention'
import More from './Icons/SidebarIcons/More'
import NavigationArrowLeft from './Icons/NavigationArrowLeft'
import Notification from './Icons/SidebarIcons/Notification'
import OfficialAccounts from './Icons/SidebarIcons/OfficialAccounts'
import OpenUrl from './Icons/OpenUrl'
import Pin from './Icons/Pin'
import Projects from './Icons/SidebarIcons/Projects'
import Refresh from './Icons/Refresh'
import Reports from './Icons/SidebarIcons/Reports'
import Settings from './Icons/SidebarIcons/Settings'
import SmallSpinner from './Icons/SmallSpinner'
import StepsArrowLeft from './Icons/StepsArrowLeft'
import StepsArrowRight from './Icons/StepsArrowRight'
import Summary from './Icons/SidebarIcons/Summary'
import Teams from './Icons/SidebarIcons/Teams'
import Tic from './Icons/Tic'
import Trash from './Icons/Trash'
import Trends from './Icons/SidebarIcons/Trends'
import TvPanel from './Icons/SidebarIcons/TvPanel'
import Upload from './Icons/Upload'
import UserFeatures from './Icons/SidebarIcons/UserFeatures'
import Users from './Icons/SidebarIcons/Users'
import Warning from './Icons/Warning'

export const ICONS = {
  add: Add,
  alert: Alert,
  analysis: Analysis,
  arrowDown: ArrowDown,
  arrowRight: ArrowRight,
  calendar: Calendar,
  categories: Categories,
  check: Check,
  chevronClosed: ChevronClosed,
  chevronLeft: ChevronLeft,
  chevronOpen: ChevronOpen,
  chevronRight: ChevronRight,
  clipboardFilled: ClipboardFilled,
  close: Close,
  config: Config,
  customers: Customers,
  cx: CX,
  dashboard: Dashboard,
  done: Done,
  download: DownloadIcon,
  edit: Edit,
  error: Error,
  evolution: Evolution,
  eyeClosed: EyeClosed,
  eyeOpen: EyeOpen,
  info: Info,
  insight: Insight,
  inputAlert: InputAlert,
  keyConcepts: KeyConcepts,
  learning: Learning,
  localization: Localization,
  logout: Logout,
  mention: Mention,
  more: More,
  navigationArrowLeft: NavigationArrowLeft,
  notification: Notification,
  officialAccounts: OfficialAccounts,
  openUrl: OpenUrl,
  pin: Pin,
  projects: Projects,
  refresh: Refresh,
  reports: Reports,
  settings: Settings,
  smallSpinner: SmallSpinner,
  stepsArrowLeft: StepsArrowLeft,
  stepsArrowRight: StepsArrowRight,
  summary: Summary,
  teams: Teams,
  tic: Tic,
  trash: Trash,
  trends: Trends,
  tvPanel: TvPanel,
  upload: Upload,
  userFeatures: UserFeatures,
  users: Users,
  warning: Warning,
}
