import styled from 'styled-components'
import { IconButton as MUIIconButton, Typography } from '@material-ui/core'

export const IconTypography = styled(Typography)<{ $color: string }>`
  && {
    color: ${({ $color }) => $color};
    cursor: pointer;
    flex-grow: 1;
    font-family: "Suisse BP int'l", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-top: 1rem;
  }
`

export const IconButton = styled(MUIIconButton)`
  && {
    margin-left: 8px;
    margin-right: 8px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`
