import { FC, memo } from 'react'
import { Props } from '../types'

const Insight: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M17.775 5.825L16.925 4.025L15.125 3.2L16.925 2.35L17.775 0.549999L18.6 2.35L20.4 3.2L18.6 4.025L17.775 5.825ZM6.525 5.825L5.675 4.025L3.875 3.2L5.675 2.35L6.525 0.549999L7.35 2.35L9.15 3.2L7.35 4.025L6.525 5.825ZM17.775 17.075L16.925 15.275L15.125 14.45L16.925 13.6L17.775 11.8L18.6 13.6L20.4 14.45L18.6 15.275L17.775 17.075ZM3.15 20.075L0.875 17.825C0.708333 17.6417 0.625 17.425 0.625 17.175C0.625 16.925 0.708333 16.7167 0.875 16.55L11.5 5.925C11.6833 5.75833 11.9 5.675 12.15 5.675C12.4 5.675 12.6083 5.75833 12.775 5.925L15.05 8.2C15.2167 8.36667 15.3 8.575 15.3 8.825C15.3 9.075 15.2167 9.29167 15.05 9.475L4.425 20.075C4.24167 20.2583 4.025 20.35 3.775 20.35C3.525 20.35 3.31667 20.2583 3.15 20.075ZM3.8 18.6L10.95 11.4L9.55 10L2.35 17.175L3.8 18.6Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Insight)
