import { useCallback, useContext, useEffect, useState } from 'react'
import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'
import getClients from 'apollo/requests/getClients'
import getTeams from 'apollo/requests/getTeams'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useProjectActions from 'apollo/hooks/project/useProjectActions'
import { AuthContext } from '@contexts/Auth'
import { initialValues, createProyectoValidationSchema } from './constants'
import { transformDataToOptions } from '@utils/general'
import type { ProjectFormFields } from 'models/Project'
import type { Option } from '@customTypes/shared'

const useLogic = () => {
  const { createProject, projectActionsLoading } = useProjectActions()
  const { modalType, onCloseModal } = useModal()
  const { context } = useContext(AuthContext)

  const user = context?.user

  const [clientsOptions, setClientsOptions] = useState<Option<string>[]>([])
  const [teamsOptions, setTeamsOptions] = useState<Option<string>[]>([])

  const createProjectForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(createProyectoValidationSchema),
    defaultValues: initialValues,
  })

  const clientId = createProjectForm.watch('clientId')

  const handleGetClients = useCallback(async () => {
    const clients = await getClients()

    const options = transformDataToOptions(clients)

    setClientsOptions(options)
  }, [])

  useEffect(() => {
    if (user) {
      if (user.role?.clientManagement) {
        handleGetClients()
      } else if (user.client?.id) {
        setClientsOptions([{ value: user.client.id, label: user.client.name }])
      }
    }
  }, [handleGetClients, user])

  const handleGetTeams = useCallback(async (clientId: string) => {
    const teams = await getTeams({ clientId })

    const options = transformDataToOptions(teams)

    setTeamsOptions(options)
  }, [])

  useEffect(() => {
    if (clientId) {
      handleGetTeams(clientId)
    }
  }, [clientId, handleGetTeams])

  const handleOnSubmit = useCallback(
    async (data: ProjectFormFields) => {
      await createProject({
        description: data.description,
        teamId: data.teamId,
        name: data.name,
      })

      onCloseModal()
    },

    [createProject, onCloseModal],
  )

  return {
    clientsOptions,
    createProjectForm,
    handleOnSubmit,
    isLoading: projectActionsLoading,
    isOpen: modalType === ModalType.CREATE_PROJECT,
    onCloseModal,
    teamsOptions,
    user,
  }
}

export default useLogic
