import { gql } from '@apollo/client'

// TODO these query have just been moved from one file to another, we need to check them.
export const FILTERS_QUERY = gql`
  query FiltersQuery(
    $projectId: ID!
    $datasetId: ID!
    $key: String!
    $withColumnKey: Boolean!
  ) {
    project(id: $projectId) {
      id
      name
      sharingConfig {
        sentimentRead
        sentimentUpdate
        categoryUpdate
        categoryRead
        categories
      }
      dataset(id: $datasetId) {
        id
        name
        contentColumnsKeys
        location {
          columnKey
        }
        filterValues: values(key: $key) @include(if: $withColumnKey) {
          ... on LocationValue {
            location {
              country
              county
              region
            }
          }
          ... on CategoryValue {
            category {
              name
            }
          }
          ... on SentimentValue {
            sentiment
          }
          ... on SimpleValue {
            name
          }
        }
        documentFields: attributes {
          columnKey: key
          name: value
          type
        }
      }
    }
  }
`

export const DATASET_FILTERS_OPTIONS_QUERY = gql`
  query DatasetFiltersOptionsQuery(
    $projectId: ID!
    $datasetId: ID!
    $withColumnKey: Boolean!
  ) {
    project(id: $projectId) {
      id
      name
      dataset(id: $datasetId) {
        id
        name
        contentColumnsKeys
        filtersOptions: datasetFiltersOptions @include(if: $withColumnKey) {
          name
          options {
            ... on LocationValue {
              location {
                country
                county
                region
              }
            }
            ... on CategoryValue {
              category {
                name
              }
            }
            ... on SentimentValue {
              sentiment
            }
            ... on SimpleValue {
              name
            }
          }
        }
      }
    }
  }
`

export const GET_DATASET_METRICS = gql`
  query DatasetMetric(
    $projectId: ID!
    $datasetId: ID!
    $filters: Filters
    $benchmarkKey: String!
    $withBenchmark: Boolean!
  ) {
    project(id: $projectId) {
      id
      name
      client {
        openaiInsightsEnabled
      }
      dataset(id: $datasetId) {
        name
        nps
        netNps(filters: $filters) {
          count
          prevCount
        }
        allDocuments(filters: $filters) {
          totalCount
          previousPeriodCount
        }
        sentiments: groupBy(filters: $filters, key: "sentiment") {
          ... on SentimentGroup {
            count
            prevCount
            sentiment
          }
        }
        npsScores: groupBy(
          filters: $filters
          key: "NPS"
          first: 11
          withSentiment: false
        ) {
          ... on CustomGroup {
            name: key
            count
            prevCount
          }
        }
        topBenchmark: groupBy(
          filters: $filters
          key: $benchmarkKey
          first: 10
          withSentiment: false
          withNps: true
          withPrevState: true
        ) @include(if: $withBenchmark) {
          ... on CustomGroup {
            name: key
            count
            byNps {
              nps
              count
            }
            prevState {
              name: key
              count
              byNps {
                nps
                count
              }
            }
          }
        }
        topCategories: groupBy(
          filters: $filters
          key: "category"
          first: 9999
          withSentiment: true
          withNps: true
        ) {
          ... on CategoryGroup {
            count
            category {
              name
            }
            byNps {
              nps
              count
            }
            bySentiment {
              sentiment
              count
            }
          }
        }
      }
    }
  }
`

export const GET_TAGS_BY_SENTIMENT = gql`
  query TagsBySentiment(
    $projectId: ID!
    $datasetId: ID!
    $first: Int = 20
    $filters: Filters
  ) {
    project(id: $projectId) {
      id
      name
      dataset(id: $datasetId) {
        name
        tags: groupBy(
          key: "tag"
          first: $first
          withSentiment: true
          filters: $filters
        ) {
          ... on TagGroup {
            count
            tag {
              name
            }
            bySentiment {
              sentiment
              count
            }
          }
        }
      }
    }
  }
`

export const GET_DATASET_FIELDS = gql`
  query DatasetFieldsQuery(
    $projectId: ID!
    $datasetId: ID!
    $withLocation: Boolean!
    $withDate: Boolean!
  ) {
    project(id: $projectId) {
      id
      dataset(id: $datasetId) {
        id
        contentColumnsKeys
        hasLocation: byLocation(filters: {}) {
          count
        }
        hasDate: date {
          datasetDateRange: range {
            start: from
            end: to
          }
        }
        dateKey: date @include(if: $withDate) {
          columnKey
        }
        locationKey: location @include(if: $withLocation) {
          columnKey
        }
        documentFieldKeys: attributes(type: DOCUMENT_COLUMN) {
          columnKey: key
        }
      }
    }
  }
`

export const GET_DATASET_QUERY = gql`
  query DatasetQuery($projectId: ID!, $datasetId: ID!, $filters: Filters) {
    project(id: $projectId) {
      id
      name
      sharingConfig {
        sentimentRead
        sentimentUpdate
        categoryUpdate
        categoryRead
        categories
      }
      dataset(id: $datasetId) {
        id
        name
        contentColumnsKeys
        byLocation(filters: {}) {
          count
        }
        documentFields: attributes(type: DOCUMENT_COLUMN) {
          name: value
        }
        byTheme(filters: $filters) {
          count
          prevCount
          theme
          nps
          netNps
          bySentiment {
            count
            sentiment
          }
          byCategory {
            category
            count
            prevCount
            nps
            netNps
            bySentiment {
              count
              sentiment
            }
          }
        }
      }
    }
  }
`

export const GET_DATASET_INSIGHTS = gql`
  query GetSummaryInsights($input: SummaryInsightsInput!) {
    getSummaryInsights(input: $input)
  }
`

export const GET_DATASET_TAGS = gql`
  query GetDatasetTags(
    $datasetId: ID!
    $filters: Filters
    $order: GroupOrdering
    $first: Int
    $after: Int = 0
  ) {
    getDataseTags(
      datasetId: $datasetId
      filters: $filters
      order: $order
      first: $first
      after: $after
    ) {
      total
      tags {
        tag
        count
        prevCount
        evolution
        netNpsCount {
          count
          prevCount
          evolution
        }
        sentimentsCount {
          count
          sentiment
        }
      }
    }
  }
`
