import styled from 'styled-components/macro'

export const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header header'
    'sidebar view';
  height: 100%;
  position: relative;
`

export const Main = styled.main<{ withSidebar: boolean; withFilters: boolean }>`
  height: 100%;
  grid-area: view;
  min-height: 0;
  overflow: ${({ withFilters, withSidebar }) =>
    withFilters || withSidebar ? 'unset' : 'auto'};
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'filters'
    'content';
`

export const FiltersWrapper = styled.div<{ isCollapsed: boolean }>`
  grid-area: filters;
  z-index: 2;
  max-height: 600px;
`

export const Body = styled.div<{ withSidebar: boolean; withFilters: boolean }>`
  align-self: center;
  flex: 1 0 0;
  max-width: ${({ withFilters, withSidebar }) =>
    !withFilters && !withSidebar && '1400px'};
  ${({ withFilters, withSidebar }) =>
    (withFilters || withSidebar) &&
    `
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    `}
  width: 100%;
  margin: 0 auto;
  grid-area: content;
  height: 100%;
`
