import { FC, memo } from 'react'
import type { Props } from '../types'

const Check: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.4425 6.01837L7.5 10.9609L4.8075 8.27587L3.75 9.33337L7.5 13.0834L13.5 7.08337L12.4425 6.01837ZM9 1.83337C4.86 1.83337 1.5 5.19337 1.5 9.33337C1.5 13.4734 4.86 16.8334 9 16.8334C13.14 16.8334 16.5 13.4734 16.5 9.33337C16.5 5.19337 13.14 1.83337 9 1.83337ZM9 15.3334C5.685 15.3334 3 12.6484 3 9.33337C3 6.01837 5.685 3.33337 9 3.33337C12.315 3.33337 15 6.01837 15 9.33337C15 12.6484 12.315 15.3334 9 15.3334Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Check)
