export const getPercentage = (value: number, total: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  })
  return formatter.format((100 * value) / total)
}

export const SetNPSText = (textValue: string, isPlural = true) => {
  if (textValue === 'NPS') {
    return isPlural ? 'Valoraciones' : 'Valoración'
  }
  return textValue
}
