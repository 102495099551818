import styled from 'styled-components/macro'
import { Paper } from '@material-ui/core'
import Icon from '@components/Icon'
import { Popover as MaterialPopover } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  height: 3rem;
  min-width: 18rem;
  width: 30%;
  margin-left: auto;
`

export const Content = styled.div`
  min-width: 100%;
`

export const Popover = styled(MaterialPopover)`
  .MuiPopover-paper {
    box-shadow: -7px 11px 10px 0px rgba(161, 161, 161, 0.25)
  }
`

export const DateRangeContainer = styled(Paper)`
  padding: 1.3em;
`

export const CalendarIcon = styled(Icon)``

export const DateRangeButton = styled.div<{$open: boolean}>`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25em 1em;
  position: relative;
  background-color: ${({ theme, $open }) => $open ? theme.colours.gray[10] : 'transparent' };
  cursor: pointer;
  ${CalendarIcon} {
    color: ${ ({theme, $open}) => $open ? theme.colours.violet[70] : 'currentColor' }
  }
  :hover {
    background-color: ${({ theme }) => theme.colours.gray[10] };
    ${CalendarIcon} {
      color: ${ ({theme}) => theme.colours.violet[70] }
    }
  }
`

export const OpenIcon = styled(Icon)`
  position: relative;
  top: 5px;
`

export const DateRangeButtonContent = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 0.5em;
`

export const DatesContainer = styled.span`
  padding-left: 0.6em;
`