import styled from 'styled-components'
import { Menu as MUIMenu, MenuItem as MUIMenuItem } from '@material-ui/core'

export const Menu = styled(MUIMenu)`
  && {
    ul {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

export const MenuItem = styled(MUIMenuItem)`
  && {
    font-size: 14px;
  }
`
