import { FC, memo } from 'react'
import TableMaterial from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import EnhancedTableHead from './TableHead/TableHead'
import Spinner from '@components/Spinner'
import { Props } from './types'
import { defaultRowsPerPageOptions } from './constants'
import useLogic from './logic'
import * as S from './styles'

const Table: FC<Props> = ({
  columns,
  rows,
  rowsTotal,
  rowId,
  defaultRowsPerPage = 10,
  defaultOrderBy = null,
  defaultOrder = 'asc',
  isLoading,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  stickyHeight,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
}) => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useLogic({
    defaultOrder,
    defaultOrderBy,
    defaultRowsPerPage,
    onSortChange,
    onPageChange,
    onRowsPerPageChange,
  })

  return (
    <S.Container>
      {isLoading ? (
        <S.SpinnerContainer>
          <Spinner />
        </S.SpinnerContainer>
      ) : (
        <>
          <S.TableContainer $height={stickyHeight}>
            <TableMaterial size="medium" stickyHeader={Boolean(stickyHeight)}>
              <EnhancedTableHead
                headCells={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rows.map(row => {
                  return (
                    <S.TableRow hover tabIndex={-1} key={row[rowId]}>
                      {columns.map(column => {
                        const CellContent = column.component
                        const onClick = column.onValueClick

                        return (
                          <S.TableCell
                            $align={column.align}
                            key={`${row[rowId]}-${column.id}`}
                          >
                            {CellContent && <CellContent rowData={row} />}
                            {!CellContent && (
                              <S.Clickable
                                $addStyles={Boolean(onClick)}
                                onClick={() => onClick && onClick(row)}
                              >
                                {row[column.key]}
                              </S.Clickable>
                            )}
                          </S.TableCell>
                        )
                      })}
                    </S.TableRow>
                  )
                })}
              </TableBody>
            </TableMaterial>
          </S.TableContainer>

          {onPageChange &&
            onRowsPerPageChange &&
            rowsPerPageOptions &&
            rowsTotal && (
              <S.TablePagination
                labelRowsPerPage="Elementos por página:"
                rowsPerPageOptions={rowsPerPageOptions}
                count={rowsTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
        </>
      )}
    </S.Container>
  )
}

export default memo(Table)
