import * as React from 'react'
import { kebabCase } from 'lodash/fp'
import { getColor } from '@utils/colors'
import styled from 'styled-components/macro'

interface Option {
  label: string
  onClick?: () => void
  children?: React.ReactNode
  value?: string
  colorHover?: string
}

interface Props {
  label: string
  onClick: () => void
  children?: any
  value?: string
  colorHover?: string
}

export default class SingleDropdownOption extends React.Component<
  Props,
  { isChildrenVisible: boolean }
> {
  state = {
    isChildrenVisible: false,
  }

  handleMouseEnter = () => {
    this.setState({ isChildrenVisible: true })
  }

  handleMouseLeave = () => {
    this.setState({ isChildrenVisible: false })
  }

  submenuRef = (el: any) => {
    if (!el) return

    const bcr = el.getBoundingClientRect()

    if (bcr.right > window.innerWidth - 20) {
      // The submenu doesn't fit, needs to be moved to the other direction.
      el.classList.add('is-left')
    }
  }

  render() {
    const { onClick, label, children, value } = this.props

    // TODO: Refactor
    /* eslint-disable */
    let content = (
      <Li
        key={`option-${kebabCase(label)}`}
        color={value}
        onClick={onClick}
        role="menuitem"
        colorHover={this.props.colorHover}
      >
        {label}
      </Li>
    )
    /* eslint-enable */
    let caret

    if (children) {
      let childrenContent
      caret = <span className="caret caret-right" />

      if (this.state.isChildrenVisible) {
        const childrenOptions = this.props.children.map((option: Option) => (
          <SingleDropdownOption
            key={`option-${kebabCase(option.label)}`}
            label={option.label}
            onClick={option.onClick}
            value={option.value}
          />
        ))
        childrenContent = (
          <ul ref={this.submenuRef} className="c-ButtonDropdown-submenu">
            {childrenOptions}
          </ul>
        )
      }

      // TODO: Refactor
      /* eslint-disable */
      content = (
        <Li
          key={`option-${kebabCase(label)}`}
          role="menuitem"
          onClick={onClick}
          color={value}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          colorHover={this.props.colorHover}
        >
          {label}
          {caret}
          {childrenContent}
        </Li>
      )
      /* eslint-enable */
    }
    return content
  }
}
const Li = styled.li<{ color: string; colorHover?: string }>`
  &&&:hover {
    color: ${(props: any) =>
      props.colorHover ? props.colorHover : getColor(props.color)};
  }
`
