import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import EvolutionArrowDefault from '@components/EvolutionArrow'

export const Container = styled.div`
  padding: 24px 0 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Card = styled(Paper)``

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 12px;
`

export const CardTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colours.gray[100]};
`

export const EvolutionArrow = styled(EvolutionArrowDefault)`
  justify-content: right;
`
