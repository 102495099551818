import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 1.3em;
`

export const DatePickerContainer = styled.div<{$monthMaxDate: number; $monthMinDate: number }>`
  .react-datepicker__current-month{
    display: none;
  }
  .react-datepicker__navigation {
    display: none;
  }
  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.2em;
  }
  
  .react-datepicker__year-dropdown-container,
  .react-datepicker__month-dropdown-container {
    position: relative;

    :hover {
      .react-datepicker__month-dropdown,
      .react-datepicker__year-dropdown {
        border-bottom-color: ${({theme}) => theme.colours.violet[30] };
        border-left-color: ${({theme}) => theme.colours.violet[30] };
        border-right-color: ${({theme}) => theme.colours.violet[30] };
      }
      .react-datepicker__month-read-view,
      .react-datepicker__year-read-view {
        border-color: ${({theme}) => theme.colours.violet[30] };
      }
    }
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    text-align: left;
    left: 0;
    border: 1px solid ${ ({ theme }) => theme.colours.gray[20] };
    border-radius: 0 0 4px 4px;
  }

  .react-datepicker__month-dropdown {
    width: 198px;
  }

  .react-datepicker__year-dropdown {
    width: 95px;
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    max-height: 21em;
    overflow-y: auto;
    top: 30px;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option {
    padding: 0.6em 0.8em;
    background-color: ${({ theme }) => theme.colours.white };
    text-transform: capitalize;
    border: 0;
    border-radius: 0;

    :hover {
      background-color: ${({ theme }) => theme.colours.gray[10] };
    }
  }

  .react-datepicker__month-read-view,
  .react-datepicker__year-read-view {
    border: 1px solid ${ ({ theme }) => theme.colours.gray[20] };
    background-color: white;
    border-radius: 4px;
    padding: 0.5em 0.8em;
    text-align: left;
    width: 100%;
    visibility: visible !important;
    text-transform: capitalize;
  }

  .react-datepicker__month-dropdown-container {
    width: 198px;
  }

  .react-datepicker__year-dropdown-container {
    width: 95px;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__month-option:nth-child(-n+${({ $monthMinDate }) => $monthMinDate !== null ? $monthMinDate : null }){
    display: none;
  }

  .react-datepicker__month-option:nth-child(n+${({ $monthMaxDate }) => $monthMaxDate !== null ? ($monthMaxDate + 2) : null }){
    display: none;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 0;
    width: 42px;
    border-radius: 0;
  }

  .react-datepicker__day {
    height: 32px;
    line-height: 32px;
    color: ${({ theme }) => theme.colours.gray[80]};
    :hover {
      color: ${({ theme }) => theme.colours.gray[90]};
    }
  }

  .react-datepicker__day-name {
    color: ${({theme}) => theme.colours.gray[50] }
  }

  .react-datepicker {
    border: none
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
    padding-bottom: 0;
  }

  .react-datepicker__month-option--selected,
  .react-datepicker__year-option--selected {
    display: none;
  }

  .react-datepicker__month-option--selected_month,
  .react-datepicker__year-option--selected_year {
    background-color: ${ ({ theme }) => theme.colours.gray[10] };
    color: ${ ({ theme }) => theme.colours.violet[70] };
  }

  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colours.gray[10]};
    color: ${({ theme }) => theme.colours.gray[90]};

    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end {
      background-color: ${({ theme }) => theme.colours.gray[20]};
      color: ${({ theme }) => theme.colours.gray[90]};
    }
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colours.gray[20]} !important;
    color: ${({ theme }) => theme.colours.gray[90]} !important;
  }

  .react-datepicker__day--in-selecting-range {
    :hover {
      background-color: ${({ theme }) => theme.colours.gray[20]};
      color: ${({ theme }) => theme.colours.gray[90]};
    }
  }

  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
    background-color: ${({theme}) => theme.colours.gray[10]};
    color: ${({ theme }) => theme.colours.gray[90]};
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    left: 0px;
    transform: rotate(0deg) scale(0.6);
    top: 7px;
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colours.gray[50]};
    cursor: not-allowed;
    :hover {
      color: ${({ theme }) => theme.colours.gray[50]};
    }
  }
`