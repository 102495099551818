import { FC, memo } from 'react'

import { Props } from '../types'

const ArrowDown: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={(size * 10) / 7}
    viewBox="0 0 11 7"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...rest}
  >
    <title>A5C46D54-0F73-402E-A2AB-108BF5624884</title>

    <defs>
      <polygon id="path-1" points="0 0 300 0 300 520 0 520"></polygon>

      <filter
        x="-0.5%"
        y="-0.3%"
        width="102.0%"
        height="101.2%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset
          dx="2"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>

        <feGaussianBlur
          stdDeviation="0.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>

        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.04 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>

    <g id="v4-new" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Mensajes---Result-box-NPS---Deployed"
        transform="translate(-230.000000, -889.000000)"
      >
        <rect fill="#F2F4F7" x="0" y="0" width="1280" height="1440"></rect>

        <g id="Métricas---NPS" transform="translate(210.000000, 783.000000)">
          <g id="Background">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            />

            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
          </g>

          <g
            id="promotores"
            transform="translate(20.000000, 68.000000)"
            fill="#C5CBD3"
          >
            <g
              transform="translate(0.000000, 32.000000)"
              id="icons/triangle/down-copy-3"
            >
              <g transform="translate(0.000000, 6.000000)">
                <polygon
                  id="Triangle-Copy-18"
                  transform="translate(5.500000, 3.500000) scale(1, -1) translate(-5.500000, -3.500000) "
                  points="5.5 0 11 7 0 7"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default memo(ArrowDown)
