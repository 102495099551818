import { Component, ReactNode } from 'react'
// import { Prompt } from 'react-router-dom'

import Uppy, { UppyFile } from '@uppy/core'
import AwsS3Multipart, { AwsS3Part } from '@uppy/aws-s3-multipart'

import StatusBar from '@uppy/react/lib/StatusBar'

import './StatusBar.scss'
import { getAPIUrl } from '@utils/index'
import UploadContext from '@contexts/UploadContext'

/**
 * Types
 */

interface Props {
  children: ReactNode
}

interface State {
  error: string
  isUploading: boolean
  datasetId?: string
  delimiter?: string
  startUpload: (datasetId: string, file: File, delimiter: string) => void
  cancelUpload: () => void
}

export enum UploadErrorsEnum {
  MontlyQuotaReached = 'Límite mensual alcanzado',
}

/**
 * Helpers
 */

const fetchOptions: Partial<any> = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
}

/**
 * Component
 */

class UploadProvider extends Component<Props, State> {
  state: State = {
    error: '',
    isUploading: false,

    startUpload: (datasetId: string, file: File, delimiter: string): void => {
      if (this.state.isUploading) {
        this.setState({ error: 'Upload already in progress' })
      }

      this.uppy.reset()

      this.setState({
        isUploading: true,
        datasetId,
        delimiter,
      })

      this.uppy.addFile({
        name: file.name,
        type: 'text/csv',
        data: file,
      } as any)
    },

    cancelUpload: (): void => {
      this.uppy.reset()

      this.setState({
        isUploading: false,
      })
    },
  }

  uppy = Uppy({
    autoProceed: true,
    debug: process.env.NODE_ENV !== 'production',
  })

  componentDidMount() {
    this.uppy.use(AwsS3Multipart, {
      createMultipartUpload: this.createMultipartUpload,
      listParts: this.listParts,
      prepareUploadPart: this.prepareUploadPart,
      abortMultipartUpload: this.abortMultipartUpload,
      completeMultipartUpload: this.completeMultipartUpload,
    })
  }

  componentWillUnmount() {
    this.uppy.close()
  }

  createMultipartUpload = (): Promise<{ uploadId: string; key: string }> => {
    const { datasetId, delimiter } = this.state
    if (!datasetId) {
      throw new Error('Could not start upload: missing datasetId')
    }

    return fetch(getAPIUrl('/upload/new'), {
      method: 'POST',
      ...fetchOptions,
      body: JSON.stringify({ datasetId, delimiter }),
    }).then(res => res.json())
  }

  listParts = (
    file: UppyFile,
    { uploadId }: { uploadId: string; key: string },
  ): Promise<AwsS3Part> => {
    return fetch(getAPIUrl(`/upload/${uploadId}`), {
      method: 'GET',
      ...fetchOptions,
    }).then(res => res.json())
  }

  prepareUploadPart = (
    file: UppyFile,
    {
      uploadId,
      number: partNumber,
    }: { uploadId: string; key: string; body: Blob; number: number },
  ): Promise<{ url: string }> => {
    return fetch(getAPIUrl(`/upload/${uploadId}/${partNumber}`), {
      method: 'PUT',
      ...fetchOptions,
    }).then(res => res.json())
  }

  abortMultipartUpload = (
    file: UppyFile,
    { uploadId }: { uploadId: string; key: string },
  ): Promise<void> => {
    return fetch(getAPIUrl(`/upload/${uploadId}`), {
      method: 'DELETE',
      ...fetchOptions,
    }).then(() => undefined)
  }

  completeMultipartUpload = (
    file: UppyFile,
    { uploadId, parts }: { uploadId: string; key: string; parts: AwsS3Part[] },
  ): Promise<{ location?: string }> => {
    return fetch(getAPIUrl(`/upload/${uploadId}/complete`), {
      ...fetchOptions,
      method: 'POST',
      body: JSON.stringify({ parts }),
    })
      .then(response => {
        if (response.status === 429) {
          this.setState({
            isUploading: false,
            error: UploadErrorsEnum.MontlyQuotaReached,
          })
          return {}
        }

        this.setState({ isUploading: false })
        return {}
      })
      .catch(err => {
        this.setState({ isUploading: false })
        return {}
      })
  }

  render() {
    const { children } = this.props
    // const { isUploading } = this.state

    return (
      <UploadContext.Provider value={this.state}>
        {children}

        <StatusBar uppy={this.uppy} showProgressDetails />

        {/*<Prompt
          when={isUploading}
          message="La subida de un archivo se esta llevando a cabo. ¿Está seguro de querer continuar?"
        />*/}
      </UploadContext.Provider>
    )
  }
}

export default UploadProvider
