import { FC } from 'react'

type ChartProps = {
  id: string
  height: number | string
}

export const Chart: FC<ChartProps> = ({ id, height }) => {
  return (
    <div
      id={id}
      style={{
        width: '98%',
        height: '100%',
        minHeight: height,
      }}
    ></div>
  )
}
