import moment from 'moment/moment'

export const dateFormats = [
  'DD-MM-YYYY',
  'D-MM-YYYY',
  'DD-MM-YY',
  'D-M-YY',
  'DD/MM/YYYY',
  'D/MM/YYYY',
  'DD/MM/YY',
  'D/M/YY',
  'MM-DD-YYYY',
  'M-D-YY',
  'MM/DD/YYYY',
  'M/D/YY',
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  // 24hs
  'DD-MM-YYYY H:mm',
  'DD-MM-YY H:mm',
  'D-M-YY H:mm',
  'DD/MM/YYYY H:mm',
  'DD/MM/YY H:mm',
  'D/M/YY H:mm',
  'DD-MM-YYYY HH:mm',
  'DD-MM-YY HH:mm',
  'D-M-YY HH:mm',
  'DD/MM/YYYY HH:mm',
  'DD/MM/YY HH:mm',
  'D/M/YY HH:mm',
  // 12hs
  'DD-MM-YYYY h:mm a',
  'DD-MM-YY h:mm a',
  'D-M-YY h:mm a',
  'DD/MM/YYYY h:mm a',
  'DD/MM/YY h:mm a',
  'D/M/YY h:mm a',

  'DD-MM-YYYY hh:mm a',
  'DD-MM-YY hh:mm a',
  'D-M-YY hh:mm a',
  'DD/MM/YYYY hh:mm a',
  'DD/MM/YY hh:mm a',
  'D/M/YY hh:mm a',

  // MDY 12hs
  'MM-DD-YYYY h:mm a',
  'M-D-YY h:mm a',
  'MM/DD/YYYY h:mm a',
  'M/D/YY h:mm a',

  'MM-DD-YYYY hh:mm a',
  'M-D-YY hh:mm a',
  'MM/DD/YYYY hh:mm a',
  'M/D/YY hh:mm a',

  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'YYYYMMDD',
]

export const getDateFormat = (date: string) => {
  for (const i in dateFormats) {
    if (moment(date, dateFormats[i], true).utc().isValid()) {
      return dateFormats[i]
    }
  }

  return false
}
