import { FC, memo } from 'react'
import { Controller } from 'react-hook-form'
import type { Props } from './types'
// TODO we keep the sentisis component select until we have the AutoComplete component of mui
import { Select } from 'sentisis-components'
import ErrorMessage from '@components/ErrorMessage/ErrorMessage'
import * as commonStyles from '../styles'
import * as styles from './styles'

const S = {
  ...commonStyles,
  ...styles,
}

const SelectController: FC<Props> = ({
  form,
  control,
  hasError,
  name,
  isFixedOptions,
  ...rest
}) => {
  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <S.SelectContainer $hasError={Boolean(error)}>
              <Select
                id={name}
                error={!!(error || hasError)}
                {...rest}
                {...field}
                onChange={selected => field.onChange(selected.value)}
                menuPosition={isFixedOptions ? 'fixed' : ''}
                onMenuOpen={() => {
                  if (form) {
                    form.clearErrors(name)
                  }
                }}
              />
              {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
            </S.SelectContainer>
          )
        }}
      />
    </S.Container>
  )
}

export default memo(SelectController)
