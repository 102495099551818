/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CancelJob
// ====================================================

export interface CancelJob {
  cancelJob: boolean
}

export interface CancelJobVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDatasetMutation
// ====================================================

export interface CreateDatasetMutation_createDataset {
  __typename: 'Dataset'
  id: string
}

export interface CreateDatasetMutation {
  createDataset: CreateDatasetMutation_createDataset
}

export interface CreateDatasetMutationVariables {
  projectId: string
  name: string
  contentColumnKey: string
  location?: LocationFieldInput | null
  date?: DateFieldInput | null
  documentFields?: DocumentFieldInput[] | null
  delimiter: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDatasetMutation
// ====================================================

export interface DeleteDatasetMutation {
  deleteDataset: boolean
}

export interface DeleteDatasetMutationVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDocumentsMutation
// ====================================================

export interface DeleteDocumentsMutation {
  deleteDocuments: boolean | null
}

export interface DeleteDocumentsMutationVariables {
  filters?: Filters | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteJobMutation
// ====================================================

export interface DeleteJobMutation {
  deleteJob: boolean
}

export interface DeleteJobMutationVariables {
  id: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: shareProject
// ====================================================

export interface shareProject {
  shareProject: boolean | null
}

export interface shareProjectVariables {
  projectId: string
  teamId: string
  sentimentRead?: boolean | null
  sentimentUpdate?: boolean | null
  categoryUpdate?: boolean | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDatasetMutation
// ====================================================

export interface UpdateDatasetMutation_updateDataset {
  __typename: 'Dataset'
  id: string
  name: string
  createdAt: any
  status: DatasetStatus
}

export interface UpdateDatasetMutation {
  updateDataset: UpdateDatasetMutation_updateDataset
}

export interface UpdateDatasetMutationVariables {
  id: string
  name?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateJobMutation
// ====================================================

export interface UpdateJobMutation_updateJob_analysisProgress {
  __typename: 'AnalysisProgress'
  percentage: number | null
}

export interface UpdateJobMutation_updateJob {
  __typename: 'Job'
  id: string
  name: string | null
  createdAt: any
  lastUpdate: any
  status: JobStatus
  statusMessage: string | null
  analysisProgress: UpdateJobMutation_updateJob_analysisProgress | null
}

export interface UpdateJobMutation {
  updateJob: UpdateJobMutation_updateJob
}

export interface UpdateJobMutationVariables {
  id: string
  name?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDocumentsMutation
// ====================================================

export interface UpdateDocumentsMutation_updateDocuments {
  __typename: 'Document'
  id: string
  content: string
  allCategories: string[]
  sentiment: Sentiment | null
}

export interface UpdateDocumentsMutation {
  updateDocuments: (UpdateDocumentsMutation_updateDocuments | null)[]
}

export interface UpdateDocumentsMutationVariables {
  filters?: Filters | null
  sentiment?: string | null
  newCategories?: (string | null)[] | null
  removeCategories?: (string | null)[] | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserMutation
// ====================================================

export interface UpdateUserMutation_updateUser {
  __typename: 'User'
  id: string
}

export interface UpdateUserMutation {
  updateUser: UpdateUserMutation_updateUser
}

export interface UpdateUserMutationVariables {
  password: PasswordUpdateInput
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllClientsQuery
// ====================================================

export interface AllClientsQuery_allClients {
  __typename: 'Client'
  id: string
  name: string
}

export interface AllClientsQuery {
  allClients: AllClientsQuery_allClients[]
}

export interface AllClientsQueryVariables {
  first?: number | null
  after?: number | null
  blocked?: boolean | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllProjectsQuery
// ====================================================

export interface AllProjectsQuery_allProjects_team {
  __typename: 'Team'
  id: string
}

export interface AllProjectsQuery_allProjects_sharingConfig {
  __typename: 'SharingConfig'
  categories: (string | null)[] | null
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryRead: boolean | null
  categoryUpdate: boolean | null
}

export interface AllProjectsQuery_allProjects_allDatasets_allJobs_analysisProgress {
  __typename: 'AnalysisProgress'
  percentage: number | null
}

export interface AllProjectsQuery_allProjects_allDatasets_allJobs {
  __typename: 'Job'
  id: string
  name: string | null
  createdAt: any
  lastUpdate: any
  status: JobStatus
  statusMessage: string | null
  analysisProgress: AllProjectsQuery_allProjects_allDatasets_allJobs_analysisProgress | null
}

export interface AllProjectsQuery_allProjects_allDatasets {
  __typename: 'Dataset'
  id: string
  name: string
  createdAt: any
  lastUpdate: any
  status: DatasetStatus
  allJobs: AllProjectsQuery_allProjects_allDatasets_allJobs[]
}

export interface AllProjectsQuery_allProjects {
  __typename: 'Project'
  id: string
  name: string
  description: string
  createdAt: any
  team: AllProjectsQuery_allProjects_team
  sharingConfig: AllProjectsQuery_allProjects_sharingConfig | null
  allDatasets: AllProjectsQuery_allProjects_allDatasets[]
}

export interface AllProjectsQuery {
  allProjects: (AllProjectsQuery_allProjects | null)[]
}

export interface AllProjectsQueryVariables {
  pagination?: {
    first?: number | null
    after?: number | null
  }
  clientId?: string | null
  teamId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllTeamsQuery
// ====================================================

export interface AllTeamsQuery_allTeams_client {
  __typename: 'Client'
  id: string
  name: string
}

export interface AllTeamsQuery_allTeams {
  __typename: 'Team'
  id: string
  name: string
  client: AllTeamsQuery_allTeams_client
}

export interface AllTeamsQuery {
  allTeams: AllTeamsQuery_allTeams[]
}

export interface AllTeamsQueryVariables {
  first?: number | null
  after?: number | null
  clientId?: string | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DatasetDateRangeQuery
// ====================================================

export interface DatasetDateRangeQuery_project_dataset_documentFields {
  __typename: 'DocumentField'
  name: string
}

export interface DatasetDateRangeQuery_project_dataset_byLocation {
  __typename: 'LocationGroup'
  count: number
}

export interface DatasetDateRangeQuery_project_dataset_date_datasetDateRange {
  __typename: 'DateRange'
  start: any | null
  end: any | null
}

export interface DatasetDateRangeQuery_project_dataset_date {
  __typename: 'DateField'
  datasetDateRange: DatasetDateRangeQuery_project_dataset_date_datasetDateRange | null
}

export interface DatasetDateRangeQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  name: string
  documentFields: DatasetDateRangeQuery_project_dataset_documentFields[] | null
  allCategories: string[] | null
  byLocation: DatasetDateRangeQuery_project_dataset_byLocation[] | null
  date: DatasetDateRangeQuery_project_dataset_date | null
}

export interface DatasetDateRangeQuery_project {
  __typename: 'Project'
  id: string
  dataset: DatasetDateRangeQuery_project_dataset | null
}

export interface DatasetDateRangeQuery {
  project: DatasetDateRangeQuery_project | null
}

export interface DatasetDateRangeQueryVariables {
  projectId: string
  datasetId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DatasetFieldsQuery
// ====================================================

export interface DatasetFieldsQuery_project_dataset_hasLocation {
  __typename: 'LocationGroup'
  count: number
}

export interface DatasetFieldsQuery_project_dataset_hasDate_datasetDateRange {
  __typename: 'DateRange'
  start: any | null
  end: any | null
}

export interface DatasetFieldsQuery_project_dataset_hasDate {
  __typename: 'DateField'
  datasetDateRange: DatasetFieldsQuery_project_dataset_hasDate_datasetDateRange | null
}

export interface DatasetFieldsQuery_project_dataset_documentFieldKeys {
  __typename: 'DocumentField'
  columnKey: string
}

export interface DatasetFieldsQuery_project_dataset_dateKey {
  __typename: 'DateField'
  columnKey: string
}

export interface DatasetFieldsQuery_project_dataset_locationKey {
  __typename: 'LocationField'
  columnKey: string
}

export interface DatasetFieldsQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  hasLocation: DatasetFieldsQuery_project_dataset_hasLocation[] | null
  hasDate: DatasetFieldsQuery_project_dataset_hasDate | null
  contentKey: string | null
  documentFieldKeys:
    | DatasetFieldsQuery_project_dataset_documentFieldKeys[]
    | null
  dateKey: DatasetFieldsQuery_project_dataset_dateKey | null
  locationKey: DatasetFieldsQuery_project_dataset_locationKey | null
}

export interface DatasetFieldsQuery_project {
  __typename: 'Project'
  id: string
  dataset: DatasetFieldsQuery_project_dataset | null
}

export interface DatasetFieldsQuery {
  project: DatasetFieldsQuery_project | null
}

export interface DatasetFieldsQueryVariables {
  projectId: string
  datasetId: string
  withLocation: boolean
  withDate: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DatasetQuery
// ====================================================

export interface DatasetQuery_project_sharingConfig {
  __typename: 'SharingConfig'
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryUpdate: boolean | null
  categoryRead: boolean | null
  categories: (string | null)[] | null
}

export interface DatasetQuery_project_dataset_byLocation {
  __typename: 'LocationGroup'
  count: number
}

export interface DatasetQuery_project_dataset_documentFields {
  __typename: 'DocumentField'
  name: string
}

export interface DatasetQuery_project_dataset_byTheme_bySentiment {
  __typename: 'SentimentCount'
  count: number
  sentiment: Sentiment
}

export interface DatasetQuery_project_dataset_byTheme_byCategory_bySentiment {
  __typename: 'SentimentCount'
  count: number
  sentiment: Sentiment
}

export interface DatasetQuery_project_dataset_byTheme_byCategory {
  __typename: 'CategoryCount'
  category: string
  count: number
  prevCount: number
  nps: number | null
  netNps: number | null
  bySentiment:
    | (DatasetQuery_project_dataset_byTheme_byCategory_bySentiment | null)[]
    | null
}

export interface DatasetQuery_project_dataset_byTheme {
  __typename: 'ThemeCount'
  count: number
  theme: string
  nps: number | null
  netNps: number | null
  bySentiment:
    | (DatasetQuery_project_dataset_byTheme_bySentiment | null)[]
    | null
  byCategory: DatasetQuery_project_dataset_byTheme_byCategory[]
}

export interface DatasetQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  name: string
  byLocation: DatasetQuery_project_dataset_byLocation[] | null
  documentFields: DatasetQuery_project_dataset_documentFields[] | null
  byTheme: DatasetQuery_project_dataset_byTheme[] | null
}

export interface DatasetQuery_project {
  __typename: 'Project'
  id: string
  name: string
  sharingConfig: DatasetQuery_project_sharingConfig | null
  dataset: DatasetQuery_project_dataset | null
}

export interface DatasetQuery {
  project: DatasetQuery_project | null
}

export interface DatasetQueryVariables {
  projectId: string
  datasetId: string
  filters?: Filters | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DownloadDatasetQuery
// ====================================================

export interface DownloadDatasetQuery {
  datasetDownloadUrl: string
}

export interface DownloadDatasetQueryVariables {
  id: string
  filters?: Filters | null
  extended?: boolean | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EvolutionChartQuery
// ====================================================

export interface EvolutionChartQuery_project_sharingConfig {
  __typename: 'SharingConfig'
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryUpdate: boolean | null
  categoryRead: boolean | null
  categories: (string | null)[] | null
}

export interface EvolutionChartQuery_project_dataset_documentFields {
  __typename: 'DocumentField'
  name: string
}

export interface EvolutionChartQuery_project_dataset_date_range {
  __typename: 'DateRange'
  start: any | null
  end: any | null
}

export interface EvolutionChartQuery_project_dataset_date {
  __typename: 'DateField'
  range: EvolutionChartQuery_project_dataset_date_range | null
}

export interface EvolutionChartQuery_project_dataset_groups_LocationGroup {
  __typename:
    | 'LocationGroup'
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
}

export interface EvolutionChartQuery_project_dataset_groups_TimeGroup_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface EvolutionChartQuery_project_dataset_groups_TimeGroup_byNps {
  __typename: 'NpsCount'
  nps: number
  count: number
}

export interface EvolutionChartQuery_project_dataset_groups_TimeGroup {
  __typename: 'TimeGroup'
  group: any
  total: number
  bySentiment:
    | (EvolutionChartQuery_project_dataset_groups_TimeGroup_bySentiment | null)[]
    | null
  byNps:
    | (EvolutionChartQuery_project_dataset_groups_TimeGroup_byNps | null)[]
    | null
}

export type EvolutionChartQuery_project_dataset_groups =
  | EvolutionChartQuery_project_dataset_groups_LocationGroup
  | EvolutionChartQuery_project_dataset_groups_TimeGroup

export interface EvolutionChartQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  documentField: EvolutionChartQuery_project_dataset_documentFields[] | null
  date: EvolutionChartQuery_project_dataset_date | null
  groups: EvolutionChartQuery_project_dataset_groups[] | null
}

export interface EvolutionChartQuery_project {
  __typename: 'Project'
  id: string
  sharingConfig: EvolutionChartQuery_project_sharingConfig | null
  dataset: EvolutionChartQuery_project_dataset | null
}

export interface EvolutionChartQuery {
  project: EvolutionChartQuery_project | null
}

export interface EvolutionChartQueryVariables {
  projectId: string
  datasetId: string
  filters: Filters
  key: string
  order?: GroupOrdering | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FiltersQuery
// ====================================================

export interface FiltersQuery_project_sharingConfig {
  __typename: 'SharingConfig'
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryUpdate: boolean | null
  categoryRead: boolean | null
  categories: (string | null)[] | null
}

export interface FiltersQuery_project_dataset_byTheme_byCategory {
  __typename: 'CategoryCount'
  category: string
}

export interface FiltersQuery_project_dataset_byTheme {
  __typename: 'ThemeCount'
  byCategory: FiltersQuery_project_dataset_byTheme_byCategory[]
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_SentimentGroup {
  __typename:
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_SentimentGroup {
  __typename:
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_SentimentGroup {
  __typename:
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup {
  __typename: 'LocationGroup'
  count: number
  bySentiment:
    | (FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment | null)[]
    | null
  location: FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_location
}

export type FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy =
  | FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_SentimentGroup
  | FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup {
  __typename: 'LocationGroup'
  count: number
  groupBy:
    | (FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy | null)[]
    | null
  bySentiment:
    | (FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment | null)[]
    | null
  location: FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_location
}

export type FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy =
  | FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_SentimentGroup
  | FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup {
  __typename: 'LocationGroup'
  count: number
  groupBy:
    | (FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy | null)[]
    | null
  bySentiment:
    | (FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_bySentiment | null)[]
    | null
  location: FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup_location
}

export type FiltersQuery_project_dataset_byLocation_groupBy =
  | FiltersQuery_project_dataset_byLocation_groupBy_SentimentGroup
  | FiltersQuery_project_dataset_byLocation_groupBy_LocationGroup

export interface FiltersQuery_project_dataset_byLocation_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface FiltersQuery_project_dataset_byLocation_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface FiltersQuery_project_dataset_byLocation {
  __typename: 'LocationGroup'
  groupBy: (FiltersQuery_project_dataset_byLocation_groupBy | null)[] | null
  count: number
  bySentiment:
    | (FiltersQuery_project_dataset_byLocation_bySentiment | null)[]
    | null
  location: FiltersQuery_project_dataset_byLocation_location
}

export interface FiltersQuery_project_dataset_columnValues_LocationGroup {
  __typename:
    | 'LocationGroup'
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'TimeGroup'
  count: number
}

export interface FiltersQuery_project_dataset_columnValues_CustomGroup {
  __typename: 'CustomGroup'
  count: number
  value: string
}

export type FiltersQuery_project_dataset_columnValues =
  | FiltersQuery_project_dataset_columnValues_LocationGroup
  | FiltersQuery_project_dataset_columnValues_CustomGroup

export interface FiltersQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  name: string
  byTheme: FiltersQuery_project_dataset_byTheme[] | null
  byLocation: FiltersQuery_project_dataset_byLocation[] | null
  columnValues: FiltersQuery_project_dataset_columnValues[] | null
}

export interface FiltersQuery_project {
  __typename: 'Project'
  id: string
  name: string
  sharingConfig: FiltersQuery_project_sharingConfig | null
  dataset: FiltersQuery_project_dataset | null
}

export interface FiltersQuery {
  project: FiltersQuery_project | null
}

export interface FiltersQueryVariables {
  projectId: string
  datasetId: string
  key: string
  withColumnKey: boolean
  order?: GroupOrdering | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FiltersDocumentFieldsQuery
// ====================================================

export interface FiltersDocumentFieldsQuery_dataset_documentFields {
  __typename: 'DocumentField'
  name: string
  columnKey: string
}

export interface FiltersDocumentFieldsQuery_dataset_byLocation {
  __typename: 'LocationGroup'
  count: number
}

export interface FiltersDocumentFieldsQuery_dataset {
  __typename: 'Dataset'
  id: string
  documentFields: FiltersDocumentFieldsQuery_dataset_documentFields[] | null
  byLocation: FiltersDocumentFieldsQuery_dataset_byLocation[] | null
}

export interface FiltersDocumentFieldsQuery {
  dataset: FiltersDocumentFieldsQuery_dataset | null
}

export interface FiltersDocumentFieldsQueryVariables {
  datasetId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HeaderQuery
// ====================================================

export interface HeaderQuery_project_sharingConfig {
  __typename: 'SharingConfig'
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryUpdate: boolean | null
  categoryRead: boolean | null
  categories: (string | null)[] | null
}

export interface HeaderQuery_project_dataset_byLocation {
  __typename: 'LocationGroup'
  count: number
}

export interface HeaderQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  name: string
  byLocation: HeaderQuery_project_dataset_byLocation[] | null
}

export interface HeaderQuery_project {
  __typename: 'Project'
  id: string
  name: string
  sharingConfig: HeaderQuery_project_sharingConfig | null
  dataset: HeaderQuery_project_dataset | null
}

export interface HeaderQuery {
  project: HeaderQuery_project | null
}

export interface HeaderQueryVariables {
  projectId: string
  datasetId: string
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationViewQuery
// ====================================================

export interface LocationViewQuery_project_sharingConfig {
  __typename: 'SharingConfig'
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryUpdate: boolean | null
  categoryRead: boolean | null
  categories: (string | null)[] | null
}

export interface LocationViewQuery_project_dataset_allDocuments {
  __typename: 'DocumentsConnection'
  totalCount: number
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_SentimentGroup {
  __typename:
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_SentimentGroup {
  __typename:
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_SentimentGroup {
  __typename:
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup {
  __typename: 'LocationGroup'
  count: number
  bySentiment:
    | (LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment | null)[]
    | null
  location: LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup_location
}

export type LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy =
  | LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_SentimentGroup
  | LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy_LocationGroup

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup {
  __typename: 'LocationGroup'
  count: number
  groupBy:
    | (LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_groupBy | null)[]
    | null
  bySentiment:
    | (LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_bySentiment | null)[]
    | null
  location: LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup_location
}

export type LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy =
  | LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_SentimentGroup
  | LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy_LocationGroup

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup {
  __typename: 'LocationGroup'
  count: number
  groupBy:
    | (LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_groupBy | null)[]
    | null
  bySentiment:
    | (LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_bySentiment | null)[]
    | null
  location: LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup_location
}

export type LocationViewQuery_project_dataset_byLocation_groupBy =
  | LocationViewQuery_project_dataset_byLocation_groupBy_SentimentGroup
  | LocationViewQuery_project_dataset_byLocation_groupBy_LocationGroup

export interface LocationViewQuery_project_dataset_byLocation_bySentiment {
  __typename: 'SentimentCount'
  sentiment: Sentiment
  count: number
}

export interface LocationViewQuery_project_dataset_byLocation_location {
  __typename: 'ExtendedLocation'
  name: string
}

export interface LocationViewQuery_project_dataset_byLocation {
  __typename: 'LocationGroup'
  groupBy:
    | (LocationViewQuery_project_dataset_byLocation_groupBy | null)[]
    | null
  count: number
  bySentiment:
    | (LocationViewQuery_project_dataset_byLocation_bySentiment | null)[]
    | null
  location: LocationViewQuery_project_dataset_byLocation_location
}

export interface LocationViewQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  allDocuments: LocationViewQuery_project_dataset_allDocuments
  byLocation: LocationViewQuery_project_dataset_byLocation[] | null
}

export interface LocationViewQuery_project {
  __typename: 'Project'
  id: string
  sharingConfig: LocationViewQuery_project_sharingConfig | null
  dataset: LocationViewQuery_project_dataset | null
}

export interface LocationViewQuery {
  project: LocationViewQuery_project | null
}

export interface LocationViewQueryVariables {
  projectId: string
  datasetId: string
  filters?: Filters | null
  order?: Ordering | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MessagesQuery
// ====================================================

export interface MessagesQuery_project_sharingConfig {
  __typename: 'SharingConfig'
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryUpdate: boolean | null
  categoryRead: boolean | null
  categories: (string | null)[] | null
}

export interface MessagesQuery_project_dataset_allDocuments_messages_original {
  __typename: 'OriginalDocument'
  allCategories: string[]
  sentiment: Sentiment
}

export interface MessagesQuery_project_dataset_allDocuments_messages {
  __typename: 'Document'
  id: string
  content: string
  date: any | null
  allCategories: string[]
  sentiment: Sentiment | null
  custom: any | null
  original: MessagesQuery_project_dataset_allDocuments_messages_original | null
}

export interface MessagesQuery_project_dataset_allDocuments {
  __typename: 'DocumentsConnection'
  totalCount: number
  hasNextPage: boolean
  messages: MessagesQuery_project_dataset_allDocuments_messages[]
}

export interface MessagesQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  name: string
  createdAt: any
  status: DatasetStatus
  allDocuments: MessagesQuery_project_dataset_allDocuments
  allCategories: string[] | null
}

export interface MessagesQuery_project {
  __typename: 'Project'
  id: string
  name: string
  createdAt: any
  sharingConfig: MessagesQuery_project_sharingConfig | null
  dataset: MessagesQuery_project_dataset | null
}

export interface MessagesQuery_viewer_user_client {
  __typename: 'Client'
  id: string
}

export interface MessagesQuery_viewer_user {
  __typename: 'User'
  id: string
  client: MessagesQuery_viewer_user_client
}

export interface MessagesQuery_viewer {
  __typename: 'Viewer'
  user: MessagesQuery_viewer_user
}

export interface MessagesQuery {
  project: MessagesQuery_project | null
  viewer: MessagesQuery_viewer | null
}

export interface MessagesQueryVariables {
  projectId: string
  datasetId: string
  filters?: Filters | null
  first?: number | null
  after?: number | null
  order?: Ordering | null
  withOriginal?: boolean | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ProjectSubscription
// ====================================================

export interface ProjectSubscription_project_team {
  __typename: 'Team'
  id: string
}

export interface ProjectSubscription_project_sharingConfig {
  __typename: 'SharingConfig'
  categories: (string | null)[] | null
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryRead: boolean | null
  categoryUpdate: boolean | null
}

export interface ProjectSubscription_project_allDatasets_allJobs_analysisProgress {
  __typename: 'AnalysisProgress'
  percentage: number | null
}

export interface ProjectSubscription_project_allDatasets_allJobs {
  __typename: 'Job'
  id: string
  name: string | null
  createdAt: any
  lastUpdate: any
  status: JobStatus
  statusMessage: string | null
  analysisProgress: ProjectSubscription_project_allDatasets_allJobs_analysisProgress | null
}

export interface ProjectSubscription_project_allDatasets {
  __typename: 'Dataset'
  id: string
  name: string
  createdAt: any
  lastUpdate: any
  status: DatasetStatus
  allJobs: ProjectSubscription_project_allDatasets_allJobs[]
}

export interface ProjectSubscription_project {
  __typename: 'Project'
  id: string
  name: string
  description: string
  team: ProjectSubscription_project_team
  sharingConfig: ProjectSubscription_project_sharingConfig | null
  allDatasets: ProjectSubscription_project_allDatasets[]
}

export interface ProjectSubscription {
  project: ProjectSubscription_project | null
}

export interface ProjectSubscriptionVariables {
  projectId: string
  datasetsLimit?: number | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResultBoxQuery
// ====================================================

export interface ResultBoxQuery_project_sharingConfig {
  __typename: 'SharingConfig'
  sentimentRead: boolean | null
  sentimentUpdate: boolean | null
  categoryUpdate: boolean | null
  categoryRead: boolean | null
  categories: (string | null)[] | null
}

export interface ResultBoxQuery_project_dataset_allDocuments {
  __typename: 'DocumentsConnection'
  totalCount: number
  previousPeriodCount: number | null
}

export interface ResultBoxQuery_project_dataset_sentiment_LocationGroup {
  __typename:
    | 'LocationGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface ResultBoxQuery_project_dataset_sentiment_SentimentGroup {
  __typename: 'SentimentGroup'
  count: number
  sentiment: Sentiment
}

export type ResultBoxQuery_project_dataset_sentiment =
  | ResultBoxQuery_project_dataset_sentiment_LocationGroup
  | ResultBoxQuery_project_dataset_sentiment_SentimentGroup

export interface ResultBoxQuery_project_dataset_topCategories_LocationGroup {
  __typename:
    | 'LocationGroup'
    | 'SentimentGroup'
    | 'TagGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface ResultBoxQuery_project_dataset_topCategories_CategoryGroup_category {
  __typename: 'Category'
  id: string
  name: string
}

export interface ResultBoxQuery_project_dataset_topCategories_CategoryGroup {
  __typename: 'CategoryGroup'
  count: number
  category: ResultBoxQuery_project_dataset_topCategories_CategoryGroup_category
}

export type ResultBoxQuery_project_dataset_topCategories =
  | ResultBoxQuery_project_dataset_topCategories_LocationGroup
  | ResultBoxQuery_project_dataset_topCategories_CategoryGroup

export interface ResultBoxQuery_project_dataset_topTags_LocationGroup {
  __typename:
    | 'LocationGroup'
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'CustomGroup'
    | 'TimeGroup'
}

export interface ResultBoxQuery_project_dataset_topTags_TagGroup_tag {
  __typename: 'Tag'
  name: string
}

export interface ResultBoxQuery_project_dataset_topTags_TagGroup {
  __typename: 'TagGroup'
  count: number
  tag: ResultBoxQuery_project_dataset_topTags_TagGroup_tag
}

export type ResultBoxQuery_project_dataset_topTags =
  | ResultBoxQuery_project_dataset_topTags_LocationGroup
  | ResultBoxQuery_project_dataset_topTags_TagGroup

export interface ResultBoxQuery_project_dataset_topCustom_LocationGroup {
  __typename:
    | 'LocationGroup'
    | 'SentimentGroup'
    | 'CategoryGroup'
    | 'TagGroup'
    | 'TimeGroup'
}

export interface ResultBoxQuery_project_dataset_topCustom_CustomGroup {
  __typename: 'CustomGroup'
  name: string
  count: number
}

export type ResultBoxQuery_project_dataset_topCustom =
  | ResultBoxQuery_project_dataset_topCustom_LocationGroup
  | ResultBoxQuery_project_dataset_topCustom_CustomGroup

export interface ResultBoxQuery_project_dataset {
  __typename: 'Dataset'
  id: string
  nps: number | null
  allDocuments: ResultBoxQuery_project_dataset_allDocuments
  sentiment: ResultBoxQuery_project_dataset_sentiment[] | null
  topCategories: ResultBoxQuery_project_dataset_topCategories[] | null
  topTags: ResultBoxQuery_project_dataset_topTags[] | null
  topCustom: ResultBoxQuery_project_dataset_topCustom[] | null
}

export interface ResultBoxQuery_project {
  __typename: 'Project'
  id: string
  name: string
  createdAt: any
  sharingConfig: ResultBoxQuery_project_sharingConfig | null
  dataset: ResultBoxQuery_project_dataset | null
}

export interface ResultBoxQuery {
  project: ResultBoxQuery_project | null
}

export interface ResultBoxQueryVariables {
  projectId: string
  datasetId: string
  filters?: Filters | null
  order?: Ordering | null
  groupOrder?: GroupOrdering | null
  npsKey?: string
  customKey: string
  withCustomKey: boolean
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserQuery
// ====================================================

export interface UserQuery_viewer_user_client {
  __typename: 'Client'
  id: string
  name: string
  token: string | null
  apiEnabled: boolean
  logo: string | null
}

export interface UserQuery_viewer_user_team {
  __typename: 'Team'
  id: string
  name: string
}

export interface UserQuery_viewer_user {
  __typename: 'User'
  id: string
  name: string | null
  email: string
  client: UserQuery_viewer_user_client
  team: UserQuery_viewer_user_team
}

export interface UserQuery_viewer {
  __typename: 'Viewer'
  user: UserQuery_viewer_user
  isManager: boolean
}

export interface UserQuery {
  viewer: UserQuery_viewer | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ViewerQuery
// ====================================================

export interface ViewerQuery_viewer_user_client {
  __typename: 'Client'
  id: string
  name: string
  token: string | null
  apiEnabled: boolean
  logo: string | null
}

export interface ViewerQuery_viewer_user_role {
  __typename: 'Role'
  id: string
  name: string
  sentimentRead: boolean
  sentimentUpdate: boolean
  categoryRead: boolean
  categoryUpdate: boolean
  userManagement: boolean
  projectManagement: boolean
  clientManagement: boolean
  teamManagement: boolean
  datasetManagement: boolean
  overrideSharingConfig: boolean
  shareProject: boolean
  hierarchy: number
}

export interface ViewerQuery_viewer_user_team {
  __typename: 'Team'
  id: string
  name: string
}

export interface ViewerQuery_viewer_user {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  client: ViewerQuery_viewer_user_client
  role: ViewerQuery_viewer_user_role
  team: ViewerQuery_viewer_user_team
}

export interface ViewerQuery_viewer {
  __typename: 'Viewer'
  isManager: boolean
  user: ViewerQuery_viewer_user
}

export interface ViewerQuery {
  viewer: ViewerQuery_viewer | null
}

export interface ViewerQueryVariables {
  withUser?: boolean | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: DatasetSubscription
// ====================================================

export interface DatasetSubscription_dataset_allJobs_analysisProgress {
  __typename: 'AnalysisProgress'
  percentage: number | null
}

export interface DatasetSubscription_dataset_allJobs {
  __typename: 'Job'
  id: string
  name: string | null
  createdAt: any
  lastUpdate: any
  status: JobStatus
  statusMessage: string | null
  analysisProgress: DatasetSubscription_dataset_allJobs_analysisProgress | null
}

export interface DatasetSubscription_dataset {
  __typename: 'Dataset'
  id: string
  name: string
  allJobs: DatasetSubscription_dataset_allJobs[]
}

export interface DatasetSubscription {
  dataset: DatasetSubscription_dataset | null
}

export interface DatasetSubscriptionVariables {
  datasetId: string
  filter?: JobFilters | null
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DatasetStatus {
  Completed = 'Completed',
  CompletedWithIncident = 'CompletedWithIncident',
  Created = 'Created',
  Error = 'Error',
  Processing = 'Processing',
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum GroupSort {
  COUNT = 'COUNT',
  GROUP = 'GROUP',
}

export enum JobStatus {
  Completed = 'Completed',
  Analyzed = 'Analyzed',
  Failed = 'Failed',
  Pending = 'Pending',
  Running = 'Running',
  Uploading = 'Uploading',
  Cancelled = 'Cancelled',
  Deleted = 'Deleted',
}

export enum Sentiment {
  NEGATIVE = 'NEGATIVE',
  OBJECTIVE = 'OBJECTIVE',
  POSITIVE = 'POSITIVE',
  UNKNOWN = 'UNKNOWN',
}

export enum Sort {
  DATE = 'DATE',
  NONE = 'NONE',
}

export interface CategoryOperator {
  or?: string[] | null
  and?: string[] | null
  not?: string[] | null
}

export interface ContentOperator {
  match?: string | null
  ors?: [string[]]
  ands?: string[]
  nots?: string[]
}

export interface CustomFilter {
  key: string
  or?: string[] | null
  not?: string[] | null
}

export interface DateFieldInput {
  columnKey: string
  format?: string | null
}

export interface DateInput {
  start?: any | null
  end?: any | null
}

export interface Filters {
  content?: ContentOperator | null
  category?: CategoryOperator | null
  tag?: TagOperator | null
  theme?: ThemeOperator | null
  sentiment?: SentimentOperator | null
  custom?: CustomFilter[] | null
  location?: LocationOperator | null
  date?: DateInput | null
  selectedIds?: (string | null)[] | null
  unselectedIds?: (string | null)[] | null
  datasetId?: string | null
  edited?: boolean | null
  sentimentEdited?: boolean | null
  categoriesEdited?: boolean | null
  originalCategory?: CategoryOperator | null
  originalSentiment?: SentimentOperator | null
}

export interface GroupOrdering {
  sort: GroupSort
  direction: Direction
}

export interface JobFilters {
  status?: JobStatus | null
}

export interface LocationFieldInput {
  columnKey: string
  country: string
}

export interface LocationInput {
  country?: string | null
  county?: string | null
  region?: string | null
}

export interface LocationOperator {
  or?: LocationInput[] | null
}

export interface Ordering {
  sort: Sort
  direction: Direction
}

export interface PasswordUpdateInput {
  current: string
  desired: string
}

export interface SentimentOperator {
  or?: Sentiment[] | null
  not?: Sentiment[] | null
}

export interface TagOperator {
  and?: string[] | null
}

export interface ThemeOperator {
  or?: string[] | null
}

export interface DocumentFieldInput {
  columnKey: string
  name: string
  type?: string // DOCUMENT_COLUMN by default
}

//==============================================================
// END Enums and Input Objects
//==============================================================
