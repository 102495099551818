import { getOr, isNil } from 'lodash/fp'
import {
  FilterGroup,
  FiltersColumnKeyEnum,
  SplitFiltersMenu,
} from '@components/AdvanceFilters/types'
import { getFilterValues } from '@utils/filters'
import { excludedKeys, fixedGroups } from '@components/AdvanceFilters/constants'
import { DatasetAttributeType } from '../../models/Dataset'
import { CUSTOM_ATTRIBUTES_TO_HIDE } from '@views/Messages/constants'

// TODO these methods have just been moved from one file to another, we need to check them.

export const getCurrentKey: (
  activeLevel: string,
) => string | false = activeLevel => {
  if (isNil(activeLevel)) return false

  const selectedGroup = fixedGroups.find(g => g.name === activeLevel)

  // Return group key if exists and is different from content.
  const groupKey =
    selectedGroup?.columnKey !== FiltersColumnKeyEnum.content &&
    selectedGroup?.columnKey

  return selectedGroup ? groupKey : activeLevel
}

export const getFilterOptions = (key, data) => {
  if (key === false) return []
  if (key === 'contentFields') {
    const contentFieldsValues = data?.project?.dataset?.contentColumnsKeys || []

    return [...contentFieldsValues]
      .sort()
      .map((column: string) => ({ label: column, value: column }))
  }

  const filterValues = getOr([], 'project.dataset.filterValues', data)

  return getFilterValues(key, filterValues)
}

export const getFiltersToShow = (documentFields, data, getPermits) => {
  const { sentimentRead, categoriesRead } = getPermits(
    getOr([], 'project', data),
  )

  const { excludedKeys, myFilters, benchmarkFilter } =
    splitFilterTypes(documentFields)

  const contentColumns = data?.project?.dataset?.contentColumnsKeys
  const locationColumn = data?.project?.dataset?.location?.columnKey

  let allFilters = [...fixedGroups, ...excludedKeys]

  if (myFilters.length > 0) {
    allFilters.push({ name: 'Mis filtros', columnKey: 'myFilters' })
  }

  if (benchmarkFilter) {
    allFilters.push(benchmarkFilter)
  }

  if (!sentimentRead) {
    allFilters = allFilters.filter(
      f => f.columnKey !== FiltersColumnKeyEnum.sentiment,
    )
  }

  if (!categoriesRead) {
    allFilters = allFilters.filter(
      f => f.columnKey !== FiltersColumnKeyEnum.category,
    )
  }

  if (contentColumns && contentColumns.length === 1) {
    allFilters = allFilters.filter(
      f => f.columnKey !== FiltersColumnKeyEnum.contentFields,
    )
  }

  if (!locationColumn) {
    allFilters = allFilters.filter(
      f => f.columnKey !== FiltersColumnKeyEnum.location,
    )
  }

  return allFilters
}

/**
 * Check if query key is included in fixed filters
 * @returns true when key is not included in fixed filters
 */
export const keyIsGroup = (key: string): boolean => {
  const fixedKeys = fixedGroups.map(group => group.columnKey)
  return (
    ![...fixedKeys, 'nps'].includes(key) && !key.toLowerCase().includes('nps')
  )
}

// Split elements for menu
export const splitFilterTypes = (fields: FilterGroup[]) =>
  fields.reduce(
    (acc: SplitFiltersMenu, current: FilterGroup) => {
      // exclude type DATASET_ATTRIBUTE from filters
      if (current.type === DatasetAttributeType.DATASET_ATTRIBUTE) {
        return acc
      }

      if (
        current?.name.toLowerCase() === 'nps' ||
        excludedKeys.includes(current?.columnKey.toLowerCase())
      ) {
        return { ...acc, excludedKeys: [...acc.excludedKeys, current] }
      }

      if (current.type === DatasetAttributeType.BENCHMARK_COLUMN) {
        return { ...acc, benchmarkFilter: current }
      }

      if (!CUSTOM_ATTRIBUTES_TO_HIDE.includes(current.name)) {
        return { ...acc, myFilters: [...acc.myFilters, current] }
      }

      return acc
    },
    { excludedKeys: [], myFilters: [], benchmarkFilter: null },
  )
