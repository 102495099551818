import { FC, memo } from 'react'
import type { Props } from '../../types'

const ChevronOpen: FC<Props> = ({ size = 16, ...rest }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 4.5L-1.13412e-07 4.5L4.5 -5.67099e-08L9 4.5Z" fill="#282A33" />
    </svg>
)

export default memo(ChevronOpen)


