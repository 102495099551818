import { FC, memo } from 'react'
import type { Props } from '../../types'

const Customers: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect
      x="0.5"
      y="4.38892"
      width="15"
      height="10.1111"
      rx="1.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 14.5V2.94444C11 2.1467 10.3284 1.5 9.5 1.5H6.5C5.67157 1.5 5 2.1467 5 2.94444V14.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(Customers)
