import stringify from 'csv-stringify/lib/sync'

export const generateCSV = (rows, hasDate: boolean, hasNPS: boolean) => {
  // Define el encabezado del archivo CSV
  const firstHalf = [
    'TIPO',
    'NOMBRE',
    'TEMA',
    'VOLUMEN DE MENSAJES',
    '% CONVERSACIONAL',
  ]

  const secondHalf = [
    'SENTIMIENTO POSITIVO',
    'SENTIMIENTO NEUTRO',
    'SENTIMIENTO NEGATIVO',
  ]

  if (hasNPS) {
    secondHalf.push('NPS')
  }

  const headers = hasDate
    ? [...firstHalf, '% EVOLUCIÓN', ...secondHalf]
    : [...firstHalf, ...secondHalf]

  // Crea un arreglo con los datos de cada fila y de sus hijos
  const data = rows.flatMap(row => {
    // Crea una fila para la fila principal
    const rowData = {
      TIPO: 'Tema',
      NOMBRE: row.id,
      TEMA: '',
      'VOLUMEN DE MENSAJES': row.data.mentions,
      '% CONVERSACIONAL': `${row.data.mentionsPerc}%`,
      '% EVOLUCIÓN': `${row.data.evolution}%`,
      'SENTIMIENTO POSITIVO': `${row.data.sentiment.positive}%`,
      'SENTIMIENTO NEUTRO': `${row.data.sentiment.objective}%`,
      'SENTIMIENTO NEGATIVO': `${row.data.sentiment.negative}%`,
      NPS: row.data.nps,
    }

    // Crea una fila para cada elemento "children" y ordena por número de mensajes
    const childrenData = row.children
      .map(child => ({
        TIPO: 'Categoría',
        NOMBRE: `${child.id}`,
        TEMA: row.id,
        'VOLUMEN DE MENSAJES': child.data.mentions,
        '% CONVERSACIONAL': `${child.data.mentionsPerc}%`,
        '% EVOLUCIÓN': `${child.data.evolution}%`,
        'SENTIMIENTO POSITIVO': `${child.data.sentiment.positive}%`,
        'SENTIMIENTO NEUTRO': `${child.data.sentiment.objective}%`,
        'SENTIMIENTO NEGATIVO': `${child.data.sentiment.negative}%`,
        NPS: child.data.nps,
      }))
      .sort((a, b) => b['VOLUMEN DE MENSAJES'] - a['VOLUMEN DE MENSAJES'])

    // Retorna las filas creadas
    return [rowData, ...childrenData]
  })

  // Descarga el archivo CSV
  const csv = stringify(data, {
    header: true,
    columns: headers,
  })

  return downloadCSV(csv, 'data.csv')
}

const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)

  document.body.appendChild(a)

  a.click()
  document.body.removeChild(a)
}
