import { FC, memo } from 'react'
import type { Props } from '../../types'

const Users: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 0C7.72386 0 7.5 0.223858 7.5 0.5C7.5 0.776142 7.72386 1 8 1V0ZM8 15C7.72386 15 7.5 15.2239 7.5 15.5C7.5 15.7761 7.72386 16 8 16V15ZM8 1C8.27614 1 8.5 0.776142 8.5 0.5C8.5 0.223858 8.27614 0 8 0V1ZM8 16C8.27614 16 8.5 15.7761 8.5 15.5C8.5 15.2239 8.27614 15 8 15V16ZM8 1H13.8333V0H8V1ZM13.8333 1C14.4777 1 15 1.52233 15 2.16667H16C16 0.97005 15.03 0 13.8333 0V1ZM15 2.16667V13.8333H16V2.16667H15ZM15 13.8333C15 14.4777 14.4777 15 13.8333 15V16C15.03 16 16 15.03 16 13.8333H15ZM13.8333 15H8V16H13.8333V15ZM8 0H2.16667V1H8V0ZM2.16667 0C0.97005 0 0 0.97005 0 2.16667H1C1 1.52233 1.52233 1 2.16667 1V0ZM0 2.16667V13.8333H1V2.16667H0ZM0 13.8333C0 15.03 0.97005 16 2.16667 16V15C1.52233 15 1 14.4777 1 13.8333H0ZM2.16667 16H8V15H2.16667V16Z"
      fill="currentColor"
    />
    <path
      d="M12 12V11C12 9.89543 11.1046 9 10 9H6C4.89543 9 4 9.89543 4 11V12"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="8"
      cy="5"
      r="2"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default memo(Users)
