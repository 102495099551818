import { useCallback, useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AuthContext } from '@contexts/Auth'
import { useRouter } from '@hooks/useRouter'
import { LinkType, SidebarProps, ConfigType } from './types'
import { trackEvent } from '@utils/metrics'
import { MESSAGES, CATEGORIES, LOCATION, SUMMARY } from './constants'
import { composePath } from '@utils/index'
import { generateMenuLinks } from './utils'
import { useProSidebar } from 'react-pro-sidebar'
import useProjectConfig from 'apollo/hooks/project/useProjectConfig'

const useLogic = ({ onToggle }: SidebarProps) => {
  const router = useRouter()
  const { projectId, datasetId } = useParams<{
    projectId: string
    datasetId: string
  }>()

  const [toggle, setToggle] = useState(false)

  const { getPermits } = useContext(AuthContext)

  const { projectConfig, projectConfigLoading, projectConfigError } =
    useProjectConfig({ projectId, datasetId })

  const { collapseSidebar } = useProSidebar()
  const [collapsed, setCollapsed] = useState(false)
  const [isProjectNameTooltipVisible, setIsProjectNameTooltipVisible] =
    useState(false)
  const [isDatasetNameTooltipVisible, setIsDatasetNameTooltipVisible] =
    useState(false)

  const toggleCollapseSidebar = useCallback(() => {
    setCollapsed(!collapsed)
    collapseSidebar()
  }, [collapsed, collapseSidebar])

  const handleToggle = useCallback(
    (value: boolean) => {
      setToggle(value)
      onToggle(toggle)
    },
    [onToggle, toggle],
  )

  const handleTrackEvent = useCallback((path: string) => {
    return path.includes(MESSAGES)
      ? trackEvent(MESSAGES)
      : path.includes(CATEGORIES)
      ? trackEvent(CATEGORIES)
      : path.includes(LOCATION)
      ? trackEvent(LOCATION)
      : path.includes(SUMMARY)
      ? trackEvent(SUMMARY)
      : null
  }, [])

  const onNavigate = useCallback(
    (path: string) => {
      router.history.push(path)
    },
    [router.history],
  )

  const handleClick = useCallback(
    link => {
      if (toggle && !link.clickable) {
        handleToggle(!toggle)
      } else {
        handleTrackEvent(link.path)
        onNavigate(link.path)
      }
    },
    [toggle, handleToggle, handleTrackEvent, onNavigate],
  )

  const routerHasPath = useCallback(
    (path: string): boolean => {
      const location = router.location
      if (location.search) {
        const fullPath = location.pathname + location.search
        return fullPath.includes(path)
      }

      return location.pathname.includes(path)
    },
    [router.location],
  )

  /**
   * Return true/false if a given link path matches with the current url
   */
  const checkIsLinkActive = useCallback(
    (link: LinkType, parentPath: string): boolean => {
      if (!link.children?.length) {
        return routerHasPath(parentPath)
      }

      if (routerHasPath(parentPath) && link.children.length) {
        return true
      }

      const hasChildrenMatches = link.children.reduce((acc, l) => {
        if (acc) {
          return true
        }
        const childPath = composePath(parentPath, link.path, l.path)

        return routerHasPath(childPath)
      }, false)

      return hasChildrenMatches
    },
    [routerHasPath],
  )

  const links = useMemo(() => {
    let config: ConfigType = {
      pathname: router.pathname,
      search: router.location.search,
      canRead: false,
    }

    if (!projectConfigLoading && projectConfig) {
      const { categoriesRead } = getPermits(projectConfig)

      config = {
        ...config,
        project: projectConfig,
        projectId,
        datasetId,
        canRead: categoriesRead,
      }
    }

    return generateMenuLinks(config).filter(({ visible }) => visible)
  }, [
    projectConfig,
    projectConfigLoading,
    getPermits,
    projectId,
    datasetId,
    router,
  ])

  return {
    checkIsLinkActive,
    collapsed,
    error: projectConfigError,
    handleClick,
    handleTrackEvent,
    isLoading: projectConfigLoading,
    isDatasetNameTooltipVisible,
    isProjectNameTooltipVisible,
    links,
    onNavigate,
    projectConfig,
    setIsDatasetNameTooltipVisible,
    setIsProjectNameTooltipVisible,
    toggle,
    toggleCollapseSidebar,
  }
}

export default useLogic
