/**
 * Gracfully handle the errors and report them
 * to sentry
 *
 * @prop {Error}  err     - Error to report
 * @prop {Object} context - Additional informations
 */
export default function logError(err, context) {
  /* eslint-disable no-console */
  console.error(err)
  console.log('Error context:')
  console.log(JSON.stringify(context, null, 2))
  /* eslint-enable no-console */
}
