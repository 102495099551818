import { FC, memo } from 'react'
import type { Props } from '../types'

const Pin: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.00002 4.00033V11.667C7.00002 12.3742 6.71907 13.0525 6.21897 13.5526C5.71887 14.0527 5.0406 14.3337 4.33335 14.3337C3.62611 14.3337 2.94783 14.0527 2.44774 13.5526C1.94764 13.0525 1.66669 12.3742 1.66669 11.667V3.33366C1.66669 2.89163 1.84228 2.46771 2.15484 2.15515C2.4674 1.84259 2.89133 1.66699 3.33335 1.66699C3.77538 1.66699 4.1993 1.84259 4.51187 2.15515C4.82443 2.46771 5.00002 2.89163 5.00002 3.33366V10.3337C5.00002 10.5105 4.92978 10.68 4.80476 10.8051C4.67973 10.9301 4.51016 11.0003 4.33335 11.0003C4.15654 11.0003 3.98697 10.9301 3.86195 10.8051C3.73693 10.68 3.66669 10.5105 3.66669 10.3337V4.00033H2.66669V10.3337C2.66669 10.7757 2.84228 11.1996 3.15484 11.5122C3.4674 11.8247 3.89133 12.0003 4.33335 12.0003C4.77538 12.0003 5.1993 11.8247 5.51187 11.5122C5.82443 11.1996 6.00002 10.7757 6.00002 10.3337V3.33366C6.00002 2.62641 5.71907 1.94814 5.21897 1.44804C4.71887 0.947944 4.0406 0.666992 3.33335 0.666992C2.62611 0.666992 1.94783 0.947944 1.44774 1.44804C0.947638 1.94814 0.666687 2.62641 0.666687 3.33366V11.667C0.666687 12.6395 1.053 13.5721 1.74063 14.2597C2.42826 14.9474 3.36089 15.3337 4.33335 15.3337C5.30581 15.3337 6.23845 14.9474 6.92608 14.2597C7.61371 13.5721 8.00002 12.6395 8.00002 11.667V4.00033H7.00002Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Pin)
