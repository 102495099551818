import { PureComponent } from 'react';
import PropTypes from 'prop-types'

import './tableCss/SimpleTooltip.scss'

/**
 * SimpleTooltip is triggered by hovering the children node.
 */
class SimpleTooltip extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    className: PropTypes.string,
    onShow: PropTypes.func,
    centered: PropTypes.bool,
    delay: PropTypes.number,
  }

  static defaultProps = {
    className: '',
    onShow() {},
    centered: true,
    delay: 500,
  }

  state = {
    isVisible: false,
    isHover: false,
    timeout: null,
  }

  handleMouseEnter = () => {
    if (this.state.isVisible || this.state.isHover) return

    const timeout = window.setTimeout(() => {
      this.setState({ isVisible: true })
      this.props.onShow()
    }, this.props.delay)

    this.setState({ isHover: true, timeout })
  }

  handleMouseLeave = () => {
    if (this.state.timeout) window.clearTimeout(this.state.timeout)
    this.setState({
      isHover: false,
      isVisible: false,
    })
  }

  containerRef = el => {
    if (!el) return
    this.$container = el
  }

  tooltipRef = el => {
    if (!el || !this.$container) return

    const containerRect = this.$container.getBoundingClientRect()
    const elRect = el.getBoundingClientRect()
    const margin = 5

    /* eslint-disable no-param-reassign */
    el.style.position = 'fixed'

    // Align the tooltip on top of the container
    el.style.top = `${containerRect.top - elRect.height - margin}px`

    if (this.props.centered) {
      // Only center the tooltip when we have enough space on the screen.
      // Otherwhise just right-align it as far as it gets
      const wantedLeft =
        containerRect.left + (containerRect.width / 2 - elRect.width / 2)
      if (wantedLeft + elRect.width + margin < window.innerWidth) {
        el.style.left = `${wantedLeft}px`
      } else {
        el.style.left = `${window.innerWidth - margin - elRect.width}px`
      }
    }
    /* eslint-enable */
  }

  get tooltip() {
    return (
      <div ref={this.tooltipRef} className="c-SimpleTooltip">
        {this.props.text}
      </div>
    )
  }

  render() {
    return (
      <div
        className={this.props.className}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={this.containerRef}
      >
        {this.state.isVisible && this.props.text ? this.tooltip : null}
        {this.props.children}
      </div>
    )
  }
}

export default SimpleTooltip
