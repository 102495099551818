import styled from 'styled-components/macro'
import DefaultModal, {
  Content as ModalContent,
  Title as ModalTitle,
  SubTitle as ModalSubTitle,
  Buttons as ModalButtons,
  InnerContent as ModalInnerContent,
} from '@components/Modal/Modal'

export const Container = styled(DefaultModal)`
  ${ModalContent} {
    max-width: 586px;
  }
`

export const Title = styled(ModalTitle)``

export const SubTitle = styled(ModalSubTitle)``

export const Content = styled(ModalInnerContent)``

export const HiddenInput = styled.input`
  display: none;
`

export const InputLabel = styled.h2`
  color: ${({ theme }) => theme.colours.gray[110]};
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
`

export const FileContent = styled.div``

export const FileName = styled.h4`
  font-size: 14px;
  font-weight: 400;
`

export const SupportedFormatContent = styled.div`
  margin-top: 24px;
`

export const SupportedFormatTitle = styled(InputLabel)``

export const SupportedFormatList = styled.ul`
  list-style-type: disc;
  margin-top: 8px;
  padding: 0 23px;
`

export const FormatListItem = styled.li<{ $hasError: boolean }>`
  color: ${({ theme, $hasError }) =>
    $hasError ? theme.colours.red[90] : theme.colours.gray[110]};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
`

export const FileSelection = styled.p`
  color: ${({ theme }) => theme.colours.blue[70]};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-top: 8px;
  width: max-content;
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colours.red[90]};
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin: 8px 0;
`

export const Buttons = styled(ModalButtons)``
