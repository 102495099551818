import './EmptyMessage.styles.scss'
const EmptyMessage = ({ id }) => {
  return (
    <div id={id} className="empty-message">
      <span>Sin categorías</span>
    </div>
  )
}

export default EmptyMessage
