import { useCallback } from 'react'
import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'

const useLogic = () => {
  const { connectPowerBiPayload, modalType, onCloseModal } = useModal()

  const handleOnCancel = useCallback(() => {
    if (connectPowerBiPayload.onCancel) {
      connectPowerBiPayload.onCancel()
    }

    onCloseModal()
  }, [connectPowerBiPayload, onCloseModal])

  const handleOnConnect = useCallback(() => {
    if (connectPowerBiPayload.onConnect) {
      connectPowerBiPayload.onConnect()
    }

    onCloseModal()
  }, [connectPowerBiPayload, onCloseModal])

  return {
    isOpen: modalType === ModalType.CONNECT_POWER_BI,
    handleOnCancel,
    handleOnConnect,
  }
}

export default useLogic
