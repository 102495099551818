import Dialog from '@material-ui/core/Dialog'
import styled, { css } from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: unset;
  max-height: 95vh;
  outline: none;
  overflow-y: auto;
  padding: 40px;
  position: relative;

  p {
    white-space: pre-wrap;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colours.gray[110]};
  font-size: 16px;
  font-weight: 600;
  line-height: 0.8;
  margin-bottom: 8px;
`

export const SubTitle = styled.h1`
  color: ${({ theme }) => theme.colours.gray[70]};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 8px;
`

export const InnerContent = styled.div`
  margin-top: 24px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: right;
  gap: 16px;
  padding-top: 24px;
`

export const Container = styled(Dialog)<{ $disableFullScreen: boolean }>`
  position: relative;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colours.white};
    border-radius: 8px;
    box-shadow: 0 6px 19px 7px rgba(0, 0, 0, 0.21);
    color: inherit;
    margin: 0;
    max-height: none;
    max-width: none;
    width: unset;

    ${({ $disableFullScreen }) =>
      $disableFullScreen &&
      css`
        border-radius: 3.5px;
        width: calc(100vw - 16px);
        ${Content} {
          height: unset;
        }
      `}
  }
`
