import styled from 'styled-components'
import {
  Button as MUIButton,
  Divider as MUIDivider,
  IconButton as MUIIconButton,
  Typography,
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import DefaultIcon from '@components/Icon'

export const Wrapper = styled.div`
  grid-area: header;
  z-index: 999;
`
export const Navbar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.colours.white};
    max-height: 60px;
    color: black;
    box-shadow: 0px 0px 0px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colours.gray[20]};
  }
`

export const IconTypography = styled(Typography)`
  && {
    font-family: "Suisse BP int'l", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #6fccc3;
    margin-top: 1rem;
    flex-grow: 1;
    cursor: pointer;
  }
`

export const Button = styled(MUIButton)`
  && {
    font-family: "Suisse Int'l", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: black;
    text-transform: none;
  }
`

export const AdminButton = styled(Button)`
  && {
    border-color: ${({ theme }) => theme.colours.gray[20]};
    borderwidth: 2px;
    fontweight: bold;
    border-radius: 2rem;
    padding: 0rem 1rem 0rem 1rem;
  }
`

export const IconButton = styled(MUIIconButton)`
  && {
    margin-right: 8px;
    padding: 0px;
    margin-left: 8px;
  }
  &&:hover {
    background-color: transparent;
  }
`

export const Icon = styled(DefaultIcon)`
  && {
    margin-top: 0.5rem;
  }

  &&:hover {
    cursor: pointer;
  }
`

export const Divider = styled(MUIDivider)`
  && {
    background-color: ${({ theme }) => theme.colours.gray[30]};
    height: 1.5rem;
    margin-top: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
