import { FC, memo } from 'react'
import type { Props } from '../../types'

const Notification: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.3333 5.0004C12.3333 2.5149 10.3932 0.5 8 0.5C5.60677 0.5 3.66667 2.5149 3.66667 5.0004C3.66667 10.2509 1.5 11.751 1.5 11.751H14.5C14.5 11.751 12.3333 10.2509 12.3333 5.0004"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.24987 14.7513C8.99146 15.214 8.51542 15.4987 8.00042 15.4987C7.48543 15.4987 7.00939 15.214 6.75098 14.7513"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default memo(Notification)
