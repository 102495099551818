import { FC, memo } from 'react'
import type { Props } from '../types'

const StepsArrowLeft: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M6 11L1 6L6 1" stroke="currentColor" />
  </svg>
)

export default memo(StepsArrowLeft)
