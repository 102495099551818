import { FC, memo } from 'react'
import useLogic from './logic'
import * as S from './styles'
import Button from '@components/Button/Button'

const DatasetAddFileModal: FC = () => {
  const {
    datasetFileData,
    datasetName,
    error,
    formatConditions,
    handleAssignInputRef,
    handleChangeFile,
    handleOnCloseModal,
    handleOpenExplorerFiles,
    handleUploadFile,
    isOpen,
  } = useLogic()

  return (
    <S.Container open={isOpen} onClose={handleOnCloseModal}>
      <S.Title>Añadir archivo {datasetName ? `a ${datasetName}` : ''}</S.Title>
      <S.SubTitle>
        Sube un nuevo archivo con el que actualizar el Dataset. Recuerda que los
        datos deben compartir el mismo formato y debe tener las mismas columnas
        para analizar.
      </S.SubTitle>
      <S.Content>
        <S.HiddenInput
          data-test="file-selector"
          ref={handleAssignInputRef}
          type="file"
          onChange={handleChangeFile}
        />
        <S.InputLabel>Archivo</S.InputLabel>
        <S.FileContent>
          {datasetFileData?.file && datasetFileData?.file?.name && (
            <S.FileName>{datasetFileData.file.name}</S.FileName>
          )}
          <S.FileSelection
            onClick={handleOpenExplorerFiles}
            data-test="upload-file"
          >
            Seleccionar archivo
          </S.FileSelection>
        </S.FileContent>
        {error && <S.Error>{error}</S.Error>}
        <S.SupportedFormatContent>
          <S.SupportedFormatTitle>Formatos soportados</S.SupportedFormatTitle>
          <S.SupportedFormatList>
            {formatConditions.map(condition => (
              <S.FormatListItem
                key={condition.text}
                $hasError={condition.hasError}
              >
                {condition.text}
              </S.FormatListItem>
            ))}
          </S.SupportedFormatList>
        </S.SupportedFormatContent>
        <S.Buttons>
          <Button variant="secondary" onClick={handleOnCloseModal}>
            Cancelar
          </Button>
          <Button
            onClick={handleUploadFile}
            disabled={!!error || !datasetFileData?.file}
          >
            Actualizar Dataset
          </Button>
        </S.Buttons>
      </S.Content>
    </S.Container>
  )
}

export default memo(DatasetAddFileModal)
