import { FC, memo } from 'react'
import InputController from '@components/FormControllers/InputController/InputController'
import * as S from './styles'
import useLogic from './logic'
import Button from '@components/Button/Button'

const RenameDatasetOrJobModal: FC = () => {
  const {
    handleOnCloseModal,
    handleOnSubmit,
    isLoading,
    isOpen,
    modalPayload,
    updateDatasetForm,
  } = useLogic()

  const isDataset = modalPayload?.type === 'Dataset'

  return (
    <S.Container open={isOpen} onClose={handleOnCloseModal}>
      <S.Title>
        Renombrar {isDataset ? 'dataset' : ''} {modalPayload?.name}
      </S.Title>
      <S.SubTitle>
        Se modificará el nombre {isDataset ? 'del Dataset' : ''}
      </S.SubTitle>
      <S.Content>
        <S.Form onSubmit={updateDatasetForm.handleSubmit(handleOnSubmit)}>
          <InputController
            name="name"
            control={updateDatasetForm.control}
            label="Nombre"
            placeholder="Introduce el nombre"
            fullWidth
          />
          <S.Buttons>
            <Button variant="secondary" onClick={handleOnCloseModal}>
              Cancelar
            </Button>
            <Button
              type="submit"
              disabled={isLoading || !updateDatasetForm.formState.isValid}
            >
              Guardar
            </Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default memo(RenameDatasetOrJobModal)
