import { FC, memo } from 'react'

import { Props } from '../types'

const ArrowRight: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 7 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...rest}
  >
    <title>icons/triangle/right</title>
    <g
      id="Elements"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <g
        id="Iconography"
        transform="translate(-1272.000000, -2211.000000)"
        fill="#C5CBD3"
      >
        <g id="Arrows" transform="translate(1144.000000, 2073.000000)">
          <g
            id="result-box-arrows-11x11px"
            transform="translate(0.000000, 66.000000)"
          >
            <g id="Group-2" transform="translate(91.000000, 30.000000)">
              <g
                id="Triangle-Copy-19"
                transform="translate(37.000000, 42.000000)"
              >
                <polygon
                  transform="translate(3.500000, 5.500000) scale(1, -1) rotate(-270.000000) translate(-3.500000, -5.500000) "
                  points="3.5 2 9 9 -2 9"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default memo(ArrowRight)
