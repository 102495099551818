import { FC, memo } from 'react'
import type { Props } from '../../types'

const Teams: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.4091 14V12.6667C11.4091 11.1939 10.188 10 8.68182 10H3.22727C1.72104 10 0.5 11.1939 0.5 12.6667V14"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse
      cx="5.95481"
      cy="4.66667"
      rx="2.72727"
      ry="2.66667"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4996 13.9998V12.6664C15.4986 11.4512 14.6575 10.3902 13.4541 10.0864"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7275 2.08643C11.9343 2.38854 12.7783 3.45175 12.7783 4.66976C12.7783 5.88776 11.9343 6.95098 10.7275 7.25309"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default memo(Teams)
