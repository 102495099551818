import styled, { css } from 'styled-components'

// TODO: this style must apply in SelectComponent but we are using legacy Select from sentisis-component
// so we place rebranding styles here
export const SelectContainer = styled.div<{ $hasError }>`
  .react-select__control {
    border-radius: 4px;
    height: 48px;

    ${({ $hasError }) => {
      return $hasError
        ? css`
            border-color: ${({ theme }) => theme.colours.red[70]};
            :hover {
              border-color: ${({ theme }) => theme.colours.red[70]};
            }
          `
        : css`
            :not(.react-select__control--menu-is-open) {
              border-color: ${({ theme }) => theme.colours.gray[40]};
              :hover {
                border-color: ${({ theme }) => theme.colours.violet[30]};
              }
            }
          `
    }}
  }

  .react-select__option--is-focused {
    color: ${({ theme }) => theme.colours.violet[70]};
    background-color: ${({ theme }) => theme.colours.gray[10]};
  }

  .react-select__menu {
    top: 95%;
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.colours.gray[70]};
  }

  .sentisis-input_label {
    color: ${({ theme }) => theme.colours.gray[90]};
    line-height: 1;
  }
`
