import type { DatasetFormFields } from 'models/Dataset'
import { array, object, string } from 'yup'

// A temporary hack to avoid having to always set the select with some option.
export const UNSELECTED_VALUE = 'unselected'
export const UnselectOption = {
  value: UNSELECTED_VALUE,
  label: '- Ninguna -',
}

export const initialSteps = [
  {
    name: 'Detalle y Análisis',
    isOptional: false,
    isActive: true,
    isDone: false,
  },
  {
    name: 'Filtros avanzados',
    isOptional: true,
    isActive: false,
    isDone: false,
  },
  {
    name: 'Filtros adicionales',
    isOptional: true,
    isActive: false,
    isDone: false,
  },
  {
    name: 'Benchmark comparativo',
    isOptional: true,
    isActive: false,
    isDone: false,
  },
]

// TODO is validation is inherited from the dataset flow from the modal
export const formInitialValues: Partial<DatasetFormFields> = {
  /** Details */
  datasetName: '',
  delimiter: ',',

  /** Analysis fields multi column */
  analysis: [null],

  /** Advanced Filters */
  npsColumn: null,
  locationColumn: null,
  dateColumn: null,
  dateFormat: null,
  country: null,

  /** Additional Fields */
  customFilters: [],

  /** Benchmark */
  benchmark: null,
}

const validateDynamicField = (val, ctx): boolean => {
  if (val === null) {
    return true
  }
  // if item has name, it should have a column
  // returns true when is valid
  const valid = !(val.column === null && !!val.name)
  if (valid === false) {
    return ctx.createError({
      message: 'Selecciona una columna',
      path: ctx.options.path,
    })
  }

  return true
}

const stringOption = object().shape({ label: string(), value: string() })

const dynamicField = object().shape({
  id: string(),
  name: string().nullable(),
  column: stringOption.nullable(),
})

export const datasetValidationSchema = object().shape({
  datasetName: string().required('Este campo es requerido'),
  filename: string(),
  delimiter: string(),
  /** Analysis */
  analysis: array(
    stringOption.test(
      'analysisColumn',
      'Selecciona una columna',
      val => !!val?.column,
    ),
  ),

  /** Advanced Filters */
  npsColumn: stringOption.nullable(),
  locationColumn: stringOption.nullable(),
  dateColumn: stringOption.nullable(),
  dateFormat: stringOption.nullable(),
  country: stringOption.nullable(),

  /* Custom Filters */
  customFilters: array(
    dynamicField.test('customFilters', validateDynamicField),
  ).nullable(),
})

export enum DatasetError {
  DATE = 'date',
  NPS = 'nps',
  URL = 'url',
  LOCATION = 'location',
  TEXT = 'text',
}

export const npsValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const specialCharsReg = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
export const validUrlReg =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
