import Loadable from 'react-loadable'

import Loading from '@components/Loading'

/**
 *
 * @deprecated TODO remove LoadableComponent
 */
const LoadableComponent = Loadable({
  loader: () => import('@views/Summary/Summary'),
  loading: Loading,
  delay: 200,
  timeout: 10000,
})

const LoadableSummary = props => <LoadableComponent {...props} />

export default LoadableSummary
