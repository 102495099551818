import { Colors } from 'sentisis-components'

export type ThemeMode = 'LIGHT' | 'DARK'

const rebrandingColors = {
  white: '#FFF',
  black: '#1D2227',
  violet: {
    30: '#E6CDFA',
    60: '#CDA2ED',
    70: '#B97DE6',
    80: '#B473E5',
    90: '#A86FDD',
  },
  turqoise: {
    10: '#D9FFFC',
    60: '#70CCC4',
    70: '#58C3BD',
    80: '#43BBB9',
    100: '#3CA7A7',
  },
  gray: {
    10: '#F7F7F9',
    20: '#EEEEF0',
    30: '#E0E0E3',
    40: '#D4D4D6',
    50: '#BBBBBD',
    60: '#A7A7AA',
    70: '#969699',
    80: '#87888B',
    90: '#5B5D66',
    100: '#40434c',
    110: '#282A33',
  },
  slate: {
    10: '#F0F0F2',
    20: '#D9D9DB',
    30: '#C2C2C5',
    40: '#ACACAF',
    50: '#959599',
    60: '#7F7F82',
    70: '#68686B',
    80: '#515155',
    90: '#3B3B3E',
    100: '#88888a',
  },
  blue: {
    20: '#E5F6FD',
    30: '#3f75cc',
    70: '#4985DC',
    80: '#286CD1',
    90: '#2564C2',
  },
  orange: {
    10: '#FFF4E5',
    30: '#FFAE00',
    50: '#ED6C02',
    80: '#663C00',
  },
  red: {
    5: '#FDEDED',
    30: '#E6312E',
    40: '#FAA796',
    60: '#5F2120',
    70: '#DE6047',
    80: '#D34326',
    90: '#C73F23',
  },
}

const colorsToMove = {
  black: '#49555F',
  wine: '#242E6D',
  bgGray: '#F7F8FA',
  shadowBorder: '#ebf0f4',
  skyBlue: '#4C87D9',
  smoky: '#CCD1D8',
  fieldBgGray: '#E4EAEF',
  green: '#10D4AB',
  boxShadowGray: 'rgba(0, 82, 163, 0.06)',
  mermaid: '#10D4AB',
  link: '#4A90E2',
  white: '#FFF',
  emotion: '#7c85c1',
  error: '#E6312E',
  blue: {
    10: '#D5DCEB',
    50: '#0052A3',
    link: '#4985DC',
    tag: '#1E69B5',
    dark: 'rgb(31,43,89)',
    deep: '#1F2B59',
  },
  red: {
    10: '#E45B46',
    20: '#FBEDEB',
  },
  orange: {
    10: '#FFAE00',
  },
  purple: {
    10: '#283270',
    20: '#3E4EB8',
  },
  bubblesColors: [
    '#ff8fe4',
    '#64ebbb',
    '#e3abff',
    '#8add88',
    '#adbcff',
    '#cbec4f',
    '#7c85c1',
    '#ffde4b',
    '#6baff3',
    '#ffb448',
    '#3aabc5',
    '#dc8d6a',
    '#60f4fe',
    '#ff88a0',
  ],
  gray: {
    5: '#C5CBD3', // icon-disable
    10: '#F2F4F7',
    15: '#E0E2E4',
    20: '#9BA7B5',
    25: '#CCD1D8', // cancel buttons BG
    30: '#5C6B7E',
    35: '#a4afba',
    40: '#7a8d9f',
    45: '#a5afba', // grayFaint
    50: '#4a637c',
    55: '#7E8C9C',
    // Bold titles
    60: '#637181',
    light: '#A4AFBB',
    dark: '#4A637C',
  },
  // rebranding colors are used here because Tag component use it
  turqoise: {
    80: '#43BBB9',
    100: '#3CA7A7',
  },
  sentiment: {
    positive: '#6CDB78',
    POSITIVE: '#6CDB78',
    negative: '#E92E25',
    NEGATIVE: '#E92E25',
    objective: '#FFAE00',
    OBJECTIVE: '#FFAE00',
    unknown: '#4a637c73',
    UNKNOWN: '#4a637c73',
  },
  nps: {
    promoters: '#6EDBA4',
    detractors: '#F5817F',
    passives: '#FFBE4C',
  },
  tagCategoryBg: '#246BB3',
  tagOperator: {
    negative: '#C9271F',
    objective: '#E29A00',
    unknown: 'rgba(48, 64, 80, 0.2)',
    positive: '#5FC169',
    category: '#3F75CC',
    custom: 'rgb(69,92,115)',
  },
  sources: {
    twitter: '#73B8E6',
    facebook: '#3D5C99',
    instagram: '#003366',
    youtube: '#BE0000',
    media: '#FF9900',
  },
  products: {
    drive: '#0054a1',
    race: '#11e2b6',
    explore: '#fbaf3a',
    maps: '#ff648a',
  },
  categories: [
    '#283F59',
    '#0294B7',
    '#4A90E2',
    '#836EC5',
    '#DD3583',
    '#7E8C9C',
    '#65BED3',
    '#93BDEE',
    '#B4A7DC',
    '#EB84B4',
  ],
  themes: [
    '#ff8fe4',
    '#64ebbb',
    '#e3abff',
    '#8add88',
    '#adbcff',
    '#cbec4f',
    '#7c85c1',
    '#ffde4b',
    '#6baff3',
    '#ffb448',
    '#3aabc5',
    '#dc8d6a',
    '#60f4fe',
    '#ff88a0',
  ],
  graphics: [
    '#2D559E',
    '#01E5F2',
    '#00B7AF',
    '#7A62F0',
    '#BB62F0',
    '#283F59',
    '#0294B7',
    '#4A90E2',
    '#836EC5',
    '#DD3583',
  ],
  age: ['#08b9af', '#8366bf', '#37c0d0', '#273d57', '#a5afba'],
  maps: [
    '#99AAFF',
    '#39E6AC',
    '#FF4CB5',
    '#FFD500',
    '#D593F5',
    '#FFA200',
    '#33F1FF',
    '#B2E617',
    '#91B1F3',
    '#FF6464',
  ],
}

/* TODO: All components must use rebrading colors */
const light = {
  name: 'LIGHT' as ThemeMode,
  colors: { ...Colors, ...colorsToMove, lightGray: '#A4AFBB' },
  colours: rebrandingColors,
  shadows: {
    small: '0 2px 4px rgba(75, 85, 95, .11)',
  },
  zIndex: {
    overVisualization: 10,
    overContent: 100,
    overEverything: 1000,
  },
}

export type Theme = typeof light
const themes = {
  light,
  dark: {}, // future implementation,
}

export default themes as { [key in keyof typeof themes]: Theme }
