import { FC, memo } from 'react'
import type { Props } from '../types'

const Info: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="icon/info1/active2">
      <g id="info 2">
        <rect
          id="Rectangle 2 Copy 56"
          x="0.75"
          y="0.75"
          width="14.5"
          height="14.5"
          rx="7.25"
          stroke="#10D4AB"
          strokeWidth="1.5"
        />
        <g id="Group 8">
          <path
            id="Rectangle 2 Copy 57"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.11285 6.55566H8.89063V11.889H7.11285V6.55566Z"
            fill="currentColor"
          />
          <rect
            id="Rectangle 2 Copy 58"
            x="7.11285"
            y="3.55566"
            width="1.77778"
            height="1.77778"
            rx="0.888889"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default memo(Info)
