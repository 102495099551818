import { useState, useCallback } from 'react'
import moment from 'moment'
import { DateRange } from 'moment-range'
import { useLogicsParams, DateEvent, Dates } from './types'

const useLogic = ({
  datasetDateRange,
  dateRange,
  handleChangeDate,
}: useLogicsParams) => {
  // TODO INAC-8009 The commented code related to the Timezone has generated a new bug, we revert the changes until further fix.
  const minDate =
    datasetDateRange &&
    datasetDateRange.start &&
    moment.utc(datasetDateRange.start).toDate()
  // toLocalTimezone(moment.utc(props.datasetDateRange.start))

  const maxDate =
    datasetDateRange &&
    datasetDateRange.end &&
    moment.utc(datasetDateRange.end).toDate()
  // toLocalTimezone(moment.utc(props.datasetDateRange.end))

  const start = dateRange.start.toDate()
  const end = dateRange.end.toDate()
  /*const start = toLocalTimezone(props.dateRange.start.utc())
  const end = toLocalTimezone(props.dateRange.end.utc())*/

  const [dates, setDates] = useState<Dates>({
    startDate: start,
    endDate: end,
  })

  const [anchorEl, setAnchorEl] = useState(null)

  const onDateChange = useCallback(
    (data: DateEvent) => {
      /*const start = moment.utc(data.startDate)
    const end = moment.utc(data.endDate)

    const dateRange = new DateRange(
      toLocalTimezone(start),
      toLocalTimezone(end),
    )*/

      const dateRange = new DateRange(data.startDate, data.endDate)
      handleChangeDate(dateRange)
    },
    [handleChangeDate],
  )

  // Dates
  const handleDatesChange = useCallback(dates => {
    setDates(dates)
  }, [])

  const handleSubmitDates = useCallback(() => {
    setAnchorEl(null)
    onDateChange({
      realTimePeriod: -1,
      dateRange: 0,
      ...dates,
    })
  }, [dates, onDateChange])

  // Popover
  const id = 'datepicker-popover'

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)

  return {
    end,
    maxDate,
    minDate,
    start,

    dates,
    handleDatesChange,
    handleSubmitDates,
    handleClick,
    handleClose,
    open,
    id,
    anchorEl
  }
}

export default useLogic
