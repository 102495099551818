import { useState, useRef, useCallback } from 'react'
import { endOfDay, startOfDay } from 'date-fns-datepicker'
import { CalendarDates, Props } from './types'

const useLogic = ({start, end, minDate, maxDate, onChange}: Props) => {

  // Save calendar dates
  const [startCalendarDates, setStartCalendarDates] = useState<CalendarDates>({start, end})
  const [endCalendarDates, setEndCalendarDates] = useState<CalendarDates>({start, end})
  
  // Save not available months due to min and max dates and hide them in react-datepicker dropdown
  const getMonthIfYearIsEqual = useCallback((date: Date, dateReference: Date): number => {
    return date.getFullYear() === dateReference.getFullYear() ? dateReference.getMonth() : null
  }, [])

  const [startMinMonth, setStartMinMonth] = useState<number>(getMonthIfYearIsEqual(start, minDate))
  const [startMaxMonth, setStartMaxMonth] = useState<number>(getMonthIfYearIsEqual(start, maxDate))
  const [endMinMonth, setEndMinMonth] = useState<number>(getMonthIfYearIsEqual(end, minDate))
  const [endMaxMonth, setEndMaxMonth] = useState<number>(getMonthIfYearIsEqual(end, maxDate))

  // Temp dates to draw date range between calndars when 
  // user has selected a start date in start calendar
  // and cursor is over end calendar
  const [tempEndDateForStartCalendar, setTempEndDateForStartCalendar] = useState<Date>(null)
  const [tempStartDateForEndCalendar, setTempStartDateForEndCalendar] = useState<Date>(null)

  // react-datepicker refs
  const startCalendar = useRef(null)
  const endCalendar = useRef(null)

  const handleDateChange = useCallback((isStartCalendar: boolean) => (dates: [Date, Date]) => {
    // Empty previous dates
    setStartCalendarDates({ start: null, end: null })
    setEndCalendarDates({ start: null, end: null })
    setTempEndDateForStartCalendar(null)
    setTempStartDateForEndCalendar(null)

    // Get new dates
    const [start, end] = dates
    const datesObject = {
      start: startOfDay(start),
      end: end ? endOfDay(end) : end, // Get end of day of end date
    }

    // If there is end date
    if (end) {
      const sameMonthAndYear =
        start.getFullYear() === end.getFullYear() &&
        start.getMonth() === end.getMonth()

      // we check if start and end date is same month
      if (sameMonthAndYear) {
        // If it's true, we just update one calendar
        if (isStartCalendar) {
          setStartCalendarDates(datesObject)
        } else {
          setEndCalendarDates(datesObject)
        }
      } else {
        // If not, we update both calendars
        setStartCalendarDates(datesObject)
        setEndCalendarDates(datesObject)
      }
    } else {
      // If there isn't end date, just update one calendar
      if (isStartCalendar) {
        setStartCalendarDates(datesObject)
      } else {
        setEndCalendarDates(datesObject)
      }
    }

    // Send dates
    onChange({
      startDate: datesObject.start,
      endDate: datesObject.end,
    })
  }, [onChange])

  const handleStartYearChange = useCallback((date: Date) => {
    // If year is equal to min date year
    if (date.getFullYear() === minDate.getFullYear()) {
      // Calculate month to display in datepicker dropdown
      setStartMinMonth(minDate.getMonth())
      setStartMaxMonth(null)

      // Force react-datepicker to update view with new selected month
      const selectedDate = new Date(
        date.getFullYear(),
        minDate.getMonth(),
        minDate.getDate(),
      )
      startCalendar.current.setPreSelection(selectedDate)

      return null
    }

    // If year is equal to max date year
    if (date.getFullYear() === maxDate.getFullYear()) {
      // Calculate month to display in datepicker dropdown
      setStartMinMonth(null)
      setStartMaxMonth(maxDate.getMonth())

      // Force react-datepicker to update view with new selected month
      const selectedDate = new Date(
        date.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate(),
      )
      startCalendar.current.setPreSelection(selectedDate)

      return null
    }

    // If month are different, show all months
    setStartMinMonth(null)
    setStartMaxMonth(null)
  }, [minDate, maxDate])

  const handleEndYearChange = useCallback((date: Date) => {
    // Logic is same that handleStartYearChange function
    if (date.getFullYear() === minDate.getFullYear()) {
      setEndMinMonth(minDate.getMonth())
      setEndMaxMonth(null)

      const selectedDate = new Date(
        date.getFullYear(),
        minDate.getMonth(),
        minDate.getDate(),
      )
      endCalendar.current.setPreSelection(selectedDate)

      return null
    }

    if (date.getFullYear() === maxDate.getFullYear()) {
      setEndMinMonth(null)
      setEndMaxMonth(maxDate.getMonth())

      const selectedDate = new Date(
        date.getFullYear(),
        maxDate.getMonth(),
        maxDate.getDate(),
      )
      endCalendar.current.setPreSelection(selectedDate)

      return null
    }

    setEndMinMonth(null)
    setEndMaxMonth(null)
  }, [minDate, maxDate])

  const formatWeekDay = weekDay => {
    const weekDaysMap = {
      1: 'L',
      2: 'M',
      3: 'M',
      4: 'J',
      5: 'V',
      6: 'S',
      0: 'D',
    }

    return weekDaysMap[weekDay.getDay()]
  }

  const handleEndCalendarMouseEnter = useCallback(() => {
    // If user
    if (
      startCalendarDates.start && // has selected start date on start calendar
      !startCalendarDates.end && // has not selected end date yet
      !endCalendarDates.end
    ) {
      // create a temp end date with one year offset
      // to style start calendar when cursor is over end calendar
      const temporalEnd = new Date(startCalendarDates.start)
      temporalEnd.setFullYear(end.getFullYear() + 1)
      setTempEndDateForStartCalendar(temporalEnd)

      // and set end calendar start date temp to start calendar start date
      setTempStartDateForEndCalendar(startCalendarDates.start)
    }
  }, [startCalendarDates.start, startCalendarDates.end, endCalendarDates.end, end])

  const handleEndCalendarMouseLeave = useCallback(() => {
    // Remove temp dates
    setTempEndDateForStartCalendar(null)
    setTempStartDateForEndCalendar(null)
  }, [])

  return {
    endCalendar,
    endCalendarDates,
    endMaxMonth,
    endMinMonth,
    formatWeekDay,
    handleDateChange,
    handleEndCalendarMouseEnter,
    handleEndCalendarMouseLeave,
    handleEndYearChange,
    handleStartYearChange,
    startCalendar,
    startCalendarDates,
    startMaxMonth,
    startMinMonth,
    tempEndDateForStartCalendar,
    tempStartDateForEndCalendar,
  }
}

export default useLogic