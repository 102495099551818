import { FC, memo } from 'react'
import { Button, ButtonTypes } from 'sentisis-components'
import useLogic from './logic'
import * as S from './styles'

const ConnectPowerBI: FC = () => {
  const { isOpen, handleOnCancel, handleOnConnect } = useLogic()

  return (
    <S.Container open={isOpen} onClose={handleOnCancel}>
      <S.Title>Conectar Power BI</S.Title>
      <S.Content>
        Estas a punto de conectar Power BI con Séntisis mediante una exportación
        Redshift. Asegúrate de haber seguido las instrucciones para una correcta
        sincronización de los datos. ¿Seguro que quieres continuar con la
        conexión?
        <S.Buttons>
          <Button text="" type={ButtonTypes.FLAT} onClick={handleOnCancel}>
            Cancelar
          </Button>
          <Button text="" onClick={handleOnConnect}>
            Conectar
          </Button>
        </S.Buttons>
      </S.Content>
    </S.Container>
  )
}

export default memo(ConnectPowerBI)
