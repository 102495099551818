import { FC } from 'react'
import {
  Container,
  Content,
  Title,
  SubTitle,
  InnerContent,
  Buttons,
} from './styles'
import type { Props } from './types'

const Modal: FC<Props> = ({
  children,
  className,
  disableBackdropClick,
  disableEscapeKeyDown,
  disableFullScreen,
  disableRestoreFocus,
  onClose,
  open,
}) => {
  const handleClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Container
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableRestoreFocus={disableRestoreFocus}
      className={className}
      $disableFullScreen={!!disableFullScreen}
    >
      <Content>{children}</Content>
    </Container>
  )
}

export default Modal

export { Content, Title, SubTitle, InnerContent, Buttons }
