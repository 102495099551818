import {
  SentimentGroup,
  SentimentObject,
  Word,
  TagCloudData,
  SentimentPaletteEnum,
  TopTagsResponse,
} from './TopTags.types'

const toSentimentObject = (groups: SentimentGroup[]): SentimentObject => {
  return groups.reduce(
    (acc, group) => ({
      ...acc,
      [group.sentiment.toLowerCase()]: group.count,
    }),
    {} as SentimentObject,
  )
}

// Returns a sentiment depending the number of ratio
const getTagSentimentByRatio = (word: Word) => {
  const { negative = 0, positive = 0 } = word
  if (!positive) {
    return 'NEGATIVE'
  }

  if (!negative) {
    return 'POSITIVE'
  }

  const ratio = positive / negative
  if (ratio >= 0.9 && ratio <= 1.1) {
    return 'OBJECTIVE'
  }

  if (ratio > 1.1) {
    return 'POSITIVE'
  }

  return 'NEGATIVE'
}

export const getTagSentiment = (word: Word) => {
  const { negative = 0, positive = 0, objective = 0 } = word
  const negAnPos = negative + positive

  // Step 0 (objective): no negative or positive mentions
  if (!negAnPos) {
    return 'OBJECTIVE'
  }

  // Step 1 (ratio): not enough objectives mentions
  if (objective <= negAnPos * 20) {
    return getTagSentimentByRatio(word)
  }

  // Step 2 (objective): not enough positive and negative mentions
  if (negAnPos <= 20) {
    return 'OBJECTIVE'
  }

  // Step 3 (objective): way more objective mentions than negative and positive
  if (objective >= negAnPos * 30) {
    return 'OBJECTIVE'
  }

  return getTagSentimentByRatio(word)
}

export const parseAsRawTags = (response: TopTagsResponse[]) =>
  response?.map((tag) => {
    const sentiments = toSentimentObject(tag.bySentiment)
    return {
      label: tag.tag.name,
      size: tag.count,
      ...sentiments,
    }
  }) || []

export const processTopTags = (tags: TopTagsResponse[]) => {
  const rawSentiments = parseAsRawTags(tags)
  const chartData: TagCloudData[] = rawSentiments.map((word) => {
    const sentiment = getTagSentiment(word)
    return {
      label: word.label,
      size: word.size,
      type: sentiment,
      labelSettings: { fill: SentimentPaletteEnum[sentiment] },
    }
  })

  return chartData
}
