import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useModal from 'apollo/hooks/useModal'
import useSnackbar from 'apollo/hooks/useSnackbar'
import { ModalType } from 'apollo/reactive/modal'
import { getEvolution } from '@utils/evolution'
import { FiltersContext } from '@providers/FiltersProvider'
import { getPercentage } from '@utils/format'
import getDatasetInsights from '../../../apollo/requests/getDatasetInsights'
import type { SummaryInsightsInput } from 'models/Dataset'


const useLogic = () => {
  const { modalType, onCloseModal, insightsPayload } = useModal()
  const [loading, setLoading] = useState(false)
  const [loadingValue, setLoadingValue] = useState(0)
  const [openAIResponse, setOpenAIResponse] = useState('')
  const { openSnackbar } = useSnackbar()
  const history = useHistory()
  const loadingTimeout = useRef(null)
  const {
    filtersState: { datasetDateRange },
  } = useContext(FiltersContext)
  const hasDate = Boolean(datasetDateRange?.start)

  const isOpen = modalType === ModalType.INSIGHTS

  const handleOnCloseModal = useCallback(() => {
    setLoading(false)
    setLoadingValue(0)
    clearTimeout(loadingTimeout.current)
    loadingTimeout.current = null

    onCloseModal()
  }, [onCloseModal])

  const handleCopyText = useCallback(async () => {
    await navigator.clipboard.writeText(openAIResponse)

    openSnackbar({
      title: 'Se ha copiado el texto al portapapeles',
      iconName: 'check',
      horizontal: 'center',
    })
  }, [openAIResponse, openSnackbar])

  const inProgress = useCallback(() => {
    if (loadingValue === 99) {
      return null
    }

    let time = 0

    if (loadingValue < 70) {
      time = 1500 // normal
    } else if (loadingValue < 90) {
      time = 2000 // slow
    } else {
      time = 3500 // ultra slow
    }

    loadingTimeout.current = setTimeout(() => {
      // if simulation reaches 100%, wait at 99% until request is finished
      const newValue = loadingValue + 5
      setLoadingValue(newValue >= 100 ? 99 : newValue)
    }, time)
  }, [loadingValue])

  const finishProgress = useCallback(() => {
    // if request finishes, clear simulation timeout, set progress at 100%
    // and delay loading as false to avoid flickering
    setLoadingValue(100)
    clearTimeout(loadingTimeout.current)

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const fetchOpenAI = useCallback(
    async (promptConfig: SummaryInsightsInput) => {
      try {
        setLoading(true)
        inProgress()

        const message = await getDatasetInsights({ input: promptConfig })
        setOpenAIResponse(message)
        finishProgress()
      } catch (error) {
        setOpenAIResponse('Ha ocurrido un error, contacte con soporte.')
        finishProgress()
      }
    },
    [inProgress, finishProgress],
  )

  // watch route change to clear modal data
  useEffect(() => {
    history.listen(() => {
      setLoading(false)
      setLoadingValue(0)
      setOpenAIResponse('')
    })
  }, [history])

  // increase progress recursively
  useEffect(() => {
    if (loading && loadingValue > 0 && loadingValue < 100) {
      inProgress()
    }
  }, [loadingValue, loading, inProgress])

  // get prompt data before making a request to open ai
  useEffect(() => {
    if (!isOpen || openAIResponse || loading) {
      return null
    }

    const { topCategories, nps, netNPS, npsScores, sentiments, documents } =
      insightsPayload

    if (!topCategories) {
      return null
    }

    const hasNps = Boolean(nps)

    const categories = hasNps
      ? topCategories.byNetNps
      : topCategories.bySentiment

    // NPS top worst category has negative numbers
    // while no NPS top categories has positive ones
    const worst = categories.negative
      .sort((current, next) =>
        hasNps ? current.value - next.value : next.value - current.value,
      )
      .slice(0, 5)

    const best = categories.positive
      .sort((current, next) => next.value - current.value)
      .slice(0, 5)

    const npsPercent = {
      positive: null,
      objective: null,
      negative: null,
    }

    const npsEvolutionByGroup = {
      positive: null,
      objective: null,
      negative: null,
    }

    const sentimentsPercent = {
      positive: null,
      objective: null,
      negative: null,
    }

    const sentimentsEvolution = {
      positive: null,
      objective: null,
      negative: null,
    }

    if (hasNps) {
      const total = documents.totalCount

      const subTotal = {
        positive: 0,
        objective: 0,
        negative: 0,
      }

      const subTotalPrev = {
        positive: 0,
        objective: 0,
        negative: 0,
      }

      npsScores.forEach(score => {
        switch (score.name) {
          // promoters
          case '10':
          case '9':
            subTotal.positive += score.count
            subTotalPrev.positive += score.prevCount
            break
          // neutral
          case '8':
          case '7':
            subTotal.objective += score.count
            subTotalPrev.objective += score.prevCount
            break
          // detractors
          case '6':
          case '5':
          case '4':
          case '3':
          case '2':
          case '1':
          case '0':
            subTotal.negative += score.count
            subTotalPrev.negative += score.prevCount
            break

          default:
            break
        }
      })

      npsPercent.positive = getPercentage(subTotal.positive, total)
      npsPercent.objective = getPercentage(subTotal.objective, total)
      npsPercent.negative = getPercentage(subTotal.negative, total)

      npsEvolutionByGroup.positive = getEvolution(
        subTotal.positive,
        subTotalPrev.positive,
      )

      npsEvolutionByGroup.objective = getEvolution(
        subTotal.objective,
        subTotalPrev.objective,
      )

      npsEvolutionByGroup.negative = getEvolution(
        subTotal.negative,
        subTotalPrev.negative,
      )
    } else {
      sentiments.forEach(sentiment => {
        sentimentsPercent[sentiment.type.toLowerCase()] = sentiment.percentage
        sentimentsEvolution[sentiment.type.toLowerCase()] = getEvolution(
          sentiment.value,
          sentiment.prevValue,
        )
      })
    }

    const promptConfig: SummaryInsightsInput = hasNps
      ? {
          worst,
          best,
          nps: netNPS.count,
          npsEvolution: getEvolution(netNPS.count, netNPS.prevCount),
          npsPercent,
          npsEvolutionByGroup,
          hasDate,
        }
      : {
          worst,
          best,
          sentimentsPercent,
          sentimentsEvolution,
          hasDate,
        }

    fetchOpenAI(promptConfig)
  }, [isOpen, openAIResponse, insightsPayload, hasDate, loading, fetchOpenAI])

  return {
    isOpen,
    loading,
    loadingValue,
    openAIResponse,
    handleCopyText,
    handleOnCloseModal,
  }
}

export default useLogic
