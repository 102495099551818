import { FC, memo } from 'react'
import type { Props } from '../../types'

const UserFeatures: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8 4C7.72386 4 7.5 4.22386 7.5 4.5C7.5 4.77614 7.72386 5 8 5V4ZM12.812 4.5L13.1711 4.15205C13.0769 4.05486 12.9474 4 12.812 4V4.5ZM13.75 5.46793L14.2435 5.54842C14.2689 5.39239 14.2191 5.23351 14.1091 5.11998L13.75 5.46793ZM13.337 8L12.8435 7.91951C12.8127 8.1085 12.8925 8.2985 13.0491 8.40877C13.2056 8.51904 13.4114 8.53022 13.579 8.43756L13.337 8ZM15.5 6.80389H16C16 6.62691 15.9064 6.46311 15.754 6.3732C15.6015 6.2833 15.4129 6.28069 15.258 6.36634L15.5 6.80389ZM8 19C7.72386 19 7.5 19.2239 7.5 19.5C7.5 19.7761 7.72386 20 8 20V19ZM8 5C8.27614 5 8.5 4.77614 8.5 4.5C8.5 4.22386 8.27614 4 8 4V5ZM8 20C8.27614 20 8.5 19.7761 8.5 19.5C8.5 19.2239 8.27614 19 8 19V20ZM8 5H12.812V4H8V5ZM12.453 4.84795L13.3909 5.81588L14.1091 5.11998L13.1711 4.15205L12.453 4.84795ZM13.2565 5.38744L12.8435 7.91951L13.8305 8.08049L14.2435 5.54842L13.2565 5.38744ZM13.579 8.43756L15.742 7.24145L15.258 6.36634L13.095 7.56244L13.579 8.43756ZM15 6.80389V17.8333H16V6.80389H15ZM15 17.8333C15 18.4777 14.4777 19 13.8333 19V20C15.03 20 16 19.03 16 17.8333H15ZM13.8333 19H8V20H13.8333V19ZM8 4H2.16667V5H8V4ZM2.16667 4C0.97005 4 0 4.97005 0 6.16667H1C1 5.52233 1.52233 5 2.16667 5V4ZM0 6.16667V17.8333H1V6.16667H0ZM0 17.8333C0 19.03 0.97005 20 2.16667 20V19C1.52233 19 1 18.4777 1 17.8333H0ZM2.16667 20H8V19H2.16667V20Z"
      fill="currentColor"
    />
    <path
      d="M12 16V15C12 13.8954 11.1046 13 10 13H6C4.89543 13 4 13.8954 4 15V16"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="8"
      cy="9"
      r="2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5815 3.30389L19 3.6756L17.25 5.46793L17.663 8L15.5 6.80389L13.337 8L13.75 5.46793L12 3.6756L14.4185 3.30389L15.5 1L16.5815 3.30389Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(UserFeatures)
