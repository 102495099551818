import { FC, memo } from 'react'
import type { Props } from '../types'

const RefreshIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.63477 1.00006L5.45295 1.00006L5.45295 4.81824"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8167 15L7.99854 15L7.99853 11.1818"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.64999 13.8741C4.91031 13.9662 5.19602 13.8298 5.28814 13.5695C5.38026 13.3092 5.24391 13.0235 4.98358 12.9314L4.64999 13.8741ZM1.14131 9.26746L0.65364 9.3778L1.14131 9.26746ZM2.6786 3.95273L3.03207 4.30637C3.03576 4.30268 3.0394 4.29893 3.04298 4.29512L2.6786 3.95273ZM5.81752 1.34239C6.00662 1.14115 5.99678 0.824721 5.79554 0.635626C5.5943 0.446529 5.27787 0.456374 5.08877 0.657614L5.81752 1.34239ZM7.63423 14.6576C7.44513 14.8589 7.45498 15.1753 7.65622 15.3644C7.85746 15.5535 8.17389 15.5436 8.36298 15.3424L7.63423 14.6576ZM10.7732 12.0473L10.4197 11.6936C10.416 11.6973 10.4124 11.7011 10.4088 11.7049L10.7732 12.0473ZM8.80177 2.12592C8.54144 2.0338 8.25573 2.17015 8.16361 2.43048C8.07149 2.6908 8.20785 2.97651 8.46817 3.06863L8.80177 2.12592ZM4.98358 12.9314C3.29158 12.3326 2.02507 10.9077 1.62899 9.15711L0.65364 9.3778C1.1255 11.4633 2.6343 13.1608 4.64999 13.8741L4.98358 12.9314ZM1.62899 9.15711C1.2329 7.40655 1.76262 5.57518 3.03207 4.30637L2.32514 3.59909C0.812842 5.11063 0.181779 7.29234 0.65364 9.3778L1.62899 9.15711ZM3.04298 4.29512L5.81752 1.34239L5.08877 0.657614L2.31423 3.61034L3.04298 4.29512ZM8.36298 15.3424L11.1375 12.3897L10.4088 11.7049L7.63423 14.6576L8.36298 15.3424ZM11.1266 12.4009C12.6389 10.8894 13.27 8.70766 12.7981 6.6222L11.8228 6.84289C12.2189 8.59346 11.6891 10.4248 10.4197 11.6936L11.1266 12.4009ZM12.7981 6.6222C12.3263 4.53675 10.8175 2.8392 8.80177 2.12592L8.46817 3.06863C10.1602 3.66738 11.4267 5.09232 11.8228 6.84289L12.7981 6.6222Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(RefreshIcon)
