import { FC, memo } from 'react'
import type { Props } from '../types'

const EditIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      id="Path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5908 1.58496C12.0953 1.08046 12.8306 0.883433 13.5198 1.06809C14.209 1.25275 14.7472 1.79104 14.9319 2.4802C15.1166 3.16936 14.9195 3.90468 14.415 4.40918L4.8833 13.9409L1 15L2.05908 11.1167L11.5908 1.58496Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(EditIcon)
