import { FC, memo } from 'react'
import type { Props } from '../types'

const Calendar: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.166 0.666687C10.4422 0.666687 10.666 0.890545 10.666 1.16669V1.50006H11.6667C12.6792 1.50006 13.5 2.32087 13.5 3.33339V12.6667C13.5 13.6793 12.6792 14.5001 11.6667 14.5001H2.33334C1.32082 14.5001 0.500006 13.6793 0.500006 12.6667V5.00246L0.5 5.00002L0.500006 4.99758V3.33339C0.500006 2.32087 1.32082 1.50006 2.33334 1.50006H3.00001V1.16669C3.00001 0.890545 3.22386 0.666687 3.50001 0.666687C3.77615 0.666687 4.00001 0.890545 4.00001 1.16669V1.50006H9.66602V1.16669C9.66602 0.890545 9.88987 0.666687 10.166 0.666687ZM3.00001 2.50006V2.83335C3.00001 3.1095 3.22386 3.33335 3.50001 3.33335C3.77615 3.33335 4.00001 3.1095 4.00001 2.83335V2.50006H9.66602V2.83335C9.66602 3.1095 9.88987 3.33335 10.166 3.33335C10.4422 3.33335 10.666 3.1095 10.666 2.83335V2.50006H11.6667C12.1269 2.50006 12.5 2.87316 12.5 3.33339V4.52847C12.4479 4.51005 12.3918 4.50002 12.3333 4.50002L1.50001 4.50002V3.33339C1.50001 2.87316 1.8731 2.50006 2.33334 2.50006H3.00001ZM1.50001 5.50002V12.6667C1.50001 13.127 1.8731 13.5001 2.33334 13.5001H11.6667C12.1269 13.5001 12.5 13.127 12.5 12.6667V5.47157C12.4479 5.48999 12.3918 5.50002 12.3333 5.50002H1.50001Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Calendar)
