import styled from 'styled-components/macro'

import DefaultModal, {
  Content as ModalContent,
  Title as ModalTitle,
  Buttons as ModalButtons,
  InnerContent as ModalInnerContent,
} from '@components/Modal/Modal'

export const Container = styled(DefaultModal)`
  ${ModalContent} {
    max-width: 732px;
    width: 732px;
  }
`

export const Title = styled(ModalTitle)``

export const Content = styled(ModalInnerContent)``

export const Form = styled.form`
  overflow: hidden;
`

export const Buttons = styled(ModalButtons)``

export const Row = styled.div`
  display: flex;
  > * {
    flex: 0 0 50%;
    width: 50%;
    &:not(:last-child) {
      padding-right: 8px;
    }
  }
`
