import { useQuery, FetchPolicy } from '@apollo/client'
import { useMemo } from 'react'
import { GET_TAGS_BY_SENTIMENT } from 'apollo/queries/dataset'
import {
  TagCloudData,
  TopTagsResponse,
} from 'models/Dataset/TopTags/TopTags.types'
import { processTopTags } from 'models/Dataset/TopTags/TopTags.parsers'

export const useTopTags = ({
  fetchPolicy = 'cache-first',
  datasetId,
  projectId,
  filters,
}: {
  projectId: string
  datasetId: string
  fetchPolicy?: FetchPolicy
  filters?: Record<string, unknown>
}) => {
  const {
    data,
    loading: topTagsLoading,
    error: topTagsError,
  } = useQuery(GET_TAGS_BY_SENTIMENT, {
    fetchPolicy,
    skip: !projectId || !datasetId,
    variables: {
      datasetId,
      projectId,
      filters,
    },
  })

  const topTags: TagCloudData[] = useMemo(() => {
    const tags: TopTagsResponse[] = data?.project?.dataset?.tags
    if (!tags) {
      return null
    }
    return processTopTags(tags)
  }, [data])

  return {
    topTags,
    topTagsLoading,
    topTagsError,
  }
}
