import { gql } from '@apollo/client'

export const UPDATE_JOB = gql`
  mutation UpdateJobMutation($id: ID!, $name: String) {
    updateJob(id: $id, name: $name) {
      id
      name
      createdAt
      lastUpdate
      status
      statusMessage
      analysisProgress {
        percentage
      }
    }
  }
`

export const DELETE_JOB = gql`
  mutation DeleteJobMutation($id: ID!) {
    deleteJob(id: $id)
  }
`

export const CANCEL_JOB = gql`
  mutation CancelJob($id: ID!) {
    cancelJob(id: $id)
  }
`
