import { gql } from '@apollo/client'
import { TEAM_FRAGMENT } from '../fragments/Team'

export const GET_TEAMS = gql`
  query GetTeams(
    $first: Int
    $after: Int
    $name: String
    $clientId: ID
    $clientName: String
  ) {
    allTeams(
      pagination: { first: $first, after: $after }
      filters: { name: $name, clientId: $clientId, clientName: $clientName }
    ) {
      ...TeamData
    }
  }
  ${TEAM_FRAGMENT}
`
