import { FC, memo } from 'react'
import { Button, ButtonTypes } from 'sentisis-components'
import useLogic from './logic'
import * as S from './styles'

const DisconnectPowerBI: FC = () => {
  const { isOpen, handleOnCancel, handleOnDisconnect } = useLogic()

  return (
    <S.Container open={isOpen} onClose={handleOnCancel}>
      <S.Title>Desconectar exportación de datos</S.Title>
      <S.Content>
        Estas a punto de desconectar la integración con Power BI. Séntisis
        dejará de exportar datos y se eliminarán de Redshift los datos ya
        exportados. ¿Estas seguro de continuar con la desconexión?
        <S.Buttons>
          <Button text="" type={ButtonTypes.FLAT} onClick={handleOnCancel}>
            Cancelar
          </Button>
          <Button
            type={ButtonTypes.DANGER}
            text=""
            onClick={handleOnDisconnect}
          >
            Desconectar
          </Button>
        </S.Buttons>
      </S.Content>
    </S.Container>
  )
}

export default memo(DisconnectPowerBI)
