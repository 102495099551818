import { FC, memo } from 'react'
import type { Props } from '../../types'

const Localization: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6.63636C14 11.4091 8 15.5 8 15.5C8 15.5 2 11.4091 2 6.63636C2 3.24734 4.68629 0.5 8 0.5C11.3137 0.5 14 3.24734 14 6.63636V6.63636Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"

    />
    <ellipse
      cx="8"
      cy="6.63634"
      rx="2"
      ry="2.04545"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default memo(Localization)
