import { FC, ReactElement } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { ReactComponent as OnlyDesktopIllustration } from '@static/images/only-desktop.svg'
import IllustratedMessage from '@components/IllustratedMessage/IllustratedMessage'
import * as S from './styles'

const OnlyDesktop: FC<{ children: ReactElement }> = ({ children }) => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('sm'))

  return matches ? (
    <> {children} </>
  ) : (
    <S.Container>
      <S.LogoContainer>
        <S.SentisisLogo />
      </S.LogoContainer>
      <S.Content>
        <IllustratedMessage
          illustration={<OnlyDesktopIllustration />}
          title="¡Oh, vaya! ¿Estás utilizando tu móvil?"
        >
          <S.Text>
            Los productos de Séntisis están diseñados para ofrecer la mejor
            experiencia de usuario desde dispositivos de sobremesa.
          </S.Text>
          <S.Text>
            En estos momentos, no podemos ofrecerte una versión responsive para
            dispositivos móviles.
          </S.Text>
          <S.Text>Lamentamos las molestias que pueda ocasionarte.</S.Text>
        </IllustratedMessage>
      </S.Content>
    </S.Container>
  )
}

export default OnlyDesktop
