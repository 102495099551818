import { useCallback } from 'react'
import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'

const useLogic = () => {
  const { disconnectPowerBiPayload, modalType, onCloseModal } = useModal()

  const handleOnCancel = useCallback(() => {
    if (disconnectPowerBiPayload.onCancel) {
      disconnectPowerBiPayload.onCancel()
    }

    onCloseModal()
  }, [disconnectPowerBiPayload, onCloseModal])

  const handleOnDisconnect = useCallback(() => {
    if (disconnectPowerBiPayload.onDisconnect) {
      disconnectPowerBiPayload.onDisconnect()
    }

    onCloseModal()
  }, [disconnectPowerBiPayload, onCloseModal])

  return {
    isOpen: modalType === ModalType.DISCONNECT_POWER_BI,
    handleOnCancel,
    handleOnDisconnect,
  }
}

export default useLogic
