import { getPercentage } from '@utils/format'
import { NpsValues } from '../../models/Dataset'

export const getGroupedNpsValues = (
  npsScores: Array<{ nps: number; count: number }>,
) => {
  let detractors = []
  let promoters = []
  let passives = []

  npsScores.forEach(score => {
    if (NpsValues.detractors.includes(score.nps) && score.count) {
      detractors.push(score.count)
    } else if (NpsValues.promoters.includes(score.nps) && score.count) {
      promoters.push(score.count)
    } else {
      passives.push(score.count)
    }
  })

  return {
    detractors,
    promoters,
    passives,
  }
}

export const getNpsValuesPercentage = ({
  npsScores,
  total,
}: {
  npsScores: Array<{ nps: number; count: number }>
  total: number
}) => {
  const { detractors, promoters, passives } = getGroupedNpsValues(npsScores)

  let detractorTotal = detractors.reduce((a, b) => a + b, 0)
  let promotersTotal = promoters.reduce((a, b) => a + b, 0)
  let detractorsTotal = passives.reduce((a, b) => a + b, 0)

  const detractorsPercentage = getPercentage(detractorTotal, total, 0)
  const promotersPercentage = getPercentage(promotersTotal, total, 0)
  const passivesPercentage = getPercentage(detractorsTotal, total, 0)

  const netNps = promotersPercentage - detractorsPercentage

  return {
    netNps,
    detractorsPercentage,
    passivesPercentage,
    promotersPercentage,
  }
}
