import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import {
  DELETE_PROJECT,
  UPDATE_PROJECT,
  CREATE_PROJECT,
} from 'apollo/mutations/projects'
import type { Project, ProjectFormFields } from 'models/Project'

const useProjectActions = () => {
  const [createProjectMutation, { loading: createProjectIsLoading }] =
    useMutation(CREATE_PROJECT, {
      refetchQueries: ['AllProjectsQuery'],
    })
  const [updateProjectMutation, { loading: updateProjectIsLoading }] =
    useMutation(UPDATE_PROJECT)
  const [deleteProjectMutation, { loading: deleteProjectIsLoading }] =
    useMutation(DELETE_PROJECT)

  const createProject = useCallback(
    async (data: ProjectFormFields) => {
      await createProjectMutation({
        variables: {
          input: data,
        },
      })
    },
    [createProjectMutation],
  )

  const updateProject = useCallback(
    async ({ id, data }: { id: Project['id']; data: ProjectFormFields }) => {
      await updateProjectMutation({
        variables: {
          id,
          input: data,
        },
      })
    },
    [updateProjectMutation],
  )

  const deleteProject = useCallback(
    async (id: Project['id']) => {
      await deleteProjectMutation({
        variables: {
          id,
        },
      })
    },
    [deleteProjectMutation],
  )

  return {
    createProject,
    updateProject,
    deleteProject,
    projectActionsLoading:
      updateProjectIsLoading ||
      deleteProjectIsLoading ||
      createProjectIsLoading,
  }
}

export default useProjectActions
