import { FC, memo } from 'react'
import type { Props } from '../types'

const AnalysisIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.5 10H10.7L9.35 13.75L6.65 6.25L5.3 10H3.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 2.5H12.5C13.3284 2.5 14 3.19645 14 4.05556V14.9444C14 15.8036 13.3284 16.5 12.5 16.5H3.5C2.67157 16.5 2 15.8036 2 14.9444V4.05556C2 3.19645 2.67157 2.5 3.5 2.5H5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="5"
      y="1"
      width="6"
      height="3"
      rx="0.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(AnalysisIcon)
