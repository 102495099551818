/* Thanks Intercom! */
/* eslint-disable new-cap */
import mixpanel from 'mixpanel-browser'

/**
 * Get the user email
 *
 * @private
 * @return {string} email
 */
function getEmail() {
  return window.localStorage && window.localStorage.getItem('userEmail')
}

/**
 * Returns true when the email reprsents an awesome users (when a user
 * is part of the Séntisis team)
 *
 * @private
 * @param {string} email
 * @return {boolean}
 */
function isAwesome(email) {
  // Undefined as a string comes since we save it to localStorage
  if (!email) return true
  return /@sentisis/.test(email)
}

/**
 * Set the user data for Intercom and Mixpanel
 *
 * @param {Object} user - Userdata initial response
 */
export function setUserData(user) {
  const email = getEmail()

  // Identify the users via his id for all the events
  mixpanel.identify(user.id)

  // Update user informations
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    $last_login: new Date(),
  })

  mixpanel.people.set_once('First Login Date', new Date())

  // Differentiate internal use
  if (isAwesome(email)) {
    mixpanel.people.set({
      'User Type': 'Internal',
    })
  } else {
    mixpanel.people.set({
      'User Type': 'External',
    })
  }

  // Do not track non-sentitis users on Intercom
  if (isAwesome(email)) return false

  window.Intercom('boot', {
    app_id: 'fc3sonpl',
    email: user.email,
    name: user.name,
    user_id: user.id,
    new_session: true,
    company: {
      id: user.client.id,
      name: user.client.name,
      plan: 'CX',
      Tier: user.client.tier,
    },
    user_hash: user.intercomHash,
  })

  return true
}

/**
 * Send events to all the analytics paltform. Delay the sending when idlin
 * for better performances.
 *
 * @param {string} name    - Name of the event
 * @param {Object} payload - Data to send with the event
 */

const eventsToSend = []
let isRequestIdleCallbackScheduled = false

function processPendingEvents(deadline) {
  // Reset the flag so future events can be sent
  isRequestIdleCallbackScheduled = false

  // Go for as long as there is time remaning and work to do
  while (deadline.timeRemaining() > 0 && eventsToSend.length > 0) {
    // eslint-disable-next-line
    let { name, payload } = eventsToSend.pop()
    const email = getEmail()

    if (!email) return

    // Send all the events to mixpanel and intercom, including internal use
    mixpanel.track(name, payload)
    if (name === 'User enters a section') {
      name = `${name}: ${payload.section}`
    }
    window.Intercom('trackEvent', name, payload)
  }

  // Check if there are more events to send
  // eslint-disable-next-line no-use-before-define
  if (eventsToSend.length > 0) schedulePendingEvents()
}

function schedulePendingEvents() {
  if (isRequestIdleCallbackScheduled) return

  isRequestIdleCallbackScheduled = true
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function(handler) {
      let startTime = Date.now()
      return setTimeout(function() {
        handler({
          didTimeout: false,
          timeRemaining: function() {
            return Math.max(0, 50.0 - (Date.now() - startTime))
          },
        })
      }, 1)
    }

  // Wait at most 2 seconds before sending the event
  window.requestIdleCallback(processPendingEvents, { timeout: 2000 })
}

export function trackEvent(name, payload = {}) {
  eventsToSend.push({ name, payload })

  schedulePendingEvents()
}
