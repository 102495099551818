import { FC, memo } from 'react'
import type { Props } from '../../types'

const InputAlert: FC<Props> = ({ size = 14, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle
      cx="7"
      cy="7"
      r="6"
      stroke="#DE6047"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99922 4.59985V6.99985V4.59985Z"
      fill="#DE6047"
    />
    <path
      d="M6.99922 4.59985V6.99985"
      stroke="#DE6047"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00013 9.39985H7.00613H7.00013Z"
      fill="#DE6047"
    />
    <path
      d="M7.00013 9.39985H7.00613"
      stroke="#DE6047"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(InputAlert)
