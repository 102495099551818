import * as React from 'react'
import * as am5 from '@amcharts/amcharts5'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { getOr } from 'lodash/fp'
import { ThemeProvider } from 'styled-components/macro'
import { ProSidebarProvider } from 'react-pro-sidebar'
import { ThemeProvider as MaterialUIProvider } from '@material-ui/styles'
import materialUITheme from 'styles/material'
import themes from 'styles/themes'
import useSnackbar from 'apollo/hooks/useSnackbar'

import { QueryComponent } from '@components/Query'
import WebSocket from '@utils/websocket'

import LoadableSummary from '@views/Summary/LoadableSummary'
import LoadableMessagesView from '@views/Messages/LoadableMessagesView'
import LoadableNewDataset from '@views/NewDataset/LoadableNewDataset'
import MaintenanceView from '@views/Maintenance/Maintenance'

import LoadableLoginView from '@views/Auth/Login/LoadableLoginView'
import LoadableProfileView from '@views/Profile/LoadableProfileView'
import LoadableCategoriesView from '@views/Categories/LoadableCategoriesView'
import LoadableLocationView from '@views/Location/LoadableLocationView'
import LoadableSignupView from '@views/Auth/Signup/LoadableSignupView'
import LoadableAdminView from '@views/Admin/LoadableAdminView'
import LoadableDashboard from '@views/Dashboard/LoadableDashboard'
import LoadableEditionLogView from '@views/EditionLog/LoadableEditionLogView'
import LoadableNewProject from '@views/NewProject/LoadableNewProject'
import KeyConcepts from '@views/KeyConcepts/KeyConcepts'

import LoadableLogOutView from '@components/LoadableLogOutView'
import LoadableRecoverPassword from '@views/Auth/RecoverPassword/LoadableRecoverPassword'
import LoadableResetPasswordView from '@views/Auth/ResetPassword/LoadableResetPasswordView'
import UploadProvider from '@components/UploadProvider'
import Spinner from '@components/Spinner'
import Snackbar from '@components/Snackbar/Snackbar'

import ContainerLayoutView from 'containers/ContainerLayout'

import FiltersProvider from '@providers/FiltersProvider'
import AuthProvider from '@contexts/Auth'
import { AuthContextConsumer } from '@contexts/Auth'
import VIEWER_QUERY from './queries/ViewerQuery.graphql'
import OnlyDesktop from '@components/OnlyDesktop/OnlyDesktop'
import { FC } from 'react'
import RenameDatasetOrJobModal from 'containers/Modals/Dataset/RenameDatasetOrJobModal/RenameDatasetOrJobModal'
import DatasetAddFileModal from 'containers/Modals/Dataset/DatasetAddFileModal/DatasetAddFileModal'
import CreateProjectModal from 'containers/Modals/Project/CreateProjectModal/CreateProjectModal'
import RemoveDialogModal from 'containers/Modals/RemoveDialogModal/RemoveDialogModal'
import InsightsModal from 'containers/Modals/InsightsModal/InsightsModal'
import ConnectPowerBI from 'containers/Modals/ConnectPowerBI/ConnectPowerBI'
import DisconnectPowerBI from 'containers/Modals/DisconnectPowerBI/DisconnectPowerBI'

interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated && Component ? (
        <ContainerLayoutView>
          <Component {...props} />
        </ContainerLayoutView>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { referrer: props.location },
          }}
        />
      )
    }
  />
)

interface AdminRouteProps extends RouteProps {
  isManager: boolean
}

export const AdminRoute: FC<AdminRouteProps> = ({
  isManager,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isManager && Component ? (
        <ContainerLayoutView>
          <Component {...props} />
        </ContainerLayoutView>
      ) : (
        <Redirect to="/" />
      )
    }
  />
)

export const LoginRoute: FC<PrivateRouteProps> = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return isAuthenticated ? (
        <Redirect
          to={{
            pathname: '/',
            state: { referrer: props.location },
          }}
        />
      ) : Component ? (
        <Component {...props} />
      ) : null
    }}
  />
)

const client = WebSocket.createApolloClient('graphql')

const App = () => {
  const { snackbarProps } = useSnackbar()

  if (process.env.REACT_APP_MAINTENANCE_VIEW) {
    return <MaintenanceView />
  }

  return (
    <MaterialUIProvider theme={materialUITheme}>
      <ThemeProvider theme={themes.light}>
        <OnlyDesktop>
          <ApolloProvider client={client}>
            <ProSidebarProvider>
              <AuthProvider>
                <Router>
                  <UploadProvider>
                    <QueryComponent
                      query={VIEWER_QUERY}
                      variables={{ withUser: true }}
                    >
                      {({ data, loading, error }: any) => {
                        if (!error && (loading || !data || !data.viewer)) {
                          return <Spinner position />
                        }
                        WebSocket.reconnectWebSocket('graphql', data)
                        const isAuthenticated = Boolean(data && data.viewer)
                        if (isAuthenticated) {
                          am5.addLicense(process.env.REACT_APP_LICENSE_AMCHARTS)
                        }
                        return (
                          <FiltersProvider>
                            <AuthContextConsumer>
                              {({ context }) => {
                                const viewer = getOr('', 'viewer', context)
                                if (data && !viewer) return <Spinner position />

                                return (
                                  <>
                                    <Switch>
                                      <AdminRoute
                                        isManager={viewer && viewer.isManager}
                                        path="/admin"
                                        component={LoadableAdminView}
                                      />
                                      <LoginRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/login"
                                        component={LoadableLoginView}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={true}
                                        path="/logout"
                                        component={LoadableLogOutView}
                                      />
                                      <Route
                                        exact
                                        path="/signup"
                                        component={LoadableSignupView}
                                      />
                                      <Route
                                        exact
                                        path="/password-recovery"
                                        component={LoadableRecoverPassword}
                                      />
                                      <Route
                                        exact
                                        path="/reset-password"
                                        component={LoadableResetPasswordView}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/project"
                                        component={LoadableNewProject}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/:projectId/datasets/new"
                                        component={LoadableNewDataset}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/summary/:projectId/:datasetId"
                                        component={LoadableSummary}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/messages/:projectId/:datasetId"
                                        component={LoadableMessagesView}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/keyconcepts/:projectId/:datasetId"
                                        component={KeyConcepts}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/location/:projectId/:datasetId"
                                        component={LoadableLocationView}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/categories/:projectId/:datasetId"
                                        component={LoadableCategoriesView}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/edition-log/:projectId/:datasetId"
                                        component={LoadableEditionLogView}
                                      />
                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/profile"
                                        component={LoadableProfileView}
                                      />

                                      <PrivateRoute
                                        exact
                                        isAuthenticated={isAuthenticated}
                                        path="/"
                                        component={LoadableDashboard}
                                      />
                                      <Redirect to="/" />
                                    </Switch>
                                    <RenameDatasetOrJobModal />
                                    <DatasetAddFileModal />
                                    <CreateProjectModal />
                                    <RemoveDialogModal />
                                    <InsightsModal />
                                    <ConnectPowerBI />
                                    <DisconnectPowerBI />
                                    <Snackbar {...snackbarProps} />
                                  </>
                                )
                              }}
                            </AuthContextConsumer>
                          </FiltersProvider>
                        )
                      }}
                    </QueryComponent>
                  </UploadProvider>
                </Router>
              </AuthProvider>
            </ProSidebarProvider>
          </ApolloProvider>
        </OnlyDesktop>
      </ThemeProvider>
    </MaterialUIProvider>
  )
}

export default App
