export function isUnauthorizedErr(err: Error): boolean {
  return err.message.includes('[401]')
}

export function parseGraphqlError(error: any): { type: string } {
  try {
    return JSON.parse(error.graphQLErrors[0].message)
  } catch (e) {
    return null
  }
}
