import styled from 'styled-components'
import { InputLabel as label, TextField as input } from '@material-ui/core'
import DefaultIcon from '@components/Icon'

export const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
`

export const LabelHelper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: ${({ theme }) => theme.colours.gray[70]};
`

export const IconContainer = styled.span``

export const Icon = styled(DefaultIcon)<{ $hasAction: boolean }>`
  color: ${({ theme }) => theme.colours.gray[50]};
  cursor: ${({ $hasAction }) => ($hasAction ? 'pointer' : 'default')};
`

export const Label = styled(label)`
  &.MuiFormLabel-root {
    color: ${({ theme }) => theme.colours.gray[90]};
  }
`

export const Input = styled(input)`
  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.colours.white};
  }

  .MuiInputBase-input {
    border-radius: 6px;
    color: ${({ theme }) => theme.colours.gray[90]};
    padding: ${({ InputProps }) =>
      InputProps && InputProps.startAdornment ? '16px 0px' : '16px 12px'};

    ::placeholder {
      color: ${({ theme }) => theme.colours.gray[70]};
      opacity: 1;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colours.gray[40]};
  }

  .MuiOutlinedInput-root {
    :hover .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colours.violet[30]};
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colours.gray[60]};
      border-width: 1px;
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colours.red[70]};
      }
      :hover .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colours.red[70]};
      }
    }

    &.Mui-disabled {
      border-color: ${({ theme }) => theme.colours.gray[30]};
      background-color: ${({ theme }) => theme.colours.gray[30]};
      color: ${({ theme }) => theme.colours.gray[100]};

      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colours.gray[30]};
      }

      &.MuiOutlinedInput-adornedEnd {
        svg {
          color: ${({ theme }) => theme.colours.gray[80]};
        }
      }
    }
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 14px;
  }
`
