import { gql } from '@apollo/client'
import { PROJECT_FRAGMENT } from 'apollo/fragments/Project'

export const GET_PROJECT = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      ...ProjectData
    }
  }
  ${PROJECT_FRAGMENT}
`

export const GET_PROJECT_CONFIG = gql`
  query GetProjectConfig($projectId: ID!, $datasetId: ID!) {
    project(id: $projectId) {
      id
      name
      sharingConfig {
        sentimentRead
        sentimentUpdate
        categoryUpdate
        categoryRead
        categories
      }
      dataset(id: $datasetId) {
        id
        name
        contentColumnsKeys
        byLocation(filters: {}) {
          count
        }
      }
    }
  }
`
