import { gql } from '@apollo/client'
import { CLIENT_FRAGMENT } from '../fragments/Client'

export const GET_CLIENTS = gql`
  query GetClients(
    $first: Int
    $after: Int
    $name: String
    $tier: String
    $blocked: Boolean
  ) {
    allClients(
      pagination: { first: $first, after: $after }
      filters: { name: $name, tier: $tier, blocked: $blocked }
    ) {
      ...ClientData
    }
  }
  ${CLIENT_FRAGMENT}
`

export const GET_CLIENT = gql`
  query ClientQuery($id: ID!) {
    client(id: $id) {
      id
      name
      tier
      createdAt
      logo
      token
      blocked
      apiEnabled
      openaiInsightsEnabled
      aiTagsEnabled
      aiCategorizerEnabled
      preconfiguredProjectManagement
      support
      monthlyAnalysisQuota
      analyzedCount
      previousAnalyzedCount
    }
  }
`
