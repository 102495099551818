import * as queryString from 'query-string'
import {
  pick,
  set,
  mergeWith,
  isArray,
  uniq,
  mapKeys,
  isNil,
  get,
  defaultTo,
  has,
  orderBy,
} from 'lodash/fp'
import { composePath } from '@utils/index'
import { Filters } from '@customTypes/graphql-public'
import { isString } from 'lodash'
import { Moment } from 'moment'

const mergePush = mergeWith((objValue, srcValue) => {
  if (isArray(objValue)) {
    return uniq(objValue.concat(srcValue))
  }

  // Default merge behavior
  return undefined
})

/**
 * Created a URL to the filter dataset view with the passed filters applied.
 * @param projectId id of the project
 * @param filters filters to apply in the URl
 * @param add filters to merge with the `filters`
 * @returns Absolute URL to the view
 */
export function getFilterDatasetUrl(
  projectId: string,
  filters: Filters,
  add?: Filters,
): string {
  const allFilters = add ? mergePush(filters, add) : filters

  return `${composePath('/projects', projectId, 'filter')}?${encodeUrlFilters(
    allFilters,
  )}`
}

/**
 * Transforms the filters into a query string
 * @param filters filters to include in the query
 * @returns Query string
 */
export function encodeUrlFilters(filters: Filters): string {
  const flattened = Object.keys(filters).reduce((acc, filter) => {
    const flatOperator = mapKeys(operator => {
      return knownFilters.includes(`${filter}*331${operator}`)
        ? `${filter}*331${operator}`
        : `custom*331${filter}*331${operator}`
    }, filters[filter as keyof Filters] as object)

    return { ...acc, ...flatOperator }
  }, {} as { [key: string]: string[] | null | undefined })

  return queryString.stringify(flattened)
}

const knownFilters = [
  'text',
  'group*331or',
  'category*331and',
  'category*331or',
  'originalCategory*331or',
  'originalCategory*331and',
  'originalCategory*331not',
  'category*331not',
  'tag*331and',
  'tag*331or',
  'theme*331or',
  'sentiment*331or',
  'originalSentiment*331or',
  'originalSentiment*331and',
  'originalSentiment*331not',
  'sentiment*331not',
  'location*331not',
  'location*331or',
  'date*331and',
  'start',
  'end',
  'content*331match',
  'content*ors',
  'content*331ands',
  'content*331nots',
  'contentFields*331or',
  'contentFields*331not',
]

/**
 * Parses a query string and returns the filters found in it.
 * @param qs query string to parse
 * @returns Filters founds in the query string
 */
export function parseQueryString(qs: string): Filters {
  const srcFilters = queryString.parse(qs)

  const customFilters: any = Object.keys(srcFilters)
    .filter((f: string) => f.includes('custom*331'))
    .reduce((acc, k) => {
      return { ...acc, [k.split('custom*331')[1]]: srcFilters[k] }
    }, {} as Filters)

  const nestedFilters = pick(knownFilters, srcFilters)

  return Object.keys({ ...nestedFilters, ...customFilters }).reduce(
    (acc, key) => {
      const [type, operator] = key.split('*331')

      const result = set(
        [type, operator],
        [].concat(nestedFilters[key] || customFilters[key]),
        acc,
      )

      return result
    },
    {} as Filters,
  )
}

export function momentToDateWithOffset(momentDate: Moment): string {
  const date = momentDate.toDate()
  const adjustedDate = date.getTime() - date.getTimezoneOffset() * 60000

  return new Date(adjustedDate).toISOString()
}

/**
 * Parses filters from context to query format for url
 * @param filters
 * @returns
 */
export function parseCustomFiltersForQuery(filters: any): any {
  return Object.keys(filters).reduce(
    (acc: any, key: any) => {
      if (
        knownFilters.includes(`${key}*331or`) ||
        knownFilters.includes(`${key}*331not`) ||
        knownFilters.includes(`${key}*331and`) ||
        knownFilters.includes(`${key}*331ors`) ||
        knownFilters.includes(`${key}*331ands`) ||
        knownFilters.includes(`${key}*331nots`) ||
        knownFilters.includes(`${key}*331match`)
      ) {
        if (key === 'location')
          return {
            ...acc,
            location: { or: getLocationFilterObject(filters[key].or) },
          }

        if (key === 'date') {
          const andFilter = get([key, 'and'], filters)
          // when one date is selected 'and' is an object otherwise an array
          const dates = isArray(andFilter) ? andFilter[0] : andFilter
          const dateNumber = defaultTo(new Date(), dates.split('/')[0])
          let startDate: Date = new Date(dateNumber && parseInt(dateNumber))
          startDate = new Date(
            startDate.getTime() - startDate.getTimezoneOffset() * 60000,
          )
          const start = startDate.toISOString()
          let end: any = new Date(parseInt(dates.split('/')[1]))
          end = new Date(end.getTime() - end.getTimezoneOffset() * 60000)
          end = end.toISOString()
          return {
            ...acc,
            date: {
              start,
              end,
            },
          }
        }

        if (key === 'content') {
          Object.keys(filters['content']).map(operator => {
            return (filters['content'][operator] =
              operator === 'match'
                ? filters['content'][operator].toString()
                : filters['content'][operator].map(f => {
                    return isString(f) ? f.split(',') : f
                  }))
          })
          return { ...acc, content: filters[key] }
        }

        return { ...acc, [key]: filters[key] }
      }
      return {
        ...acc,
        custom: acc.custom.concat({
          key,
          or: filters[key].or || [],
          not: filters[key].not || [],
        }),
      }
    },
    { custom: [] } as any,
  )
}

export const parseLocationFilter = (locationOr: string) => {
  const [country, county, region] = locationOr.split('*441')

  return region || county || country
}

export const getLocationFilterObject = (locationOr: string[]) => {
  if (!locationOr) return []

  return locationOr.map(loc => {
    const [country, county, region] = loc.split('*441')

    return { region, county, country }
  })
}

export const sortAsNumericValues = (
  arr: any[],
  key: string,
  order: 'ASC' | 'DESC' = 'ASC',
) => {
  return arr.sort((a, b) => {
    const valA = a[key]
    const valB = b[key]
    if (isNil(valA) || isNil(valB)) {
      return 0
    }

    if (order === 'ASC') {
      return valA - valB
    }
    return valB - valA
  })
}

export const sortStringsValues = (
  arr: any[],
  key: string,
  order: 'ASC' | 'DESC' = 'ASC',
) => {
  if (arr && arr.length > 0) {
    if (has('name', arr[0]) || has('label', arr[0])) {
      const sortByKey = has('name', arr[0]) ? 'name' : 'label'
      const sortDirection = order === 'ASC' ? 'asc' : 'desc'
      return orderBy(sortByKey, sortDirection, arr)
    }

    return arr.sort((a, b) => {
      const valA = a[key]
      const valB = b[key]
      if (isNil(valA) || isNil(valB)) {
        return 0
      }

      if (order === 'ASC') {
        return valA.localeCompare(valB)
      }
      return valB.localeCompare(valA)
    })
  } else {
    return []
  }
}

export const getFilterValues = (filterKey: string, values: any[]) => {
  const filterValues = defaultTo([], values)
  const locationOptions = []

  switch (filterKey) {
    case 'location':
      filterValues.forEach(filterValue => {
        const country = get('location.country', filterValue)
        const county = get('location.county', filterValue)
        const region = get('location.region', filterValue)
        if (country) {
          if (region) {
            locationOptions.push({
              label: `${county}, ${region}`,
              value: `${country}*441${county}*441${region}`,
            })
          } else if (county) {
            locationOptions.push({
              label: county,
              value: `${country}*441${county}`,
            })
          }
        }
      })
      return locationOptions
    default: {
      return filterValues.filter(Boolean).map(o => {
        const filterName =
          o.name ||
          o.sentiment ||
          get('category.name', o) ||
          get('sentiment.name', o)
        return {
          value: filterName,
          label: filterName,
        }
      })
    }
  }
}
