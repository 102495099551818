import { FC, memo } from 'react'
import Table from '@components/TableV2/Table'
import { WordCloudChart } from '@components/Charts/WordCloud/WordCloudChart'
import InfoTooltip from '@components/InfoTooltip'
import FancyTooltip from '@components/FancyTooltip'
import {
  defaultRowsPerPage,
  keyConceptId,
  rowsPerPageOptions,
} from './constants'
import useLogic from './logic'
import * as S from './styles'

const KeyConcepts: FC = () => {
  const {
    columns,
    datasetId,
    isLoading,
    projectId,
    rows,
    total,
    filters,
    handlePageChange,
    handleRowsPerPageChange,
    handleSortChange,
  } = useLogic()

  const tooltipText =
    '¿Qué conceptos se utilizan más en la conversación? La [nube de palabras](https://learning.sentisis.io/es/articles/482435-conceptos-clave) muestra los conceptos más mencionados en toda la conversación. No incluye las queries monitorizadas en las escuchas.'
  const $tooltip = (
    <FancyTooltip title="conceptos clave" text={tooltipText} right={false} />
  )

  return (
    <S.Container>
      <S.Card>
        <S.CardHeader>
          <S.CardTitle>Conceptos Clave</S.CardTitle>

          <InfoTooltip tooltip={$tooltip} />
        </S.CardHeader>

        <WordCloudChart
          projectId={projectId}
          datasetId={datasetId}
          filters={filters}
        />
      </S.Card>

      <S.Card>
        <S.CardHeader>
          <S.CardTitle>Análisis detallado</S.CardTitle>
        </S.CardHeader>

        <Table
          isLoading={isLoading}
          columns={columns}
          rows={rows}
          rowsTotal={total}
          rowId={keyConceptId}
          defaultRowsPerPage={defaultRowsPerPage}
          defaultOrderBy="count"
          defaultOrder="desc"
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortChange}
        />
      </S.Card>
    </S.Container>
  )
}

export default memo(KeyConcepts)
