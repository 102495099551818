import {
  Root,
  percent,
  Container,
  ISpritePointerEvent,
} from '@amcharts/amcharts5'
import * as am5wc from '@amcharts/amcharts5/wc'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { TagCloudData } from 'models/Dataset/TopTags/TopTags.types'
import { useHistory } from 'react-router-dom'

type WordCloudChartParams = {
  chartData: TagCloudData[]
  chartId: string
  baseUrl: string
  history: ReturnType<typeof useHistory>
}

export const randomId = Math.random().toString().substring(8)

export const makeCloudChart = ({
  chartData,
  chartId,
  baseUrl,
  history,
}: WordCloudChartParams) => {
  const root = Root.new(chartId)
  root.setThemes([am5themes_Animated.new(root)])

  // Add wrapper container
  const container = root.container.children.push(
    Container.new(root, {
      width: percent(100),
      height: percent(100),
      layout: root.verticalLayout,
    }),
  )

  const series = container.children.push(
    am5wc.WordCloud.new(root, {
      categoryField: 'label',
      valueField: 'size',
      angles: [0],
      minFontSize: 18,
      maxFontSize: 48,
      randomness: 0.2,
    }),
  )

  // Configure labels
  series.labels.template.setAll({
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontFamily: 'Source Sans Pro',
    cursorOverStyle: 'pointer',
    templateField: 'labelSettings',
  })

  // Add click event on words
  series.labels.template.events.on('click', (event: ISpritePointerEvent) => {
    const term = event.target.dataItem.dataContext

    history.push({
      pathname: baseUrl,
      search: `?tag%2A331or=${term['label']}`,
    })
  })

  series.data.setAll(chartData)

  return root
}
