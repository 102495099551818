import styled from 'styled-components/macro'

export const Link = styled.span<{ $size?: number }>`
  font-size: ${({ $size }) => `${$size}px`};
  color: ${({ theme }) => theme.colours.blue[70]};
  &:hover {
    color: ${({ theme }) => theme.colours.blue[70]};
    text-decoration: underline;
  }
`

export const A = styled.a``
