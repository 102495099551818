import { FC, memo } from 'react'
import type { Props } from '../../types'

const Projects: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="6"
      rx="1.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.5"
      y="9.5"
      width="15"
      height="6"
      rx="1.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 3.5H3.5075"
      stroke="currentColor"
      strokeWidth="1.40625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 12.5H3.5075"
      stroke="currentColor"
      strokeWidth="1.40625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(Projects)
