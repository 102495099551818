import styled, { css } from 'styled-components/macro'
import { ReactComponent as EditIconSvg } from '@components/IconsToRefactor/edit-icon.svg'
import { getColor } from '@utils/colors'

export const EditIcon = styled(EditIconSvg)`
  width: 100%;
  height: 12px;
  path {
    fill: white;
  }
  stroke: transparent;
`

export const TagContainer = styled.div<{ color: string }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: left;

  height: 22px;
  cursor: pointer;
  color: white;
  stroke: white;
  :not(:only-child) & {
    margin-right: 0px;
  }
  border-radius: 1px;
  margin-right: 4px;
  margin-bottom: 4px;
  background-color: ${(props: any) => getColor(props.color)};
`

export const Text = styled.p<{ title; ref; $applyEllipsis }>`
  margin: auto;
  padding: 0 6px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  ${({ $applyEllipsis }) => {
    return (
      $applyEllipsis &&
      css`
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;
      `
    )
  }}

  :hover {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const TagOperator = styled.div<{ color: string }>`
  height: 22px;
  min-width: 22px;
  padding: 0 4px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  background-color: ${(props: any) => getColor(props.color, true)};
`

export const TagButton = styled.button<{ color: string; height?: number }>`
  width: 22px;
  height: ${(props: any) => props.height || 22}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  padding: 0px;
  border-radius: 1px;
  background-color: ${(props: any) => getColor(props.color, true)};
`
export const X = styled.span`
  font-size: 14px;
  height: 22px;
  width: 22px;
  display: inline-block;
  line-height: 19px;
  :hover {
    color: white;
  }
  color: rgba(255, 255, 255, 0.5);
`
