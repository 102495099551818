import { FC, memo } from 'react'
import type { Props } from '../types'

const Warning: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.9998 3.94913L17.9023 15.875H4.09734L10.9998 3.94913ZM10.9998 0.291626L0.916504 17.7083H21.0832L10.9998 0.291626Z"
      fill="currentColor"
    />
    <path
      d="M11.9165 13.125H10.0832V14.9583H11.9165V13.125Z"
      fill="currentColor"
    />
    <path
      d="M11.9165 7.62496H10.0832V12.2083H11.9165V7.62496Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Warning)
