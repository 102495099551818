export const CLIENTS_DOMAINS_PARAMS: Record<
  string,
  {
    domain: string
    logo: string
    logoWidth: number
    logoHeight: number
    description: string
    descriptionColor: string
  }
> = {
  default: {
    domain: '',
    logo: 'logoSentisis',
    logoWidth: 130,
    logoHeight: 40,
    description: 'Customer Experience',
    descriptionColor: '#6fccc3',
  },
  netquest: {
    domain: 'netquest.sentisis.io',
    logo: 'logoNetquest',
    logoWidth: 152,
    logoHeight: 40,
    description: 'Digital Shopper Monitor',
    descriptionColor: '#A16CCF',
  },
}
