import { FC, memo } from 'react'
import type { Props } from '../types'

const TrashIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1 4L1.57143 4L13 4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1668 4.06668C12.1668 3.79053 11.943 3.56668 11.6668 3.56668C11.3907 3.56668 11.1668 3.79053 11.1668 4.06668H12.1668ZM2.8335 4.06668C2.8335 3.79053 2.60964 3.56668 2.3335 3.56668C2.05735 3.56668 1.8335 3.79053 1.8335 4.06668H2.8335ZM3.8335 4.00004C3.8335 4.27618 4.05735 4.50004 4.3335 4.50004C4.60964 4.50004 4.8335 4.27618 4.8335 4.00004H3.8335ZM9.16683 4.00004C9.16683 4.27618 9.39069 4.50004 9.66683 4.50004C9.94297 4.50004 10.1668 4.27618 10.1668 4.00004H9.16683ZM11.1668 4.06668V13.6333H12.1668V4.06668H11.1668ZM11.1668 13.6333C11.1668 14.1237 10.7822 14.5 10.3335 14.5V15.5C11.3576 15.5 12.1668 14.6526 12.1668 13.6333H11.1668ZM10.3335 14.5H3.66683V15.5H10.3335V14.5ZM3.66683 14.5C3.21816 14.5 2.8335 14.1237 2.8335 13.6333H1.8335C1.8335 14.6526 2.64274 15.5 3.66683 15.5V14.5ZM2.8335 13.6333V4.06668H1.8335V13.6333H2.8335ZM4.8335 4.00004V2.66671H3.8335V4.00004H4.8335ZM4.8335 2.66671C4.8335 2.20647 5.20659 1.83337 5.66683 1.83337V0.833374C4.65431 0.833374 3.8335 1.65419 3.8335 2.66671H4.8335ZM5.66683 1.83337H8.3335V0.833374H5.66683V1.83337ZM8.3335 1.83337C8.79373 1.83337 9.16683 2.20647 9.16683 2.66671H10.1668C10.1668 1.65419 9.34602 0.833374 8.3335 0.833374V1.83337ZM9.16683 2.66671V4.00004H10.1668V2.66671H9.16683Z"
      fill="currentColor"
    />
    <path
      d="M5.66667 6.86511V12.7223"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33317 6.86511V12.7223"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(TrashIcon)
