enum TierType {
  FREE,
  PRO,
  ENTERPRISE,
}

export const normalizeClient = (input: any) => ({
  id: (input?.id || '') as string,

  analyzedCount: (input?.analyzedCount || 0) as number,
  apiEnabled: (input?.apiEnabled || false) as boolean,
  openaiInsightsEnabled: (input?.openaiInsightsEnabled || false) as boolean,
  aiTagsEnabled: (input?.aiTagsEnabled || false) as boolean,
  aiCategorizerEnabled: (input?.aiCategorizerEnabled || false) as boolean,
  blocked: (input?.blocked || true) as boolean,
  createdAt: (input?.createdAt || new Date()) as Date,
  hiddenSentiment: (input?.hiddenSentiment || false) as boolean,
  logo: (input?.logo || '') as string,
  monthlyAnalysisQuota: (input?.monthlyAnalysisQuota || 0) as number,
  name: (input?.name || '') as string,
  preconfiguredProjectManagement: (input?.preconfiguredProjectManagement ||
    false) as boolean,
  previousAnalyzedCount: (input?.previousAnalyzedCount || 0) as number,
  support: (input?.support || false) as boolean,
  tier: (input?.tier || TierType.PRO) as TierType,
  token: (input?.token || '') as string,
})

export type Client = ReturnType<typeof normalizeClient>
