import { FC, memo } from 'react'
import { Props } from './types'
import * as S from './styles'

const Progress: FC<Props> = ({
  value,
  color = 'violet',
  percentPosition = 'end',
}) => {
  const percentAtStart = percentPosition === 'start'
  const percentAtEnd = percentPosition === 'end'

  return (
    <S.Container>
      {percentAtStart && <S.TextProgress>{value}%</S.TextProgress>}
      <S.LinearProgress variant="determinate" value={value} $color={color} />
      {percentAtEnd && <S.TextProgress>{value}%</S.TextProgress>}
    </S.Container>
  )
}

export default memo(Progress)
