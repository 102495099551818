import { FC, memo } from 'react'
import type { Props } from '../types'

const Tic: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 1.62993L12.2717 0.900391L5.46458 7.83634L1.72831 4.09375L1 4.8233L5.46953 9.30039L6.19784 8.57085L6.19289 8.56589L13 1.62993Z"
      fill="currentColor"
    />
    <path
      d="M12.2717 0.900391L12.6255 0.547136C12.5312 0.452662 12.4031 0.399826 12.2696 0.400395C12.1361 0.400964 12.0083 0.454892 11.9148 0.550167L12.2717 0.900391ZM13 1.62993L13.3569 1.98016C13.5489 1.7845 13.5475 1.4707 13.3539 1.27668L13 1.62993ZM5.46458 7.83634L5.11072 8.1896C5.20504 8.28407 5.33322 8.33691 5.46671 8.33634C5.6002 8.33577 5.72792 8.28184 5.82143 8.18657L5.46458 7.83634ZM1.72831 4.09375L2.08216 3.7405C1.98837 3.64655 1.86106 3.59375 1.72831 3.59375C1.59555 3.59375 1.46825 3.64655 1.37446 3.7405L1.72831 4.09375ZM1 4.8233L0.646147 4.47004C0.451284 4.66524 0.451284 4.98136 0.646148 5.17655L1 4.8233ZM5.46953 9.30039L5.11568 9.65364C5.20947 9.7476 5.33678 9.80039 5.46953 9.80039C5.60229 9.80039 5.72959 9.7476 5.82338 9.65364L5.46953 9.30039ZM6.19784 8.57085L6.55169 8.9241C6.74656 8.7289 6.74655 8.41277 6.55168 8.21758L6.19784 8.57085ZM6.19289 8.56589L5.83603 8.21566C5.644 8.41133 5.64535 8.72513 5.83904 8.91915L6.19289 8.56589ZM11.9178 1.25364L12.6461 1.98319L13.3539 1.27668L12.6255 0.547136L11.9178 1.25364ZM5.82143 8.18657L12.6285 1.25061L11.9148 0.550167L5.10772 7.48612L5.82143 8.18657ZM5.81843 7.48309L2.08216 3.7405L1.37446 4.44701L5.11072 8.1896L5.81843 7.48309ZM1.37446 3.7405L0.646147 4.47004L1.35385 5.17655L2.08216 4.44701L1.37446 3.7405ZM0.646148 5.17655L5.11568 9.65364L5.82338 8.94714L1.35385 4.47004L0.646148 5.17655ZM5.82338 9.65364L6.55169 8.9241L5.84399 8.21759L5.11568 8.94714L5.82338 9.65364ZM6.55168 8.21758L6.54673 8.21262L5.83904 8.91915L5.844 8.92411L6.55168 8.21758ZM12.6431 1.27971L5.83603 8.21566L6.54974 8.91611L13.3569 1.98016L12.6431 1.27971Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Tic)
