import Loadable from 'react-loadable'

import Loading from '@components/Loading'

const LoadableComponent = Loadable({
  loader: () => import('@views/Auth/Login/Login'),
  loading: Loading,
  delay: 200,
  timeout: 10000,
})

const LoadableLoginView = props => <LoadableComponent {...props} />

export default LoadableLoginView
