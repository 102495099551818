import styled, { css } from 'styled-components'
import {
  TableCell as TableCellMaterial,
  TableContainer as TableContainerMaterial,
  TablePagination as TablePaginationMaterial,
  TableRow as TableRowMaterial,
} from '@material-ui/core'

export const Container = styled.div``

// TODO: cant use Material TableCell align property because bulma.css overrides styles
export const TableCell = styled(TableCellMaterial)<{ $align: string }>`
  && {
    text-align: ${({ $align }) => $align};
    color: ${({ theme }) => theme.colours.gray[100]};
    padding: 10px 16px;
    font-size: 16px;
  }
`

export const TableContainer = styled(TableContainerMaterial)<{
  $height?: number
}>`
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
`

export const TablePagination = styled(TablePaginationMaterial)`
  &.MuiTablePagination-root {
    display: block;
    color: ${({ theme }) => theme.colours.gray[100]};
  }
`

export const TableRow = styled(TableRowMaterial)`
  &.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => theme.colours.gray[10]};
  }
`

export const SpinnerContainer = styled.div`
  padding: 20px 0;
`

export const Clickable = styled.span<{ $addStyles: boolean }>`
  ${({ $addStyles }) => {
    return (
      $addStyles &&
      css`
        cursor: pointer;
        :hover {
          color: ${({ theme }) => theme.colors.green};
        }
      `
    )
  }}
`
