import { useTopTags } from 'apollo/hooks/dataset/useTopTags'
import { useLayoutEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { WordCloudChartProps } from './WordCloudChart.types'
import { makeCloudChart, randomId } from './WordCloudChart.utils'

const useLogic = ({ projectId, datasetId, filters }: WordCloudChartProps) => {
  const history = useHistory()
  const { topTags, topTagsLoading, topTagsError } = useTopTags({
    projectId,
    datasetId,
    filters,
  })
  const wordCloudChartId = useMemo(() => `wordCloud-id-${randomId}`, [])

  useLayoutEffect(() => {
    if (!topTags || !wordCloudChartId) {
      return
    }

    const baseUrl = `/messages/${projectId}/${datasetId}`

    const chart = makeCloudChart({
      chartData: topTags,
      chartId: wordCloudChartId,
      baseUrl,
      history,
    })

    return () => {
      chart.dispose()

      // amcharts set cursor style as body inline style when hovering a chart label
      // clicking on a chart label to redirect to mentions causes this style to not be deleted
      // so we must delete it when effect is cleared
      document.body.style.cursor = ''
    }
  }, [history, datasetId, projectId, topTags, wordCloudChartId])

  return {
    wordCloudChartId,
    topTags,
    topTagsLoading,
    topTagsError,
  }
}
export default useLogic
