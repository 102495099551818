import { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import Progress from '@components/Progress/Progress'
import Button from '@components/Button/Button'
import * as S from './styles'
import useLogic from './logic'

const InsightsModal = () => {
  const {
    isOpen,
    loading,
    loadingValue,
    openAIResponse,
    handleCopyText,
    handleOnCloseModal,
  } = useLogic()

  return (
    <S.Container open={isOpen} onClose={handleOnCloseModal}>
      <S.TitleContainer>
        <S.Title>Séntisis Insights</S.Title>
        <S.CloseIcon onClick={handleOnCloseModal} />
      </S.TitleContainer>
      <S.Content $hasMarkdown={Boolean(openAIResponse)}>
        {loading ? (
          <>
            <S.SubTitle>
              Estamos interpretando tus datos, este proceso durará unos segundos
            </S.SubTitle>
            <S.ProgressContainer>
              <Progress value={loadingValue} />
            </S.ProgressContainer>
          </>
        ) : (
          <>
            <S.MarkdownContainer>
              <ReactMarkdown>{openAIResponse}</ReactMarkdown>
            </S.MarkdownContainer>
          </>
        )}
      </S.Content>
      {!loading && (
        <>
          <S.OpenAiDesc>
            Séntisis Insights integra la tecnología OpenAI para esta
            interpretación de nuestros datos.
          </S.OpenAiDesc>
          <S.Buttons>
            <Button variant="secondary" onClick={handleCopyText}>
              Copiar texto
            </Button>
            <Button onClick={handleOnCloseModal}>Aceptar</Button>
          </S.Buttons>
        </>
      )}
    </S.Container>
  )
}

export default memo(InsightsModal)
