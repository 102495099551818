import Loadable from 'react-loadable'

import Loading from '@components/Loading'

const LoadableComponent = Loadable({
  loader: () => import('@views/Auth/Signup/Signup'),
  loading: Loading,
  delay: 200,
})

const LoadableSignupView = props => <LoadableComponent {...props} />

export default LoadableSignupView
