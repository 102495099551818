import styled from "styled-components";
import { ReactComponent as SentisisLogoSVG } from '@static/images/sentisis-logo.svg'

export const Container = styled.div`
  background-color: white;
  min-height: 100%;
  padding: 1em 2em;
`

export const LogoContainer = styled.div`
  text-align: center;
  padding: 1.25em 0 1.75em;
`

export const SentisisLogo = styled(SentisisLogoSVG)`
  width: 9em;
`

export const Text = styled.p`
  color: #88888A;
  font-size: 16px;
  margin-bottom: 1em;
`

export const Content = styled.div`
  max-width: 27em;
  margin: auto;
`