import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'
import { useCallback } from 'react'

const useLogic = () => {
  const { removeDialogPayload, modalType, onCloseModal } = useModal()

  const handleOnCloseModal = useCallback(() => {
    if (removeDialogPayload?.onClose) {
      removeDialogPayload.onClose()
    }

    onCloseModal()
  }, [removeDialogPayload, onCloseModal])

  const handleOnRemove = useCallback(() => {
    if (removeDialogPayload?.onRemove) {
      removeDialogPayload.onRemove()
    }

    handleOnCloseModal()
  }, [removeDialogPayload, handleOnCloseModal])

  return {
    handleOnCloseModal,
    handleOnRemove,
    isOpen: modalType === ModalType.REMOVE_DIALOG,
    removeDialogPayload,
  }
}

export default useLogic
