import { useReactiveVar } from '@apollo/client'
import { useCallback } from 'react'
import {
  AddMessageCategoryPayload,
  ChangeSentimentPayload,
  DeleteMessageCategoryPayload,
  DeleteMessagesModalPayload,
  DownloadMessageModalPayload,
  EditMessageCategoriesPayload,
  MaxMessagesEditablePayload,
  ModalAddFileToDatasetPayload,
  ModalConnectPowerBiPayload,
  ModalDisconnectPowerBiPayload,
  ModalInsightsPayload,
  ModalRemoveDialogPayload,
  ModalRenameDatasetOrJobPayload,
  ModalType,
  modalVar,
  resetModalVar,
  setModalVar,
  updateModalVar,
} from 'apollo/reactive/modal'

const useModal = () => {
  const {
    modalType,
    addMessageCategoryPayload,
    changeSentimentPayload,
    deleteMessageCategoryPayload,
    deleteMessagePayload,
    messagesDownloadPayload,
    editMessageCategoriesPayload,
    maxMessagesEditablePayload,
    addFileToDatasetPayload,
    removeDialogPayload,
    renameDatasetOrJobPayload,
    insightsPayload,
    connectPowerBiPayload,
    disconnectPowerBiPayload,
  } = useReactiveVar(modalVar)

  const onCloseModal = useCallback((dataClearDelay?: number) => {
    updateModalVar({ modalType: null })
    if (dataClearDelay) {
      setTimeout(() => {
        resetModalVar()
      }, dataClearDelay)
    } else {
      resetModalVar()
    }
  }, [])

  const openDownloadMessageModal = useCallback(
    (data: DownloadMessageModalPayload) => {
      setModalVar({
        modalType: ModalType.MESSAGES_DOWNLOAD,
        messagesDownloadPayload: data,
      })
    },
    [],
  )

  const openDeleteDocumentModal = useCallback(
    (data: DeleteMessagesModalPayload) => {
      setModalVar({
        modalType: ModalType.DELETE_MESSAGES,
        deleteMessagePayload: data,
      })
    },
    [],
  )

  const openEditMessageCategoriesModal = useCallback(
    (data: EditMessageCategoriesPayload) => {
      setModalVar({
        modalType: ModalType.EDIT_MESSAGES_CATEGORIES,
        editMessageCategoriesPayload: data,
      })
    },
    [],
  )

  const openDeleteMessageCategoryModal = useCallback(
    (data: DeleteMessageCategoryPayload) => {
      setModalVar({
        modalType: ModalType.DELETE_MESSAGE_CATEGORY,
        deleteMessageCategoryPayload: data,
      })
    },
    [],
  )

  const openAddMessageCategoryModal = useCallback(
    (data: AddMessageCategoryPayload) => {
      setModalVar({
        modalType: ModalType.ADD_MESSAGE_CATEGORY,
        addMessageCategoryPayload: data,
      })
    },
    [],
  )

  const openChangeMessagesSentimentModal = useCallback(
    (data: ChangeSentimentPayload) => {
      setModalVar({
        modalType: ModalType.CHANGE_MESSAGES_SENTIMENT,
        changeSentimentPayload: data,
      })
    },
    [],
  )

  const openMaxMessagesEditableModal = useCallback(
    (data: MaxMessagesEditablePayload) => {
      setModalVar({
        modalType: ModalType.MAX_MESSAGES_EDITABLE,
        maxMessagesEditablePayload: data,
      })
    },
    [],
  )

  const openAddDatasetFileModal = useCallback(
    (data: ModalAddFileToDatasetPayload) => {
      setModalVar({
        modalType: ModalType.ADD_DATASET_FILE,
        addFileToDatasetPayload: data,
      })
    },
    [],
  )

  const openCreateProjectModal = useCallback(() => {
    setModalVar({
      modalType: ModalType.CREATE_PROJECT,
    })
  }, [])

  const openRemoveDialogModal = useCallback(
    (data: ModalRemoveDialogPayload) => {
      setModalVar({
        modalType: ModalType.REMOVE_DIALOG,
        removeDialogPayload: data,
      })
    },
    [],
  )

  const openRenameDatasetOrJobModal = useCallback(
    (data: ModalRenameDatasetOrJobPayload) => {
      setModalVar({
        modalType: ModalType.RENAME_DATASET_OR_JOB,
        renameDatasetOrJobPayload: data,
      })
    },
    [],
  )

  const openInsightsModal = useCallback((data: ModalInsightsPayload) => {
    setModalVar({
      modalType: ModalType.INSIGHTS,
      insightsPayload: data,
    })
  }, [])

  const openConnectPowerBIModal = useCallback(
    (data: ModalConnectPowerBiPayload) => {
      setModalVar({
        modalType: ModalType.CONNECT_POWER_BI,
        connectPowerBiPayload: data,
      })
    },
    [],
  )

  const openDisconnectPowerBIModal = useCallback(
    (data: ModalDisconnectPowerBiPayload) => {
      setModalVar({
        modalType: ModalType.DISCONNECT_POWER_BI,
        disconnectPowerBiPayload: data,
      })
    },
    [],
  )

  return {
    modalType,
    onCloseModal,
    addMessageCategoryPayload,
    changeSentimentPayload,
    deleteMessageCategoryPayload,
    deleteMessagePayload,
    messagesDownloadPayload,
    editMessageCategoriesPayload,
    maxMessagesEditablePayload,
    addFileToDatasetPayload,
    removeDialogPayload,
    renameDatasetOrJobPayload,
    insightsPayload,
    connectPowerBiPayload,
    disconnectPowerBiPayload,
    openDownloadMessageModal,
    openDeleteDocumentModal,
    openEditMessageCategoriesModal,
    openDeleteMessageCategoryModal,
    openAddMessageCategoryModal,
    openChangeMessagesSentimentModal,
    openMaxMessagesEditableModal,
    openAddDatasetFileModal,
    openCreateProjectModal,
    openRemoveDialogModal,
    openRenameDatasetOrJobModal,
    openInsightsModal,
    openConnectPowerBIModal,
    openDisconnectPowerBIModal,
  }
}

export default useModal
