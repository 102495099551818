import { FC, memo } from 'react'
import type { Props } from '../types'

const SmallSpinner: FC<Props> = ({ size = 16, ...rest }) => (
  <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8c.533 0 1.067.4 1.067 1.067C9.067 1.733 8.533 2 8 2 4.667 2 2 4.667 2 8s2.667 6 6 6 6-2.667 6-6c0-.533.4-1.067 1.067-1.067.666 0 .933.534.933 1.067 0 4.4-3.6 8-8 8z"
      fill="currentColor"
    />
  </svg>
)

export default memo(SmallSpinner)
