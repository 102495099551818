import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { DATASET_FILTERS_OPTIONS_QUERY } from 'apollo/queries/dataset'
import { normalizeDatasetFiltersOptions } from 'models/Dataset';

type MyFiltersParams = {
  datasetId: string;
  projectId: string;
  key: string | false;
}

const useDatasetFiltersOptions = ({
  datasetId,
  projectId,
  key,
}: MyFiltersParams) => {

  const { data, loading: datasetFilterOptionsIsLoading } = useQuery(DATASET_FILTERS_OPTIONS_QUERY, {
    variables: {
      projectId,
      datasetId,
      withColumnKey: key !== 'contentFields',
    },
  })

  const datasetFilterOptions = useMemo(() => {
    const filtersOptions = data?.project?.dataset?.filtersOptions

    if (!filtersOptions) {
      return []
    }

    return normalizeDatasetFiltersOptions(filtersOptions)
  }, [data])

  return {
    datasetFilterOptions,
    datasetFilterOptionsIsLoading
  }
}

export default useDatasetFiltersOptions
