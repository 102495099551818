import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_PROJECT_CONFIG } from 'apollo/queries/project'
import { useMemo } from 'react'

const useProjectConfig = ({
  projectId,
  datasetId,
  fetchPolicy = 'cache-and-network',
}: {
  projectId?: string
  datasetId?: string
  fetchPolicy?: QueryHookOptions['fetchPolicy']
}) => {
  const { data, loading, error } = useQuery(GET_PROJECT_CONFIG, {
    fetchPolicy,
    skip: !projectId || !datasetId,
    variables: {
      projectId,
      datasetId,
    },
  })

  const projectConfig = useMemo(
    () => (data?.project ? data.project : null),
    [data],
  )

  return {
    projectConfig,
    projectConfigLoading: loading,
    projectConfigError: error,
  }
}

export default useProjectConfig
