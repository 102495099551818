import styled from 'styled-components/macro'
import { Icon } from 'sentisis-components'

export const TextSearchWrapper = styled.div`
  background: white;
  position: relative;
  padding: 12px 20px;
  box-shadow: rgb(0 0 0 / 2%) 2px 2px 1px 0;
  z-index: 3;
`

export const Search = styled.div`
  display: flex;
  flex: 1;
  background: #f7f8fa;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 0 20px;
  box-sizing: border-box;
  outline: none;
  align-items: center;
  gap: 10px;

  form {
    width: 100%;
  }
`

export const StyledIcon = styled(Icon)`
  display: flex;
  color: var(--theme-ui-colors-gray-20);
`

export const FiltersWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1px 20px 0;
  position: relative;
`

export const FiltersList = styled.ul`
  list-style: none;
  height: 100%;
  display: grid;
  grid-column-gap: 30px;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  li {
    margin-bottom: 0;
    width: 100%;
    height: 100%;
  }
`

export const Field = styled.li``

export const TabSelectButton = styled.button`
  display: flex;
  height: 100%;
  width: 100%;
  appearance: none;
  border: none;
  background-color: transparent;
  align-items: center;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding: 0;
`

export const Caret = styled.span`
  display: block;
  margin: 0 0 0 10px;
  border-width: 5px 5px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray[30]} transparent transparent;
  content: ' ';
  top: 1px;
  position: relative;
  transform: rotate(0deg);
  &.is-open {
    transform: rotate(180deg);
    opacity: 1;
  }
`

export const Label = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colours.gray[110]};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  align-items: center;
  justify-content: space-between;
  height: 32px; /* Ajuste de altura */
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
  text-transform: capitalize;
  padding: 8px;

  ${TabSelectButton}.is-open & {
    color: ${({ theme }) => theme.colours.violet[90]};
    border-bottom: 2px solid ${({ theme }) => theme.colours.violet[90]};

    ${Caret} {
      border-color: ${({ theme }) => theme.colours.violet[90]} transparent
        transparent;
    }
  }

  ${TabSelectButton}:hover & {
    position: relative;
    z-index: 1;
    background-color: ${({ theme }) => theme.colours.gray[10]};
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
  }
`

export const MatchInput = styled.input`
  background-color: #f7f8fa;
  color: var(--theme-ui-colors-gray-20);
  font-size: 14px;
  line-height: 18px;
  padding: 11px 0;
  border: none;
  width: 100%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  outline: none;
  font-family: inherit;

  &::placeholder {
    color: #5c6875;
  }
`

export const FilterButton = styled.button`
  appearance: none;
  background: transparent;
  color: ${({ theme }) => theme.colours.violet[90]};
  border: 1px solid ${({ theme }) => theme.colours.violet[90]};
  border-radius: 27px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colours.violet[70]}14;
  }

  &:active {
    background-color: ${({ theme }) => theme.colours.violet[70]}33;
  }
`

export const LabelText = styled.div`
  display: inherit;
  white-space: nowrap;
`
