import { makeVar } from '@apollo/client'
import { MessagesQuery_project_dataset_allDocuments_messages } from '@customTypes/index'
import { SingleEditVal } from '@views/Messages/MessagesDashboardContainer/types'
import { DatasetMetrics } from 'models/Dataset'
import type { CategoryUnionEntity } from 'models/Category'

export enum ModalType {
  ADD_DATASET_FILE,
  ADD_MESSAGE_CATEGORY,
  CHANGE_MESSAGES_SENTIMENT,
  CREATE_PROJECT,
  DELETE_MESSAGE_CATEGORY,
  DELETE_MESSAGES,
  EDIT_MESSAGES_CATEGORIES,
  EXAMPLE_ACTION,
  MAX_MESSAGES_EDITABLE,
  MESSAGES_DOWNLOAD,
  REMOVE_DIALOG,
  RENAME_DATASET_OR_JOB,
  INSIGHTS,
  CONNECT_POWER_BI,
  DISCONNECT_POWER_BI,
}

export type DownloadMessageModalPayload = {
  datasetId: string
  filters: any
  extended: boolean
  totalToAction: number
  onClose: () => void
}

export type MessagesSectionModalsCommon = {
  onClose: () => void
  onEdit: () => void
  onEditSuccess: () => void
  onEditError: (error: string) => void
}

export type DeleteMessagesModalPayload = MessagesSectionModalsCommon & {
  filters: any
  totalToAction: number
}

export type EditMessageCategoriesPayload = MessagesSectionModalsCommon & {
  filters: any
  categories: CategoryUnionEntity[]
  totalToEdit: number
  selectedMessages: MessagesQuery_project_dataset_allDocuments_messages[]
  allMessagesSelected: boolean
}

export type MaxMessagesEditablePayload = {
  onClose: () => void
}

export type DeleteMessageCategoryPayload = MessagesSectionModalsCommon & {
  filters: any
  category: SingleEditVal
}

export type AddMessageCategoryPayload = MessagesSectionModalsCommon & {
  filters: any
  categories: CategoryUnionEntity[]
}

export type ChangeSentimentPayload = MessagesSectionModalsCommon & {
  filters: any
  sentiment: SingleEditVal
  totalToAction: number
}

export type ModalAddFileToDatasetPayload = {
  projectId: string
  datasetId: string
  dataseName: string
  onClose?: () => void
}

export type ModalRemoveDialogPayload = {
  acceptButtonText?: string
  cancelButtonText?: string
  description?: string
  onClose: () => void
  onRemove: () => void
  title?: string
}

export type ModalRenameDatasetOrJobPayload = {
  id: string
  name: string
  onClose?: () => void
  type: 'Dataset' | 'Job'
}

export type ModalInsightsPayload = {
  nps: DatasetMetrics['nps']
  netNPS: DatasetMetrics['netNps']
  npsScores: DatasetMetrics['npsScores']
  topCategories: DatasetMetrics['topCategories']
  sentiments: DatasetMetrics['sentiments']
  documents: DatasetMetrics['documents']
}

export type ModalConnectPowerBiPayload = {
  onCancel: () => void
  onConnect: () => void
}

export type ModalDisconnectPowerBiPayload = {
  onCancel: () => void
  onDisconnect: () => void
}

export type Modal = {
  modalType: ModalType | null
  addFileToDatasetPayload: ModalAddFileToDatasetPayload | null
  addMessageCategoryPayload: AddMessageCategoryPayload | null
  changeSentimentPayload: ChangeSentimentPayload | null
  deleteMessageCategoryPayload: DeleteMessageCategoryPayload | null
  deleteMessagePayload: DeleteMessagesModalPayload | null
  editMessageCategoriesPayload: EditMessageCategoriesPayload | null
  maxMessagesEditablePayload: MaxMessagesEditablePayload | null
  messagesDownloadPayload: DownloadMessageModalPayload | null
  removeDialogPayload: ModalRemoveDialogPayload | null
  renameDatasetOrJobPayload: ModalRenameDatasetOrJobPayload | null
  insightsPayload: ModalInsightsPayload | null
  connectPowerBiPayload: ModalConnectPowerBiPayload | null
  disconnectPowerBiPayload: ModalDisconnectPowerBiPayload | null
}

const emptyModal: Modal = {
  modalType: null,
  addFileToDatasetPayload: null,
  addMessageCategoryPayload: null,
  changeSentimentPayload: null,
  deleteMessageCategoryPayload: null,
  deleteMessagePayload: null,
  editMessageCategoriesPayload: null,
  maxMessagesEditablePayload: null,
  messagesDownloadPayload: null,
  removeDialogPayload: null,
  renameDatasetOrJobPayload: null,
  insightsPayload: null,
  connectPowerBiPayload: null,
  disconnectPowerBiPayload: null,
}

export const modalVar = makeVar<Modal>(emptyModal)

export const resetModalVar = () => {
  modalVar(emptyModal)
}

export const setModalVar = (data: Partial<Modal>) => {
  modalVar({ ...emptyModal, ...data })
}

export const updateModalVar = (data: Partial<Modal>) => {
  modalVar({ ...modalVar(), ...data })
}
