import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useDatasetTags from 'apollo/hooks/dataset/useDatasetTags'
import useDatasetMetrics from 'apollo/hooks/dataset/useDatasetMetrics'
import { FiltersContext } from '@providers/FiltersProvider'
import { SORT } from '@customTypes/sort'
import { encodeUrlFilters } from '@utils/filters'
import { trackEvent } from '@utils/metrics'
import { getPercentage } from '@utils/format'
import Evolution from '@components/TableV2/Evolution/Evolution'
import Sentiment from '@components/TableV2/Sentiment/Sentiment'
import EvolutionNPS from '@components/TableV2/EvolutionNPS/EvolutionNPS'
import { defaultRowsPerPage } from './constants'
import type { Column } from '@components/TableV2/TableHead/types'
import type { RowItem } from '@components/TableV2/types'

const useLogic = () => {
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const [order, setOrder] = useState({
    sort: 'COUNT',
    direction: 'DESC',
  })
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const { projectId, datasetId } = useParams<{
    projectId: string
    datasetId: string
  }>()
  const history = useHistory()

  const { filtersState, addFilterToQuery, getFilterList } =
    useContext(FiltersContext)
  const { datasetDateRange, dateRange } = filtersState
  const withDate = datasetDateRange?.start

  const filters = withDate
    ? {
        date: dateRange,
      }
    : {}

  const { datasetTags, datasetTagsIsLoading } = useDatasetTags({
    datasetId,
    order,
    filters,
    first: rowsPerPage,
    after: page * rowsPerPage,
  })

  const { datasetMetrics, datasetMetricsLoading } = useDatasetMetrics({
    datasetId,
    projectId,
    filters,
  })

  const handlePageChange = useCallback(item => {
    setPage(item.page)
  }, [])

  const handleRowsPerPageChange = useCallback(item => {
    setPage(item.page)
    setRowsPerPage(item.rowsPerPage)
  }, [])

  const handleSortChange = useCallback(item => {
    const { order: itemOrder, orderBy } = item

    const tableToApiSortMap = {
      asc: SORT.ASC,
      desc: SORT.DESC,
    }

    const tableToApiFieldSortMap = {
      tag: 'NAME',
      count: 'COUNT',
      countPercent: 'COUNT',
      evolution: 'COUNT_EVOLUTION',
      nps: 'NPS',
      npsEvolution: 'NPS_EVOLUTION',
    }

    setOrder({
      sort: tableToApiFieldSortMap[orderBy],
      direction: tableToApiSortMap[itemOrder],
    })
  }, [])

  const handleNavigationToMentionsByTag = useCallback(
    (rowData: RowItem) => {
      addFilterToQuery('tag', 'and', rowData.name)

      history.push(
        `/messages/${projectId}/${datasetId}?${encodeUrlFilters(
          getFilterList(),
        )}`,
      )
    },
    [datasetId, history, projectId, addFilterToQuery, getFilterList],
  )

  useEffect(() => {
    trackEvent('User enters a section', {
      section: 'Key Concepts',
    })
  }, [])

  useEffect(() => {
    if (!datasetTags) {
      return null
    }

    const { total: totalTags, tags } = datasetTags

    const mappedRows = tags.map(
      ({ tag, count, evolution, netNpsCount, sentimentsCount }) => ({
        tag,
        name: tag,
        count,
        countPercent: `${getPercentage(count, totalTags, 0)}%`,
        evolution,
        sentimentsCount,
        nps: netNpsCount.count,
        npsEvolution: netNpsCount.evolution,
        filterKey: 'tag',
        filterParam: 'name',
      }),
    )

    setTotal(totalTags)
    setRows(mappedRows)
  }, [datasetTags])

  useEffect(() => {
    if (!datasetMetrics) {
      return null
    }

    const hasNPS = Boolean(datasetMetrics.nps)

    const name: Column = {
      id: 'name',
      label: 'Conceptos',
      sortable: 'tag',

      key: 'tag',
      onValueClick: handleNavigationToMentionsByTag,
    }

    const mentions: Column = {
      id: 'mentions',
      label: 'Menciones',
      sortable: 'count',
      align: 'right',

      key: 'count',
      onValueClick: handleNavigationToMentionsByTag,
    }

    const mentionsPercent: Column = {
      id: 'mentionsPercent',
      label: '%',
      sortable: 'countPercent',
      align: 'right',

      key: 'countPercent',
    }

    const evolution: Column = {
      id: 'evolution',
      label: 'Evol.',
      align: 'right',
      sortable: 'evolution',

      component: Evolution,
    }

    const sentiment: Column = {
      id: 'sentiment',
      label: 'Sentimiento',
      align: 'right',
      component: Sentiment,
    }

    const nps: Column = {
      id: 'nps',
      label: 'NPS',
      sortable: 'nps',
      align: 'right',

      key: 'nps',
    }

    const npsEvolution: Column = {
      id: 'npsEvolution',
      label: 'Evol. NPS',
      align: 'right',
      sortable: 'npsEvolution',

      component: EvolutionNPS,
    }

    let newColumns: Column[] = []

    if (hasNPS) {
      if (withDate) {
        newColumns = [
          name,
          mentions,
          mentionsPercent,
          evolution,
          sentiment,
          nps,
          npsEvolution,
        ]
      } else {
        newColumns = [name, mentions, mentionsPercent, sentiment, nps]
      }
    } else {
      if (withDate) {
        newColumns = [name, mentions, mentionsPercent, evolution, sentiment]
      } else {
        newColumns = [name, mentions, mentionsPercent, sentiment]
      }
    }

    setColumns(newColumns)
  }, [datasetMetrics, withDate, handleNavigationToMentionsByTag])

  return {
    columns,
    datasetId,
    isLoading: datasetTagsIsLoading || datasetMetricsLoading,
    projectId,
    rows,
    total,
    filters,
    handlePageChange,
    handleRowsPerPageChange,
    handleSortChange,
  }
}

export default useLogic
