import { FC, memo } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns-datepicker/locale/es'
import * as S from './styles'
import useLogic from './logic'
import { Props } from './types'

registerLocale('es', es)

const DateRange: FC<Props> = ({
  start,
  end,
  minDate,
  maxDate = new Date(),
  onChange,
}) => {
  const {
    startMinMonth,
    startMaxMonth,
    endMinMonth,
    endMaxMonth,
    tempEndDateForStartCalendar,
    tempStartDateForEndCalendar,
    startCalendar,
    endCalendar,
    startCalendarDates,
    endCalendarDates,
    formatWeekDay,
    handleDateChange,
    handleStartYearChange,
    handleEndYearChange,
    handleEndCalendarMouseEnter,
    handleEndCalendarMouseLeave,
  } = useLogic({
    start,
    end,
    minDate,
    maxDate,
    onChange,
  })

  return (
    <S.Container>
      <S.DatePickerContainer
        $monthMinDate={startMinMonth}
        $monthMaxDate={startMaxMonth}
      >
        <DatePicker
          ref={startCalendar}
          startDate={startCalendarDates.start}
          endDate={startCalendarDates.end || tempEndDateForStartCalendar}
          selected={startCalendarDates.start}
          onChange={handleDateChange(true)}
          showMonthDropdown
          showYearDropdown
          inline
          selectsRange
          maxDate={maxDate}
          minDate={minDate}
          locale="es"
          formatWeekDay={formatWeekDay}
          onYearChange={handleStartYearChange}
          focusSelectedMonth
          disabledKeyboardNavigation
        />
      </S.DatePickerContainer>
      <S.DatePickerContainer
        $monthMinDate={endMinMonth}
        $monthMaxDate={endMaxMonth}
        onMouseEnter={handleEndCalendarMouseEnter}
        onMouseLeave={handleEndCalendarMouseLeave}
      >
        <DatePicker
          ref={endCalendar}
          startDate={endCalendarDates.start || tempStartDateForEndCalendar}
          endDate={endCalendarDates.end}
          selected={endCalendarDates.end || endCalendarDates.start}
          onChange={handleDateChange(false)}
          showMonthDropdown
          showYearDropdown
          inline
          selectsRange
          maxDate={maxDate}
          minDate={minDate}
          locale="es"
          formatWeekDay={formatWeekDay}
          onYearChange={handleEndYearChange}
          focusSelectedMonth
          disabledKeyboardNavigation
        />
      </S.DatePickerContainer>
    </S.Container>
  )
}

export default memo(DateRange)
