import { FC, memo } from 'react'
import type { Props } from '../../types'

const Learning: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M2 13.625C2 12.5895 2.83947 11.75 3.875 11.75H14"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.875 0.5H14V15.5H3.875C2.83947 15.5 2 14.6605 2 13.625V2.375C2 1.33947 2.83947 0.5 3.875 0.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7.25L8.375 5.375L5.75 7.25V1.25C5.75 0.835786 6.08579 0.5 6.5 0.5H10.25C10.6642 0.5 11 0.835786 11 1.25V7.25Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(Learning)
