import { FC, memo } from 'react'
import type { Props } from '../../types'

const CX: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect width="32" height="32" rx="16" fill="#D9FFFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1988 11.5241V12.9098V14.9742V17.0443V18.4318C23.1988 18.6127 23.1269 18.7863 22.9989 18.9141C22.8708 19.042 22.6972 19.1137 22.5163 19.1134H21.8375C21.6565 19.1134 21.4829 19.1853 21.3549 19.3133C21.2269 19.4413 21.155 19.615 21.155 19.796V20.4757C21.154 20.8511 20.8497 21.155 20.4744 21.1555H17.7144C17.5335 21.1555 17.36 21.2275 17.2322 21.3555C17.1043 21.4835 17.0326 21.6571 17.0329 21.8381V22.5178C17.0329 22.6988 16.9609 22.8723 16.8329 23.0002C16.7049 23.128 16.5313 23.1997 16.3503 23.1995H15.6473C15.4664 23.1997 15.2928 23.128 15.1648 23.0002C15.0367 22.8723 14.9648 22.6988 14.9648 22.5178V21.8381C14.965 21.6571 14.8933 21.4835 14.7655 21.3555C14.6376 21.2275 14.4641 21.1555 14.2832 21.1555H11.5233C11.148 21.155 10.8437 20.8511 10.8427 20.4757V19.7932C10.8427 19.4162 10.5371 19.1106 10.1601 19.1106H9.48135C9.30042 19.1109 9.12681 19.0392 8.99879 18.9113C8.87077 18.7835 8.79883 18.6099 8.79883 18.429V12.9098V11.5241C8.79986 11.1479 9.10513 10.8434 9.48135 10.8434H10.1601C10.5371 10.8434 10.8427 10.5378 10.8427 10.1609V9.48203C10.8426 9.10579 11.1471 8.8005 11.5233 8.79947H20.4744C20.8506 8.8005 21.155 9.10579 21.155 9.48203V10.159C21.155 10.536 21.4606 10.8416 21.8375 10.8416H22.5163C22.8933 10.8416 23.1988 11.1472 23.1988 11.5241ZM21.1326 18.4069V17.0446V12.91V11.5542C21.1326 11.1773 20.827 10.8717 20.4501 10.8717H11.5494C11.1761 10.8742 10.8741 11.1762 10.8715 11.5496V12.911V18.4069C10.8736 18.7804 11.1759 19.0828 11.5494 19.0848H14.2841C14.465 19.0848 14.6385 19.1567 14.7664 19.2848C14.8942 19.4128 14.9659 19.5864 14.9657 19.7674V20.4471C14.9657 20.6281 15.0376 20.8016 15.1657 20.9294C15.2937 21.0573 15.4673 21.129 15.6482 21.1288H16.3512C16.7266 21.1293 17.0317 20.8262 17.0338 20.4509V19.7711C17.0335 19.5901 17.1052 19.4165 17.2331 19.2885C17.3609 19.1605 17.5344 19.0885 17.7154 19.0885H20.4501C20.631 19.0888 20.8046 19.0171 20.9326 18.8892C21.0607 18.7614 21.1326 18.5878 21.1326 18.4069Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(CX)
