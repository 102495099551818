import styled from 'styled-components/macro'
import DefaultLogo from '@components/SentisisLogo/SentisisLogo'

export const Container = styled.div`
  align-items: center;
  background: linear-gradient(45.88deg, #1883a3 0%, #14c0aa 100%);
  display: grid;
  justify-content: center;
  min-height: 100vh;
`

export const Content = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 0.188rem 1.25rem 0 rgba(0, 82, 163, 0.06);
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  padding: 3rem 2.5rem;
  width: 26rem;
`

export const LogoContainer = styled.div`
  border-bottom: 1px solid #f2f4f7;
  display: flex;
  justify-content: center;
  padding: 0 0 1.25rem;
  width: 100%;
`

export const Logo = styled(DefaultLogo)``

export const Title = styled.h1`
  color: #10d4ab;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-top: 1rem;
  white-space: nowrap;
`

export const Subtitle = styled.p`
  color: #0052a3;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: normal;
  margin: 0.313rem auto;
  text-align: center;
`
