import * as React from 'react'
import styled from 'styled-components/macro'

import logError from '@utils/logError'

const CodeBlock = styled.pre`
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1em 1.5em;
  line-height: 1.5;
  margin: 1em 0;
  width: 100%;
  overflow-x: hidden;
  white-space: pre-wrap;
  font-size: 14px;
`

const ErrorHandlerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  line-height: 1.5;

  flex: 1;
  justify-content: center;
  align-items: center;

  & > a {
    color: $dark_gray;
  }
`

const Title = styled.h2`
  margin: 0;
  color: #4c5a6a;
  font-size: 18px;
`

const Subtitle = styled.p`
  margin: 0;
  color: #a4aeb9;
  font-size: 14px;
`

interface Props {
  error: object
  context?: object
}

const ErrorHandler: React.SFC<Props> = props => {
  const { error, context } = props

  if (error) {
    logError(error, context)
  }

  return (
    <ErrorHandlerContainer className="c-ErrorHandler">
      <Title className="c-ErrorHandler__title">Ha ocurrido un error</Title>
      <Subtitle className="c-ErrorHandler__subtitle">
        Recarga la página o inténtalo más tarde.
      </Subtitle>
      {process.env.NODE_ENV !== 'production' && (
        <CodeBlock>{error.toString()}</CodeBlock>
      )}
    </ErrorHandlerContainer>
  )
}

export default ErrorHandler
