import { useQuery, FetchPolicy } from '@apollo/client'
import { useMemo } from 'react'
import { GET_DATASET_METRICS } from 'apollo/queries/dataset'
import { normalizeDatasetMetrics } from 'models/Dataset'

type NpsScoreMetricsParams = {
  benchmarkKey?: string
  projectId: string
  datasetId: string
  filters?: unknown
  fetchPolicy?: FetchPolicy
}

const useDatasetMetrics = ({
  fetchPolicy = 'network-only',
  datasetId,
  projectId,
  filters = {},
  benchmarkKey = '',
}: NpsScoreMetricsParams) => {
  const {
    data,
    loading: datasetMetricsLoading,
    error: datasetMetricsError,
    refetch: datasetMetricsRefetch,
  } = useQuery(GET_DATASET_METRICS, {
    fetchPolicy,
    skip: !projectId || !datasetId,
    variables: {
      datasetId,
      projectId,
      filters,
      benchmarkKey,
      withBenchmark: !!benchmarkKey,
    },
  })

  const datasetMetrics = useMemo(() => {
    const response = data?.project
    if (!response) {
      return null
    }

    return normalizeDatasetMetrics(response)
  }, [data])

  return {
    datasetMetrics,
    datasetMetricsLoading,
    datasetMetricsError,
    datasetMetricsRefetch,
  }
}

export default useDatasetMetrics
