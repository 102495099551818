import { Fragment, useMemo } from 'react'
import * as React from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router'
import { find, get, getOr, isArray, isEqual, omit, prop } from 'lodash/fp'

import Tag from '@components/Tag/Tag'
import { FiltersContextConsumer } from '@providers/FiltersProvider'
import { parseLocationFilter } from '@utils/filters'

import { Filters, translatedSentiments } from '@customTypes/index'
import { FilterProviderStore } from '@providers/FiltersProvider'
import {
  FilterKey,
  SentimentKeys,
  TagWrapper,
  TagWrapperProps,
} from '@components/TagWrapper/TagWrapper'
import { notEmpty, wrap } from '@utils/general'
import { Emotion, getEmotions } from '@utils/emotionCategories'
import { FiltersColumnKeyEnum } from '@components/AdvanceFilters/types'

const FiltersResultsBar: React.FunctionComponent<{ location: any }> = ({
  location,
}) => {
  const inEditLog = location.pathname.includes('edition-log')

  const emotions: Emotion[] = getEmotions()
  const translatedFilters: { [key: string]: string } = useMemo(
    () => ({
      originalSentiment: 'Sentimiento original',
      originalCategory: 'Categoría original',
      category: inEditLog ? 'Categoría actual' : 'Categoría',
      theme: 'Tema',
      sentiment: inEditLog ? 'Sentimiento actual' : 'Sentimiento',
      location: 'Localización',
      tag: 'Concepto',
    }),
    [inEditLog],
  )

  const searchEmotion = filter => find(e => isEqual(prop('text', e), filter))

  return (
    <FiltersContextConsumer>
      {(context: FilterProviderStore) => {
        // used to iterate over by key
        const { filtersState, removeFilterFromQuery, clearAllFilters } = context
        const { filters: filtersContext } = filtersState
        const filters: any = omit('date', filtersContext)
        const filterTypes = Object.keys(filters)
        const showContentSeparator = getContentCount(filters, filterTypes)

        return filterTypes.length ? (
          <S.ActiveFiltersWrapper>
            <S.FiltersTags className="container is-fluid">
              <S.TagContainer>
                {filterTypes.map((filterType: any, filterTypeIndex: number) => {
                  return (
                    <Fragment key={filterType}>
                      {
                        /* Text filter */
                        filters[filterType].match && (
                          <Tag
                            key={'content_math'}
                            color={filterType}
                            persistentButton
                            crossClick={() =>
                              removeFilterFromQuery(
                                filterType,
                                'match',
                                filters[filterType].match,
                              )
                            }
                          >
                            {filters[filterType].match}
                          </Tag>
                        )
                      }

                      {
                        /* Advanced text ORS */
                        filters[filterType].ors &&
                          get([filterType, 'ors'])(filters).map(
                            (arrFilter: string[], arrIndex: number) => {
                              /**
                               * ORs filter [[a,b], [c,d]], where the second array represents ANDs
                               */
                              const arrFilterLength = filters[filterType].ors
                                .join(',')
                                .split(',').length
                              return wrap(arrFilter).map(
                                (filter: FilterKey, tagIndex: number) => {
                                  // If ORS filter contains ANDs, we treat them as OR visually
                                  const operatorLabel =
                                    arrIndex === 0 || arrFilter.length > 1
                                      ? 'or'
                                      : 'and'
                                  const advancedFilterProps: TagWrapperProps = {
                                    tagIndex,
                                    operator: 'ors',
                                    operatorLabel,
                                    filter,
                                    filterType,
                                    sibling:
                                      (arrFilterLength === 2 &&
                                        arrIndex === 1) ||
                                      (tagIndex > 0 && !showContentSeparator),
                                    removeFn: removeFilterFromQuery,
                                  }

                                  // Show AND separator in middle of list, in front of AND tags
                                  const showANDSeparator =
                                    (tagIndex === 0 && filterTypeIndex > 0) ||
                                    (arrIndex === 1 &&
                                      tagIndex === 0 &&
                                      showContentSeparator)

                                  return (
                                    <>
                                      {showANDSeparator && (
                                        <S.ANDSeparator and />
                                      )}
                                      <TagWrapper
                                        {...advancedFilterProps}
                                        key={`ors-${filterType}-${tagIndex}-${arrIndex}`}
                                      >
                                        {`Texto avanzado: ${filter}`}
                                      </TagWrapper>
                                    </>
                                  )
                                },
                              )
                            },
                          )
                      }

                      {
                        /* Advanced text ANDS */
                        filters[filterType].ands &&
                          wrap(get([filterType, 'ands', 0], filters)).map(
                            (filter: FilterKey, tagIndex: number) => {
                              const emotion = searchEmotion(filter)(emotions)
                              const isEmotion = notEmpty(emotion)

                              let showANDSeparator =
                                tagIndex === 0 && showContentSeparator
                              const andsFilterProps: TagWrapperProps = {
                                tagIndex,
                                operator: 'ands',
                                operatorLabel: 'and',
                                filter,
                                filterType,
                                sibling: !showContentSeparator,

                                removeFn: removeFilterFromQuery,
                              }
                              return (
                                <>
                                  {showANDSeparator && <S.ANDSeparator />}
                                  <TagWrapper
                                    {...andsFilterProps}
                                    isEmotion={isEmotion}
                                    key={`ands-${filterType}-${tagIndex}`}
                                  >
                                    {`Texto avanzado: ${
                                      isEmotion
                                        ? getOr(null, 'name', emotion)
                                        : filter
                                    }`}
                                  </TagWrapper>
                                </>
                              )
                            },
                          )
                      }

                      {
                        /* Advanced text NOTS */
                        filters[filterType].nots &&
                          wrap(get([filterType, 'nots', 0])(filters)).map(
                            (filter: FilterKey, tagIndex: number) => {
                              const emotion = searchEmotion(filter)(emotions)
                              const isEmotion = notEmpty(emotion)

                              const andsFilterProps: TagWrapperProps = {
                                tagIndex,
                                operator: 'nots',
                                operatorLabel: 'not',
                                filter,
                                filterType,
                                removeFn: removeFilterFromQuery,
                              }
                              const showANDSeparator =
                                tagIndex === 0 && showContentSeparator

                              return (
                                <>
                                  {showANDSeparator && <S.ANDSeparator />}
                                  <TagWrapper
                                    {...andsFilterProps}
                                    isEmotion={isEmotion}
                                    key={`nots-${filterType}-${filterTypeIndex}`}
                                  >
                                    {`Texto avanzado: ${
                                      isEmotion
                                        ? getOr(null, 'name', emotion)
                                        : filter
                                    }`}
                                  </TagWrapper>
                                </>
                              )
                            },
                          )
                      }

                      {filters[filterType].or &&
                        wrap(get([filterType, 'or'])(filters)).map(
                          (filter: FilterKey, tagIndex: number) => {
                            const emotion = searchEmotion(filter)(emotions)
                            const isEmotion = notEmpty(emotion)
                            const orFilterProps: TagWrapperProps = {
                              tagIndex,
                              operator: 'or',
                              filter,
                              filterType,
                              sibling: tagIndex > 0 && filterTypeIndex > 0,
                              removeFn: removeFilterFromQuery,
                            }
                            const typeLabel = `${
                              translatedFilters[filterType] || filterType
                            }`
                            if (
                              ['sentiment', 'originalSentiment'].includes(
                                filterType,
                              )
                            ) {
                              orFilterProps.filter = filter as SentimentKeys
                              orFilterProps.color = filter

                              return (
                                <TagWrapper
                                  {...orFilterProps}
                                  key={`sentiment-or-${filterType}-${tagIndex}`}
                                >
                                  {inEditLog
                                    ? `${typeLabel}: ${translatedSentiments[filter]}`
                                    : `${translatedSentiments[filter]}`}
                                </TagWrapper>
                              )
                            }

                            if (filterType === 'tag') {
                              const showANDSeparator =
                                filterTypeIndex > 0 && tagIndex === 0

                              const typeLabel = `${
                                translatedFilters[filterType] || filterType
                              }`

                              return (
                                <>
                                  {showANDSeparator && <S.ANDSeparator />}
                                  <TagWrapper
                                    {...orFilterProps}
                                    isEmotion={isEmotion}
                                    key={`tag-${filterType}-${tagIndex}`}
                                  >
                                    {`${typeLabel}: ${
                                      isEmotion
                                        ? getOr(null, 'name', emotion)
                                        : filter
                                    }`}
                                  </TagWrapper>
                                </>
                              )
                            }

                            const tagType =
                              filterType === FiltersColumnKeyEnum.contentFields
                                ? ''
                                : `${typeLabel}:`

                            return (
                              <TagWrapper
                                {...orFilterProps}
                                isEmotion={isEmotion}
                                key={`or-${filterType}-${tagIndex}`}
                              >
                                {`${tagType}${
                                  getOr(null, 'name', emotion) ||
                                  parseLocationFilter(filter) ||
                                  filter
                                }`}
                              </TagWrapper>
                            )
                          },
                        )}
                      {filters[filterType].and &&
                        wrap(get([filterType, 'and'])(filters)).map(
                          (filter: FilterKey, tagIndex: number) => {
                            const emotion = searchEmotion(filter)(emotions)
                            const isEmotion = notEmpty(emotion)
                            const elementsInFilter = Object.keys(
                              filters[filterType],
                            ).length
                            const andFilterProps: TagWrapperProps = {
                              tagIndex,
                              operator: 'and',
                              filter,
                              filterType,
                              removeFn: removeFilterFromQuery,
                            }
                            const typeLabel = `${
                              translatedFilters[filterType] || filterType
                            }`

                            const showAndSeparator =
                              elementsInFilter > 1 && tagIndex === 0

                            return (
                              <>
                                {showAndSeparator && <S.ANDSeparator />}
                                <TagWrapper
                                  {...andFilterProps}
                                  isEmotion={isEmotion}
                                  key={`and-${filterType}-${tagIndex}`}
                                >
                                  {`${typeLabel}: ${
                                    isEmotion
                                      ? getOr(null, 'name', emotion)
                                      : parseLocationFilter(filter) || filter
                                  }`}
                                </TagWrapper>
                              </>
                            )
                          },
                        )}
                      {filters[filterType].not &&
                        wrap(get([filterType, 'not'])(filters)).map(
                          (filter: FilterKey, tagIndex: number) => {
                            const emotion = searchEmotion(filter)(emotions)
                            const isEmotion = notEmpty(emotion)

                            const notFilterProps: TagWrapperProps = {
                              tagIndex,
                              operator: 'not',
                              filter,
                              filterType,
                              removeFn: removeFilterFromQuery,
                            }

                            const tagType =
                              filterType === FiltersColumnKeyEnum.contentFields
                                ? ''
                                : `${
                                    translatedFilters[filterType] || filterType
                                  }: `

                            if (filterType === 'sentiment') {
                              return (
                                <TagWrapper
                                  {...notFilterProps}
                                  key={`sentiment-not-${filterType}-${tagIndex}`}
                                >
                                  {`${translatedSentiments[filter]}`}
                                </TagWrapper>
                              )
                            }

                            const showAndSeparator =
                              Object.keys(filters[filterType]).length > 1 &&
                              tagIndex === 0

                            return (
                              <>
                                {showAndSeparator && <S.ANDSeparator />}
                                <TagWrapper
                                  {...notFilterProps}
                                  isEmotion={isEmotion}
                                  key={`not-${filterType}-${tagIndex}`}
                                >
                                  {`${tagType}${
                                    isEmotion
                                      ? getOr(null, 'name', emotion)
                                      : String(filter)
                                  }`}
                                </TagWrapper>
                              </>
                            )
                          },
                        )}
                    </Fragment>
                  )
                })}
                {Object.keys(filters).length ? (
                  <S.ClearFilters onClick={() => clearAllFilters()}>
                    Borrar Todos
                  </S.ClearFilters>
                ) : null}
              </S.TagContainer>
            </S.FiltersTags>
          </S.ActiveFiltersWrapper>
        ) : null
      }}
    </FiltersContextConsumer>
  )
}

/**
 * Counts every item in Advanced text fields
 * @param filters filter tags from filter consumer context
 * @param filterTypes array of keys from filters
 */
function getContentCount(filters: Filters, filterTypes: string[]) {
  if (filterTypes.length && filterTypes.includes('content')) {
    const contentCount = Object.values(filters.content).reduce(
      (acc: number, content: string[] | [string[]]) => {
        if (isArray(content[0])) {
          return acc + content.join(',').split(',').length
        }
        return acc + content.length
      },
      0,
    )

    return contentCount > 2
  }
  return false
}

// Styled components namespace
const S = {
  FiltersTags: styled.div`
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray[20]};
    width: 100%;
    text-align: left;
  `,
  ActiveFiltersWrapper: styled.div`
    display: block;
    padding: 12px 20px 7px;
    background-color: #fff;
    box-shadow: inset 2px 2px 4px 1px rgba(0, 0, 0, 0.04);
    align-items: center;
    max-height: calc(500px - 120px);
    overflow-y: auto;
  `,

  TagContainer: styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
    border: 0px;
    border-radius: 3px;
    color: white;
    &&& {
      flex-wrap: wrap;
    }
  `,
  ClearFilters: styled.button`
    height: 22px;
    outline: none;
    border: 0;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.gray[20]};
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 4px;
    cursor: pointer;
  `,
  ANDSeparator: styled('div')<{ category?: boolean; and?: boolean }>`
    height: 22px;
    padding: 0 4px;
    margin: 0 8px 4px 4px;
    user-select: none;
    display: block;
    text-align: center;
    line-height: 22px;
    border-radius: 1px;
    font-size: 12px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.colors.tagOperator.custom};
    &:before {
      content: 'AND';
    }
    &:first-of-type {
      display: none;
    }
  `,
}

export default withRouter(FiltersResultsBar)
