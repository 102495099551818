import { FC, memo } from 'react'
import type { Props } from '../../types'

const Logout: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clip-path="url(#clip0_179_18154)">
      <path
        d="M5.66667 15H2.55556C1.69645 15 1 14.3036 1 13.4444V2.55556C1 1.69645 1.69645 1 2.55556 1H5.66667"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1112 11.8889L15.0001 7.99997L11.1112 4.11108"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0001 7.99997H5.66681"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_179_18154">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default memo(Logout)
