import { FC, memo } from 'react'
import type { Props } from '../../types'

const Categories: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="13.5" cy="3.5" r="2" stroke="currentColor" />
    <circle cx="6" cy="10" r="4.5" stroke="currentColor" />
  </svg>
)

export default memo(Categories)
