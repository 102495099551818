import { AllClientsQuery, AllTeamsQuery } from '@customTypes/index'
import { Query, Mutation } from '@apollo/client/react/components'
import { DocumentNode } from '@apollo/client/link/core/types'
import { MutationFunction, OperationVariables } from '@apollo/client'

export type MutationProps = {
  mutation: DocumentNode
  children: MutationFunction<any, OperationVariables>
  refetchQueries?: string[]
}

export const MutationComponent = ({
  children,
  refetchQueries = undefined,
  mutation,
  ...rest
}) => (
  <Mutation mutation={mutation} refetchQueries={refetchQueries} {...rest}>
    {children}
  </Mutation>
)
export const QueryComponent = ({ children, query, ...rest }) => (
  <Query query={query} {...rest}>
    {children}
  </Query>
)
export const AllClientsQueryComponent = ({ children, query }) => (
  <Query<AllClientsQuery> query={query}>{children}</Query>
)

export const AllTeamsQueryComponent = ({ children, query, variables }) => (
  <Query<AllTeamsQuery> query={query} variables={variables}>
    {children}
  </Query>
)

export const AllRolesQueryComponent = ({ children, query, variables }) => (
  <Query query={query} variables={variables}>
    {children}
  </Query>
)
