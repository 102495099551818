import { FC, memo } from 'react'
import type { Props } from '../types'

const StepsArrowRight: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M1 10.5L5 5.5L1 0.5" stroke="currentColor" />
  </svg>
)

export default memo(StepsArrowRight)
