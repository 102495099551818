/* Local imports */
import { useCallback, memo } from 'react'
import { Redirect } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import { Sidebar as MUISidebar } from 'react-pro-sidebar'

import ErrorHandler from '@components/ErrorHandler'
import Icon from '@components/Icon'
import LoadingEllipsis from '@components/AdvanceFilters/LoadingEllipsis'
import * as S from './styles'
import { LinkType, SidebarProps } from './types'
import useLogic from './logic'

/* Component definition */
const Sidebar = (props: SidebarProps) => {
  const {
    projectConfig,
    error,
    isLoading,
    checkIsLinkActive,
    links,
    isProjectNameTooltipVisible,
    setIsProjectNameTooltipVisible,
    isDatasetNameTooltipVisible,
    setIsDatasetNameTooltipVisible,
    toggleCollapseSidebar,
    collapsed,
    handleClick,
  } = useLogic(props)

  const renderLinks = useCallback(
    (links: LinkType[]) => {
      return links.map(link => (
        <S.MenuItem
          key={link.path}
          icon={
            <S.Icon
              $isActive={checkIsLinkActive(link, link.path)}
              name={link.icon}
              size={16}
            />
          }
          onClick={() => handleClick(link)}
          active={checkIsLinkActive(link, link.path)}
          style={{
            background: checkIsLinkActive(link, link.path) ? '#F7F7F9' : '',
            color: checkIsLinkActive(link, link.path) ? '#282A33' : '#5B5D66',
          }}
        >
          {link.title}
        </S.MenuItem>
      ))
    },
    [checkIsLinkActive, handleClick],
  )

  const calledWithError = error && !isLoading

  if (calledWithError && error?.message?.includes('not found')) {
    return <Redirect to={`/`} />
  } else if (calledWithError) {
    return <ErrorHandler error={error} />
  }

  if (isLoading) {
    return (
      <S.LoadingWrapper>
        <LoadingEllipsis others={null} />
      </S.LoadingWrapper>
    )
  }

  const datasetName = projectConfig?.dataset?.name || ''
  const projectName = projectConfig?.name || ''

  return (
    <S.Wrapper>
      <MUISidebar
        breakPoint="sm"
        transitionDuration={400}
        backgroundColor="white"
        width="250px"
        collapsedWidth="48px"
        rtl={false}
      >
        <S.Menu>
          {datasetName && projectName && (
            <>
              <S.HeaderMenuItemContainer>
                <S.MenuItem icon={<Icon name="cx" size={40} />}>
                  {/* TODO use @components/tooltip */}
                  <Tooltip
                    title={projectName}
                    placement="right-start"
                    open={isProjectNameTooltipVisible}
                  >
                    <S.Title
                      onMouseEnter={e => setIsProjectNameTooltipVisible(true)}
                      onMouseLeave={e => setIsProjectNameTooltipVisible(false)}
                    >
                      {projectName}
                    </S.Title>
                  </Tooltip>
                  {/* TODO use @components/tooltip */}
                  <Tooltip
                    title={datasetName}
                    placement="right-start"
                    open={isDatasetNameTooltipVisible}
                  >
                    <S.SubTitle
                      onMouseEnter={e => setIsDatasetNameTooltipVisible(true)}
                      onMouseLeave={e => setIsDatasetNameTooltipVisible(false)}
                    >
                      {datasetName}
                    </S.SubTitle>
                  </Tooltip>
                </S.MenuItem>
              </S.HeaderMenuItemContainer>

              {!collapsed && <S.SeparatorLine />}
            </>
          )}
          {renderLinks(links)}
        </S.Menu>
        <S.ToogleIcon onClick={() => toggleCollapseSidebar()}>
          <Icon name={collapsed ? 'chevronRight' : 'chevronLeft'} size={50} />
        </S.ToogleIcon>
      </MUISidebar>
    </S.Wrapper>
  )
}

export default memo(Sidebar)
