import { gql } from '@apollo/client'
import { CUSTOM_CATEGORY_FRAGMENT } from 'apollo/fragments/CustomCategory'

export const PROJECT_FRAGMENT = gql`
  fragment ProjectData on Project {
    id
    createdAt
    description
    name
    targets
    client {
      id
      name
    }
    team {
      id
      name
    }
    allCategories {
      id
      name
      renamed
    }
    customCategories {
      ...CustomCategoryData
    }
    webhook {
      enabled
      url
      secret
    }
    bidock {
      enabled
      conf
      exportASAP
      updatedAt
      status
      statusInfo
    }
  }
  ${CUSTOM_CATEGORY_FRAGMENT}
`
