import { FC, memo } from 'react'
import type { Props } from '../../types'

const OfficialAccounts: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2 1H12.8C13.4627 1 14 1.6467 14 2.44444V6.77778C14 10.7665 11.3137 14 8 14C6.4087 14 4.88258 13.2391 3.75736 11.8847C2.63214 10.5302 2 8.69323 2 6.77778V2.44444C2 1.6467 2.53726 1 3.2 1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 7.14925L7.4 9L11 5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(OfficialAccounts)
