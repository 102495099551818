import * as React from 'react'

import './Spinner.css'

interface Props {
  position?: boolean
  flex?: boolean
  className?: string
  small?: boolean
  padding?: boolean
}

const Spinner: React.SFC<Props> = ({
  position,
  flex,
  className,
  small,
  padding,
}) => {
  let spinnerClassName = 'Spinner'
  if (position) spinnerClassName += ' with-position'
  if (flex) spinnerClassName += ' with-flex'
  if (padding) spinnerClassName += ' with-padding'
  if (className) spinnerClassName += ` ${className}`

  const width = small ? 25 : 50
  const height = small ? 25 : 50

  return (
    <div className={spinnerClassName}>
      <svg
        viewBox="0 0 50 50"
        className="Spinner__svg"
        height={height}
        width={width}
      >
        <circle
          className="Spinner__path"
          cx="25"
          cy="25.2"
          r="19.9"
          fill="none"
          strokeWidth="6"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  )
}

export default Spinner
