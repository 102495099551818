// Libs
import { get } from 'lodash/fp'

// Graphql Subscriptions
import { ApolloClient, split, InMemoryCache } from '@apollo/client'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from '@apollo/client/link/ws'

import { getAPIUrl, getSocketUrl } from '@utils/index'
import { HttpLink } from '@apollo/client'

class WebSocket {
  // //
  // attributes
  // //
  private clients: any = {}

  // //
  // public methods
  // //
  public createApolloClient(endPoint: string) {
    if (this.clients[endPoint]) return this.clients[endPoint].client
    // Create an http link:
    const httpLink = new HttpLink({
      uri: getAPIUrl(`/${endPoint}`),
      credentials: 'include',
    })

    const wsOptions: any = {
      reconnect: true,
    }

    // Create a WebSocket link:
    const wsLink = new WebSocketLink({
      uri: getSocketUrl('graphql'),
      options: wsOptions,
    })

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )
    // Configure Apollo client

    // Load cache as any because of apollo types issue on 14.4.2
    const cache: any = new InMemoryCache()

    // apollo client setup
    const client = new ApolloClient({
      link: link,
      cache,
      connectToDevTools: true,
    })

    this.clients[endPoint] = {
      client,
      wsLink,
    }

    return client
  }

  public reconnectWebSocket(endPoint: string, data: any) {
    const client = this.clients[endPoint]
    if (
      !client ||
      !data ||
      !get('viewer.user.client.id', data) ||
      !get('viewer.user.id', data)
    )
      return

    client.wsLink.subscriptionClient.connectionParams().then((params: any) => {
      Object.assign(params, {
        idUser: data.viewer.user.id,
        idClient: data.viewer.user.client.id,
      })
      client.wsLink.subscriptionClient.connect()
    })
  }
}

// Instanciate it in order to have a singleton instance
const websocket = new WebSocket()
export default websocket
