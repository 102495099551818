import { ChangeEvent, MouseEvent, useCallback, useState } from 'react'
import { Order } from './TableHead/types'
import { Props } from './types'

const useLogic = ({
  defaultOrder,
  defaultOrderBy,
  defaultRowsPerPage,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
}: Partial<Props>) => {
  const [order, setOrder] = useState<Order>(defaultOrder)
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const handleRequestSort = useCallback(
    (event: MouseEvent<unknown>, property: any) => {
      const isAsc = orderBy === property && order === 'asc'

      const newOrder: Order = isAsc ? 'desc' : 'asc'
      const newOrderBy = property

      setOrder(newOrder)
      setOrderBy(newOrderBy)

      if (onSortChange) {
        onSortChange({
          order: newOrder,
          orderBy: newOrderBy,
        })
      }
    },
    [order, orderBy, onSortChange],
  )

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage)

      if (onPageChange) {
        onPageChange({
          page: newPage,
        })
      }
    },
    [onPageChange],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const rowsPerPage = parseInt(event.target.value, 10)
      const page = 0

      setRowsPerPage(rowsPerPage)
      setPage(page)

      if (onRowsPerPageChange) {
        onRowsPerPageChange({
          page,
          rowsPerPage,
        })
      }
    },
    [onRowsPerPageChange],
  )

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}

export default useLogic
