import * as React from 'react'
import Tag from '@components/Tag/Tag'
import { translatedSentiments } from '@customTypes/index'

export type SentimentKeys = keyof typeof translatedSentiments
export type FilterKey = string | SentimentKeys

export interface TagWrapperProps {
  tagIndex: number
  operator: string
  filter: string | SentimentKeys
  filterType: string
  removeFn: Function
  sibling?: boolean
  color?: string
  operatorLabel?: string
  isEmotion?: boolean
}

/**
 *
 * @param props Wrap Tag element for filter results adding common logic for aspects
 * @returns
 */
export const TagWrapper: React.FunctionComponent<TagWrapperProps> = props => {
  const {
    tagIndex,
    operatorLabel,
    filter,
    filterType,
    removeFn,
    sibling,
    color,
    operator,
    children,
    isEmotion,
  } = props

  // For content tags (Advanced search), operator is different to label.
  const opLabel = operatorLabel ? operatorLabel : operator

  /*
  Show operator in tag when
  - not first element in group
  - Operator is NOT
  - When have an specific Sibling condition
  */
  const operatorKey =
    sibling || tagIndex > 0 || opLabel === 'not' ? opLabel : null

  return (
    <Tag
      key={`${String(filter)}-${operator}`}
      color={isEmotion ? 'emotion' : color || filterType}
      operator={operatorKey}
      persistentButton
      crossClick={() => removeFn(filterType, operator, filter)}
    >
      {children}
    </Tag>
  )
}
