import styled from 'styled-components/macro'
import { Select as DefaultSelect } from 'sentisis-components'
import { TagsInput as DefaultTagsInput } from 'sentisis-components'
import Field from './Field'
import DefaultSpinner from '@components/Spinner'

export const Form = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colours.white};
  padding: 30px 20px 10px 20px;
  z-index: 1;
  position: absolute;
  max-height: clamp(100vh - 250px, 100vh - 308px, 515px);
  overflow-y: auto;
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 4%);
`

export const Label = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colours.gray[90]};
  line-height: 18px;
  margin-right: 10px;
  margin-bottom: 6px;
  font-weight: 600;
`

export const Hint = styled.div`
  color: ${({ theme }) => theme.colors.gray.light};
  font-size: 12px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 30px);
  font-size: 14px;
  max-height: clamp(100vh - 250px, 100vh - 308px, 515px);
  z-index: 1;
  background-color: ${({ theme }) => theme.colours.white};

  // adds bottom padding below last field in each form
  &::after {
    content: '.';
    text-indent: -9999px;
  }

  .react-select__control {
    border-radius: 5px;
  }

  .react-select__control--menu-is-open {
    border-color: ${({ theme }) => theme.colours.violet[70]};
    border-radius: 5px 5px 0px 0px;

    :hover {
      border-color: ${({ theme }) => theme.colours.violet[70]};
      border-radius: 5px 5px 0px 0px;
    }
  }

  .react-select__menu {
    border-color: ${({ theme }) => theme.colours.violet[70]};
    border-radius: 0px 0px 5px 5px;

    :hover {
      border-color: ${({ theme }) => theme.colours.violet[70]};
    }
  }
`

export const FieldWrapper = styled(Field)`
  width: 100%;
  outline: none;
`

export const OverflowFix = styled.div<{ $offset: number }>`
  padding-top: ${({ $offset }) => $offset || 0}px;
`

export const Select = styled(DefaultSelect)<{ $active: boolean }>`
  .react-select__option {
    font-size: 14px;
    padding-left: 6px;
  }

  .react-select__option--is-focused {
    color: ${({ theme }) => theme.colours.violet[70]};
    background-color: ${({ theme }) => theme.colours.gray[10]};
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.colours.gray[70]};
  }

  .react-select__control:hover {
    border-color: ${({ theme }) => theme.colours.gray[60]};
  }

  .react-select__control:not(:hover) {
    border-color: ${(props: any) =>
      props.$active
        ? ({ theme }) => theme.colours.violet[70]
        : ({ theme }) => theme.colours.gray[60]};
  }
`

export const TagsInput = styled(DefaultTagsInput)`
  .c-TagsInput__input {
    border-color: red;
  }
`

export const Spinner = styled(DefaultSpinner)`
  width: 100%;
`

export const FilterType = styled.span`
  font-weight: 400;
  font-size: 14px;
`
