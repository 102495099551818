import { FC, memo } from 'react'
import type { Props } from '../../types'

const KeyConcepts: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.30967 2.21259C3.00648 3.44115 1.6909 5.99493 2.06229 8.61825C2.45666 11.4039 4.65639 13.5838 7.43984 13.9473C10.1056 14.2954 12.6797 12.8955 13.853 10.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2V8H14Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(KeyConcepts)
