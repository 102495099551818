import { FC, memo } from 'react'
import { Snackbar as SnackbarMaterial } from '@material-ui/core'
import * as S from './styles'
import { Props } from './types'

export const Snackbar: FC<Props> = ({
  children,
  isOpen,
  title,
  type = 'info',
  iconName = 'error',
  onClose,
  horizontal = 'center',
  vertical = 'bottom',
  autoHideDuration = 6000,
}) => {
  return (
    <SnackbarMaterial
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <S.Content $type={type}>
        {iconName && (
          <S.IconContainer>
            <S.Icon name={iconName} />
          </S.IconContainer>
        )}
        <S.TextContainer>
          <S.Title>{title}</S.Title>
          <S.Desc>{children}</S.Desc>
        </S.TextContainer>
        <S.CloseContainer>
          <S.CloseButton onClick={onClose}>
            <S.CloseIcon name="close" />
          </S.CloseButton>
        </S.CloseContainer>
      </S.Content>
    </SnackbarMaterial>
  )
}

export default memo(Snackbar)
