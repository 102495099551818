import Loadable from 'react-loadable'

import Loading from '@components/Loading'

/**
 *
 * @deprecated TODO remove LoadableComponent
 */
const LoadableComponent = Loadable({
  loader: () => import('./Messages'),
  loading: Loading,
  delay: 200,
  timeout: 10000,
})

const LoadableMessagesView = props => <LoadableComponent {...props} />

export default LoadableMessagesView
