import { FC, memo } from 'react'
import type { Props } from '../types'

const UploadIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.4144 4.32913L7.45472 1.11669C7.3078 0.958081 7.07354 0.964154 6.92625 1.11669L3.96654 4.32954C3.82072 4.48734 3.82072 4.7428 3.96654 4.9002C4.11272 5.05801 4.3492 5.05801 4.49538 4.9002L6.81707 2.37669V9.59643C6.81707 9.81921 6.98436 10 7.19087 10C7.39738 10 7.56466 9.81918 7.56466 9.59643V2.37625L9.88635 4.89977C10.0322 5.05757 10.269 5.05757 10.4148 4.89977C10.5606 4.7424 10.5606 4.48691 10.4144 4.32913Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <rect
      x="1"
      y="12"
      width="12.3815"
      height="1"
      rx="0.5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </svg>
)

export default memo(UploadIcon)
