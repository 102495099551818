import { FC, memo } from 'react'
import type { Props } from '../types'

const Add: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M5.98928 0.506577V11.5066" stroke="currentColor" strokeWidth="1.5" />
    <path d="M11.489 6.00647H0.48904" stroke="currentColor" strokeWidth="1.5" />
  </svg>
)

export default memo(Add)
