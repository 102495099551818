import { gql } from '@apollo/client'
import { PROJECT_FRAGMENT } from 'apollo/fragments/Project'

export const CREATE_PROJECT = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    project: createProject(input: $input) {
      id
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $input: UpdateProjectInput!) {
    updateProject(id: $id, input: $input) {
      ...ProjectData
    }
  }
  ${PROJECT_FRAGMENT}
`

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`
