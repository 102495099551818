import styled from 'styled-components/macro'
import DefaultModal, {
  Content as ModalContent,
  Title as ModalTitle,
  SubTitle as ModalSubTitle,
  Buttons as ModalButtons,
  InnerContent as ModalInnerContent,
} from '@components/Modal/Modal'

export const Container = styled(DefaultModal)`
  ${ModalContent} {
    padding: 40px;
    max-width: 586px;
    width: 586px;
  }
`

export const Title = styled(ModalTitle)``

export const SubTitle = styled(ModalSubTitle)``

export const Content = styled(ModalInnerContent)``

export const Form = styled.form``

export const Buttons = styled(ModalButtons)``
