import { FC, memo } from 'react'
import type { Props } from '../types'

const LogoSentisis: FC<Props> = ({ height = 16, width = 16, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 130 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4274 16.0001C38.9023 16.0001 40.098 14.8074 40.098 13.336V10.6641C40.098 9.19278 38.9023 8.00005 37.4274 8.00005H35.2859C33.5145 8.00005 32.0786 6.56736 32.0783 4.80008V2.66406C32.0783 1.19299 30.8827 0.000262831 29.4077 0.000262831L10.6902 0C9.2153 0 8.01965 1.19273 8.01965 2.66406V4.80008C8.01965 6.56736 6.58345 8.00005 4.81184 8.00005H2.67058C1.19565 8.00005 0 9.19278 0 10.6641V13.336C0 14.8074 1.19565 16.0001 2.67058 16.0001H4.81184C6.58134 16.0001 8.01596 17.4294 8.01965 19.1935V21.3361C8.01965 22.8074 9.2153 24.0002 10.69 24.0002H28.8705C30.6421 24.0002 32.0783 25.4328 32.0783 27.2001V28.8C32.0783 30.5673 30.6421 31.9999 28.8705 31.9999H11.2222H11.2277C9.45611 31.9999 8.01965 30.5673 8.01965 28.8V28.8087V26.6639C8.01965 25.1926 6.824 24.0002 5.34907 24.0002L2.67058 23.9999C1.19565 23.9999 0 25.1926 0 26.6639V29.3359C0 30.8072 1.19565 31.9999 2.67058 31.9999H4.81263L4.81184 32.0002C6.58028 32.0002 8.01411 33.4276 8.01965 35.1902V37.3359C8.01965 38.8073 9.2153 40 10.69 40H29.4077C30.8827 40 32.0783 38.8073 32.0783 37.3359V35.1997C32.0786 33.4326 33.5148 31.9999 35.2864 31.9999H37.4274C38.9023 31.9999 40.098 30.8075 40.098 29.3362V26.6642C40.098 25.1929 38.9023 24.0002 37.4274 24.0002H35.2859C33.5145 23.9999 32.0786 22.5675 32.0783 20.8002V18.64C32.0783 17.1821 30.8935 16.0001 29.432 16.0001H11.2222L11.2275 15.9998C9.45664 15.9998 8.02096 14.5685 8.01965 12.8022L8.01991 11.2C8.01991 9.43274 9.45611 8.00005 11.2277 8.00005L28.8705 8.00031C30.6421 8.00031 32.0783 9.43274 32.0783 11.2V13.336C32.0783 14.8074 33.274 16.0001 34.7489 16.0001H37.4274Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.1818 10.4325C53.0016 9.648 52.2807 9.17385 51.2645 9.17385C50.4453 9.17385 49.7569 9.46822 49.7569 10.0076C49.7569 10.5146 50.2485 10.7921 51.0023 10.9556L51.9527 11.1517C53.5915 11.5115 54.9845 11.9693 54.9845 13.5384C54.9845 15.2384 53.3786 16.1704 51.4776 16.1704C49.3638 16.1704 48.0037 14.9769 47.8398 13.5221L49.6096 13.2606C49.7569 14.1272 50.3797 14.7971 51.5432 14.7971C52.5429 14.7971 53.1981 14.4376 53.1981 13.7836C53.1981 13.13 52.4936 12.9011 51.6579 12.721L50.5926 12.4924C49.1836 12.198 48.0364 11.6258 48.0364 10.1219C48.0364 8.68367 49.5603 7.80082 51.3957 7.80082C53.0835 7.80082 54.5748 8.63452 54.886 10.2039L53.1818 10.4325Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.1793 7.04886H58.7863L60.1957 5.11994H62.3425L60.1793 7.04886ZM57.8033 11.1845H61.9818C61.9327 9.9584 61.0477 9.19014 59.9498 9.19014C58.8849 9.19014 57.8686 9.79492 57.8033 11.1845ZM55.9679 11.9856C55.9679 9.51737 57.5575 7.80082 59.9335 7.80082C62.49 7.80082 63.981 9.74603 63.8337 12.5249H57.8033C57.836 13.8491 58.721 14.7154 59.9498 14.7154C61.097 14.7154 61.6706 14.176 61.9491 13.4567L63.7025 13.6856C63.3255 14.8789 62.1622 16.1704 59.9828 16.1704C57.3117 16.1704 55.9679 14.3232 55.9679 11.9856Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.1114 8.96132C67.5703 8.24222 68.4388 7.80092 69.5859 7.80092C70.6185 7.80092 71.3884 8.14418 71.8473 8.79784C72.1914 9.30484 72.3716 9.99136 72.3716 11.0211V15.9742H70.5529V11.4787C70.5529 10.6287 70.4707 10.0402 70.1102 9.68069C69.881 9.45177 69.504 9.30484 69.0123 9.30484C67.6196 9.30484 67.1114 10.3509 67.1114 11.8222V15.9742H65.2926V7.96414H67.1114V8.96132Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.519 5.64308H76.3378V7.96414H77.9766V9.35373H76.3378V13.5057C76.3378 14.3396 76.5343 14.5357 77.3045 14.5357H77.9603V15.9742H76.6326C74.8795 15.9742 74.519 15.5 74.519 13.8326V9.35373H73.3062V7.96414H74.519V5.64308Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.4353 15.9742H81.2541V7.96417H79.4353V15.9742ZM79.4353 6.95069H81.2541V5.11981H79.4353V6.95069Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.0386 10.4325C87.8581 9.648 87.1372 9.17385 86.1213 9.17385C85.3019 9.17385 84.6137 9.46822 84.6137 10.0076C84.6137 10.5146 85.1053 10.7921 85.8591 10.9556L86.8095 11.1517C88.4483 11.5115 89.841 11.9693 89.841 13.5384C89.841 15.2384 88.2351 16.1704 86.3342 16.1704C84.2203 16.1704 82.8602 14.9769 82.6966 13.5221L84.4661 13.2606C84.6137 14.1272 85.2363 14.7971 86.3998 14.7971C87.3994 14.7971 88.0549 14.4376 88.0549 13.7836C88.0549 13.13 87.3504 12.9011 86.5146 12.721L85.4494 12.4924C84.0401 12.198 82.8932 11.6258 82.8932 10.1219C82.8932 8.68367 84.4171 7.80082 86.2525 7.80082C87.9403 7.80082 89.4313 8.63452 89.7427 10.2039L88.0386 10.4325Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.2838 15.9742H93.1025V7.96417H91.2838V15.9742ZM91.2838 6.95069H93.1025V5.11981H91.2838V6.95069Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.887 10.4325C99.7065 9.648 98.9856 9.17385 97.9697 9.17385C97.1503 9.17385 96.4621 9.46822 96.4621 10.0076C96.4621 10.5146 96.9537 10.7921 97.7075 10.9556L98.6579 11.1517C100.297 11.5115 101.689 11.9693 101.689 13.5384C101.689 15.2384 100.084 16.1704 98.1825 16.1704C96.069 16.1704 94.7086 14.9769 94.545 13.5221L96.3145 13.2606C96.4621 14.1272 97.0846 14.7971 98.2482 14.7971C99.2478 14.7971 99.9033 14.4376 99.9033 13.7836C99.9033 13.13 99.1988 12.9011 98.363 12.721L97.2978 12.4924C95.8885 12.198 94.7416 11.6258 94.7416 10.1219C94.7416 8.68367 96.2655 7.80082 98.1009 7.80082C99.7887 7.80082 101.28 8.63452 101.591 10.2039L99.887 10.4325Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.413 31.9867H50.232V23.9766H48.413V31.9867ZM48.413 22.9634H50.232V21.1325H48.413V22.9634Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.0667 24.974C54.5254 24.2549 55.394 23.8136 56.5409 23.8136C57.5735 23.8136 58.3436 24.1568 58.8023 24.8105C59.1464 25.3175 59.3267 26.004 59.3267 27.0338V31.9868H57.5079V27.4914C57.5079 26.6414 57.426 26.0529 57.0653 25.6934C56.836 25.4644 56.4593 25.3175 55.9674 25.3175C54.5746 25.3175 54.0667 26.3636 54.0667 27.8349V31.9868H52.2476V23.9768H54.0667V24.974Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.4741 21.6558H63.2931V23.9768H64.9316V25.3664H63.2931V29.5183C63.2931 30.3523 63.4896 30.5484 64.2598 30.5484H64.9153V31.9869H63.5879C61.8348 31.9869 61.4741 31.5127 61.4741 29.8453V25.3664H60.2616V23.9768H61.4741V21.6558Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.6849 27.1973H71.8633C71.8143 25.9712 70.9293 25.2029 69.8314 25.2029C68.7662 25.2029 67.7505 25.8077 67.6849 27.1973ZM65.8495 27.9984C65.8495 25.5299 67.4391 23.8136 69.8151 23.8136C72.3716 23.8136 73.8626 25.7585 73.7153 28.5377H67.6849C67.7175 29.8619 68.6023 30.7281 69.8314 30.7281C70.9786 30.7281 71.5522 30.1888 71.8307 29.4695L73.5841 29.6984C73.2073 30.8916 72.0438 32.1832 69.8641 32.1832C67.1932 32.1832 65.8495 30.3357 65.8495 27.9984Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.1744 31.9867H76.9932V21.1325H75.1744V31.9867Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.009 31.9867H80.8278V21.1325H79.009V31.9867Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.8439 31.9867H84.6626V23.9766H82.8439V31.9867ZM82.8439 22.9634H84.6626V21.1325H82.8439V22.9634Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.9874 27.8184C91.9874 26.1836 91.1682 25.2684 89.9554 25.2684C88.6937 25.2684 88.0055 26.3145 88.0055 27.8347C88.0055 29.4693 88.6937 30.4013 89.9554 30.4013C91.1516 30.4013 91.9874 29.5839 91.9874 27.8184ZM91.9054 30.7445C91.3811 31.4636 90.6602 31.856 89.5784 31.856C87.5794 31.856 86.1374 30.3195 86.1374 27.8184C86.1374 25.3828 87.6614 23.8137 89.7096 23.8137C90.7585 23.8137 91.4794 24.2547 91.922 24.8434L91.9547 23.9769H93.7244V31.1041C93.7244 32.5917 93.413 33.4091 92.8231 33.9647C92.1842 34.5861 91.2009 34.8802 89.972 34.8802C88.1693 34.8802 86.7437 34.1611 86.4652 32.3628L88.2676 32.2973C88.4315 33.0658 88.9397 33.4417 90.021 33.4417C90.611 33.4417 91.1026 33.3108 91.4304 33.0167C91.7252 32.7389 91.9054 32.3302 91.9054 31.4636V30.7445Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.0353 27.1973H101.214C101.165 25.9712 100.28 25.2029 99.1818 25.2029C98.1166 25.2029 97.1009 25.8077 97.0353 27.1973ZM95.1999 27.9984C95.1999 25.5299 96.7895 23.8136 99.1655 23.8136C101.722 23.8136 103.213 25.7585 103.066 28.5377H97.0353C97.068 29.8619 97.9527 30.7281 99.1818 30.7281C100.329 30.7281 100.903 30.1888 101.181 29.4695L102.935 29.6984C102.558 30.8916 101.394 32.1832 99.2145 32.1832C96.5437 32.1832 95.1999 30.3357 95.1999 27.9984Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.343 24.974C106.802 24.2549 107.671 23.8136 108.818 23.8136C109.85 23.8136 110.62 24.1568 111.079 24.8105C111.423 25.3175 111.604 26.004 111.604 27.0338V31.9868H109.785V27.4914C109.785 26.6414 109.703 26.0529 109.342 25.6934C109.113 25.4644 108.736 25.3175 108.244 25.3175C106.851 25.3175 106.343 26.3636 106.343 27.8349V31.9868H104.525V23.9768H106.343V24.974Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.535 26.5597L118.782 26.8049C118.618 25.9386 117.962 25.2684 116.881 25.2684C115.701 25.2684 114.816 26.1676 114.816 27.9819C114.816 29.8128 115.717 30.7279 116.881 30.7279C117.798 30.7279 118.601 30.2704 118.831 29.1751L120.601 29.4041C120.191 31.1861 118.651 32.1832 116.848 32.1832C114.374 32.1832 112.948 30.4338 112.948 27.9984C112.948 25.5625 114.406 23.8137 116.93 23.8137C118.749 23.8137 120.191 24.876 120.535 26.5597Z"
      fill="#1D2227"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.305 27.1973H127.483C127.434 25.9712 126.549 25.2029 125.451 25.2029C124.386 25.2029 123.37 25.8077 123.305 27.1973ZM121.469 27.9984C121.469 25.5299 123.059 23.8136 125.435 23.8136C127.992 23.8136 129.483 25.7585 129.335 28.5377H123.305C123.338 29.8619 124.222 30.7281 125.451 30.7281C126.599 30.7281 127.172 30.1888 127.451 29.4695L129.204 29.6984C128.827 30.8916 127.664 32.1832 125.484 32.1832C122.813 32.1832 121.469 30.3357 121.469 27.9984Z"
      fill="#1D2227"
    />
  </svg>
)

export default memo(LogoSentisis)
