// Libs
import * as React from 'react'
import { useLazyQuery } from '@apollo/client'

// Queries
import { get } from 'lodash/fp'
import Spinner from '@components/Spinner'
import { ViewerQuery } from '@customTypes/index'
import { ViewerQueryVariables } from '@customTypes/index'
import VIEWER_QUERY from '../queries/ViewerQuery.graphql'
import { useState } from 'react'

interface ProviderState {
  viewer?: any
  user?: any
}

export interface AuthStore {
  context: ProviderState
  getPermits: (project: any) => any
}

const UploadContext = React.createContext({} as AuthStore)

type AuthProviderProps = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, setState] = useState<ProviderState>(null)

  const [loadData, { called, data, loading }] = useLazyQuery<
    ViewerQuery,
    ViewerQueryVariables
  >(VIEWER_QUERY, {
    variables: {
      withUser: true,
    },
  })

  React.useEffect(() => {
    loadData()

    if (data) {
      setState(prevState => ({
        ...prevState,
        viewer: data.viewer,
        user: data.viewer.user,
      }))
    }
  }, [data, loadData])

  const getPermits = (project: any) => {
    const { user } = state
    let sentimentRead = (user && user.role.sentimentRead) || false
    let sentimentUpdate = (user && user.role.sentimentUpdate) || false
    let categoriesRead = (user && user.role.categoryRead) || false
    let categoriesUpdate = (user && user.role.categoryUpdate) || false

    if (get('sharingConfig', project)) {
      const {
        sentimentRead: projectSentimentRead,
        sentimentUpdate: projectSentimentUpdate,
        categoryUpdate: projectCategoryUpdate,
        categoryRead: projectCategoryRead,
      } = project.sharingConfig

      sentimentRead = sentimentRead && projectSentimentRead
      sentimentUpdate = sentimentUpdate && projectSentimentUpdate
      categoriesRead = categoriesRead && projectCategoryRead
      categoriesUpdate = categoriesUpdate && projectCategoryUpdate
    }
    return { categoriesUpdate, sentimentUpdate, sentimentRead, categoriesRead }
  }

  // //
  // Private functions
  // //

  if (called && loading) {
    return <Spinner />
  }

  return (
    <UploadContext.Provider
      value={{
        context: state,
        getPermits,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}

export default React.memo(AuthProvider)

export const AuthContextConsumer = UploadContext.Consumer

export const AuthContext = UploadContext
