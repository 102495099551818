import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_DATASET_TAGS } from 'apollo/queries/dataset'
import { normalizeDatasetTags } from 'models/Dataset'

type Params = {
  datasetId: string
  filters?: any
  order?: any
  first?: number
  after?: number
}

const useDatasetTags = ({
  datasetId,
  filters,
  order,
  first,
  after = 0,
}: Params) => {
  const { data, loading: datasetTagsIsLoading } = useQuery(GET_DATASET_TAGS, {
    variables: {
      datasetId,
      filters,
      order,
      first,
      after,
    },
  })

  const datasetTags = useMemo(() => {
    const tagsData = data?.getDataseTags

    if (!tagsData) {
      return {
        total: 0,
        tags: [],
      }
    }

    return normalizeDatasetTags(tagsData)
  }, [data])

  return {
    datasetTags,
    datasetTagsIsLoading,
  }
}

export default useDatasetTags
