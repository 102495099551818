import styled from 'styled-components/macro'
import DefaultModal, {
  Content as ModalContent,
  Buttons as ModalButtons,
  InnerContent as ModalInnerContent,
} from '@components/Modal/Modal'
import IconDefault from '@components/Icon'

export const Container = styled(DefaultModal)`
  ${ModalContent} {
    max-width: 800px;
  }
`

export const MarkdownContainer = styled.div`
  // Revert bulma css styles
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
    margin: revert;
  }
  ul {
    list-style: initial;
    padding: revert;
  }
  li {
    padding-bottom: 8px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CloseIcon = styled(IconDefault).attrs({
  name: 'close',
})`
  color: ${({ theme }) => theme.colours.gray[70]};
  &:hover {
    cursor: pointer;
  }
`

export const Title = styled.h1`
  font-size: 22px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 24px;
`

export const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
`

export const Content = styled(ModalInnerContent)<{ $hasMarkdown: boolean }>`
  margin-top: 0;
  max-height: 50vh;
  overflow-y: auto;
  padding-right: ${({ $hasMarkdown }) => ($hasMarkdown ? '16px' : null)};
`

export const Buttons = styled(ModalButtons)``

export const OpenAiDesc = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colours.gray[70]};
  margin-top: 24px;
`

export const ProgressContainer = styled.div`
  padding-top: 24px;
`
