import styled from 'styled-components/macro'

export const WrapperDefault = `
opacity: 0;
max-height: 0px;
transition: opacity 50ms linear, max-height 300ms ease-out 30ms;
overflow: hidden;
`

export const WrapperActive = `
max-height: 500px;
opacity: 1;
transition: max-height 300ms ease-out 50ms, opacity 250ms linear 100ms ;
`

export const Wrapper = styled.div<{ active: boolean }>`
  position: relative;
  isolation: isolate;
  z-index: 1;
  ${props => (props.active ? WrapperActive : WrapperDefault)}
`

export const ToggleFiltersBar = styled.div<{ active: boolean }>`
  height: 25px;
  background: white;
  cursor: pointer;
  user-select: none;
  display: grid;
  place-content: center;
  position: relative;
  ${props => props.active && filterBarActive}
  border-top: 1px solid #eef2f5;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.04);
`

export const FiltersButton = styled.div`
  width: 25px;
  height: 25px;
  pointer-events: none;
  transform: rotate(270deg);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SearchLabel = styled.div`
  position: absolute;
  left: 25px;
  height: 25px;
  font-size: 14px;
  padding-top: 3px;
  opacity: 1;
  transition: opacity 300ms 400ms;
  color: var(--theme-ui-colors-gray-50);
  svg {
    top: 3px;
    margin-right: 18px;
    position: relative;
    height: 14px;
    fill: currentColor;
  }
`

export const filterBarActive = `
${FiltersButton} {
  transform:  rotate(90deg)
};
${SearchLabel}{
  transition: none;
    opacity: 0;
  }
`
export const MagicSearchWrapper = styled.div`
  background-color: white;
`
