import WebSocket from '@utils/websocket'
import { GET_TEAMS } from 'apollo/queries/team'
import { FetchPolicy } from '@apollo/client'
import { normalizeClient } from 'models/Client'
import type { AllTeamsQuery } from '@customTypes/graphql-public'

const getTeams = async ({
  after,
  clientId,
  clientName,
  fetchPolicy = 'cache-first',
  first,
  name,
}: {
  after?: number
  clientId?: string
  clientName?: string
  fetchPolicy?: FetchPolicy
  first?: number
  name?: string
} = {}) => {
  const apolloClient = WebSocket.createApolloClient('graphql')
  // @ts-ignore
  const { data } = await apolloClient.query<AllTeamsQuery>({
    errorPolicy: 'all',
    fetchPolicy,
    query: GET_TEAMS,
    variables: {
      after,
      clientId,
      clientName,
      first,
      name,
    },
  })

  const response = data?.allTeams

  if (!response) {
    return []
  }

  return response.map(normalizeClient)
}

export default getTeams
