import WebSocket from '@utils/websocket'
import { FetchPolicy } from '@apollo/client'
import { GET_DATASET_INSIGHTS } from '../queries/dataset'
import type { SummaryInsightsInput } from 'models/Dataset'

const getDatasetInsights = async ({
  fetchPolicy = 'network-only',
  input,
}: {
  fetchPolicy?: FetchPolicy
  input: SummaryInsightsInput
}) => {
  const apolloClient = WebSocket.createApolloClient('graphql')
  const { data, errors } = await apolloClient.query({
    errorPolicy: 'all',
    fetchPolicy,
    query: GET_DATASET_INSIGHTS,
    variables: {
      input,
    },
  })

  if (errors && errors[0]) {
    throw new Error(errors[0]?.message)
  }

  if (!data?.getSummaryInsights) {
    return ''
  }

  return data.getSummaryInsights
}

export default getDatasetInsights
