import { gql } from '@apollo/client'

export const CUSTOM_CATEGORY_FRAGMENT = gql`
  fragment CustomCategoryData on CustomCategory {
    id
    name
    filters {
      id
      words
      operator
    }
    description
  }
`
