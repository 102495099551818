import { FC, memo } from 'react'
import type { Props } from '../../types'

const Reports: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6875 0.5H3.875C3.11561 0.5 2.5 1.17157 2.5 2V14C2.5 14.8284 3.11561 15.5 3.875 15.5H12.125C12.8844 15.5 13.5 14.8284 13.5 14V5.75L8.6875 0.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6875 0.5V5.75H13.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(Reports)
