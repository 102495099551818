/**
 * Get the evolution
 *
 * @param {number} current  - Current value
 * @param {number} prev     - Previous value
 * @return {number} Evolution
 */
export function getEvolution(current, prev) {
  if (prev === current) return 0
  if (prev === 0) return 100
  if (!prev) return 0

  // Handle negative numbers evolution
  // ((300 - -50) / -50) * 100 is not the same as ((-50 - 300) / -50) * 100
  if (prev < 0) {
    return Math.round(((prev - current) / prev) * 100)
  }

  const partial = ((current - prev) / prev) * 100

  return Math.abs(partial) < 1
    ? Number(partial).toFixed(2)
    : Math.round(partial)
}
