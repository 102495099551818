import * as React from 'react';
import { ApolloConsumer } from '@apollo/client'
import { ApolloClient } from '@apollo/client'

// Web reference resource:
// https://www.pluralsight.com/guides/composing-react-components-with-typescript

type WithClient = {
  client?: ApolloClient<any>
}
export function withApolloClient<Props extends WithClient>(
  WrappedComponent: React.ComponentType<Props>,
) {
  return class extends React.Component<Props> {
    render() {
      return (
        <ApolloConsumer>
          {client => <WrappedComponent client={client} {...this.props} />}
        </ApolloConsumer>
      )
    }
  }
}
