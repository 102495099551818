import { complement, isArray, isEmpty, map } from 'lodash/fp'

type Valorations = {
  PROMOTORS: number
  PASIVES: number
  DETRACTORS: number
}

interface NpsValoration {
  valorations: Valorations
  total: number
  average: number
}

// Row {name, id}
// transformDataToOptions :: Array(Row) -> Array({ label, value})
export const transformDataToOptions = map((row: any) => ({
  label: row.name,
  value: row.id,
}))

export const calculateNpsValorations = (nps: any): NpsValoration => {
  const valorations: Valorations = { DETRACTORS: 0, PASIVES: 0, PROMOTORS: 0 }
  let total = 0
  let average = 0
  nps.forEach((g: any) => {
    total += g.count
    average += g.nps * g.count
    if (g.nps <= 6) {
      valorations.DETRACTORS += g.count
    } else if (g.nps <= 8) {
      valorations.PASIVES += g.count
    } else {
      valorations.PROMOTORS += g.count
    }
  })
  return {
    valorations,
    total,
    average: average / total,
  }
}
// wrap :: ( a | [a]) -> [a]
export const wrap = elto => (isArray(elto) ? elto : [elto])

export const notEmpty = complement(isEmpty)

export const random = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min
