import { FC, memo } from 'react'
import type { Props } from '../types'

const AlertIcon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 18 19"
    {...rest}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path
        fill="#FFD166"
        d="M9.884 1.67l7.339 13.862A1 1 0 0 1 16.339 17H1.661a1 1 0 0 1-.884-1.468L8.117 1.67a1 1 0 0 1 1.767 0z"
      />
      <text
        fill="#FFF"
        fontFamily="SourceSansPro-SemiBold, Source Sans Pro SemiBold"
        fontSize="14"
        fontWeight="500"
        transform="translate(0 1)"
      >
        <tspan x="6.795" y="14">
          !
        </tspan>
      </text>
    </g>
  </svg>
)

export default memo(AlertIcon)
