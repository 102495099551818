import * as React from 'react'
import ButtonDropdown from '@components/ButtonDropdown/ButtonDropdown'
import { isEmpty } from 'lodash/fp'
import {
  EditIcon,
  TagButton,
  TagContainer,
  TagOperator,
  Text,
  X,
} from './Tag.styles'

interface Props {
  className?: string
  color: string
  operator?: string
  children: React.ReactNode
  // Fired when clicking the button on the tag
  onClickButton?: () => void
  onClickTag?: () => void
  crossClick?: () => void
  editable?: boolean
  options?: {
    label: string
    onClick?: () => void
    children?: React.ReactNode
  }[]
  clearable?: boolean
  onEdit?: () => void
  persistentButton?: boolean
  applyEllipsis?: boolean
}

interface State {
  isOver: boolean
  isClicked: boolean
}

class Tag extends React.Component<Props, State> {
  state = {
    isOver: false,
    isClicked: false,
  }
  // wrapperRef: any
  wrapperRef = React.createRef<any>()
  textRef = React.createRef<HTMLElement>()

  handleButtonClick = () => {
    this.props.onClickButton()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: MouseEvent) => {
    if (!isEmpty(this.wrapperRef.current)) {
      if (!this.wrapperRef.current.contains(event.target)) {
        this.setState({ isOver: false, isClicked: false })
      }
    }
  }

  handleIsOver = () =>
    this.setState({
      isOver: !this.state.isOver,
    })

  handleClick = () => {
    this.setState({ isClicked: !this.state.isClicked })
  }

  get clearButton() {
    return (
      <TagButton onClick={this.props.crossClick} color={this.props.color}>
        <X>x</X>
      </TagButton>
    )
  }

  render() {
    const {
      children,
      className,
      color,
      operator,
      editable,
      options = [],
      clearable = true,
      persistentButton,
      applyEllipsis,
    } = this.props

    const element = this.textRef?.current || { offsetWidth: 0, scrollWidth: 0 }
    const hasEllipsis =
      applyEllipsis && element.offsetWidth < element.scrollWidth
    const tooltip = hasEllipsis ? children : null

    let tag = (
      <>
        {operator ? <TagOperator color={color}>{operator}</TagOperator> : null}
        <Text
          onClick={this.props.onClickTag}
          title={tooltip}
          ref={this.textRef}
          $applyEllipsis={applyEllipsis}
        >
          {children}
        </Text>
        {clearable && (this.state.isOver || persistentButton)
          ? this.clearButton
          : null}
      </>
    )

    if (editable)
      tag = (
        <>
          {this.state.isOver || this.state.isClicked || persistentButton ? (
            <ButtonDropdown options={options} divider>
              {tag}
              {clearable ? null : (
                <TagButton color={this.props.color} onClick={this.handleClick}>
                  <EditIcon />
                </TagButton>
              )}
            </ButtonDropdown>
          ) : (
            tag
          )}
        </>
      )

    return (
      <TagContainer
        onMouseEnter={this.handleIsOver}
        onMouseLeave={this.handleIsOver}
        ref={this.wrapperRef}
        className={className}
        color={color}
      >
        {tag}
      </TagContainer>
    )
  }
}

export default Tag
