import * as React from 'react';
import classNames from 'classnames'
import { ReactComponent as InfoActive } from '@components/IconsToRefactor/info-active.svg'
import { ReactComponent as Info } from '@components/IconsToRefactor/info.svg'
import './InfoTooltip.scss'
import { trackEvent } from '@utils/metrics'
import ClickOutside from '@components/ClickOutsideWrapper'

interface Props {
  tooltip: React.ReactElement<any>
  children?: React.ReactNode

  className?: string

  // Aligns the tooltip on the right
  right?: boolean
}

class InfoTooltip extends React.PureComponent<
  Props,
  { isTooltipDisplayed: boolean }
> {
  state = {
    isTooltipDisplayed: false,
  }

  // Used to close on click outside of Modal
  node: any = React.createRef()

  handleClickInfoIcon = () => {
    trackEvent('User clicks on an info tooltip')
    return this.setState(state => ({
      isTooltipDisplayed: !state.isTooltipDisplayed,
    }))
  }

  handleClickOutside = () => {
    if (this.state.isTooltipDisplayed) {
      this.setState({ isTooltipDisplayed: false })
    }
  }

  render() {
    const tooltipClassName = classNames('c-InfoTooltip', this.props.className, {
      'c-InfoTooltip--is-visible': this.state.isTooltipDisplayed,
      'c-InfoTooltip--is-element': this.props.children,
    })
    const activeIcon = this.state.isTooltipDisplayed ? <InfoActive /> : <Info />

    // If a children is passed, wrap it. Info Icon used otherwise.
    const content = this.props.children || activeIcon

    let tooltipEl
    if (this.state.isTooltipDisplayed) {
      tooltipEl = React.cloneElement(this.props.tooltip, {
        right: this.props.right,
      })
    }

    const infoIcon = (
      <ClickOutside onClick={this.handleClickOutside}>
        <button onClick={this.handleClickInfoIcon} className={tooltipClassName}>
          {content}
          {tooltipEl}
        </button>
      </ClickOutside>
    )

    return infoIcon
  }
}

export default InfoTooltip
