export enum ValidationFormat {
  FILE_FORMAT,
  DELIMITER,
  UNICODE,
  SAME_COLUMNS,
  DEFAULT,
  ONE_FILE
}

export const CheckList = [
  {
    key: ValidationFormat.FILE_FORMAT,
    text: 'Archivo CSV',
    hasError: false,
  },
  {
    key: ValidationFormat.DELIMITER,
    text: 'Textos separados por una coma o punto y coma',
    hasError: false,
  },
  {
    key: ValidationFormat.UNICODE,
    text: 'Unicode codificado como UTF-8',
    hasError: false,
  },
  {
    key: ValidationFormat.SAME_COLUMNS,
    text: 'Tener las mismas columnas para analizar',
    hasError: false,
  },
]
