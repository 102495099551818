import { FC, memo } from 'react'
import type { Props } from '../types'

const ClipboardFilled: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1 5.33337H9C9.55228 5.33337 10 5.78109 10 6.33337V14.3334C10 14.8857 9.55228 15.3334 9 15.3334H1C0.447715 15.3334 0 14.8857 0 14.3334V6.33337C0 5.78109 0.447715 5.33337 1 5.33337Z"
      fill="currentColor"
    />
    <path
      d="M14 1H6C5.44772 1 5 1.44772 5 2V4.33334H9C10.1046 4.33334 11 5.22877 11 6.33334V11H14C14.5523 11 15 10.5523 15 10V2C15 1.44772 14.5523 1 14 1Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ClipboardFilled)
