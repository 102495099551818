import { FC, memo } from 'react'
import SelectController from '@components/FormControllers/SelectController/SelectController'
import InputController from '@components/FormControllers/InputController/InputController'
import Button from '@components/Button/Button'
import * as S from './styles'
import useLogic from './logic'

const CreateProjectModal: FC = () => {
  const {
    clientsOptions,
    createProjectForm,
    handleOnSubmit,
    isLoading,
    isOpen,
    onCloseModal,
    teamsOptions,
    user,
  } = useLogic()

  const isUserClientManagement = !!user?.role?.clientManagement
  const hasTeamOptions = teamsOptions?.length > 0

  return (
    <S.Container open={isOpen} onClose={onCloseModal}>
      <S.Title>Crear proyecto</S.Title>

      <S.Content>
        <S.Form onSubmit={createProjectForm.handleSubmit(handleOnSubmit)}>
          <S.Row>
            <SelectController
              control={createProjectForm.control}
              disabled={!isUserClientManagement}
              label="Cliente"
              name="clientId"
              options={clientsOptions}
              placeholder="Selecciona una opción"
              isFixedOptions={true}
            />

            <SelectController
              control={createProjectForm.control}
              disabled={!isUserClientManagement || !hasTeamOptions}
              label="Equipo"
              name="teamId"
              options={teamsOptions}
              placeholder="Selecciona una opción"
              isFixedOptions={true}
            />
          </S.Row>

          <S.Row>
            <InputController
              name="name"
              control={createProjectForm.control}
              label="Nombre del proyecto"
              placeholder="Nombre del proyecto"
              fullWidth
            />

            <InputController
              name="description"
              control={createProjectForm.control}
              label="Descripción"
              placeholder="Descripción del proyecto"
              fullWidth
            />
          </S.Row>

          <S.Buttons>
            <Button variant="secondary" onClick={onCloseModal}>
              Cancelar
            </Button>

            <Button
              type="submit"
              disabled={isLoading || !createProjectForm.formState.isValid}
            >
              Crear
            </Button>
          </S.Buttons>
        </S.Form>
      </S.Content>
    </S.Container>
  )
}

export default memo(CreateProjectModal)
