import { FiltersContextConsumer } from '@providers/FiltersProvider'
import './BarWithFilters.scss'

import DatePickerHeader from '@components/DatePickerHeader'
import { AdvancedFilters } from '@components/AdvanceFilters/AdvancedFilters'
import { useRouter } from '@hooks/useRouter'
import * as S from './styles'

function BarWithFilters() {
  const router = useRouter()
  const isSummary = router.pathname.split('/').includes('summary')
  const isKeyconcepts = router.pathname.split('/').includes('keyconcepts')

  return (
    <FiltersContextConsumer>
      {context => {
        const { filtersState, handleChangeDate, handleClickOpenFilters } =
          context
        const { dateRange, datasetDateRange } = filtersState
        const withDate = datasetDateRange && datasetDateRange.start
        return (
          <S.DatePickerContainer>
            {withDate && (
              <DatePickerHeader
                closeOpenFilters={() => handleClickOpenFilters(false, '')}
                handleChangeDate={handleChangeDate}
                dateRange={dateRange}
                datasetDateRange={datasetDateRange}
              />
            )}
            {!isSummary && !isKeyconcepts && (
              <AdvancedFilters className="c-FiltersBar-Select" />
            )}
          </S.DatePickerContainer>
        )
      }}
    </FiltersContextConsumer>
  )
}

export default BarWithFilters
