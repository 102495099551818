import styled from 'styled-components'
import { Menu as MUIMenu, MenuItem as MUIMenuItem } from 'react-pro-sidebar'
import { Divider } from '@material-ui/core'
import DefaultIcon from '@components/Icon'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const LoadingWrapper = styled.div`
  display: flex;
  min-width: 159px;
  justify-content: left;
  align-items: left;
  height: 100%;
  width: 100%;

  & > svg {
    max-width: 50px;
  }
`

export const Icon = styled(DefaultIcon)<{ $isActive?: boolean }>`
  display: flex;
  justify-content: left;
  align-items: left;
  width: 30px;
  height: 30px;
  padding-left: 9px;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colours.violet[70] : theme.colours.gray[90]};
  background-color: none;
`

export const CXIcon = styled(Icon)`
  margin-left: 5px;
`

export const Title = styled.h3`
  font-size: 14px;
  color: ${({ theme }) => theme.colours.slate[100]};
  font-weight: 400;
  text-align: left;
  margin-bottom: -4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`

export const SubTitle = styled.h6`
  font-size: 14px;
  color: ${({ theme }) => theme.colours.black};
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`

export const ToogleIcon = styled.span`
  position: absolute;
  bottom: 50%;
  right: -23px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`

export const Menu = styled(MUIMenu)`
  padding-top: 10px;
`

export const MenuItem = styled(MUIMenuItem)`
  &:hover {
    & > a {
      background-color: ${({ theme }) => theme.colours.gray[10]} !important;
    }
  }

  &.ps-active > a {
    background-color: #f3f3f3 !important;
  }
`

export const HeaderMenuItemContainer = styled.div`
  padding-left: 4px;
`

export const SeparatorLine = styled(Divider)`
  && {
    margin: 16px;
    background-color: ${({ theme }) => theme.colours.gray[30]};
    width: 90%;
    height: 0.5px;
  }
`
