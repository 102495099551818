import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import {
  CREATE_DATASET,
  DELETE_DATASET,
  UPDATE_DATASET,
} from 'apollo/mutations/dataset'
import { UNSELECTED_VALUE } from '@views/NewDataset/constants'
import {
  CreateDatasetMutationVariables,
  DatasetAttributeType,
  DatasetFormFields,
} from 'models/Dataset'
import type {
  CreateDatasetMutation,
  UpdateDatasetMutation,
  UpdateDatasetMutationVariables,
  DeleteDatasetMutation,
  DeleteDatasetMutationVariables,
} from '@customTypes/graphql-public'

const useDatasetActions = () => {
  // create
  const [
    createDatasetMutation,
    { loading: createLoading, error: createError },
  ] = useMutation<CreateDatasetMutation, CreateDatasetMutationVariables>(
    CREATE_DATASET,
  )
  const [
    updateDatasetMutation,
    { loading: updateLoading, error: updateError },
  ] = useMutation<UpdateDatasetMutation, UpdateDatasetMutationVariables>(
    UPDATE_DATASET,
  )
  const [
    deleteDatasetMutation,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation<DeleteDatasetMutation, DeleteDatasetMutationVariables>(
    DELETE_DATASET,
  )

  const normalizeDatasetInput = (
    formData: DatasetFormFields,
    projectId: string,
  ) => {
    const {
      analysis,
      benchmark,
      country,
      customFilters,
      datasetName,
      dateColumn,
      dateFormat,
      delimiter,
      locationColumn,
      npsColumn,
      urlColumn,
    } = formData

    const newDatasetVariables: CreateDatasetMutationVariables = {
      projectId,
      name: datasetName,
      contentColumnsKeys: [],
      delimiter,
      attributes: [],
    }

    // TODO UNSELECTED_VALUE is temporary fix to solve empty state from sentisis-components select
    if (
      locationColumn?.value &&
      country?.value &&
      locationColumn?.value !== UNSELECTED_VALUE
    ) {
      newDatasetVariables.location = {
        columnKey: locationColumn.value,
        country: country.value,
      }
    }

    if (dateColumn && dateFormat && dateColumn?.value !== UNSELECTED_VALUE) {
      newDatasetVariables.date = {
        columnKey: dateColumn.value,
        format: dateFormat.value,
      }
    }

    if (npsColumn && npsColumn?.value !== UNSELECTED_VALUE) {
      newDatasetVariables.attributes.push({
        key: npsColumn.value,
        value: 'NPS',
      })
    }

    if (urlColumn && urlColumn?.value !== UNSELECTED_VALUE) {
      newDatasetVariables.attributes.push({
        key: urlColumn.value,
        value: 'URL',
      })
    }

    if (
      benchmark &&
      benchmark.column?.value &&
      benchmark.column?.value !== UNSELECTED_VALUE
    ) {
      newDatasetVariables.attributes.push({
        key: benchmark.column?.value,
        value: benchmark.name,
        type: DatasetAttributeType.BENCHMARK_COLUMN,
      })
    }

    customFilters.forEach(field => {
      const name = field?.name || null
      const columnKey = field?.column?.value
      if (name && columnKey) {
        newDatasetVariables.attributes.push({
          key: columnKey.trim(),
          value: name.trim(),
        })
      }
    })

    analysis.forEach(field => {
      const columnKey = field?.column?.value || null
      if (columnKey) {
        newDatasetVariables.contentColumnsKeys.push(columnKey)
      }
    })

    return newDatasetVariables
  }

  const createDataset = useCallback(
    async (formData: DatasetFormFields, projectId: string): Promise<string> => {
      const data = normalizeDatasetInput(formData, projectId)
      const response = await createDatasetMutation({ variables: data })

      if (response?.data?.createDataset?.id) {
        return response.data.createDataset.id
      }
    },
    [createDatasetMutation],
  )

  const updateDataset = useCallback(
    async (id: string, name: string): Promise<string> => {
      const response = await updateDatasetMutation({ variables: { id, name } })

      if (response?.data?.updateDataset?.id) {
        return response.data.updateDataset.id
      }
    },
    [updateDatasetMutation],
  )

  const deleteDataset = useCallback(
    async (id: string): Promise<void> => {
      await deleteDatasetMutation({ variables: { id } })
    },
    [deleteDatasetMutation],
  )

  return {
    createDataset,
    deleteDataset,
    updateDataset,
    datasetActionsError: createError || updateError || deleteError,
    datasetActionsLoading: createLoading || updateLoading || deleteLoading,
  }
}

export default useDatasetActions
