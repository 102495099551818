import { FC, HTMLProps, Suspense } from 'react'
import { isUndefined } from 'lodash/fp'
import ErrorHandler from '@components/ErrorHandler/ErrorHandler'
import Spinner from '@components/Spinner'
import { Icon, Icons } from 'sentisis-components'
import FiltersResultsBar from '@components/FiltersResultsBar'
import FiltersSelect from './FiltersSelect'
import { Option } from './types'
import {
  FiltersButton,
  MagicSearchWrapper,
  SearchLabel,
  ToggleFiltersBar,
  Wrapper,
} from './styles'
import useLogic from './logic'
import { getFilterOptions } from './helpers'

export const AdvancedFilters: FC<HTMLProps<HTMLElement>> = () => {
  const {
    error,
    loading,
    filtersConfig,
    key,
    data,
    filtersCTX,
    filtersOpenCTX,
    isExpanded,
    handleChangeFilters,
    handleClickOpenFilters,
    mainFiltersQuery,
    handleToggleFilters,
    datasetFilterOptions,
    datasetFilterOptionsIsLoading,
  } = useLogic()

  if (error && !loading) {
    return <ErrorHandler error={error} />
  }

  if (isUndefined(filtersConfig)) {
    return <Spinner />
  }

  const filterOptions: Option[] = getFilterOptions(key, data)

  // Holds main config for FiltersSelect component
  const filtersSelectConfig = {
    context: {
      filters: filtersCTX,
      openState: filtersOpenCTX,
    },
    values: filterOptions,
    config: filtersConfig,
  }

  return (
    <Suspense fallback={Spinner}>
      <MagicSearchWrapper>
        <Wrapper active={isExpanded}>
          <FiltersSelect
            handleChangeFilters={handleChangeFilters}
            handleClickOpenFilters={handleClickOpenFilters}
            handleQueryOptions={mainFiltersQuery}
            options={filtersSelectConfig}
            myFilters={datasetFilterOptions}
            myFiltersLoading={datasetFilterOptionsIsLoading}
          />
          <FiltersResultsBar />
        </Wrapper>
        <ToggleFiltersBar onClick={handleToggleFilters} active={isExpanded}>
          <SearchLabel>
            <Icon icon={Icons.Search} />
            Magic Search
          </SearchLabel>

          <FiltersButton>
            <Icon icon={Icons.ExpandArrow} />
          </FiltersButton>
        </ToggleFiltersBar>
      </MagicSearchWrapper>
    </Suspense>
  )
}
