import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import Dropdown from '@components/Dropdown/Dropdown'
import Image from '@components/Image'
import HeaderLogo from '@components/HeaderLogo/HeaderLogo'
import { getClientDomain } from '@utils/url'
import * as S from './styles'
import type { Props } from './types'

export const Header: FC<Props> = ({ user, adminLink }) => {
  const history = useHistory()
  const [auth] = useState(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const hasDomain = getClientDomain()

  const handleUserData = useCallback(() => {
    const ls = window.localStorage
    if (ls) ls.setItem('userEmail', user.email)
  }, [user])

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const navigateTo = useCallback(
    (path: string) => () => {
      history.push(path)
    },
    [history],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  useEffect(() => {
    handleUserData()
  }, [handleUserData])

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'Mi cuenta',
        onClick: navigateTo('/profile'),
      },
      {
        label: 'Salir',
        onClick: navigateTo('/logout'),
      },
    ],
    [navigateTo],
  )

  return (
    <S.Wrapper>
      <Box sx={{ flexGrow: 1 }}>
        <S.Navbar position="static">
          <Toolbar>
            <HeaderLogo />
            {auth && (
              <>
                {user.client.logo && (
                  <img
                    src={user.client.logo}
                    alt="logo"
                    style={{ maxHeight: 30 }}
                  />
                )}

                {!!hasDomain && (
                  <Image name="logoSentisis" width={130} height={40} />
                )}

                {(user.client.logo || !!hasDomain) && !!adminLink && (
                  <S.Divider orientation="vertical" variant="middle" flexItem />
                )}

                {!!adminLink && (
                  <S.AdminButton
                    id="admin"
                    color="inherit"
                    variant="outlined"
                    size="small"
                    onClick={navigateTo('/admin')}
                  >
                    Admin
                  </S.AdminButton>
                )}

                {(user.client.logo || !!adminLink) && (
                  <S.Divider orientation="vertical" variant="middle" flexItem />
                )}

                <Dropdown
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={Boolean(anchorEl)}
                  options={dropdownOptions}
                >
                  <S.Button
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    endIcon={
                      <S.Icon
                        name={anchorEl ? 'chevronOpen' : 'chevronClosed'}
                        size={12}
                      />
                    }
                  >
                    {user?.client?.name}
                  </S.Button>
                </Dropdown>
              </>
            )}
          </Toolbar>
        </S.Navbar>
      </Box>
    </S.Wrapper>
  )
}
