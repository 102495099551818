import Spinner from '@components/Spinner'
import { FC } from 'react'
import { Chart } from '../ChartWrapper/Chart'
import EmptyMessage from '../EmptyMessage/EmptyMessage'
import useLogic from './WordCloudChart.logic'
import { WordCloudChartProps } from './WordCloudChart.types'

export const WordCloudChart: FC<WordCloudChartProps> = props => {
  const { wordCloudChartId, topTags, topTagsLoading, topTagsError } =
    useLogic(props)

  if (topTagsLoading) {
    return <Spinner />
  }
  if (topTags?.length === 0 || topTagsError) {
    return <EmptyMessage id={wordCloudChartId} />
  }

  return <Chart id={wordCloudChartId} height={260} />
}
