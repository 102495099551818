import { PureComponent } from 'react';
import classNames from 'classnames'
import Markdown from 'markdown'

import './FancyTooltip.scss'

interface Props {
  text: string
  title: string
  right: boolean
  className: string
}
class FancyTooltip extends PureComponent<Props, {}> {
  static defaultProps = {
    right: false,
    className: '',
  }

  tooltipRef = (el: HTMLDivElement) => {
    if (!el) return
    const margin = 15
    const bcr = el.getBoundingClientRect()
    if (bcr.right >= window.innerWidth - margin) {
      el.classList.add('is-left')
    }
  }

  render() {
    const html = { __html: Markdown.markdown.toHTML(this.props.text) }
    const className = classNames('c-FancyTooltip', this.props.className, {
      'is-right': this.props.right,
    })

    /* eslint-disable react/no-danger */
    return (
      <div className={className} ref={this.tooltipRef}>
        <div className="c-FancyTooltip__title">{this.props.title}</div>
        <div className="c-FancyTooltip__text" dangerouslySetInnerHTML={html} />
      </div>
    )
    /* eslint-enable */
  }
}

export default FancyTooltip
