import { CategoryUnionEntity } from '../../models/Category'

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const sortCategories = (list: CategoryUnionEntity[]) =>
  list.sort((a, b) => {
    if (a.renamed && !b.renamed) {
      return a.renamed > b.name ? 1 : b.name > a.renamed ? -1 : 0
    }

    if (b.renamed && !a.renamed) {
      return a.name > b.renamed ? 1 : b.renamed > a.name ? -1 : 0
    }

    if (b.renamed && a.renamed) {
      return a.renamed > b.renamed ? 1 : b.renamed > a.renamed ? -1 : 0
    }

    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  })

// Function to remove accents from a character
function removeAccents(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function orderListByField({
  list,
  field,
  order,
}: {
  list: any[]
  field: string
  order: Direction
}) {
  return list.slice().sort((a, b) => {
    const aValue =
      typeof a[field] === 'string'
        ? removeAccents(a[field].toUpperCase())
        : a[field]
    const bValue =
      typeof b[field] === 'string'
        ? removeAccents(b[field].toUpperCase())
        : b[field]

    if (aValue < bValue) return order === Direction.ASC ? -1 : 1
    if (aValue > bValue) return order === Direction.ASC ? 1 : -1
    return 0
  })
}
