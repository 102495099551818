import { FC, memo } from 'react'
import type { Props } from '../../types'

const Mention: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.9995 3.16343H14.4995V3.16347L14.9995 3.16343ZM15 10.1223H15.5V10.1222L15 10.1223ZM2 15L1.5 14.9999C1.49997 15.2114 1.63293 15.4 1.83207 15.471C2.03122 15.542 2.2535 15.48 2.38724 15.3163L2 15ZM2.00054 10.1223H2.50054V10.1222L2.00054 10.1223ZM2 3.16343H1.5V3.16347L2 3.16343ZM5.03375 11.2857V10.7857H4.79655L4.6465 10.9694L5.03375 11.2857ZM13.5551 2.5C14.1831 2.5 14.4995 2.89259 14.4995 3.16343H15.4995C15.4995 2.14918 14.5225 1.5 13.5551 1.5V2.5ZM14.4995 3.16347L14.5 10.1223L15.5 10.1222L15.4995 3.16339L14.4995 3.16347ZM14.5 10.1223C14.5 10.3931 14.1836 10.7857 13.5556 10.7857V11.7857C14.5231 11.7857 15.5 11.1365 15.5 10.1223H14.5ZM1.50054 10.1223C1.50054 10.4435 1.50041 11.6629 1.50027 12.802C1.5002 13.3716 1.50014 13.9211 1.50008 14.3282C1.50006 14.5317 1.50004 14.6996 1.50002 14.8167C1.50002 14.8752 1.50001 14.921 1.50001 14.9522C1.5 14.9678 1.5 14.9797 1.5 14.9878C1.5 14.9918 1.5 14.9948 1.5 14.9969C1.5 14.9979 1.5 14.9987 1.5 14.9992C1.5 14.9994 1.5 14.9996 1.5 14.9997C1.5 14.9998 1.5 14.9999 1.5 14.9999C1.5 14.9999 1.5 14.9999 2 15C2.5 15.0001 2.5 15 2.5 15C2.5 15 2.5 14.9999 2.5 14.9999C2.5 14.9997 2.5 14.9995 2.5 14.9993C2.5 14.9988 2.5 14.998 2.5 14.997C2.5 14.995 2.5 14.9919 2.5 14.9879C2.5 14.9799 2.5 14.9679 2.50001 14.9523C2.50001 14.9212 2.50002 14.8754 2.50002 14.8168C2.50004 14.6998 2.50006 14.5318 2.50008 14.3283C2.50014 13.9212 2.5002 13.3717 2.50027 12.8022C2.50041 11.6631 2.50054 10.4436 2.50054 10.1223H1.50054ZM1.5 3.16347L1.50054 10.1223L2.50054 10.1222L2.5 3.16339L1.5 3.16347ZM3.44438 1.5C2.47694 1.5 1.5 2.14918 1.5 3.16343H2.5C2.5 2.89259 2.8164 2.5 3.44438 2.5V1.5ZM13.5551 1.5H3.44438V2.5H13.5551V1.5ZM13.5556 10.7857H5.03375V11.7857H13.5556V10.7857ZM4.6465 10.9694L1.61276 14.6837L2.38724 15.3163L5.42099 11.602L4.6465 10.9694Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Mention)
