import { DatasetAttributeType } from 'models/Dataset'

export type FilterGroup = {
  name: string
  columnKey: string
  type?: DatasetAttributeType
  __typename?: string
}

export interface Option {
  value: string
  label: string
}

export interface FiltersOpen {
  firstLevel: boolean
  secondLevel: string
}

export type AdvancedFiltersRouteParams = {
  projectId: string
  datasetId: string
}

export enum FiltersColumnKeyEnum {
  category = 'category',
  sentiment = 'sentiment',
  content = 'content',
  keyConcepts = 'tag',
  location = 'location',
  contentFields = 'contentFields',
}

export type FiltersConfig = {
  documentFields: any
  filtersToShow: any
}

export type SplitFiltersMenu = {
  excludedKeys: FilterGroup[]
  myFilters: FilterGroup[]
  benchmarkFilter: FilterGroup
}
