import * as React from 'react'
import { toLower, mapKeys } from 'lodash/fp'

import '@components/Table/tableCss/SentimentRow.css'

import '@components/Table/tableCss/utils.scss'
import SimpleTooltip from '@components/Table/SimpleTooltip'
import { formatNumber } from '@utils/format'
import { FC } from 'react'

interface Sentiment {
  positive?: number
  negative?: number
  objective?: number
  unknown?: number
}

interface Props {
  sentiment: Sentiment

  // When true don't display the unknown sentiment
  hideUnknown?: boolean

  onClickSemtimentCell?: (sentimentKey: string) => void
  // When set it will be used to generate links to the mentions
  mentionsFilters?: any
  // It has the absolute number of mentions for each sentiment. Used for the hover
  rawSentiment: Sentiment | any

  withMentionFilter?: boolean
  sentimentOrder?: (keyof Sentiment)[]
}

const SentimentRow: FC<Props> = props => {
  const {
    sentiment,
    hideUnknown,
    onClickSemtimentCell,
    rawSentiment,
    sentimentOrder,
  } = props
  const order = sentimentOrder || [
    'positive',
    'negative',
    'objective',
    'unknown',
  ]
  const normalizedSentiment = mapKeys(toLower, sentiment)

  const content = order.map(sentimentKey => {
    if (sentimentKey === 'unknown' && hideUnknown) return null

    const rawValue = rawSentiment ? rawSentiment[sentimentKey] : null
    const value = normalizedSentiment[sentimentKey] || 0
    const handleOnClick = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault()
      e.stopPropagation()
      onClickSemtimentCell(sentimentKey.toUpperCase())
    }
    return (
      <span
        className={`SentimentRow__sentiment SentimentRow__sentiment--${sentimentKey}`}
        key={sentimentKey}
        onClick={handleOnClick}
      >
        {rawValue ? (
          <SimpleTooltip centered={false} text={formatNumber(rawValue)}>
            {value}%
          </SimpleTooltip>
        ) : (
          `${value}%`
        )}
      </span>
    )
  })

  return <span className="SentimentRow">{content}</span>
}

export default SentimentRow
