import { FC, memo } from 'react'
import * as S from './styles'
import useLogic from './logic'
import Button from '@components/Button/Button'

const RemoveDialogModal: FC = () => {
  const { handleOnCloseModal, isOpen, handleOnRemove, removeDialogPayload } =
    useLogic()

  return (
    <S.Container open={isOpen} onClose={handleOnCloseModal}>
      <S.Title>{removeDialogPayload?.title || 'Vas a eliminar'}</S.Title>
      <S.SubTitle>
        {removeDialogPayload?.description || '¿Seguro que quieres eliminar?'}
      </S.SubTitle>
      <S.Buttons>
        <Button isDanger variant="secondary" onClick={handleOnCloseModal}>
          {removeDialogPayload?.cancelButtonText || 'Cancelar'}
        </Button>
        <Button isDanger type="submit" onClick={handleOnRemove}>
          {removeDialogPayload?.acceptButtonText || 'Eliminar'}
        </Button>
      </S.Buttons>
    </S.Container>
  )
}

export default memo(RemoveDialogModal)
